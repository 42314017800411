﻿$(document).ready(function () {
    if ($('div#divNewsletterSignup').length) {
        var theForm = $('#frmNewsletterSignup');
        $("#btnSubmit").click(function () {
            if (theForm.valid()) {
                $('#btnSubmit').hide();
                $(this).hide();
                $(".clsSubmitButton").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');
                theForm.submit();
            }
            else {
                var firstInstance = $('.input-validation-error').first();
                var offsetTop = firstInstance.offset().top - 100;

                $('html, body').animate({
                    scrollTop: offsetTop
                }, 500);
            }
        });
    }
});