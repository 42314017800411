﻿/*
Custom script for validating Username
*/
(function ($) {
    $.validator.addMethod("usernamevalidator", function (value, element, params) {
       try {
            return true;
            //if ( !value ) return false;
            //return $.getJSON("/api/UsernameCheck/CheckUsername/?newUsername=" + value + "&mcid=" + mcid);
        } catch (e) {
            return false;
        }
    });

    $.validator.unobtrusive.adapters.addBool("usernamevalidator");
}(jQuery));