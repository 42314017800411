﻿$(document)
    .ready(function () {
        if ($('div#divAopaContactUsForm').length) {
            var theForm = $('#frmContactUs');

            $(".submit-contact-us").click(function () {
                if (theForm.valid()) {
                    $('#btnSubmit').hide();
                    $('#btnReset').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });
        }
    });