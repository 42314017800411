export default function svgwatcher() {
/* 
 * Directive: svgwatcher
 * Triggers SVG 4 Everybody on newly loaded items
 */
	return function (scope, element, attrs) {
		scope.$watch("items", function () {
			svg4everybody();
		});
	};
}