﻿$(document)
    .ready(function () {
        if ($('div#divAftaStudentAdminForm').length) {
            var theForm = $('#frmAftaStudentAdmin');


            $('#DateOfBirth').datepicker({
                //beforeShowDay: function (date) {
                //    if (date.getDate() === getLastDayOfYearAndMonth(date.getFullYear(), date.getMonth())) {
                //        return [true, ''];
                //    }
                //    return [false, ''];
                //},
                changeMonth: true,
                changeYear: true,
                yearRange: (new Date).getFullYear() - 110 + ':' + (new Date).getFullYear()   
            });

            function getLastDayOfYearAndMonth(year, month) {
                return (new Date((new Date(year, month + 1, 1)) - 1)).getDate();
            }


            //$('#AddTestDemographic').change(function () {
            //    if (this.checked) {
            //        $('#divPaymentInfo').hide();
            //    } else {
            //        $('#divPaymentInfo').show();
            //    }
            //});

            $(".submitButtonAftaStudent").click(function () {
                if (theForm.valid()) {
                    $('#btnEnroll').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });
        }
    });