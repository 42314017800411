﻿$(document)
    .ready(function() {
        if ($('div#divIFlightPlanner').length) {
            var theForm = $('#frmFlightPlanner');

            $(".fp-button")
                .click(function () {
                    $('#FormMode').val($(this).data("mode"));
                    theForm.submit();
                });
        }
    });

