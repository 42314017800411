﻿$(document).ready(function () {
    if ($('div#divAccountPilotInfo').length) {

        // 'edit' section click handler
        $(document).on("click", ".my_account_content a.edit", function (e) {
            e.preventDefault();
            e.stopPropagation();

            var $theButton = $(this);
            var $theSection = $theButton.parents("section");
            var $theForm = $theSection.children("form");

            if ($('#formOpen').val() === "false") {
                if ($theForm[0].id.startsWith("fmPilotInfo")) {
                    editPilotInfo($theForm);
                } else if ($theForm[0].id.startsWith("fmMedical")) {
                    editMedical($theForm);
                } else if ($theForm[0].id.startsWith("fmAircraft")) {
                    editAircraft($theForm);
                }

                // set the section to be editable
                $theSection.addClass("wip");
                $('#formOpen').val($theForm[0].id);
                window.onbeforeunload = confirmOnPageExit;
            } else {
                alert('Another form is already open.\n\nPlease Save or Cancel before proceeding.');
            }
        });


        // 'save' section click handler
        $(document).on("click", ".my_account_content a.save", function (e) {
            e.preventDefault();
            e.stopPropagation();

            var $theButton = $(this);
            var $theSection = $theButton.parents("section");
            var $theForm = $theSection.children("form");

            if ($theForm.valid()) {
                if ($theForm[0].id.startsWith("fmPilotInfo")) {
                    savePilotInfo($theForm, $theSection);
                } else if ($theForm[0].id.startsWith("fmAircraft")) {
                    saveAircraft($theForm, $theSection);
                } else if ($theForm[0].id.startsWith("fmMedical")) {
                    saveMedical($theForm, $theSection);
                }
            }

        });

        // 'cancel' section click handler
        $(document).on("click", ".my_account_content a.cancel", function (e) {
            e.preventDefault();
            e.stopPropagation();

            var $theButton = $(this);
            var $theSection = $theButton.parents("section");
            var $theForm = $theSection.children("form");

            if ($theForm[0].id.startsWith("fmPilotInfo")) {
                cancelPilotInfo($theForm);
            } else if ($theForm[0].id.startsWith("fmAircraft")) {
                cancelAircraft($theForm);
            } else if ($theForm[0].id.startsWith("fmMedical")) {
                cancelMedical($theForm);
            }

            var validator = $theForm.validate();
            validator.hideErrors();
            validator.resetForm();
            validator.reset();

            //reset unobtrusive field level, if it exists
            $theForm.find("[data-valmsg-replace]")
                .removeClass("field-validation-error")
                .addClass("field-validation-valid")
                .empty();

            // set the section to be non-editable
            $theSection.removeClass("wip");

            $('#formOpen').val("false");
            window.onbeforeunload = null;
        });


        // 'delete' section click handler
        $(document).on("click", ".my_account_content a.delete", function (e) {
            e.preventDefault();
            e.stopPropagation();

            var $theButton = $(this);
            var $theSection = $theButton.parents("section");
            var $theForm = $theSection.children("form");

            if ($('#formOpen').val() === "false") {
                if ($theForm[0].id.startsWith("fmAircraft")) {
                    deleteAircraft($theForm, $theSection);
                } else if ($theForm[0].id.startsWith("fmMedical")) {
                    deleteMedical($theForm, $theSection);
                }
            } else {
                alert('Another form is already open.\n\nPlease Save or Cancel before proceeding.');
            }
        });


        // 'add' section click handler
        $("section.add").on("click", function () {
            var $theForm = $(this).children("form");

            if ($('#formOpen').val() === "false") {
                if ($theForm[0].id.startsWith("fmAircraft")) {
                    addAircraft($theForm);
                } else if ($theForm[0].id.startsWith("fmMedical")) {
                    addMedical($theForm);
                }

                $('#formOpen').val($theForm[0].id);
                $(this).addClass("wip").find("input, select").removeAttr("disabled").removeAttr("readonly");
                window.onbeforeunload = confirmOnPageExit;
            } else {
                if ($theForm[0].id !== $('#formOpen').val()) {
                    alert('Another form is already open.\n\nPlease Save or Cancel before proceeding.');
                }
            }
        });


        function resetFields(theForm) {

            theForm.find('input,select').not("input[type='hidden']").each(function () {
                if (this.type === "text" || this.type === "select-one") {
                    var elem = theForm[0].elements['init' + this.id];

                    $("#" + theForm[0].id + " #" + this.id).val($("#" + theForm[0].id + " #" + elem.id).val());
                } else if (this.type === "checkbox") {
                    this.checked = ($('#' + theForm[0].id + ' #init' + this.id).val() === "true");
                }
            });

        }

        function updateFields(theForm) {

            theForm.find('input,select').not("input[type='hidden']").each(function () {
                if (this.type === "text" || this.type === "select-one") {
                    var elem = theForm[0].elements['init' + this.id];

                    $("#" + theForm[0].id + " #" + elem.id).val($("#" + theForm[0].id + " #" + this.id).val());
                } else if (this.type === "checkbox") {
                    $('#' + theForm[0].id + ' #init' + this.id).val(this.checked);
                }
            });

        }

        function updateField(theForm, theField) {
            if (theField.type === "text" || theField.type === "select-one") {
                $("#" + theForm[0].id + " #init" + theField).val($("#" + theForm[0].id + " #" + theField).val());
            } else if (theField.type === "checkbox") {
                $("#" + theForm[0].id + " #init" + theField).val(theField.checked);
            }          
        }


        $('#btnMoveRight').click(function () {
            var result = !$('#selLeft option:selected').remove().prop('selected', false).appendTo('#selRight');

            var rightItems = "";
            $('#selRight option').each(
                function () {
                    rightItems += $(this).text() + '<br>';
                }
            );

            var $theButton = $(this);
            var $theSection = $theButton.parents("section");
            var $theForm = $theSection.children("form");
            $("#" + $theForm[0].id + " #tdRatingsList").html(rightItems);
            return result;
        });


        $('#btnMoveLeft').click(function () {
            var result = !$('#selRight option:selected').remove().prop('selected', false).appendTo('#selLeft');
            var rightItems = "";
            $('#selRight option').each(
                function () {
                    rightItems += $(this).text() + '<br>';
                }
            );
            var $theButton = $(this);
            var $theSection = $theButton.parents("section");
            var $theForm = $theSection.children("form");
            $("#" + $theForm[0].id + " #tdRatingsList").html(rightItems);
            return result;
        });

        function editPilotInfo(theForm) {
            $("#divPilotInfoConfirm").hide();
            $("#divPilotInfoAlert").hide();
            $("#divPilotInfoAlert").html('');

            var currentLeft = "";

            $('#selLeft option').each(
                function() {
                    currentLeft += $(this).val() + '|' + $(this).text() + ';';
                }
            );
            if (currentLeft) {
                currentLeft = currentLeft.slice(0, -1);
            } 
            $("#" + theForm[0].id + " #initRatingsSelLeft").val(currentLeft);

            var currentRight = "";

            $('#selRight option').each(
                function () {
                    currentRight += $(this).val() + '|' + $(this).text() + ';';
                }
            );
            if (currentRight) {
                currentRight = currentRight.slice(0, -1);
            }
            $("#" + theForm[0].id + " #initRatingsSelRight").val(currentRight);

            $("#" + theForm[0].id + " #HighestCertLevel").prop('disabled', false);
            $("#" + theForm[0].id + " #HoursFlownLast12Months").prop('disabled', false);
            $("#" + theForm[0].id + " #selLeft").prop('disabled', false);
            $("#" + theForm[0].id + " #selRight").prop('disabled', false);
        }

        function cancelPilotInfo(theForm) {
            $("#divPilotInfoConfirm").hide();
            $("#divPilotInfoAlert").hide();
            $("#divPilotInfoAlert").html('');
            $("#" + theForm[0].id + " #HighestCertLevel").prop('disabled', true);
            $("#" + theForm[0].id + " #HoursFlownLast12Months").prop('disabled', true);
            $("#" + theForm[0].id + " #selLeft").prop('disabled', true);
            $("#" + theForm[0].id + " #selRight").prop('disabled', true);

            resetFields(theForm);

            $('#selLeft').empty();
            var leftItems = $("#" + theForm[0].id + " #initRatingsSelLeft").val().split(';');

            $.each(leftItems, function(index, value){
                var item = value.split('|');
                $('#selLeft').append('<option value="' + item[0] + '">' + item[1] + '</option>');
            });

            var oldRight = "";
            $('#selRight').empty();
            var rightItems = $("#" + theForm[0].id + " #initRatingsSelRight").val().split(';');
            $.each(rightItems, function (index, value) {
                if (value) {
                    var item = value.split('|');
                    $('#selRight').append('<option value="' + item[0] + '">' + item[1] + '</option>');
                    oldRight += item[1] + '<br>';
                }
            });
            $("#" + theForm[0].id + " #tdRatingsList").html(oldRight);
        }

        function savePilotInfo(theForm, theSection) {
            $("#divPilotInfoConfirm").hide();
            $("#divPilotInfoAlert").hide();
            $("#divPilotInfoAlert").html('');

            theSection.addClass("account-spinner");

            var currentRightVal = "";
            var currentRightText = "";
            $('#selRight option').each(
                function () {
                    currentRightVal += $(this).val() + '|';
                    currentRightText += $(this).text() + '<br>';
                }
            );
            if (currentRightVal) {
                currentRightVal = currentRightVal.slice(0, -1);
            }

            $.post('/api/PilotInformation/PilotInfoUpdate',
                {
                    "FormData": $("#FormData").val(),
                    "HomeAirport": $("#" + theForm[0].id + " #HomeAirport").val(),
                    "HighestCertLevel": $("#" + theForm[0].id + " #HighestCertLevel").val(),
                    "HoursFlownLast12": $("#" + theForm[0].id + " #HoursFlownLast12Months").val(),
                    "Cerfifications": currentRightVal
                },
                function () { }
            )
                .done(function (data) {
                    if (data.homeAiportSuccess && data.pilotInfoSuccess) {
                        $("#divPilotInfoConfirm").show();

                        updateFields(theForm);

                        theSection.removeClass("wip");

                        $('#formOpen').val("false");

                        $("#" + theForm[0].id + " #HighestCertLevel").prop('disabled', true);
                        $("#" + theForm[0].id + " #HoursFlownLast12Months").prop('disabled', true);
                        $("#" + theForm[0].id + " #selLeft").prop('disabled', true);
                        $("#" + theForm[0].id + " #selRight").prop('disabled', true);
                        $("#" + theForm[0].id + " #tdRatingsList").html(currentRightText);

                        window.onbeforeunload = null;
                    } else {
                        var theError = 'The following items ( ';
                        var hadError = false;

                        if (!data.homeAiportSuccess) {
                            hadError = true;
                            theError += 'Home Airport';
                        } else {
                            updateField(theForm, $("#" + theForm[0].id + " #HomeAirport"));
                        }
                        if (!data.pilotInfoSuccess) {
                            if (hadError) theError += ", ";
                            hadError = true;
                            theError += 'Highest Cert Level, Hours Flown, Certificates and Ratings';
                        } else {
                            updateField(theForm, $("#" + theForm[0].id + " #HighestCertLevel"));
                            updateField(theForm, $("#" + theForm[0].id + " #HoursFlownLast12Months"));
                        }

                        theError += " ) did not save.";

                        $("#divPilotInfoAlert").html(theError);

                        $("#divPilotInfoAlert").show();
                    }
                    theSection.removeClass("account-spinner");
                })
                .fail(function (jqXHR, textStatus, err) {
                    $("#divPilotInfoAlert").html('There was an error saving your pilot information.');
                    $("#divPilotInfoAlert").show();
                    theSection.removeClass("account-spinner");
                });
        }

        function addAircraft(theForm) {
            $("#divAircraftConfirm").hide();
            $("#divAircraftConfirm").html('');
            $("#divAircraftAlert").hide();
            $("#divAircraftAlert").html('');
            resetFields(theForm);
        }

        function editAircraft(theForm) {
            $("#divAircraftConfirm").hide();
            $("#divAircraftConfirm").html('');
            $("#divAircraftAlert").hide();
            $("#divAircraftAlert").html('');
        }

        function cancelAircraft(theForm) {
            $("#divAircraftConfirm").hide();
            $("#divAircraftConfirm").html('');
            $("#divAircraftAlert").hide();
            $("#divAircraftAlert").html('');
            resetFields(theForm);
        }

        function saveAircraft(theForm, theSection) {
            $("#divAircraftConfirm").hide();
            $("#divAircraftConfirm").html('');
            $("#divAircraftAlert").hide();
            $("#divAircraftAlert").html('');

            theSection.addClass("account-spinner");
            $.post('/api/CustomerAircraft/ProcessAircraft',
                {
                    "FormData": $("#FormData").val(),
                    "Action": (theForm[0].id === "fmAircraft" ? "A" : "U"),
                    "NNumber": $("#" + theForm[0].id + " #NNumber").val(),
                    "SerialNumber": $("#" + theForm[0].id + " #SerialNumber").val(),
                    "InsuranceCarrier": $("#" + theForm[0].id + " #InsuranceCarrier").val(),
                    "InsuranceExpireDate": $("#" + theForm[0].id + " #InsuranceExpireDate").val(),
                },
                function () { }
            )
                .done(function (data) {
                    if (data.aircraftSuccess) {
                        //
                        // Handle Add
                        //
                        if (theForm[0].id === "fmAircraft") {
                            // clone the section
                            var newAircraft = theSection.clone()[0];

                            // fix id and classes
                            newAircraft.id = '';
                            newAircraft.classList.remove('add');
                            newAircraft.classList.remove('aircraft');
                            newAircraft.classList.remove('wip');
                            newAircraft.classList.remove('account-spinner');
                            newAircraft.removeChild(newAircraft.getElementsByTagName("h2")[0]);
                            newAircraft.getElementsByTagName("form")[0].id += $("#" + theForm[0].id + " #AircraftCount").val();
                            var newFormId = newAircraft.getElementsByTagName("form")[0].id;

                            // add the delete button
                            var del = document.createElement("a");
                            del.classList.add("button");
                            del.classList.add("bg--trash");
                            del.classList.add("delete");
                            var delText = document.createTextNode("DELETE");
                            del.appendChild(delText);
                            newAircraft.getElementsByTagName("footer")[0].appendChild(del);

                            // add the cloned section to the document
                            theSection.before(newAircraft);

                            // update the init value fields for cancel functionality
                            $('#' + newFormId + ' #initNNumber').val($('#' + newFormId + ' #NNumber').val());
                            $('#' + newFormId + ' #initSerialNumber').val($('#' + newFormId + ' #SerialNumber').val());
                            $('#' + newFormId + ' #initInsuranceCarrier').val($('#' + newFormId + ' #InsuranceCarrier').val());
                            $('#' + newFormId + ' #initInsuranceExpireDate').val($('#' + newFormId + ' #InsuranceExpireDate').val());

                            // Fix the NNumber table cell
                            var node = $('#' + newFormId + ' #NNumber').parent("td");
                            $('#' + newFormId + ' #NNumber')[0].type = "hidden";
                            node[0].appendChild(document.createTextNode($('#' + newFormId + ' #NNumber').val()));

                            // Update the aircraft counter
                            $("#" + theForm[0].id + " #AircraftCount").val(parseInt($("#" + theForm[0].id + " #AircraftCount").val()) + 1);

                            // reparse the validation
                            $.validator.unobtrusive.parse($('#' + newFormId));

                            // reset the add form fields
                            resetFields(theForm);

                            // exit editing
                            $('#secAircraftAdd').removeClass("wip");
                        } else {
                            // exit editing
                            theSection[0].classList.remove('wip');

                            // update the init value fields for cancel functionality
                            updateFields(theForm);
                        }


                        // confirm success
                        $("#divAircraftConfirm").html("Aircraft saved successfully.");
                        $("#divAircraftConfirm").show();


                        // take care of form open
                        $('#formOpen').val("false");
                        window.onbeforeunload = null;
                    } else {
                        $("#divAircraftAlert").html("Aircraft was not added.");
                        $("#divAircraftAlert").show();
                    }
                    theSection.removeClass("account-spinner");
                })
                .fail(function (jqXHR, textStatus, err) {
                    $("#divAircraftAlert").html('There was an error trying to add the aircraft.');
                    $("#divAircraftAlert").show();
                    theSection.removeClass("account-spinner");
                });
        }

        function deleteAircraft(theForm, theSection) {
            $("#divAircraftConfirm").hide();
            $("#divAircraftConfirm").html('');
            $("#divAircraftAlert").hide();
            $("#divAircraftAlert").html('');

            theSection.addClass("account-spinner");
            $.post('/api/CustomerAircraft/ProcessAircraft',
                {
                    "FormData": $("#FormData").val(),
                    "Action": "D",
                    "NNumber": $("#" + theForm[0].id + " #NNumber").val()
                },
                function () { }
            )
                .done(function (data) {
                    if (data.aircraftSuccess) {
                        $("#divAircraftConfirm").html("Aircraft deleted successfully.");
                        $("#divAircraftConfirm").show();

                        theSection.removeClass("account-spinner");
                        theSection.remove();

                        $('#formOpen').val("false");
                        window.onbeforeunload = null;
                    } else {
                        $("#divAircraftAlert").html("Aircraft was not deleted.");
                        $("#divAircraftAlert").show();
                        theSection.removeClass("account-spinner");
                    }
                })
                .fail(function (jqXHR, textStatus, err) {
                    $("#divAircraftAlert").html('There was an error trying to delete the aircraft.');
                    $("#divAircraftAlert").show();
                    theSection.removeClass("account-spinner");
                });
        }

        function addMedical(theForm) {
            $("#divMedicalConfirm").hide();
            $("#divMedicalConfirm").html('');
            $("#divMedicalAlert").hide();
            $("#divMedicalAlert").html('');
            resetFields(theForm);
        }

        function editMedical(theForm) {
            $("#divMedicalConfirm").hide();
            $("#divMedicalConfirm").html('');
            $("#divMedicalAlert").hide();
            $("#divMedicalAlert").html('');
            $("#" + theForm[0].id + " #SelectedMedicalCode").prop('disabled', false);

        }

        function cancelMedical(theForm) {
            $("#divMedicalConfirm").hide();
            $("#divMedicalConfirm").html('');
            $("#divMedicalAlert").hide();
            $("#divMedicalAlert").html('');
            $("#" + theForm[0].id + " #SelectedMedicalCode").prop('disabled', true);
            resetFields(theForm);
        }

        function saveMedical(theForm, theSection) {
            $("#divMedicalConfirm").hide();
            $("#divMedicalConfirm").html('');
            $("#divMedicalAlert").hide();
            $("#divMedicalAlert").html('');

            theSection.addClass("account-spinner");
            $.post('/api/CustomerMedical/ProcessMedical',
                {
                    "FormData": $("#FormData").val(),
                    "Action": (theForm[0].id === "fmMedical" ? "A" : "U"),
                    "Id": $("#" + theForm[0].id + " #MedicalId").val(),
                    "MedicalClassCode": $("#" + theForm[0].id + " #SelectedMedicalCode").val(),
                    "BirthDate": $("#" + theForm[0].id + " #BirthDate").val(),
                    "IssuanceDate": $("#" + theForm[0].id + " #IssuanceDate").val(),
                    "UserExpireDate": $("#" + theForm[0].id + " #UserExpireDate").val(),
                    "ExpirationReminder": $("#" + theForm[0].id + " #ExpirationReminder")[0].checked
                },
                function () { }
            )
                .done(function (data) {
                    if (data.medicalSuccess) {
                        //
                        // Handle Add
                        //
                        if (theForm[0].id === "fmMedical") {
                            // clone the section
                            var newMedical = theSection.clone()[0];

                            // fix id and classes
                            newMedical.id = '';
                            newMedical.classList.remove('add');
                            newMedical.classList.remove('wip');
                            newMedical.classList.remove('account-spinner');
                            newMedical.removeChild(newMedical.getElementsByTagName("h2")[0]);
                            newMedical.getElementsByTagName("form")[0].id += $("#" + theForm[0].id + " #MedicalCount").val();
                            var newFormId = newMedical.getElementsByTagName("form")[0].id;

                            // add the delete button
                            var del = document.createElement("a");
                            del.classList.add("button");
                            del.classList.add("bg--trash");
                            del.classList.add("delete");
                            var delText = document.createTextNode("DELETE");
                            del.appendChild(delText);
                            newMedical.getElementsByTagName("footer")[0].appendChild(del);

                            var calc = newMedical.querySelector('#spCalcExp');
                            calc.innerHTML = 'Calculated ' + data.calculateExpireDate;

                            // add the cloned section to the document
                            theSection.before(newMedical);

                            $('#' + newFormId + ' #SelectedMedicalCode').val($("#" + theForm[0].id + " #SelectedMedicalCode").val());


                            // update the init value fields for cancel functionality
                            $('#' + newFormId + ' #initSelectedMedicalCode').val($('#' + newFormId + ' #SelectedMedicalCode').val());
                            $('#' + newFormId + ' #initBirthDate').val($('#' + newFormId + ' #BirthDate').val());
                            $('#' + newFormId + ' #initIssuanceDate').val($('#' + newFormId + ' #IssuanceDate').val());
                            $('#' + newFormId + ' #initUserExpireDate').val($('#' + newFormId + ' #UserExpireDate').val());
                            $('#' + newFormId + ' #initExpirationReminder').val($('#' + newFormId + ' #ExpirationReminder')[0].checked);
                            $('#' + newFormId + ' #MedicalId').val(data.id);




                            // Update the medical counter
                            $("#" + theForm[0].id + " #MedicalCount").val(parseInt($("#" + theForm[0].id + " #MedicalCount").val()) + 1);

                            // reparse the validation
                            $.validator.unobtrusive.parse($('#' + newFormId));

                            // reset the add form fields
                            resetFields(theForm);

                            $("#" + newFormId + " #SelectedMedicalCode").prop('disabled', true);

                            // exit editing
                            $('#secMedicalAdd').removeClass("wip");
                        } else {
                            // exit editing
                            theSection[0].classList.remove('wip');

                            var calc = theSection[0].querySelector('#spCalcExp');
                            calc.innerHTML = 'Calculated ' + data.calculateExpireDate;


                            // update the init value fields for cancel functionality
                            updateFields(theForm);

                            $("#" + theForm[0].id + " #SelectedMedicalCode").prop('disabled', true);
                        }


                        // confirm success
                        $("#divMedicalConfirm").html("Medical saved successfully.");
                        $("#divMedicalConfirm").show();


                        // take care of form open
                        $('#formOpen').val("false");
                        window.onbeforeunload = null;
                    } else {
                        $("#divMedicalAlert").html("Medical was not added.");
                        $("#divMedicalAlert").show();
                    }
                    theSection.removeClass("account-spinner");
                })
                .fail(function (jqXHR, textStatus, err) {
                    $("#divMedicalAlert").html('There was an error trying to add the medical.');
                    $("#divMedicalAlert").show();
                    theSection.removeClass("account-spinner");
                });
        }

        function deleteMedical(theForm, theSection) {
            $("#divMedicalConfirm").hide();
            $("#divMedicalConfirm").html('');
            $("#divMedicalAlert").hide();
            $("#divMedicalAlert").html('');

            theSection.addClass("account-spinner");
            $.post('/api/CustomerMedical/ProcessMedical',
                    {
                        "FormData": $("#FormData").val(),
                        "Action": "D",
                        "Id": $("#" + theForm[0].id + " #MedicalId").val()
                    },
                    function () { }
                )
                .done(function (data) {
                    if (data.medicalSuccess) {
                        $("#divMedicalConfirm").html("Medical deleted successfully.");
                        $("#divMedicalConfirm").show();

                        theSection.removeClass("account-spinner");
                        theSection.remove();

                        $('#formOpen').val("false");
                        window.onbeforeunload = null;
                    } else {
                        $("#divMedicalAlert").html("Medical was not deleted.");
                        $("#divMedicalAlert").show();
                        theSection.removeClass("account-spinner");
                    }
                })
                .fail(function (jqXHR, textStatus, err) {
                    $("#divMedicalAlert").html('There was an error trying to delete the medical.');
                    $("#divMedicalAlert").show();
                    theSection.removeClass("account-spinner");
                });
        }

        /*
        $('.medBDt').on('change', function () {
            var $theSection = $(this).parents("section");
            var $theForm = $(this).parents("form");
            setDate($theSection, $theForm);
        });

        $('.medIDt').on('change', function () {
            var $theSection = $(this).parents("section");
            var $theForm = $(this).parents("form");
            setDate($theSection, $theForm);
        });

        $('.medMSel').on('change', function () {
            var $theSection = $(this).parents("section");
            var $theForm = $(this).parents("form");
            setDate($theSection, $theForm);
        });
        */

        $(document).on('change', '.medBDt', function () {
            var $theSection = $(this).parents("section");
            var $theForm = $(this).parents("form");
            setDate($theSection, $theForm);
        });

        $(document).on('change', '.medIDt', function () {
            var $theSection = $(this).parents("section");
            var $theForm = $(this).parents("form");
            setDate($theSection, $theForm);
        });

        $(document).on('change', '.medMSel', function () {
            var $theSection = $(this).parents("section");
            var $theForm = $(this).parents("form");
            setDate($theSection, $theForm);
        });



        function setDate(theSection, theForm) {
            var bDate = Date.parse($('#' + theForm[0].id + ' #BirthDate').val());
            var iDate = Date.parse($('#' + theForm[0].id + ' #IssuanceDate').val());
            var code = $('#' + theForm[0].id + ' #SelectedMedicalCode').val();

            if (code !== "") {

                if (!isNaN(iDate)) {

                    var bD;
                    if (!isNaN(bDate)) {
                        bD = $('#' + theForm[0].id + ' #BirthDate').val();
                    } else {
                        bD = "1/1/0001";
                    }

                    $.getJSON("/api/MedicalExpire/Get/?medicalType=" + code + "&birthDate=" + bD + "&issueDate=" + $('#' + theForm[0].id + ' #IssuanceDate').val())
                        .done(function (data) {
                            var calc = theSection[0].querySelector('#spCalcExp');
                            calc.innerHTML = 'Calculated ' + data;
                        })
                        .fail(function (jqXHR, textStatus, err) {
                            var calc = theSection[0].querySelector('#spCalcExp');
                            calc.innerHTML = 'Calculated --/--/----';
                        });

                } else {
                    var calc = theSection[0].querySelector('#spCalcExp');
                    calc.innerHTML = 'Calculated --/--/----';
                }
            } else {
                var calc = theSection[0].querySelector('#spCalcExp');
                calc.innerHTML = 'Calculated --/--/----';
            }
        }




        var confirmOnPageExit = function (e) {
            // If we haven't been passed the event get the window.event
            e = e || window.event;

            var message = 'A form is open. Please Save or Cancel before proceeding.';

            // For IE6-8 and Firefox prior to version 4
            if (e) {
                e.returnValue = message;
            }

            // For Chrome, Safari, IE8+ and Opera 12+
            return message;
        };


    }
});