export default function showgrid() {
	/* 
	 * Directive: showgrid
	 * Class changes for grid view
	 */
	return {
		restrict: 'A',
		link : function (scope, element) {
			$(function(){
				element.click(function() {
					$(".js-display-list").removeClass("is-active");
					$(this).addClass("is-active");
					$(this).parents(".item-list__results").removeClass("is-list");
				});
			});
		}
	}
}