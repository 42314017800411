﻿$(document).ready(function() {
    if ($("div#divAOPAMedicalInformationUpdate").length) {

        $('#BirthDate').datepicker({
            changeMonth: true,
            changeYear: true
        });
        $('#IssuanceDate').datepicker({
            changeMonth: true,
            changeYear: true
        });
        $('#UserExpireDate').datepicker({
            changeMonth: true,
            changeYear: true
        });

        $('#BirthDate').change(function () {
            setDate();
        });

        $('#IssuanceDate').change(function () {
            setDate();
        });

        $('#SelectedMedicalCode').change(function () {
            setDate();
        });

        function setDate() {
            var mt = $('#SelectedMedicalCode').find('option:selected').val();

            if (mt !== "") {
                var issDt = Date.parse($("#IssuanceDate").val());

                if (!isNaN(issDt)) {
                    var birDt = Date.parse($("#BirthDate").val());

                    var bD;
                    if (!isNaN(birDt)) {
                        bD = $("#BirthDate").val();
                    } else {
                        bD = "1/1/0001";
                    }

                    $.getJSON("/api/MedicalExpire/Get/?medicalType=" + mt + "&birthDate=" + bD + "&issueDate=" + $("#IssuanceDate").val())
                        .done(function (data) {
                            $("#spCalcExpire").text(data);
                        })
                        .fail(function (jqXHR, textStatus, err) {
                            $("#spCalcExpire").text("--/--/----");
                        });

                } else {
                    $("#spCalcExpire").text("--/--/----");
                }
            } else {
                $("#spCalcExpire").text("--/--/----");
            }
        }
    }
});
