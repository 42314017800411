﻿$(document).ready(function () {
    if ($('div#divCreditCardOfRecordUpdate').length) {

        var ccorStripe = Stripe($('#sapik').val());

        var ccorElements = ccorStripe.elements();
        var ccorStyle = {
            base: {
                backgroundColor: '#ffffff',
                fontSize: '0.9375rem',
                fontFamily: 'Roboto, Helvetica Neue, sans-serif',
                lineHeight: '3.5rem',
                ':focus': {
                    border: '10px solid black'
                }
            }
        };
        var ccorCardNumberElement =ccorElements.create("cardNumber", { style: ccorStyle, showIcon: true, placeholder: 'Card Number', disableLink: true });
        var ccorCardExpiryElement = ccorElements.create("cardExpiry", { style: ccorStyle });
        var ccorCardCvcElement = ccorElements.create("cardCvc", { style: ccorStyle });
        ccorCardNumberElement.mount("#card-number");
        ccorCardExpiryElement.mount("#card-expiry");
        ccorCardCvcElement.mount("#card-cvc");

        ccorCardNumberElement.on('change',
            function(event) {
                var displayError = document.getElementById('card-number-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }

                $('#CardBrand').val(event.brand);

                if ((event.brand !== 'visa' &&
                    event.brand !== 'mastercard' &&
                    event.brand !== 'amex' &&
                    event.brand !== 'discover')) {
                    if ($('#card-errors').is(':empty')) {
                        $('#card-errors').html('Please check your credit card information.');
                    }
                } else {
                    $('#card-errors').html('');
                }
            });

        ccorCardExpiryElement.on('change',
            function (event) {
                var displayError = document.getElementById('card-expiry-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
            });

        ccorCardCvcElement.on('change',
            function (event) {
                var displayError = document.getElementById('card-cvc-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
            });

        var checkCardFields = () => {
            var isValid = false;

            var cardElement = document.querySelector('#card-number');
            var expiryElement = document.querySelector('#card-expiry');
            var cvcElement = document.querySelector('#card-cvc');

            isValid = cardElement.classList.contains('StripeElement--complete') &&
                expiryElement.classList.contains('StripeElement--complete') &&
                cvcElement.classList.contains('StripeElement--complete');

            var brand = $('#CardBrand').val();

            if (isValid &&
                (brand === 'visa' || brand === 'mastercard' || brand === 'amex' || brand === 'discover')) {
                $('#card-errors').html('');
            } else {
                if ((brand !== 'visa' && brand !== 'mastercard' && brand !== 'amex' && brand !== 'discover') || !isValid) {
                    isValid = false;
                    if ($('#card-errors').is(':empty')) {
                        $('#card-errors').html('Please check your credit card information.');
                    }
                }
            }

            return isValid;
        }

        $(".submitButtonCCOR").click(function () {
            var theForm = $(this).closest('form');

            var ccValid = checkCardFields();

            if (theForm.valid() && ccValid) {
                $('#btnUpdate').hide();
                $(this).hide();
                $(".clsSubmitButton").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');


                ccorStripe.createToken(ccorCardNumberElement, { name: $("#CardName").val() })
                    .then(function (result) {
                        if (result.token !== null) {
                            $("#CardToken").val(JSON.stringify(result.token));
                            theForm.submit();
                        } else {
                            if ($('#card-errors').is(':empty')) {
                                $('#card-errors').html('Please check your credit card information.');
                            }
                            var spinner = document.getElementById('aopaSpinner');
                            spinner.parentElement.removeChild(spinner);
                            $(this).show();
                            $('#btnUpdate').show();
                        }
                    });
            }
        });

    }
});
