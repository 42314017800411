﻿$(document).ready(function () {
    if ($('div#divAOPAPPSUpgrade').length) {
        $(".joinButton").click(function () {
            var form = $(this).closest('form');
            if (form.valid()) {
                $(this).hide();
                $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
            }
        });


        $('input[name=SelectedPaymentOption]').on('change', function () {
            if ($('input[name=SelectedPaymentOption]:checked').val() === "UseCardOnFile") {
                $("#divPayment").css({ "visibility": "hidden" });
                $("#divPayment").css({ "display": "none" });
                $("#SelectedCreditCardType").val("VISA");
                $("#SelectedCreditCardExpMonth").val("12");
                var d = new Date();
                $("#SelectedCreditCardExpYear").val(d.getFullYear() + 5);
                $("#CcNumber").val("4111111111111111");
                $("#Cvv").val("123");
                $("#NameOnCard").val("xxxx");
            } else {
                $("#SelectedCreditCardType").val("");
                $("#SelectedCreditCardExpMonth").val("");
                $("#SelectedCreditCardExpYear").val("");
                $("#CcNumber").val("");
                $("#Cvv").val("");
                $("#NameOnCard").val("");
                $("#divPayment").css({ "visibility": "visible" });
                $("#divPayment").css({ "display": "block" });
            }
        });
    }
});
