﻿$(document)
    .ready(function () {
        if ($('div#divAftaStudentPurchaseForm').length) {
            var theForm = $('#frmAftaStudentPurchase');


            $(".afta-purchase-button").click(function () {
                $('#MembershipTerm').val($(this).data('term'));

                switch ($(this).data('term')) {
                    case "ANNUAL":
                        $('#btnAnnual').removeClass('afta-purchase-button__inactive');
                        $('#btnMonthly').addClass('afta-purchase-button__inactive');
                        break;
                    case "MONTHLY":
                        $('#btnAnnual').addClass('afta-purchase-button__inactive');
                        $('#btnMonthly').removeClass('afta-purchase-button__inactive');
                        break;
                }
                $('input[type="checkbox"][id="IncludeAar"]').prop("checked", false).change();

                loadProductOptions();
                loadMagazineOptions();
                setSummaryTotal(true);
                setAAR();
            });

            $('#SelectedMembershipOption').change(function () {
                loadMagazineOptions();
                setSummaryTotal(true);
                setAAR();
            });

            $('#SelectedMagazineOption').change(function () {
                setSummaryTotal(false);
                setAAR();
            });

            function loadProductOptions() {
                var products = JSON.parse($('#MembershipOptions').val());

                var selected = $('#SelectedMembershipOption').val();

                $('#SelectedMembershipOption').empty();
                $.each(products.membershipOptions, function () {
                    var option = document.createElement("option");

                    option.value = this.product;
                    if ($('#MembershipTerm').val() === "ANNUAL") {
                        option.text = this.annualDescription;
                    } else {
                        option.text = this.monthlyDescription;
                    }
                    if (option.value === selected) {
                        option.selected = true;
                    }

                    $('#SelectedMembershipOption').append(option);
                });
            }

            function loadMagazineOptions() {
                var productData = JSON.parse($('#ProductOptions').val());

                var selectedProduct = $('#SelectedMembershipOption').val();
                if ($('#MembershipTerm').val() === "MONTHLY") {
                    selectedProduct = 'MO_' + selectedProduct;
                }

                var prd = productData.find(o => o.productCode === selectedProduct);
                var rateData = prd.rateCodes;

                if ($('#IsDomestic').val() === "True") {
                    rateData = prd.rateCodes.filter(o => o.isDomestic);
                }

                var selected = $('#SelectedMagazineOption').val();
                var found = false;
                $('#SelectedMagazineOption').empty();
                $.each(rateData, function () {
                    var option = document.createElement("option");

                    option.value = this.rateCode;
                    option.text = this.dropDownText;
                    if (option.value === selected) {
                        option.selected = true;
                        found = true;
                    }
                    $('#SelectedMagazineOption').append(option);
                });

                if (!found) {
                    $("#SelectedMagazineOption").val($("#SelectedMagazineOption option:first").val());
                }
            }


            function setSummaryTotal(updateAar) {
                var products = JSON.parse($('#MembershipOptions').val());
                var productData = JSON.parse($('#ProductOptions').val());
                var selectedProduct = $('#SelectedMembershipOption').val();
                if ($('#MembershipTerm').val() === "MONTHLY") {
                    selectedProduct = 'MO_' + selectedProduct;
                }
                var prd = productData.find(o => o.productCode === selectedProduct);
                var rt = prd.rateCodes.find(o => o.rateCode === $('#SelectedMagazineOption').val());

                $('#afta-purchase-data_summary_membership').html(prd.summaryText);
                $('#afta-purchase-data_summary_membership_price').html('$' + parseFloat(rt.price).toFixed(2));
                $('#afta-purchase-data_summary_mag').html(rt.summaryDescription);

                if (updateAar) {
                    if ($('#MembershipTerm').val() === "ANNUAL") {
                        $('.aopa-annual-membership-price').html('$' + parseFloat(rt.price).toFixed(2));
                        $('.aopa-monthly-membership-price').html('--.--');
                        $('input[type="checkbox"][id="AARAgree"]').prop("checked", false).change();
                        $('input[type="checkbox"][id="IncludeAar"]').prop("checked", false).change();
                        $('#divAarAftaAndMonthly').hide();
                        $('#divAarAftaOnly').show();
                    } else {
                        $('.aopa-annual-membership-price').html('--.--');
                        $('.aopa-monthly-membership-price').html('$' + parseFloat(rt.price).toFixed(2));
                        $('input[type="checkbox"][id="AARAgree"]').prop("checked", false).change();
                        $('input[type="checkbox"][id="IncludeAar"]').prop("checked", false).change();
                        $('#divAarAftaAndMonthly').show();
                        $('#divAarAftaOnly').hide();
                    }
                }

                var total = 0;
                total += parseFloat($('#AftaPrice').val());
                total += rt.price;
                if ($('#MembershipTerm').val() === "ANNUAL" && $('#DiscountAmount').val() > 0) {
                    total -= $('#DiscountAmount').val();
                }
                $('#OrderTotal').val(total)
                $('#afta-purchase-data_summary_total').html('$' + parseFloat(total).toFixed(2));

                var annualProd = productData.find(o => o.productCode === $('#SelectedMembershipOption').val());
                var annRate = annualProd.rateCodes.find(o => o.rateCode === $('#SelectedMagazineOption').val());

                if ($('#DiscountAmount').val() > 0) {
                    $('#btnAnnual').html('<span class="joinv4-form-data__strikethrough">$' + parseFloat(annRate.price) + '</span> $' + parseFloat(annRate.price - $('#DiscountAmount').val()) + ' Per Year');
                } else {
                    $('#btnAnnual').text('$' + parseFloat(annRate.price) + ' Per Year');
                }

                if ($('#IsDomestic').val() === "True") {
                    var monthlyProd = productData.find(o => o.productCode === 'MO_' + $('#SelectedMembershipOption').val());
                    var mthRate = monthlyProd.rateCodes.find(o => o.rateCode === $('#SelectedMagazineOption').val());
                    $('#btnMonthly').text('$' + parseFloat(mthRate.price).toFixed(2) + ' Per Month');
                }
            }

            function setAAR() {
                if ($('#MembershipTerm').val() === "ANNUAL") {
                    $('#divAAROptional').show();

                    if ($('#IncludeAar').is(":checked")) {
                        $('#afta-purchase-data_summary_aar').html("YES");
                    } else {
                        $('#afta-purchase-data_summary_aar').html("NO");
                    }

                } else {
                    $('#divAAROptional').hide();

                    $('#afta-purchase-data_summary_aar').html("YES");
                }
            }

            $('#IncludeAar').change(function () {
                if (this.checked) {
                    $('#afta-purchase-data_summary_aar').html("YES");
                } else {
                    $('#afta-purchase-data_summary_aar').html("NO");
                }
            });


            $('input[type=radio][name=PaymentOptionSelected]').change(function () {
                if (this.value == 'USECARDONFILE') {
                    $('#divCardFields').hide();
                }
                else {
                    $('#divCardFields').show();
                }
            });


            $('#AARAgree').change(function () {
                if (this.checked && $("#TermsOfService").val() === "ACCEPTED") {
                    $('#btnEnroll').removeClass('afta-disable-button');
                    $('#btnEnroll').prop('disabled', false);

                } else {
                    $('#btnEnroll').addClass('afta-disable-button');
                    $('#btnEnroll').prop('disabled', true);
                }
            });


            $(".submitbuttonaftapurchase").click(function () {
                if (theForm.valid()) {
                    $('#btnEnroll').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });
        }
    });