﻿$(document)
    .ready(function() {
        if ($('div#divIslandGuidesForm').length) {
            var theForm = $('#frmIslandGuides');


            var igdStripe = Stripe($('#sapik').val());

            var igdElements = igdStripe.elements();
            var igdStyle = {
                base: {
                    backgroundColor: '#efefef',
                    fontSize: '0.9375rem',
                    fontFamily: 'Roboto, Helvetica Neue, sans-serif',
                    lineHeight: '42px',
                    ':focus': {
                        border: '10px solid black'
                    }
                }
            };
            var igdCardElement = igdElements.create("card", { style: igdStyle, hidePostalCode: true, disableLink: true });
            igdCardElement.mount("#card-element");


            igdCardElement.on('change',
                function (event) {
                    var displayError = document.getElementById('card-errors');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }

                    $('#CardBrand').val(event.brand);
                });


            var checkCardFields = () => {
                var isValid = false;

                var cardElement = document.querySelector('#card-element');

                isValid = cardElement.classList.contains('StripeElement--complete');

                var brand = $('#CardBrand').val();

                if (isValid &&
                    (brand === 'visa' || brand === 'mastercard' || brand === 'amex' || brand === 'discover')) {
                    $('#card-errors').html('');
                } else {
                    if ($('#card-errors').is(':empty')) {
                        $('#card-errors').html('Please check your credit card information.');
                    }
                }

                return isValid;
            }
            var igAutocomplete;

            var initAutocomplete = () => {
                // Create the autocomplete object, restricting the search predictions to
                igAutocomplete = new google.maps.places.Autocomplete(AddressLookup,
                    {
                        fields: ["address_components"],
                        types: ["address"],
                    });
                // When the user selects an address from the drop-down, populate the
                // address fields in the form.
                igAutocomplete.addListener("place_changed", fillInAddress);
            };

            initAutocomplete();

            function fillInAddress() {
                // Get the place details from the autocomplete object.
                var place = igAutocomplete.getPlace();
                var address1 = "";
                var postcode = "";

                $("#MailingStreet").val("");
                $("#MailingCity").val("");
                $("#MailingStateProvince").val("");
                $("#MailingPostalCode").val("");
                $("#MailingCountry").val("");

                // Get each component of the address from the place details,
                // and then fill-in the corresponding field on the form.
                // place.address_components are google.maps.GeocoderAddressComponent objects
                // which are documented at http://goo.gle/3l5i5Mr
                place.address_components.forEach((component) => {
                    var componentType = component.types[0];

                    switch (componentType) {
                        case "street_number":
                            {
                                address1 = `${component.long_name} ${address1}`;
                                break;
                            }

                        case "route":
                            {
                                address1 += component.short_name;
                                break;
                            }

                        case "postal_code":
                            {
                                postcode = `${component.long_name}${postcode}`;
                                break;
                            }

                        case "postal_code_suffix":
                            {
                                postcode = `${postcode}-${component.long_name}`;
                                break;
                            }
                        case "locality":
                            $("#MailingCity").val(component.long_name);
                            clearValidation("MailingCity");
                            break;
                        case "administrative_area_level_1":
                            {
                                $("#MailingStateProvince").val(component.short_name);
                                clearValidation("MailingStateProvince");
                                break;
                            }
                        case "country":
                            $("#MailingCountry").val(component.long_name);
                            if (component.short_name === "US") {
                                $("#rdoMailingCountryUS").prop("checked", true);
                                $("#divMailingCountry").hide();
                                $('#IsDomestic').val("True");
                                $('#IsInternational').val("False");
                            } else {
                                $("#rdoMailingCountryOther").prop("checked", true);
                                $("#divMailingCountry").show();
                                $('#IsDomestic').val("False");
                                $('#IsInternational').val("True");
                            }
                            clearValidation("MailingCountry");
                            break;

                    }
                });

                $("#MailingStreet").val(address1);
                clearValidation("MailingStreet");
                $("#MailingPostalCode").val(postcode);
                clearValidation("MailingPostalCode");
                $("#MailingStreet").focus();
            };

            var clearValidation = (field) => {
                var theField = $('#' + field);
                if (theField.val()) {
                    theField.removeClass("input-validation-error").addClass("valid");
                    $("span[data-valmsg-for='" + field + "']").removeClass("field-validation-error").addClass("field-validation-valid");
                    $("span[data-valmsg-for='" + field + "']").empty();
                }
            };

            $("input[type=radio][name=rdoMailingCountry]").change(function () {
                if ($('input[name="rdoMailingCountry"]:checked').val() === "US") {
                    $('#IsDomestic').val("True");
                    $('#IsInternational').val("False");
                    $("#divMailingCountry").hide();
                    $('#MailingCountry').val("United States");
                } else {
                    $('#IsDomestic').val("False");
                    $('#IsInternational').val("True");
                    $("#divMailingCountry").show();
                    $('#MailingCountry').val("");
                }
            });

            $(document.body).delegate('[type="checkbox"][readonly="readonly"]', 'click', function (e) {
                e.preventDefault();
            });

            $(".igcontinuebutton")
                .click(function () {
                    if ($(this).data("thispage") !== "igPage3") {
                        if (validateDiv($(this).data("thispage"))) {
                            var nextpage = $(this).data("nextpage");
                            $('#' + $(this).data("thispage")).hide();
                            $('#' + nextpage).show();
                            window.scrollTo(0, 0);
                        }
                    } else {
                        var nextpage = $(this).data("nextpage");
                        $('#' + $(this).data("thispage")).hide();
                        $('#' + nextpage).show();
                    }
                });

            $(".btnCancel")
                .click(function () {
                    window.location.href = "https://www.aopa.org";
                });

            $(".igbackbutton")
                .click(function () {
                    var prevpage = $(this).data("prevpage");
                    $('#' + $(this).data("thispage")).hide();
                    $('#' + prevpage).show();
                    window.scrollTo(0, 0);
                });
            
            $(".igSubmitButton").click(function () {

                var progressBar = new Sfpb_progressBar();
                var ccValid = checkCardFields();

                if (theForm.valid() && ccValid) {

                    $('#divButtonSubmit').removeClass('clsSubmitFloatRight');
                    $('#divButtonSubmit').addClass('clsSubmitNoFloatRight');
                    $('#divIGOrderButton').addClass('clsAddHeight');
                    $('#btnRegister').hide();
                    $('.btn_final_cancelback').hide();
                    $(this).hide();
                    $('body').addClass('progressbar-modal');
                    progressBar.start();
                    $(".clsSubmit").append('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');

                    igdStripe.createToken(igdCardElement, { name: $("#CardName").val() })
                        .then(function (result) {
                            if (result.token !== null) {
                                $("#CardToken").val(JSON.stringify(result.token));
                                theForm.submit();
                            } else {
                                if ($('#card-errors').is(':empty')) {
                                    $('#card-errors').html('Please check your credit card information.');
                                }
                                var spinner = document.getElementById('aopaSpinner');
                                spinner.parentElement.removeChild(spinner);
                                $(this).show();
                                progressBar.kill();
                                $('body').removeClass('progressbar-modal');
                                $('#divButtonSubmit').removeClass('clsSubmitNoFloatRight');
                                $('#divButtonSubmit').addClass('clsSubmitFloatRight');
                                $('#divIGOrderButton').removeClass('clsAddHeight');
                                $('#btnRegister').show();
                                $('.btn_final_cancelback').show();

                            }
                        });
                }
            });

            $("input[name^='ProductListItems']").change(function () {
                var total = 0.0;
                $('.ig-product-row').hide();

                $("input:checkbox[name^='ProductListItems']").each(function () {
                    if (this.checked) {
                        var cb = this.name.split(".")[0];
                        var itemData = $('[id="' + cb + '.ID"]').val().split('|');
                        total += parseFloat(itemData[3]);

                        $('#div' + itemData[0]).show();
                    }
                });
                
                $('#OrderTotal').val(total);
                $('#igOrderTotal').html('$' + total.toFixed(2));

                var nextButton = document.getElementById('btnPage4Forward');
                var regButton = document.getElementById('btnRegister');
                if (total > 0) {
                    nextButton.disabled = false;
                    regButton.disabled = false;
                    $('#btnPage4Forward').removeClass('ig-disable-button');
                    $('#btnRegister').removeClass('ig-disable-button');
                } else {
                    nextButton.disabled = true;
                    regButton.disabled = true;
                    $('#btnPage4Forward').addClass('ig-disable-button');
                    $('#btnRegister').addClass('ig-disable-button');
                }
            });


            function validateDiv(div) {
                var validator = theForm.data('validator');
                var returnValue = true;

                $('#' + div + ' :input').each(function (e) {
                    if (!$(this).is(":button") && this.id.length) {
                        if (!validator.element($('#' + this.id))) returnValue = false;
                    }
                });

                return returnValue;
            }
        }
    });