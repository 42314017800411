﻿$(document).ready(function() {
    if ($('div#divOneClickPacDonation').length) {

        $(".donateButton").click(function () {
            var form = $(this).closest('form');
            if (form.valid()) {
                $(this).hide();
                $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
            }
        });

        $('#SelectedCountry').change(function () {
            var option = $(this).find('option:selected').val();
            $.getJSON('/api/CountryState/GetStates/?countryCode=' + option)
                .done(function (data) {
                    $('#SelectedState').empty();
                    $.each(data, function (key, item) {
                        var $option = $("<option />");
                        $option.attr("value", item.value).text(item.text);
                        $('#SelectedState').append($option);
                    });
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#SelectedState').empty();
                    var $option = $("<option />");
                    $options.attr("value", "").text("- Error -");
                    $('#SelectedState').append($option);
                });
        });

        $('input[name=SelectedDonationAmount]').on('change', function () {
            if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER") {
                if ($('#MinimumDonationAmount').length) {
                    $('#MinimumDonationAmount').val('');
                    $('#MinimumDonationAmount').valid();
                    $('#MinimumDonationAmount').prop("disabled", true);
                    $("#divOtherAmount").hide();
                }
            } else {
                $('#MinimumDonationAmount').prop("disabled", false);
                $("#divOtherAmount").show();
            }

            fixAdditionalText($('input[name=SelectedDonationAmount]:checked').val());
            calculateTotal();
            fixDonationAmount($('input[name=SelectedDonationAmount]:checked').val());
        });


        $('input[name=MinimumDonationAmount]').on('change', function () {
            fixAdditionalText($('input[name=SelectedDonationAmount]:checked').val());
            calculateTotal();
            fixDonationAmount($('input[name=SelectedDonationAmount]:checked').val());
        });

        $('input[name=SelectedRegularDonationFrequency]').on('change', function () {
            calculateTotal();
            fixDonationAmount($('input[name=SelectedDonationAmount]:checked').val());
        });

        $('input[name=SelectedPledgeDonationFrequency]').on('change', function () {
            calculateTotal();
            fixDonationAmount($('input[name=SelectedDonationAmount]:checked').val());
        });

        function calculateTotal() {
            var theType = "REGULAR";
            var theAmount = 0;
            var thePayments = 1;

            if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER") {
                var data = $('input[name=SelectedDonationAmount]:checked').val().split('|');
                theType = data[1];
                theAmount = data[0];

            } else {
                var data = JSON.parse($('#OtherAmountsString').val());

                if ($.isNumeric($('#MinimumDonationAmount').val())) {
                    theAmount = parseInt($('#MinimumDonationAmount').val());
                    $.each(data, function () {
                        if (this.otherMinimum <= theAmount && this.otherMaximum > theAmount) {
                            theType = this.amountType;
                        }
                    });

                }
            }

            $('#PaymentTotal').val(parseFloat((theAmount / thePayments), 10).toFixed(2));
            $('#DonationTotal').val(parseFloat(theAmount).toFixed(2));
            $('#divPaymentAmount').html('$' + parseFloat((theAmount / thePayments), 10).toFixed(2));
        }

        function fixAdditionalText(valSelected) {
            if (valSelected !== "OTHER") {
                var data = valSelected.split('|');

                $('.ocd-addtional-text-wrapper').each(function() {
                    if ($(this).data('text-type') === data[2]) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            } else {
                var data = JSON.parse($('#OtherAmountsString').val());

                if ($.isNumeric($('#MinimumDonationAmount').val())) {
                    var amount = parseInt($('#MinimumDonationAmount').val());

                    $.each(data, function () {
                        if (this.otherMinimum <= amount && this.otherMaximum > amount) {
                            var theData = this;
                            $('.ocd-addtional-text-wrapper').each(function () {
                                if ($(this).data('text-type') === theData.additionalTextType) {
                                    $(this).show();
                                } else {
                                    $(this).hide();
                                }
                            });
                        }
                    });

                }
            }
        }

        function fixDonationAmount(valSelected) {
            if (valSelected !== "OTHER") {
                var data = valSelected.split('|');
            } else {
                var data = JSON.parse($('#OtherAmountsString').val());

                if ($.isNumeric($('#MinimumDonationAmount').val())) {
                    var amount = parseInt($('#MinimumDonationAmount').val());
                }
            }
        }

    }
});
