/*
 * AOPA custom JS
 */

import './shared/aopa-shared';
import './itemlist/itemlist';
import './bookmarks/bookmarks';
import './contentwizard/contentwizard';
import './weather/weather';
import './customformvalidation/ccexpdatevalidation';
import './customformvalidation/ccexpdatevalidation2';
import './customformvalidation/ccnumbercheckvalidation';
import './customformvalidation/radiotextvalidator';
import './customformvalidation/eitherorrequiredvalidation';
import './customformvalidation/usernamevalidation';
import './customformvalidation/airportcodevalidation';
import './customformvalidation/optionalselectcheck';
import './customformvalidation/phonenumbervalidation';
import './customformvalidation/checkboxlistrequiredvalidation';
import './wffm/wffm';
import './financeform/financeform';
import './asnairportsearch/asnairportsearch';
import './asnnominationform/asnnominationform';
import './expandableads/expandableads';
import './oneclickdonation/oneclickdonation';
import './addupdatepps/addupdateppsform';
import './event/jquery-ui-timepicker-addon';
import './scrolldownarrow/scrolldownarrow';
import './comparisontable/comparisontable';
import './timedatepicker/timedatepicker';
import './ppsupgrade/ppsupgrade';
import './optinout/optinout';
import './donationrsvp/donationrsvp';
import './ooyalashare/ooyalashare';
import './statenet/statenetdatafeed';
import './usernameandpassword/usernameandpassword';
import './medicalinformationupdate/medicalinformationupdate';
import './aircraftinformationupdate/aircraftinformationupdate';
import './lspattorneyinformation/lspattorneyinformation';
import './cfiinformationupdate/cfiinformationupdate';
import './customformvalidation/cfiexpirationdatevalidation';
import './customformvalidation/cficertificatenumbervalidation';
import './signupsubscribe/signupsubscribe';
import './customformvalidation/requiredoptionalvalidation';
import './donationrail/donationrail';
import './addressfields/addressfields';
import './forms/forms';
import './globalmemberupgrade/globalmemberupgrade';
import './customformvalidation/aopaccvalidation';
import './genericproductpurchase/genericproductpurchase';
import './eventsearch/eventsearch';
import './ppsmaintenance/ppsmaintenance';
import './pacdonationrail/pacdonationrail';
import './eventregistration/eventregistration';
import './homepagecomponents/crop-image';
import './homepagecomponents/widget-nav';
import './homepagecomponents/jquery.easy-autocomplete.min';
import './homepagecomponents/widget-airport';
import './homepagecomponents/widget-weather';
import './homepagecomponents/partners';
import './homepagecomponents/image-gallery';
import './joincheckout/joincheckout';
import './accountpages/pilotinfoform';
import './accountpages/contactinfoform';
import './freemembership/freemembership';
import './addressfields/addressfieldsaccountform';
import './lspeventregistration/lspeventregistration';
import './rustypilotregistration/rustypilotregistration';
import './eventsignin/rustypilotsignin';
import './flyinregistration/flyinregistration';
import './highschoolworkshopregistration/highschoolworkshopregistration';
import './ftgatedcontent/ftgatedcontent';
import './customformvalidation/attendancecountvalidation';
import './eventsignin/volunteerform';
import './highschoolsymposiumexhibitor/highschoolsymposiumexhibitor';
import './iflightplanner/iflightplanner';
import './picmodal/picmodal';
import './islandguides/islandguides';
import './highschoolsymposiumattendee/highschoolsymposiumattendee';
import './pilotworkshops/pilotworkshops';
import './aopamodal/aopamodal';
import './ycfmodal/ycfmodal';
import './aftapages/aftastudentadmin';
import './aftapages/aftaflightinstructoradmin';
import './aftapages/aftaschoolemployeeadmin';
import './aftapages/aftaaopauseradmin';
import './aftapages/aftaflightschooladmin';
import './oneclickdonationcombined/oneclickdonationcombined';
import './aopajoinformv4/aopajoinformv4';
import './aftapages/aftaflightinstructorpublic';
import './aircraft/aircraft';
import './aftapages/aftastudentpublic';
import './aftapages/aftabusinesscontact';
import './highschoolasaoffer/highschoolasaoffer';
import './aftapages/aftaschoolemployeepublic';
import './accountpages/aopacancellationform';
import './contactus/contactus';
import './aircraftshowcaseregistration/aircraftshowcaseregistration';
import './aftapages/aftastudentpurchase';
import './eventsignin/aviatorshowcasesignin';
import './aoparenewformv2/aoparenewformv2';
import './customformvalidation/dropdowntextvalidator';
import './membershipupgrade/membershipupgradev3';
import './oneclickpacdonation/oneclickpacdonation';
import './customformvalidation/radiotextvalidator2';
import './subscriptions/subscriptions';
import './aafcomplimentarymembership/aafcomplimentarymembership';
import './accountpages/ssoaccountcreate';
import './addupdateaar/addupdateaar';
import './creditcardofrecordupdate/creditcardofrecordupdate';
import './aoparenewformv2/aoparenewformGSv2';
import './efircpurchase/efircpurchase';
import toggleHeaderDropdown from "./header/toggleHeaderDropdown";
import toggleMobile from "./header/toggleMobile";
import stickyHeader from "./header/stickyHeader";
import toggleSearch from "./header/toggleSearch";
import './formaccountcreate/formaccountcreate';
import './globalactivationform/globalactivationform';
import './customformvalidation/nousvalidator';
import './textmessageoptinout/textmessageoptinout';
import './newslettersignup/newslettersignup';
import './customformvalidation/emailselectionvalidation';


/* get current breakpoint from CSS
 * Needs to be global and isn't jQuery, so stick it out here
 * Credit goes to https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
 */
var breakpoint = {};
breakpoint.refreshValue = function () {
  this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
};
breakpoint.refreshValue();

var hasRefreshNav = $(".js-refresh-nav").length;
if (hasRefreshNav) {
  document.addEventListener("DOMContentLoaded", () => {
    toggleHeaderDropdown();
    toggleMobile();
    stickyHeader();
    toggleSearch();
  });
}

(function ($) {
  $(document).ready(function () {
    if (!hasRefreshNav) {
      /* ***************************************************** */
      /* Safe to run in Sitecore */

      /* Main Nav columns and right align functionality */
      /* 1/20/17   Nick.Beatty@aopa.org   */

      (function ($) {

        "use strict";


        // only do this on larger viewports (not the tablet nav)
        if (window.innerWidth >= 1111) {

          // number of items at which we split the sub-menu into 2 columns.
          var menuItemsPerCol = 6;

          // check sub-menu item count for each main nav item
          // if greater than menuItemsPerCol, add the wrapping class.

          $(".header__nav-main-list li ul").each(function () {
            var m = $(this);
            if (m.children("li").length > menuItemsPerCol) {
              m.addClass("wrapNav");
            }
          });

          // on wrapping-nav-item hover, check to see if the multi-column
          // arrangement will cause the menu to collide with the RH edge
          // of the viewport. If so, add the .edge class to realign it.

          $(".header__nav-sub-list").parent().hover(function () {

            var d = $(this), // the top-level menu item
              sM = $(this).find(".header__nav-sub-list"), // the sub-menu
              l = parseInt(d.position().left, 10), // the left position of the parent menu item
              vW = $("body").width(), // viewport width
              w = 0; // variable to hold the drop down width, to use is we need to float it right

            // width of sub-menu item
            if (sM.hasClass("wrapNav")) {
              w = 500;
            } else {
              w = 250;
            }

            // if the left position of the dropdown, plus its width, is greater than
            // the viewport width, add negative left margin to the drop down menu
            // Margin is equal to the negative of the sub-menu with minus its parent's width

            if (vW <= 1111 || l + w <= vW) {
              sM.css("margin-left", "");
            } else {
              sM.css("margin-left", (w - d.width()) * -1);
            }

          });

        }

      }(jQuery));
    }

    /* Fix hidden H4 elements in newsletter footers */

    if (window.location.href.indexOf("news-and-media/all-news/") > -1) {
      $("td h4").css("display", "block");
    }


    /* Alert close */

    //create cookie for alerts
    var alertcookie = [];
    var tempCookie = getCookie("alertscookie").split(",");
    /* Alert close */
    /* State currently isn't kept */
    $(".alert__close").click(function () {
      $(this).parents(".alert").slideUp();
      var alertid = $(this).attr('data');
      var found = $.inArray(alertid, tempCookie);
      if (found < 0) {
        alertcookie.push(tempCookie);
        alertcookie.push(alertid);
      }
      else {
        alertcookie.push(tempCookie);
      }

      document.cookie = 'alertscookie=' + alertcookie.toString() + '; path=/';
    });


    /* Jump links */
    var $jumpLinkContainers = $('.jumplink');

    $jumpLinkContainers.each(function () {
      var jumpersOutputString = "";

      $(this).find('.jumplink__title').each(function () {
        var jumperTitle = $(this).data("jumptitle") ? $(this).data("jumptitle") : $(this).html();

        jumpersOutputString += '<div class="l-column column-373"><a class="jumplink__jumper" href="#' + $(this).parent().attr('id') + '"><svg><use xlink:href="/dist/assets/svg-sprite.svg#jumplink-arrow"></use></svg>' + jumperTitle + "</a></div>";
      });

      $(this).find(".jumplink__jumpers").html(jumpersOutputString);
    });

    if (!hasRefreshNav) {
      var $jumpLinks = $('.jumplink__jumper');
      $jumpLinks.click(function () {

        var headerOffset = 0;

        /*
         * If the header's stickieable get its height or at least give it a default
         * of 60, the approximate height of a single-line sticky header
         */
        if ($('.sticky-header-wrapper').length) {
          headerOffset = $('.sticky-header-wrapper').height() ? $('.sticky-header-wrapper').height() : 60;
        }

        $("html, body").animate({ scrollTop: ($($(this).attr('href')).offset().top - headerOffset) }, 'slow');
        return false;
      });
    }

    /* Jump Links(anchor links) to the Accordion section */

    var $accordionContainers = $('.accordion');
    $accordionContainers.each(function () {

      //create variable based off the hashtag in the url
      var hashTag = window.location.hash.substring(1).toLowerCase();
      if (hashTag.length > 0) {
        //create varible based off the hashtag name if it matches an element name property
        //both halves of the equality must be converted to lowercase to make it case-insensitive
        var $anchorTag = $('.accordion__item').filter(function () {
          return $(this).attr('name').toLowerCase() == hashTag;
        });

        $.each($anchorTag, function () {
          //$(this) refers to the current $anchorTag element
          //have the page scroll to the element that has a name that matches the hashtag
          $('html, body').animate({ scrollTop: $(this).offset().top - 60 }, 1000);
          $('.accordion__body', this).show();
        });
      }
    });


    /* ***************************************************** */
    /* Only run if not in Sitecore */
    if (!$('body.in-sitecore').length) {

      var breakpointDebounced = debounce(breakpoint.refreshValue, debounceLength);
      window.addEventListener('resize', breakpointDebounced);

      /* Set vars */
      var showBackToTopAt = 200;
      var transitionLength = 1000;
      var debounceLength = 250;

      /* Compatibility: Deal with object-fit cover in older browsers */
      $('html.no-objectfit div.promo-block').each(function () {
        shimCentercrop($(this));
      });

      $('html.no-objectfit div.hero__wrapper').each(function () {
        if ($(this).children("picture").length) {
          shimCentercrop($(this));
        } else {
          $(this).find(".hero__small-top, .hero__small-bottom, .hero__left, .hero__right").each(function () {
            shimCentercrop($(this));
          });
        }
      });

      /* Forms: Pretty select boxes; select2 settings */
      $('select.custom-select').select2({
        minimumResultsForSearch: -1,
        width: '100%'
      }).hide();

      /* Drawers: Item list & Accordion drawers */
      $(".item-list:not(.media-gallery) .item-list__toggle").click(function () {
        $(this).siblings(".item-list__drawer").slideToggle("slow");
        $(this).parent().toggleClass("is-open");
      });

      $(".accordion__heading").click(function () {
        var $thisAccordion = $(this);
        var $thisAccordionsParent = $thisAccordion.parent();
        var $thisAccordionsOpen = $thisAccordionsParent.siblings(".is-open");

        $thisAccordionsOpen.removeClass("is-open").find(".accordion__body").slideUp("slow");
        $thisAccordion.siblings(".accordion__body").slideToggle("slow", function () {
          var scrollAdjustment = $('.sticky-header-wrapper').height();
          var scrollTo = $thisAccordion.offset().top - scrollAdjustment;

          if (!$thisAccordion.siblings(".accordion__body").find(".video--scaled-wrapper").length) {
            $thisAccordion.siblings(".accordion__body").find(".video").each(videoSizer);
          }

          $('html,body').animate({ scrollTop: scrollTo }, 'slow');
        }
        );
        $thisAccordionsParent.toggleClass("is-open");

      });

      /* Homepage: size hero if necessary */

      if (breakpoint.value === "xx-small" || breakpoint.value === "x-small" || breakpoint.value === "small" || breakpoint.value === "medium") {
        $('#hero-homepage #ooyalaplayer').remove();
      }
      else {
        var homepageHeroSizer = function () {
          var $outerHomeHeroWrapper = $('#hero-homepage').find('.hero-homepage__outer-wrapper');
          var $innerHomeHeroWrapper = $('#hero-homepage').find('.hero-homepage__inner-wrapper');

          var innerHomeHeroWrapperWidth = $outerHomeHeroWrapper.width() > ($outerHomeHeroWrapper.height() * 16 / 9) ? $outerHomeHeroWrapper.width() : $outerHomeHeroWrapper.height() * 16 / 9;

          var innerHomeHeroWrapperHeight = $outerHomeHeroWrapper.height() > ($outerHomeHeroWrapper.width() * 9 / 16) ? $outerHomeHeroWrapper.height() : $outerHomeHeroWrapper.width() * 9 / 16;

          var innerHomeHeroWrapperMarginTop = ($outerHomeHeroWrapper.height() - innerHomeHeroWrapperHeight) / 2;
          var innerHomeHeroWrapperMarginLeft = ($outerHomeHeroWrapper.width() - innerHomeHeroWrapperWidth) / 2;

          $innerHomeHeroWrapper.width(innerHomeHeroWrapperWidth);
          $innerHomeHeroWrapper.height(innerHomeHeroWrapperHeight);
          $innerHomeHeroWrapper.css({
            marginTop: innerHomeHeroWrapperMarginTop,
            marginLeft: innerHomeHeroWrapperMarginLeft
          });
        }

        homepageHeroSizer();

        var homepageHeroDebounced = debounce(homepageHeroSizer, debounceLength);
        window.addEventListener('resize', homepageHeroDebounced);
      }


      /* Images: Captioned images from RTE */
      $('.captioned').each(function () {
        var alt = $(this).find("img").attr('alt');
        if (alt !== undefined) {
          var width = $(this).find("img").width() ? $(this).find("img").width() : 320;
          $(this).css({
            width: width
          });

          $(this).append('<div class="image-zoomable__caption">' + alt + '</div>');
        }
      });

      /* Modals: Image zoom */
      /* Initial setup; no point in doing this if there are no zoomables on the page */
      var $zoomIns = $(".js-image-zoomable__zoom--in");
      if ($zoomIns.length) {

        /* If image is zoomed, resize it on window resize */
        var zoomDebounced = debounce(unzoomResize, debounceLength);
        window.addEventListener('resize', zoomDebounced);

        $zoomIns.click(function (e) {
          e.stopPropagation();
          e.preventDefault();

          $('body').append('<div id="modal"></div>');
          /* grab large image from data-src */
          var $extantImg = $(this).siblings("img");
          $(this).siblings(".image-zoomable__bigimg").children('img').attr("src", $extantImg.data("src"));

          zoomImage('in', $(this).parents(".image-zoomable"));
        });

        $(".js-image-zoomable__zoom--out").click(function (e) {
          e.stopPropagation();
          e.preventDefault();

          zoomImage('out', $(this).parents(".image-zoomable"));
        });

        $("#modal").click(function (e) {
          if ($(".image-zoomable.is-zoomed")) {
            e.stopPropagation();
            e.preventDefault();

            zoomImage('out', $(".image-zoomable.is-zoomed"));
          }
        });
      }

      /* Navigation: Back to top link */
      scrollCheck($(this).scrollTop(), showBackToTopAt);

      $('#back-to-top').click(function () {
        /* Pretty scroll */
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        return false;
      });

      $(document).scroll(function () {
        scrollCheck($(this).scrollTop(), showBackToTopAt);
      });

      /* Navigation: Selectifier for link lists, left nav */
      var $linkLists = $('.link-list');
      var $leftNavs = $('.leftnav');

      $linkLists.each(function () {
        if ($(this).find(".link-list__item").length > 6) {
          selectifier($(this), "link-list--list", ".link-list__items", ".link-list__item", "Select link from list...");
        }
      });

      var leftnavCheck = function () {
        /* force breakpoint refresh */
        breakpoint.refreshValue();

        $leftNavs.each(function () {
          if (breakpoint.value === "xx-small" || breakpoint.value === "x-small" || breakpoint.value === "small" || breakpoint.value === "medium") {
            if (!$(this).hasClass("selectifier")) {
              selectifier($(this), "", ".leftnav__main-list", ".leftnav__main-list-item", "Within this section");
            }
          }
          else {
            if ($(this).hasClass("selectifier")) {
              unselectifier($(this), "");
            }
          }
        });
      }

      leftnavCheck();

      var leftnavDebounced = debounce(leftnavCheck, debounceLength);
      window.addEventListener('resize', leftnavDebounced);

      if (!hasRefreshNav) {
        /* Navigation: Sticky nav */
        var headerCheck = function () {
          /* force breakpoint refresh */
          breakpoint.refreshValue();

          if (breakpoint.value === "large" || breakpoint.value === "x-large" || breakpoint.value === "xx-large" || breakpoint.value === "xxx-large") {

            var headerHeight = $("#header").height();

            // prevent header from becoming sticky in Experience Editor
            if ($('body').hasClass('in-sitecore')) {
              return;
            }

            if (!$(".sticky-header-wrapper").length) {
              $("#header").wrap('<div class="sticky-header-wrapper"></div>');
            }

            if ($(window).scrollTop() > headerTopOffset && !$(".is-sticky").length) {
              $(".sticky-header-wrapper").css("height", headerHeight);
              $(".sticky-header-wrapper").addClass('is-sticky');
              $("#header").css({ top: "-" + $("#header").height() + "px" });
              $("#header").animate({ top: "0" });
            }
            else if ($(window).scrollTop() <= headerTopOffset && $(".is-sticky").length) {
              $(".sticky-header-wrapper").css("height", "auto");
              $(".sticky-header-wrapper").removeClass('is-sticky');
              $("#header").css({ top: "-" + $("#header").height() + "px" });
            }
          }
        }

        var scrollTop = $(window).scrollTop();
        var headerTopOffset = 0;
        if ($(".header__nav-main-list").length > 0) {
          headerTopOffset = $(".header__nav-main-list").offset().top + $(".header__nav-main-list").height();
        }

        $(window).scroll(function () {
          headerCheck();
        });

        headerCheck();

        var headerDebounced = debounce(headerCheck, debounceLength);
        window.addEventListener('resize', headerDebounced);

        var $NavLisWithChildren = $('#header--mobile').find("li").has("ul")

        $NavLisWithChildren.find("span").click(function (e) {
          e.stopPropagation();
          e.preventDefault();

          $(this).parent("a").parent("li").toggleClass("is-open");
          $(this).parent("a").parent("li").children("ul").slideToggle("slow");
        });
      }

      /* Navigation: Left Nav */
      $('.leftnav li svg').click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        $(this).parents("li").children("ul").slideToggle();
        $(this).parents("li").toggleClass("is-open");
      });

      if (!hasRefreshNav) {
        /* Navigation: Top nav */
        var timer;
        $('.header__nav-main-list > li', '#header').hover(function () {
          var $this = $(this);
          timer = setTimeout(function () {
            $this.children(".header__nav-sub-list").show();
            $this.addClass("is-open");
          }, 300);
        }, function () {
          clearTimeout(timer);
          $(".header__nav-sub-list").hide();
          $('.header__nav-main-list > li').removeClass("is-open");
        });
        $('.header__nav-main-list > li > a', '.touchevents #header').on("touchend", function (e) {
          if ($(this).parent().data("open") && $(this).attr("href")) {
            window.location.href = $(this).attr("href");
          }
          else {
            e.stopPropagation();
            e.preventDefault();

            $(this).parent().siblings().data("open", false);
            $(this).parent().siblings().removeClass("is-open");
            $(this).parent().siblings().children(".header__nav-sub-list").hide();
            $(this).parent().data("open", true);
            $(this).parent().children(".header__nav-sub-list").show();
            $(this).parent().addClass("is-open");
          }
        });

        $('.header__nav-sub-list-close', '#header').click(function (e) {
          e.stopPropagation();
          e.preventDefault();

          $(this).parents(".is-open").data("open", false).removeClass("is-open");
          $(this).parent().hide();
        });

        $('.header__user', '#header').hover(function () {
          $(this).children(".header__profile").show();
        }, function () {
          $(this).children(".header__profile").hide();
        });

        $('.header__user', '#header').click(function (e) {
          if (breakpoint.value === "medium" && $(".header__profile").length) {
            e.stopPropagation();
            e.preventDefault();

            $(this).children(".header__profile").toggle();
          }
        });

        $('.header__burger', '#header--mobile').click(function () {
          $('.header__drawer', '#header--mobile').slideDown(transitionLength, function () {
            $('.header__toggle	', '#header--mobile').hide();
          });
        });

        $('.header__close', '#header--mobile').click(function () {
          $('.header__drawer', '#header--mobile').slideUp(transitionLength);
          $('.header__toggle	', '#header--mobile').show();
        });
        $('.mobile-account-drawer').click(function () {
          $('.mobile-account-info .header__profile').slideToggle('slow');
          $('.mobile-account-drawer').toggleClass('rotated');
        });
      }

      /* Scrollbars: Trigger custom scrollbars */
      $('.js-custom-scroller').mCustomScrollbar({
        theme: "light-thick"
      });

      /* Search: search modal */

      function setCaretPosition(ctrl, pos) {
        if (ctrl.setSelectionRange) {
          ctrl.focus();
          ctrl.setSelectionRange(pos, pos);
        }
        else if (ctrl.createTextRange) {
          var range = ctrl.createTextRange();
          range.collapse(true);
          range.moveEnd('character', pos);
          range.moveStart('character', pos);
          range.select();
        }
      }

      if (!hasRefreshNav) {
        $('#header').find('.header__search').click(function (e) {
          e.stopPropagation();
          e.preventDefault();

          $('body').append('<div id="modal" class="modal modal--search search"><div id="search__close" class="modal__close"><svg><use xlink:href="/dist/assets/svg-sprite.svg#x"></use></svg></div><div class="search__form"><div class="search__box"><div class="placeholder">What Can We Help You Find?</div><input id="keywordInput" type="text" name="keyword"><button class="button">Search<svg><use xlink:href="/dist/assets/svg-sprite.svg#rightarrow"></use></svg></button></div></div>');

          $("#keywordInput").focus();

          $("#keywordInput").keypress(function () {
            $("#modal .placeholder").hide();
          });

          $("#keywordInput").keyup(function () {
            e.preventDefault();
            e.stopPropagation();
            var keyword = $('#keywordInput').val();
            var url = 'https://www.aopa.org/search' + '#q=' + keyword;
            if (event.keyCode == 13) {
              window.location.href = url;
            }
          });

          $("#keywordInput").blur(function () {
            if (!$("#keywordInput").val()) {
              $("#modal .placeholder").show();
            }
          });

          $('#search__close').click(function () {
            $('#modal').remove();
          });
          $(".button").click(function () {
            e.stopPropagation();
            e.preventDefault();
            var keyword = $('#keywordInput').val();
            var url = 'https://www.aopa.org/search' + '#q=' + keyword;
            window.location.href = url;
          });

        });
      }

      /* Slideshows: Content Sliders */
      var $contentSliders = $('.content-slider');
      $contentSliders.each(function () {
        $(this).data("slideWidth", $(this).find(".content-slider__item:last-child").outerWidth());
        //content-slider--videos was original class, class name was changed to content-slider--videos--added-to-remove-popup  to remove popup from content slider video
        if ($(this).hasClass("content-slider--videos--added-to-remove-popup")) {
          $(this).find(".content-slider__item").click(function (e) {

            if (!(breakpoint.value === "xx-small" || breakpoint.value === "x-small" || breakpoint.value === "small")) {
              e.stopPropagation();
              e.preventDefault();

              $('body').append('<div id="modal"></div>');
              $('#modal').addClass("modal--fade");

              $('#modal').html('<div class="modal__close"><svg><use xlink:href="/dist/assets/svg-sprite.svg#x"></use></svg></div><div class="modal__content-wrapper"><div class="modal__media-wrapper"><div class="video">' + $(this).data("popcontent") + "</div></div></div>");


              addthis.update('share', 'url', $('#modal .addthis_modal_sharing_toolbox').data('url'));
              addthis.toolbox('.addthis_modal_sharing_toolbox');

              $(".modal__close").click(function () {
                $("#modal").removeClass("modal--fade");
                $("#modal").remove();
              });
            }

          });
        }

      });

      $contentSliders.find('.content-slider__viewport').mCustomScrollbar({
        theme: "light-thick",
        axis: "x",
        updateOnContentResize: true,
        callbacks: {
          onTotalScroll: function () {
            $(this).parent().find(".content-slider__next").fadeOut();
          },
          onTotalScrollBack: function () {
            $(this).parent().find(".content-slider__prev").fadeOut();
          },
          whileScrolling: function () {
            $(this).parent().find(".content-slider__prev").fadeIn();
            $(this).parent().find(".content-slider__next").fadeIn();
          },
          onInit: function () {
            $(this).parent().find(".content-slider__prev").fadeOut();
          }
        }
      });

      $contentSliders.find(".content-slider__prev").click(function () {
        var $sliderContainer = $(this).parents(".content-slider");
        var $sliderSlider = $sliderContainer.find(".content-slider__viewport");
        var $sliderOffsetElement = $sliderContainer.find(".mCSB_container");
        var slideWidth = $sliderContainer.data("slideWidth");

        var scrollTo = parseInt($sliderOffsetElement.css("left")) + (Math.max(Math.floor($sliderContainer.width() / slideWidth), 1) * slideWidth);

        scrollTo = scrollTo <= 0 ? scrollTo : 0;

        $sliderSlider.mCustomScrollbar("scrollTo", scrollTo, {});

      });

      $contentSliders.find(".content-slider__next").click(function () {
        var $sliderContainer = $(this).parents(".content-slider");
        var $sliderSlider = $sliderContainer.find(".content-slider__viewport");
        var $sliderOffsetElement = $sliderContainer.find(".mCSB_container");
        var slideWidth = $sliderContainer.data("slideWidth");

        var scrollTo = parseInt($sliderOffsetElement.css("left")) - (Math.max(Math.floor($sliderContainer.width() / slideWidth), 1) * slideWidth);

        $sliderSlider.mCustomScrollbar("scrollTo", scrollTo, {});
      });

      var scrollerCheck = function () {
        $contentSliders.each(function () {
          if ($(this).find(".mCustomScrollBox").width() >= $(this).find(".content-slider__content").width()) {
            $(this).find(".content-slider__controls").hide();
          }
          else {
            $(this).find(".content-slider__controls").show();
          }
        });
      }

      scrollerCheck();

      var scrollerDebounced = debounce(scrollerCheck, debounceLength);
      window.addEventListener('resize', scrollerDebounced);

      /* Slideshows: Feature sliders */
      var $heroSlider = $('.hero-feature__slider').slick({
        arrows: false,
        dots: true,
        speed: 1000,
        fade: true,
        easing: 'swing',
        customPaging: function (slider, i) {
          var $slideObject = $(slider.$slides[i]).find(".hero-feature__teaser");
          var slideTeaser = $slideObject.prop('outerHTML').toString();
          return slideTeaser;
        },
      });

      if ($heroSlider.length) {
        var slideReloader = function slideReloader() {
          $heroSlider.slick("slickSetOption", "draggable", true, true);
        };

        var reloadSliderDebounced = debounce(slideReloader, debounceLength);
        window.addEventListener("resize", reloadSliderDebounced);
      }

      /* Slideshows: Slideshow components */
      $('.slideshow__wrapper').slick({
        arrows: true,
        dots: true,
        speed: 1000,
        easing: 'swing',
        nextArrow: '<div  class="slick-next"><svg><use xlink:href="/dist/assets/svg-sprite.svg#rightarrow"></use></svg></div>',
        prevArrow: '<div class="slick-prev"><svg><use xlink:href="/dist/assets/svg-sprite.svg#leftarrow"></use></svg></div>',
        customPaging: function (slider, i) {
          var $slideObject = $(slider.$slides[i]).find("img");
          var slideTeaser = $slideObject.prop('outerHTML').toString();
          return slideTeaser;
        },
      }).on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        var $dotsWrapper = $(this).find(".slideshow__dots-wrapper");
        var $targetDot = $(this).find(".slick-dots li:nth-child(" + (nextSlide + 1) + ")");
        var currentScroll = $dotsWrapper.scrollLeft();
        var targetWidth = $targetDot.outerWidth(true);
        var targetPosition = $targetDot.position().left;
        var marginModifier = targetWidth - $targetDot.outerWidth();

        /* only scroll if outside of view */
        if (((targetPosition + currentScroll) < currentScroll) || ((targetPosition + targetWidth + currentScroll) > currentScroll + $dotsWrapper.width())) {
          $dotsWrapper.animate({ scrollLeft: ((targetPosition + marginModifier) + currentScroll) }, transitionLength);
        }
      });

      var arrowRelocator = function () {
        $('.slideshow__wrapper .slick-arrow').css({
          top: function () {
            return (($(this).parent().width() * 9 / 16) / 2) + "px";
          }
        });
      };

      arrowRelocator();

      var arrowsDebounced = debounce(arrowRelocator, debounceLength);
      window.addEventListener('resize', arrowsDebounced);

      $('.slideshow__wrapper .slick-dots').wrap('<div class="slideshow__arrow-wrapper"><div class="slideshow__dots-wrapper"></div></div>').before('<div class="slideshow__prev slideshow__arrow"><svg><use xlink:href="/dist/assets/svg-sprite.svg#leftarrow"></use></svg></div>').after('<div  class="slideshow__next slideshow__arrow"><svg><use xlink:href="/dist/assets/svg-sprite.svg#rightarrow"></use></svg></div>')


      $(".slideshow__dots-wrapper").scrollLeft(0);

      $('.slideshow__prev').click(function () {
        var $parentContainer = $(this).parent();
        var dotWidth = $parentContainer.find("li:last-child").outerWidth(true);
        /* don't cut off images on the scroll */
        var scrollValue = ($parentContainer.parent().width() * -1) + $parentContainer.scrollLeft();
        scrollValue = Math.floor(scrollValue / dotWidth) * dotWidth;
        $parentContainer.animate({ scrollLeft: scrollValue }, transitionLength);
      });
      $('.slideshow__next').click(function () {
        var $parentContainer = $(this).parent();
        var dotWidth = $parentContainer.find("li:last-child").outerWidth(true);
        /* don't cut off images on the scroll */
        var scrollValue = $parentContainer.parent().width() + $parentContainer.scrollLeft();
        scrollValue = Math.floor(scrollValue / dotWidth) * dotWidth;
        $parentContainer.animate({ scrollLeft: scrollValue }, transitionLength);
      });

      $('.slideshow__zoom').click(function () {
        var $thisSlideshow = $(this).parents(".slideshow");
        $thisSlideshow.wrap('<div id="slideshow__zoom-placeholder"></div>');
        $('#slideshow__zoom-placeholder').height($thisSlideshow.outerHeight());
        $('body').append('<div id="modal" class="modal modal--fade"><div class="l-wrapper"><div id="slideshow__sizer"></div></div></div>');

        $('#modal').mCustomScrollbar({
          theme: "light-thick"
        });

        $thisSlideshow.detach().appendTo("#modal .l-wrapper");
        $thisSlideshow.find('.slideshow__wrapper').slick('setPosition');
        arrowRelocator();
      });

      $('.slideshow__close').click(function () {
        var $thisSlideshow = $(this).parents(".slideshow");
        $thisSlideshow.detach().appendTo("#slideshow__zoom-placeholder");
        $('#modal').remove();
        $thisSlideshow.unwrap();
        $thisSlideshow.find('.slideshow__wrapper').slick('setPosition');
        arrowRelocator();
      });


      /* Tabs: Tab controls */

      var $tab = $(".tab");

      /* pick out only the things that are actually in large enough columns */
      $tab = $tab.filter(function () {
        return $(this).width() > 620;
      });

      $tab.each(function () {
        $(this).addClass("js-tabbable");

        var listString = '<ul class="tab__tabs">';

        $(this).find("h3.tab__heading").each(function () {
          listString += "<li>" + $(this).html() + "</li>";
        });

        listString += "</ul>";

        $(this).children(".tab__items").before(listString);

        /* Open the first tab so that something's showing to start */
        /* But only if we're in tab mode */
        //if ($(window).width() > 880) {
        if (breakpoint.value === "large" || breakpoint.value === "x-large" || breakpoint.value === "xx-large" || breakpoint.value === "xxx-large") {
          $(this).find(".tab__item:first-child").addClass("is-open");
          $(this).find(".tab__tabs li:first-child").addClass("is-open");
        }

      });

      var tabsDebounced = debounce(tabResizer, debounceLength);
      window.addEventListener('resize', tabsDebounced);

      $tab.find('.tab__tabs li').click(function () {
        /* Handles multiple tabsets per page, so the pattern's a little unusual */
        var pattern = ".tab__item:nth-child(" + ($(this).index() + 1) + ")";
        $(this).siblings(".tab__tabs li").removeClass("is-open");
        $(this).parents(".tab").find(".tab__item").removeClass("is-open");
        $(this).addClass("is-open");
        $(this).parents(".tab").find(pattern).addClass("is-open");

        $(this).parents(".tab").find(pattern).find(".video").each(videoSizer);
      });

      /* Truncation: Deal with truncation on promos */
      $(".promo-block__cta--secondary a, .promo-block__title, .promo-block__cta--primary a").dotdotdot({
        watch: true
      });

      /* Manually bootstrap angular to deal with cases where there are multiple Item Lists in a page */
      $(".item-list").each(function () {
        angular.bootstrap($(this), ['AopaItemList']);
      });
      $(".breadcrumbs__bookmark").each(function () {
        angular.bootstrap($(this), ["AopaBookmarks"]);
      });
      $(".js-aopa-content-wizard").each(function () {
        angular.bootstrap($(this), ["AopaContentWizard"]);
      });

      /* Replace Default SVG when AddThis loads */
      var onAddThisReady = function () {
        var addThisCounter = 0;

        /*
         * AddThis' addthis.ready is supposed to trigger
         * when AddThis is 100% ready, but it tends to fire
         * early.  So have it trigger a set interval to wait
         * to replace the SVG
         */
        var addThisInterval = setInterval(function () {
          /* Ideally, replace the SVG if it exists to replace */
          if ($(".at-svc-email").length) {
            $(".at-svc-email svg").replaceWith('<svg><use xlink:href="/dist/assets/svg-sprite.svg#email"></use></svg>');
            $(".at-svc-print svg").replaceWith('<svg><use xlink:href="/dist/assets/svg-sprite.svg#print"></use></svg>');
            clearInterval(addThisInterval);
          }
          addThisCounter++;

          /* or give up eventually and leave the default SVG */
          if (addThisCounter > 30) {
            clearInterval(addThisInterval);
          }
        }, 10);

      }

      $('div.video').each(videoSizer);

      if (typeof addthis !== 'undefined') {
        addthis.addEventListener('addthis.ready', onAddThisReady);
      }

      /* Add flex behavior for leftnav line only when leftnav exists */
      $('.leftnav').each(function () {
        $(this).parent().parent(".l-wrapper").addClass("leftnav--flexify");
      });

      /* Fix issue with old style ads */
      $('#js-old-ad-code').parents('.richtext').addClass("no-clearfix");


      /* Trigger SVG backfill for older browsers */
      /* Should remain as last item called to catch SVG inserted by javascript */
      svg4everybody();
    }
  });

  /* ***************************************************** */
  /* Shared functions */

  /*
   * Deboucer borrowed from underscore.js
   */
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this, args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  /*
   * getCookie
   */
  function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) != -1) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  /*
   * scrollCheck
   * y: current scroll position of object to check
   * triggerPoint: scroll position to trigger at
   */
  function scrollCheck(y, triggerPoint) {
    if (y > triggerPoint) {
      $('#back-to-top a').fadeIn();
    }
    else {
      $('#back-to-top a').fadeOut();
    }
  }


  /*
   * selectifier
   * turn list of links (divs or lis) into "select box" type dropdown
   */
  function selectifier($thisElement, removeClass, selectorItems, selectorItem, instructions) {
    $thisElement.addClass("selectifier").removeClass(removeClass);
    $thisElement
      .find(selectorItems)
      .prepend('<div class="selectifier__instructions">' + instructions + '<span><svg><use xlink:href="/dist/assets/svg-sprite.svg#downarrow"></use></svg></span></div>')
      .find(selectorItem)
      .wrapAll('<div class="selectifier__drawer"></div>');

    $thisElement.on('click', '.selectifier__instructions', function () {
      $(this).toggleClass('is-open');
      $(this).siblings(".selectifier__drawer").toggle();
    });
  }

  /*
   * unselectifier
   * reverse selectifier
   */
  function unselectifier($thisElement, addClass) {
    $thisElement.addClass(addClass).removeClass("selectifier");
    $thisElement.find(".selectifier__instructions").remove();
    $thisElement.find(".selectifier__drawer").children().unwrap();
  }

  /*
   * tabResizer
   * Debounce function for tabs to accordions
   */
  function tabResizer() {
    /* If we're in tabs, we need to only show one item */
    // if ($(window).width() > 880) {
    if (breakpoint.value === "large" || breakpoint.value === "x-large" || breakpoint.value === "xx-large" || breakpoint.value === "xxx-large") {
      /* Can remove all the style attrs added by jQuery */
      $(".tab__item .tab__body").removeAttr("style");

      /* But otherwise we need to be careful to keep open at least one tab,
        and preferably one that the user opened */
      $(".js-tabbable .tab").each(function () {
        var $keepTab = $(this).find("li.is-open").first().length ? $(this).find("li.is-open").first() : $(this).find("li:first-child");
        var patternBody = ".tab__item:nth-child(" + ($(this).index() + 1) + ")";
        var patternTab = ".tab__tabs:nth-child(" + ($(this).index() + 1) + ")";
        $(this).find(".tab__item").removeClass("is-open");
        $(this).find(".tab__tabs").removeClass("is-open");
        $(this).find(patternBody).addClass("is-open");
        $(this).find(patternTab).addClass("is-open");
      });
    }
  }

  /*
   * unzoomResize
   * Debounce funtion for zoomed images
   */
  function unzoomResize() {
    if ($(".is-zoomed").length) {
      zoomImage('adjust', $(".is-zoomed"));
    }
  }

  /*
   * zoomImage
   * Shared function to zoom in, out, and adjust on resize
   */
  function zoomImage(direction, $zoomedWrapper = null) {
    var zoomLength = 500;
    var top = $zoomedWrapper.offset().top - $(window).scrollTop();
    top = top > 0 ? top : 0;
    var startWidth = $('.is-zoomed').width();
    var $modal = $('#modal');

    /* Max-width animation seems buggy in jQuery, so improvise */
    var maxWidth = (breakpoint.value === "xx-large" || breakpoint.value === "xxx-large") ? ($(window).width() + "px") : $('.l-wrapper').width() + "px";

    /* dimensions and positioning of zoomed image */
    var wideObject = {
      width: maxWidth,
      opacity: "1",
      top: '50%',
      left: '50%'
    };

    /* dimensions and positioning of small image */
    var startObject = {
      width: startWidth,
      opacity: "0",
      top: top + ($zoomedWrapper.height() / 2) + "px",
      left: $zoomedWrapper.offset().left + ($zoomedWrapper.width() / 2) + "px"
    };

    if (direction === "in") {
      /* move it forward enough to cover the ooyala player, which is z-index greedy */
      $zoomedWrapper.find(".image-zoomable__bigimg").css({ 'z-index': 10003 });
      $zoomedWrapper.find(".image-zoomable__bigimg").css(startObject).animate(wideObject, zoomLength);
      $zoomedWrapper.find(".image-zoomable__bigimg").attr("data-zoomed", "zoomed");
      $zoomedWrapper.addClass("is-zoomed");
      $modal.addClass('modal--fade');

    }
    else if (direction === "adjust") {
      if ($(".is-zoomed").length) {
        $(".is-zoomed .image-zoomable__bigimg").animate(wideObject, zoomLength);
      }
    }
    else {
      $zoomedWrapper.find(".image-zoomable__bigimg").css(wideObject).animate(startObject, zoomLength, function () {
        $zoomedWrapper.find(".image-zoomable__bigimg").css({ 'z-index': -1 });
      });
      $zoomedWrapper.find(".image-zoomable__bigimg").removeAttr("data-zoomed");
      $zoomedWrapper.removeClass("is-zoomed");
      $modal.removeClass('modal--fade');
      $('#modal').remove();
    }
  }

  /*
   * shimCentercrop
   * Fallback for object-fit in older browsers
   * Grabs picture sources and applies them to three divs as background images; the divs are then
   *	hidden or displayed via CSS media queries.
   */
  function shimCentercrop($wrapper) {
    var bigimg = $wrapper.find("source:first-child").attr("srcset");
    var midimg = $wrapper.find("source:nth-child(2)").attr("srcset");
    var smlimg = $wrapper.find("img").attr("src");

    if (!midimg) {
      midimg = smlimg;
    }
    if (!bigimg) {
      bigimg = smlimg;
    }

    $wrapper.prepend('<div class="centercrop-shim centercrop-shim--bigimg" style="background-image: url(' + bigimg + ')"></div><div class="centercrop-shim centercrop-shim--midimg" style="background-image: url(' + midimg + ')"></div><div class="centercrop-shim centercrop-shim--smlimg" style="background-image: url(' + smlimg + ')"></div>');
  }

  function videoSizer() {

    var width = $(this).width();
    var height = $(this).height();

    if (width > 0) {
      var padding = (height / width * 100) + "%";

      $(this).wrap('<div class="video--scaled-wrapper" style="padding-bottom: ' + padding + '"></div>');
      $(this).addClass('video--scaled');
      /* Override Ooyala settings; can't do that in the CSS because it messes up the calculations above */
      $(this).find(".innerWrapper").css({
        height: '100%',
        width: '100%'
      });

      $(this).find(".innerWrapper").parent().css({
        height: '100%',
        width: '100%'
      });
    }
  }

  $(".formSubmitButton").click(function () {
    var form = $(this).closest('form');
    if (form.valid()) {
      $('div.clsSubmit').find('.button').hide();
      $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
    }
  });

  $(".formSubmitButton2").click(function () {
    var form = $(this).closest('form');
    if (form.valid()) {
      $('.formButtons').hide();
      $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
    }
  });


  $(".js-premium-alert__close").click(function () {
    $(this).closest(".premium-alert").addClass("premium-alert__hide");
  });

  if (!hasRefreshNav) {
    // hot-fix for iPad nav
    $(".header__user .header__profile a").click(
      function () {
        window.location = $(this).attr('href');
      }
    );
  }

  /* Hide .advertisement background if no ad is served */
  /* Only run if not in Sitecore */
  if (!$('body.in-sitecore').length) {
    $(".advertisement .l-wrapper .l-column").each(function () {
      if ($(this).children().length <= 1) {
        $(this).parents(".advertisement").css("background-image", "none").css("padding", "0");
      }
    });
  }

})(jQuery);





