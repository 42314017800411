class Event {
    constructor ($filter, $sce, eventService) {
        this.$filter = $filter;
        this.$sce = $sce;
        this.eventService = eventService;
        this.templateUrl = '/js/aopa/eventsearch/templates/event.html';
        this.restrict = 'E';
        this.scope = {
            event: '='
        };
        this.controller = ['$scope', $scope => {
            // Initialize scope methods
            $scope.formatDate = () => this.formatDate($scope);
            $scope.formatAddress = () => this.formatAddress($scope);
            $scope.getDescription = () => this.getDescription($scope);
            $scope.getLink = () => this.getLink($scope);
        }];
    }

    static directiveFactory ($filter, $sce, eventService) {
        Event.instance = new Event($filter, $sce, eventService);
        return Event.instance;
    }

    formatDate ($scope) {
        const { startDate, endDate, timeZone } = $scope.event;
        const dateFormat = 'MMM d, y';
        const timeFormat = 'h:mm a';
        const formatted = {
            startDate: this.$filter('date')(startDate, dateFormat, timeZone),
            startTime: this.$filter('date')(startDate, timeFormat, timeZone),
            endDate: this.$filter('date')(endDate, dateFormat, timeZone),
            endTime: this.$filter('date')(endDate, timeFormat, timeZone)
        }
        if (formatted.startDate === formatted.endDate) {
            if (formatted.startTime === formatted.endTime) {
                return formatted.startDate + ' ' + formatted.startTime;
            }
            return formatted.startDate + ' ' + formatted.startTime + ' - ' + formatted.endTime;
        }
        return formatted.startDate + ' ' + formatted.startTime + ' - '
            + formatted.endDate + ' ' + formatted.endTime;
    }

    formatAddress ($scope) {
        const {
            line1,
            line2,
            city,
            state,
            zip
        } = $scope.event.address || {};
        const parts = [ line1, line2, city, state, zip ];
        return parts.filter(v => v).join(', ');
    }

    getDescription ($scope) {
        return this.$sce.trustAsHtml($scope.event.description);
    }

    getLink ($scope) {
        return this.eventService.getEventUrl($scope.event);
    }

    isSameDay (d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    }
}

Event.directiveFactory.$inject = ['$filter', '$sce', 'eventService'];

export default Event;
