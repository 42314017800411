﻿/*
Custom script for validating CFI Certificate Number
*/
(function ($) {
    $.validator.addMethod("cficertificatenumbercheck", function (value, element, params) {
        var crSelected = $('input[name=' + params.coursereason + ']:checked').val();

        if (crSelected) {
            if (params.requiredreasons.indexOf(crSelected) !== -1) {
                if (!value) {
                    return false;
                }
            }
        }

        return true;
    });

    jQuery.validator.unobtrusive.adapters.add("cficertificatenumbercheck", ["coursereason", "requiredreasons"],
        function (options) {
            options.rules['cficertificatenumbercheck'] = {
                coursereason: options.params.coursereason,
                requiredreasons: options.params.requiredreasons
            };
            options.messages['cficertificatenumbercheck'] = options.message;
        });
}(jQuery));