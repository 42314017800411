﻿$(document)
    .ready(function () {
        if ($('div#divHSWorkshopRegistrationForm').length) {
            var theForm = $('#frmHSWorkshopRegistration');


            $('#VaccineAcknowledge').on('change', function () {
                if ($(this).is(':checked')) {
                    $('#divVaccineAckowledge').hide();
                } else {
                    $('#divVaccineAckowledge').show();
                }
            });


            $('input[name=PayingBySelected]').on('change', function () {
                if ($('input[name=PayingBySelected]:checked').val() === "CREDIT") {
                    $('#hswCCFields').show();
                    $('#hswPOFields').hide();
                    if ($('input[name=CCDomainSelected]:checked').val() === "PERSONAL") {
                        $('#hswCAFields').hide();
                    } else {
                        $('#hswCAFields').show();
                    }
                } else {
                    $('#hswCCFields').hide();
                    $('#hswPOFields').show();
                    $('#hswCAFields').show();
                }
            });

            $('input[name=CCDomainSelected]').on('change', function () {
                if ($('input[name=CCDomainSelected]:checked').val() === "PERSONAL") {
                    $('#hswCCFields').show();
                    $('#hswPOFields').hide();
                    $('#hswCAFields').hide();
                } else {
                    if ($('input[name=PayingBySelected]:checked').val() === "CREDIT") {
                        $('#hswCCFields').show();
                        $('#hswPOFields').hide();
                    } else {
                        $('#hswCCFields').hide();
                        $('#hswPOFields').show();
                    }
                    $('#hswCAFields').show();
                }
            });

            
            $(".hsw-class-choice").on('change',
                function () {
                    var workshopClass = document.getElementById(this.id);

                    if (workshopClass.value !== "") {
                        $('.' + $(this).data("seminar-selected")).show();
                    } else {
                        $('.' + $(this).data("seminar-selected")).hide();
                    }

                    fixOtherSelect(workshopClass.value, $(this).data("other-select"));

                    calculateTotal();
                });


            function fixOtherSelect(excludeValue, selectToUpdate) {
                var othSel = document.getElementById(selectToUpdate);

                if (othSel) {
                    var curSel = othSel.value;
                    var items = $('#ValidRates').val().split('|');

                    $('#' + selectToUpdate).empty();
                    var fop = $("<option />");
                    fop.attr("value", '').text('- Not Attending -');
                    $('#' + selectToUpdate).append(fop);

                    items.forEach(function (data) {
                        var item = data.split(',');

                        if (item[0] !== excludeValue) {
                            var ni = $("<option />");
                            ni.attr("value", item[0]).text(item[1]);
                            $('#' + selectToUpdate).append(ni);
                        }
                    });
                    $('#' + selectToUpdate).val(curSel);
                }
            }

            function calculateTotal() {
                var total = 0.0;

                var fdk = document.getElementById('FrederickSelected');
                if (fdk && fdk.value !== "") {
                    $('#fdkSumGrade').html(fdk.selectedOptions[0].label);
                    total += parseFloat($('#FrederickPrice').val());
                }

                var okc = document.getElementById('OklahomaSelected');
                if (okc && okc.value !== "") {
                    $('#okcSumGrade').html(okc.selectedOptions[0].label);
                    total += parseFloat($('#OklahomaPrice').val());
                }

                var regButton = document.getElementById('btnRegister');
                if (total > 0) {
                    $('#hswPaymentSection').show();
                    $('#OrderTotal').val(total);
                    $('.event-payment-total__price').text('$' + total.toFixed(2));
                    regButton.disabled = false;
                    $('#btnRegister').removeClass('hsw-disable-button');
                } else {
                    $('#hswPaymentSection').hide();
                    $('#OrderTotal').val(-1);
                    $('.event-payment-total__price').text('$0.00');
                    regButton.disabled = true;
                    $('#btnRegister').addClass('hsw-disable-button');
                }
            }

            $(".hswRegisterButton").click(function () {
                var fdk = document.getElementById('FrederickSelected');
                var vr = document.getElementById('VaccineAcknowledge');

                if (theForm.valid() && ((fdk === null) || (fdk.value === '') || (fdk.value !== ''  && vr.checked))) {
                        $('#btnRegister').hide();
                        $(this).hide();
                        $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                        theForm.submit();
                } else {
                    $('#divVaccineAckowledge').show();
                }
            });
        }
    });
