﻿$(document)
    .ready(function () {
        if ($('div#divFlyInRegistrationForm').length) {
            var theForm = $('#frmFlyInRegistration');

            $(".btnCancel")
                .click(function () {
                    window.location.href = "https://www.aopa.org";
                });

            $(".flyincontinuebutton")
                .click(function () {
                    if (validateDiv($(this).data("thispage"))) {
                        var btn = document.getElementById(this.id);
                        var nextpage = $(this).data("nextpage");

                        if (btn != null && btn.hasAttribute("data-nextpage-choice")) {
                            var cb = document.getElementById($(this).data("nextpage-choice"));
                            if (!cb.checked) {
                                nextpage = $(this).data("alt-nextpage");
                            }
                        }

                        $('#' + $(this).data("thispage")).hide();
                        $('#' + nextpage).show();
                        infoDivs(nextpage);
                        window.scrollTo(0, 0);
                    }
                });


            $(".flyinbackbutton")
                .click(function () {
                    var btn = document.getElementById(this.id);
                    var prevpage = $(this).data("prevpage");

                    if (btn != null && btn.hasAttribute("data-prevpage-choice")) {
                        var cb = document.getElementById($(this).data("prevpage-choice"));
                        if (!cb.checked) {
                            prevpage = $(this).data("alt-prevpage");
                        }
                    }

                    $('#' + $(this).data("thispage")).hide();
                    $('#' + prevpage).show();
                    infoDivs(prevpage);
                    window.scrollTo(0, 0);
                });

            $(".fi-control-cb")
                .click(function () {
                    var cb = document.getElementById(this.id);
                    if (cb.checked) {
                        $('#' + $(this).data("reveal-div")).show();
                        calculateTotal();
                    } else {
                        $('#' + $(this).data("reveal-div")).hide();
                        calculateTotal();
                    }
                });

            $('.fly-in-guest-u').change(function () {
                var ok = true;
                var ac = 0;
                var ct = parseInt($('#GateFeeAdultCount').val(), 10);
                if (ct !== NaN && ct >= 0) {
                    ac += ct;
                } else {
                    ok = false;
                }
                ct = parseInt($('#GateFeeChildCount').val(), 10);
                if (ct !== NaN && ct >= 0) {
                    ac += ct;
                } else {
                    ok = false;
                }
                if (ok) {
                    calculateTotal();
                    $('#AttendeeCount').val(ac);
                }
            });

            $('.fly-in-meal-u').change(function () {
                var ct = parseInt($('#' + this.id).val(), 10);
                if (ct !== NaN && ct >= 0) {
                    calculateTotal();
                }
            });

            $('.fly-in-workshop-u').change(function () {
                var ct = parseInt($('#' + this.id).val(), 10);
                if (ct !== NaN && ct >= 0) {
                    calculateTotal();
                }
            });

            $('#TravelOptionsSelected')
                .change(function () {
                    var option = $(this).find('option:selected').val();

                    if (option === "FLY") {
                        $('.fly-in-travel-fly').show();
                    } else {
                        $('.fly-in-travel-fly').hide();
                    }
                });

            function validateDiv(div) {
                var validator = theForm.data('validator');
                var returnValue = true;

                $('#' + div + ' :input').each(function (e) {
                    if (!$(this).is(":button") && this.id.length) {
                        if (!validator.element($('#' + this.id))) returnValue = false;
                    }
                });

                if (div === 'fiPage2') {
                    if ($('input[name=MembershipSelected]:checked').val() === undefined || $('input[name=MembershipSelected]:checked').val() === "") {
                        $('#divMemberSelectValid').show();
                    } else {
                        $('#divMemberSelectValid').hide();
                    }
                }

                return returnValue;
            }

            $(".fiRegisterButton").click(function () {
                if (theForm.valid()) {
                    $('.btn_final_cancelback').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });

            $(".vol-selection-update").change(function () {
                var nextButton = document.getElementById('btnPage5Forward');

                var daylist = '';
                $('.VolunteerDaySelected').each(function (index, obj) {
                    if ($(this).prop('checked')) {
                        daylist += this.value + ',';
                    }
                });
                var choices = [];
                if (daylist !== '') daylist = daylist.slice(0, -1);
                if (daylist !== '' &&
                    $('input[name=VolunteerIntensitySelected]:checked').val() !== '' &&
                    $('#VolunteerCertifiedPilotSelected').val() !== '') {
                    $.each(daylist.split(','),
                        function (di, dv) {
                            $.each($('#VolunteerCertifiedPilotSelected').val().split('|'),
                                function (pi, pv) {
                                    choices.push(dv + pv + $('input[name=VolunteerIntensitySelected]:checked').val());
                                });
                        });
                }

                if (choices.length > 0) {

                    var found = false;
                    $.each(choices,
                        function (di, dv) {
                            if ($('#VolunteerOptionsAvailable').val().includes(dv)) {
                                found = true;
                            }
                        });

                    if (!found) {
                        $('#divVolunteerFilter').show();
                        nextButton.disabled = true;
                        $('#btnPage5Forward').addClass('fly-in-disable-button');
                    } else {
                        $('.fly-in-vol-airside').hide();
                        $('.fly-in-vol-showside').hide();
                        $('.fly-in-volunteer-day-wrapper').hide();

                        $('.fly-in-volunteer-checkbox').each(function (i, obj) {
                            if ($(this).data("vol-category-code") ===
                                $('input[name=VolunteerIntensitySelected]:checked').val() &&
                                $('#VolunteerCertifiedPilotSelected').val().includes($(this).data("vol-level-code"))) {

                                $('.' +
                                    $(this).data("vol-level-code").toLowerCase() +
                                    '-' +
                                    $(this).data("vol-item-day")).show();
                                if (daylist.indexOf($(this).data("vol-item-day").toUpperCase()) >= 0) {
                                    $('.fly-in-vol-day-' + $(this).data("vol-item-day")).show();
                                }
                                $(this).show();
                            } else {
                                $(this).hide();
                            }
                        });

                        $('#divVolunteerFilter').hide();
                        nextButton.disabled = false;
                        $('#btnPage5Forward').removeClass('fly-in-disable-button');
                    }
                }
            });

            $('.vol-job-selection-cb').change(function () {
                var cb = document.getElementById(this.id);
                if ($('#VolunteerJobsSelected').val().length) {
                    var items = $.map($('#VolunteerJobsSelected').val().split(','),
                        function (value) {
                            return parseInt(value, 10);
                        });

                    var index = items.indexOf(parseInt(cb.value, 10));

                    if (cb.checked) {
                        if (index === -1) {
                            items.push(parseInt(cb.value, 10));
                        }
                    } else {
                        if (index > -1) {
                            items.splice(index, 1);
                        }
                    }

                    $('#VolunteerJobsSelected').val(items.join(','));

                } else {
                    if (cb.checked) $('#VolunteerJobsSelected').val(cb.value);
                }

                SetSummary();
            });

            $('#GateFeeAdultCount').change(function() {
                $('#GateFeeAdultCount').valid();
                $('#GateFeeChildCount').valid();
            });

            $('#GateFeeChildCount').change(function () {
                $('#GateFeeAdultCount').valid();
                $('#GateFeeChildCount').valid();
            });

            function infoDivs(page) {
                switch (page) {
                    case 'fiPage1':
                        $('#fiProductName').show();
                        $('#fiMainInfo').show();
                        $('#fiMealInfo').hide();
                        $('#fiVolunteerInfo').hide();
                        $('#fiCareerFairInfo').hide();
                        $('#fiWorkshopInfo').hide();
                        $('#fiPRustyPilotInfo').hide();
                        $('#fiCampingInfo').hide();
                        $('#divEventSummary').show();

                        break;
                    case 'fiPage2':
                        $('#fiProductName').show();
                        $('#fiMainInfo').show();
                        $('#fiMealInfo').hide();
                        $('#fiVolunteerInfo').hide();
                        $('#fiCareerFairInfo').hide();
                        $('#fiWorkshopInfo').hide();
                        $('#fiPRustyPilotInfo').hide();
                        $('#fiCampingInfo').hide();
                        $('#divEventSummary').show();
                        break;
                    case 'fiPage3':
                        $('#fiProductName').show();
                        $('#fiMainInfo').show();
                        $('#fiMealInfo').show();
                        $('#fiVolunteerInfo').hide();
                        $('#fiCareerFairInfo').hide();
                        $('#fiWorkshopInfo').hide();
                        $('#fiPRustyPilotInfo').hide();
                        $('#fiCampingInfo').hide();
                        $('#divEventSummary').show();
                        break;
                    case 'fiPage4':
                        $('#fiProductName').show();
                        $('#fiMainInfo').hide();
                        $('#fiMealInfo').hide();
                        $('#fiVolunteerInfo').show();
                        $('#fiCareerFairInfo').hide();
                        $('#fiWorkshopInfo').hide();
                        $('#fiPRustyPilotInfo').hide();
                        $('#fiCampingInfo').hide();
                        $('#divEventSummary').show();
                        break;
                    case 'fiPage5':
                        $('#fiProductName').show();
                        $('#fiMainInfo').hide();
                        $('#fiMealInfo').hide();
                        $('#fiVolunteerInfo').show();
                        $('#fiCareerFairInfo').hide();
                        $('#fiWorkshopInfo').hide();
                        $('#fiPRustyPilotInfo').hide();
                        $('#fiCampingInfo').hide();
                        $('#divEventSummary').show();
                        break;
                    case 'fiPage6':
                        $('#fiProductName').show();
                        $('#fiMainInfo').hide();
                        $('#fiMealInfo').hide();
                        $('#fiVolunteerInfo').hide();
                        $('#fiCareerFairInfo').show();
                        $('#fiWorkshopInfo').show();
                        $('#fiPRustyPilotInfo').show();
                        $('#fiCampingInfo').hide();
                        $('#divEventSummary').show();
                        break;
                    case 'fiPage7':
                        $('#fiProductName').show();
                        $('#fiMainInfo').hide();
                        $('#fiMealInfo').hide();
                        $('#fiVolunteerInfo').hide();
                        $('#fiCareerFairInfo').hide();
                        $('#fiWorkshopInfo').hide();
                        $('#fiPRustyPilotInfo').hide();
                        $('#fiCampingInfo').show();
                        $('#divEventSummary').show();
                        break;
                    case 'fiPage8':
                        $('#fiProductName').hide();
                        $('#fiMainInfo').hide();
                        $('#fiMealInfo').hide();
                        $('#fiVolunteerInfo').hide();
                        $('#fiCareerFairInfo').hide();
                        $('#fiWorkshopInfo').hide();
                        $('#fiPRustyPilotInfo').hide();
                        $('#fiCampingInfo').hide();
                        $('#divEventSummary').hide();
                        break;
                }
            }

            function calculateTotal() {
                SetSummary();

                var total = 0.0;

                total += parseInt($('#GateFeeAdultCount').val(), 10) * parseFloat($('#GateFeeAdultPrice').val());
                total += parseInt($('#GateFeeChildCount').val(), 10) * parseFloat($('#GateFeeChildPrice').val());

                total += parseInt($('#BreakfastFridayAdult').val(), 10) * parseFloat($('#FridayBreakfastAdultPrice').val());
                total += parseInt($('#BreakfastFridayChild').val(), 10) * parseFloat($('#FridayBreakfastChildPrice').val());
                total += parseInt($('#DinnerFridayAdult').val(), 10) * parseFloat($('#FridayDinnerAdultPrice').val());
                total += parseInt($('#DinnerFridayChild').val(), 10) * parseFloat($('#FridayDinnerChildPrice').val());
                total += parseInt($('#BreakfastSaturdayAdult').val(), 10) * parseFloat($('#SaturdayBreakfastAdultPrice').val());
                total += parseInt($('#BreakfastSaturdayChild').val(), 10) * parseFloat($('#SaturdayBreakfastChildPrice').val());

                if (document.getElementById("WorkshopChoice").checked) {
                    total += parseInt($('#WorkshopCount').val(), 10) * parseFloat($('#WorkshopPrice').val());
                }

                if (document.getElementById("RustyPilotChoice").checked) {
                    total += parseFloat($('#RustyPilotPrice').val());
                }

                if (document.getElementById("CampingChoice").checked) {
                    total += parseFloat($('#CampingPrice').val());
                }

                $('#OrderTotal').val(total);
                if (total === 0.0) {
                    $('.event-payment-total__price').text('FREE');
                } else {
                    $('.event-payment-total__price').text('$' + total.toFixed(2));
                }


                if (total > 0) {
                    $('#fiPaymentSection').show();
                } else {
                    $('#fiPaymentSection').hide();
                }

            }

            function SetSummary() {

                if ($('#GateFeeAdultCount').val() > 0) {
                    $('#dqtyMainAdult').text(parseInt($('#GateFeeAdultCount').val(), 10));
                    var adTotal = (parseInt($('#GateFeeAdultCount').val(), 10)) * parseFloat($('#GateFeeAdultPrice').val());
                    if (adTotal > 0) {
                        $('#spMainAdult').text('$' + adTotal.toFixed(2));
                    } else {
                        $('#spMainAdult').text('FREE');
                    }
                    $('#dsrMainAdult').show();
                } else {
                    $('#dsrMainAdult').hide();
                }

                if ($('#GateFeeChildCount').val() > 0) {
                    $('#dqtyMainChild').text(parseInt($('#GateFeeChildCount').val(),10));
                    $('#spMainChild').text('$' + (parseInt($('#GateFeeChildCount').val(), 10) * parseFloat($('#GateFeeChildPrice').val())).toFixed(2));
                    $('#dsrMainChild').show();
                } else {
                    $('#dsrMainChild').hide();
                }

                if (document.getElementById("WorkshopChoice").checked && parseInt($('#WorkshopCount').val(), 10) > 0) {
                    $('#dqtyWorkshop').text($('#WorkshopCount').val());
                    $('#spWorkshop').text('$' + (parseInt($('#WorkshopCount').val(), 10) * parseFloat($('#WorkshopPrice').val())).toFixed(2));
                    $('#dsrWorkshop').show();
                } else {
                    $('#dsrWorkshop').hide();
                }

                if (document.getElementById("CampingChoice").checked) {
                    $('#dsrCamping').show();
                } else {
                    $('#dsrCamping').hide();
                }

                if (document.getElementById("CareerFairChoice").checked) {
                    $('#dsrCareerFair').show();
                } else {
                    $('#dsrCareerFair').hide();
                }

                var showWednesday = false;
                var showThursday = false;
                var showFriday = false;
                var showSaturday = false;
                var showSunday = false;

                $('#WEDNESDAY').hide();
                $('#THURSDAY').hide();
                $('#FRIDAY').hide();
                $('#SATURDAY').hide();
                $('#SUNDAY').hide();


                showFriday = MealSet("Friday", "Breakfast", "Adult", showFriday);
                showFriday = MealSet("Friday", "Breakfast", "Child", showFriday);
                showFriday = MealSet("Friday", "Dinner", "Adult", showFriday);
                showFriday = MealSet("Friday", "Dinner", "Child", showFriday);
                showSaturday = MealSet("Saturday", "Breakfast", "Adult", showSaturday);
                showSaturday = MealSet("Saturday", "Breakfast", "Child", showSaturday);

                if (parseInt($('#BreakfastFridayAdult').val(), 10) === 0 &&
                    parseInt($('#BreakfastFridayChild').val(), 10) === 0) {
                    $('#dsrFridayBreakfastMain').hide();
                }
                if (parseInt($('#DinnerFridayAdult').val(), 10) === 0 &&
                    parseInt($('#DinnerFridayChild').val(), 10) === 0) {
                    $('#dsrFridayDinnerMain').hide();
                }
                if (parseInt($('#BreakfastSaturdayAdult').val(), 10) === 0 &&
                    parseInt($('#BreakfastSaturdayChild').val(), 10) === 0) {
                    $('#dsrSaturdayBreakfastMain').hide();
                }

                if (document.getElementById("RustyPilotChoice").checked) {
                    $('#dsrRustyPilots').show();
                    showSaturday = true;
                } else {
                    $('#dsrRustyPilots').hide();
                }

                var volcbs = document.getElementsByClassName("vol-job-selection-cb");


                for (var i = 0; i < volcbs.length; i++) {
                    if (volcbs[i].checked) {
                        $('#dsr' + volcbs[i].value).show();
                        switch ($('#' + volcbs[i].id).data('day')) {
                            case 'WEDNESDAY':
                                showWednesday = true;
                                break;
                            case 'THURSDAY':
                                showThursday = true;
                                break;
                            case 'FRIDAY':
                                showFriday = true;
                                break;
                            case 'SATURDAY':
                                showSaturday = true;
                                break;
                            case 'SUNDAY':
                                showSunday = true;
                                break;
                        }
                    } else {
                        $('#dsr' + volcbs[i].value).hide();
                    }
                }


                if (showWednesday)
                    $('#WEDNESDAY').show();
                if (showThursday)
                    $('#THURSDAY').show();
                if (showFriday)
                    $('#FRIDAY').show();
                if (showSaturday)
                    $('#SATURDAY').show();
                if (showSunday)
                    $('#SUNDAY').show();
            }

            function MealSet(day, meal, person, showDay) {
                var show = false;
                if (parseInt($('#' + meal + day + person).val(), 10) > 0) {
                    $('#dsr' + day + meal + 'Main').show();
                    $('#dsr' + day + meal + person).show();
                    $('#dqty' + day + meal + person).text(parseInt($('#' + meal + day + person).val(), 10));
                    $('#sp' + day + meal + person).text('$' + (parseInt($('#' + meal + day + person).val(), 10) * parseFloat($('#' + day + meal + person + 'Price').val())).toFixed(2));
                    show = true;
                } else {
                    $('#dsr' + day + meal + person).hide();
                }

                return showDay || show;
            }

            $('.fi-membership-options').change(function () {
                var nextButton = document.getElementById('btnPage3Forward');
                $('#divMemberSelectValid').hide();
                if ($('input[name=MembershipSelected]:checked').val() === "YES") {
                    $('.fi-non-member-text').hide();
                    nextButton.disabled = false;
                    $('#btnPage3Forward').removeClass('fly-in-disable-button');
                } else {
                    $('.fi-non-member-text').show();
                    nextButton.disabled = true;
                    $('#btnPage3Forward').addClass('fly-in-disable-button');
                }
            });
        }
    });
