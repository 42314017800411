﻿$(document).ready(function () {
    if ($('div#divLSPAttorneyInformation').length) {
        $('.lsp-country-select').change(function () {
            var option = $(this).find('option:selected').val();
            var stateId = $(this).data('lsp-state');

            $.getJSON('/api/CountryState/GetStates/?countryCode=' + option)
                .done(function (data) {
                    $('#' + stateId).empty();
                    $.each(data, function (key, item) {
                        var $option = $("<option />");
                        $option.attr("value", item.value).text(item.text.replace('Error','Select'));
                        $('#' + stateId).append($option);
                    });
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#' + stateId).empty();
                    var $option = $("<option />");
                    $options.attr("value", "").text("- Select -");
                    $('#' + stateId).append($option);
                });
        });

        $(".add-action-clear").click(function() {
            $("#LspPracticeAreasTemp option:selected").removeAttr("selected");
            $("#LspPracticeStateTemp option:selected").removeAttr("selected");
            $("#LspFaaCertificationsTemp option:selected").removeAttr("selected");
        });

        $('.add-action-button').click(function () {
            var theElement = $(this).data('add-element');
            $("#Lsp" + theElement + "Temp option:selected").each(function () {
                var selval = $(this)[0].value;

                var exists = false;
                var lis = document.getElementById('ul' + theElement).getElementsByTagName("li");

                for (var i = 0; i < lis.length; i++) {
                    if (lis[i].innerHTML.replace('&amp;','&') === selval) {
                        exists = true;
                    }
                }

                if (!exists) {
                    $('#ul' + theElement).append('<li data-add-element="' + theElement + '">' + selval + '</li>');
                }
            });
            $("#Lsp" + theElement + "Temp option:selected").removeAttr("selected");
            SetSelected(theElement);
        });

        $(".add-action-ul").on('click', 'li', (function () {
                var theElement = $(this).data('add-element');
                $(this).remove();
                SetSelected(theElement);
            })
        );

        function SetSelected(theElement) {
            var sts = '';

            $('#ul' + theElement + ' li').each(function (i, li) {
                if (sts.length) {
                    sts += '|' + $(li).text();
                } else {
                    sts = $(li).text();
                }
            });

            $('#Lsp' + theElement).val(sts);

            if (sts !== '') {
                $('#div' + theElement).show();
            } else {
                $('#div' + theElement).hide();
            }
        }



        $('#btnPSAdd').click(function () {
            $("#LspPracticeStateTemp option:selected").each(function () {
                var selval = $(this)[0].value.split('|');

                if (selval[0] === "USA") {
                    var exists = $('#ulPracticeStates li:contains(' + selval[1] + ')').length;
                    if (!exists) {
                        $('#ulPracticeStates')
                            .append('<li>' + selval[1] + '</li>');
                    }
                } else {
                    var exists = $('#ulPracticeProvinces li:contains(' + selval[1] + ')').length;
                    if (!exists) {
                        $('#ulPracticeProvinces')
                            .append('<li>' + selval[1] + '</li>');
                    }
                }
            });
            $("#LspPracticeStateTemp option:selected").removeAttr("selected");
            SetSelectedPractice("States");
            SetSelectedPractice("Provinces");
        });

        $("#ulPracticeStates").on('click', 'li', (function () {
                $(this).remove();
                SetSelectedPractice("States");
            })
        );

        $("#ulPracticeProvinces").on('click', 'li', (function () {
                $(this).remove();
                SetSelectedPractice("Provinces");
            })
        );

        function SetSelectedPractice(stateprov) {
            var sts = '';

            $('#ulPractice' + stateprov + ' li').each(function (i, li) {
                if (sts.length) {
                    sts += '|' + $(li).text();
                } else {
                    sts = $(li).text();
                }
            });

            $('#LspPractice' + stateprov).val(sts);

            if (sts !== '') {
                $('#divPractice' + stateprov).show();
            } else {
                $('#divPractice' + stateprov).hide();
            }
        }

        $('.alt-addr-button-add').click(function () {
            var theAlt = $(this).data('alt-addr');

            $('#btn' + theAlt + 'AddressAdd').hide();
            $('#btn' + theAlt + 'AddressRemove').show();
            $('#div' + theAlt + 'Address').show();

            $('#' + theAlt + 'AddressRemove').val('False');
        });

        $('.alt-addr-button-remove').click(function () {
            var theAlt = $(this).data('alt-addr');
            $('#btn' + theAlt + 'AddressAdd').show();
            $('#btn' + theAlt + 'AddressRemove').hide();
            $('#div' + theAlt + 'Address').hide();

            if ($('#' + theAlt + 'AddressId').val() !== "" && $('#' + theAlt + 'AddressId').val() !== "0") {
                $('#' + theAlt + 'AddressRemove').val('True');
            } else {
                $('#' + theAlt + 'AddressId').val('') 
                $('#' + theAlt + 'Address1').val('');
                $('#' + theAlt + 'Address2').val('');
                $('#' + theAlt + 'City').val('');
                $('#' + theAlt + 'Country').val('').change();
                $('#' + theAlt + 'PostalCode').val('');
                $('#' + theAlt + 'AddressRemove').val('False');
            }

        });
    }
});
