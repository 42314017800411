﻿$(document)
    .ready(function () {
        if ($('div#divAftaStudentPublicForm').length) {
            var theForm = $('#frmAftaStudentPublic');

            var aspfStripe = Stripe($('#sapik').val());

            var aspfElements = aspfStripe.elements();
            var aspfStyle = {
                base: {
                    backgroundColor: '#eeeded',
                    fontSize: '15px',
                    fontFamily: 'Roboto, Helvetica Neue, sans-serif',
                    lineHeight: '42px',
                    ':focus': {
                        border: '1px solid #c1c2c3'
                    }
                }
            };
            var aspfCardElement = aspfElements.create("card", { style: aspfStyle, hidePostalCode: true, disableLink: true });
            aspfCardElement.mount("#card-element");

            aspfCardElement.on('change',
                function (event) {
                    var displayError = document.getElementById('card-errors');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }

                    $('#CardBrand').val(event.brand);
                });



            var autocomplete;

            var initAutocomplete = () => {
                // Create the autocomplete object, restricting the search predictions to
                autocomplete = new google.maps.places.Autocomplete(AddressLookup,
                    {
                        fields: ["address_components"],
                        types: ["address"],
                    });
                autocomplete.setComponentRestrictions({
                    country: ["us"]
                });
                // When the user selects an address from the drop-down, populate the
                // address fields in the form.
                autocomplete.addListener("place_changed", fillInAddress);
            };

            initAutocomplete();


            function fillInAddress() {
                // Get the place details from the autocomplete object.
                var place = autocomplete.getPlace();
                var address1 = "";
                var postcode = "";

                $("#MailingStreet").val("");
                $("#MailingCity").val("");
                $("#MailingStateProvince").val("");
                $("#MailingPostalCode").val("");

                // Get each component of the address from the place details,
                // and then fill-in the corresponding field on the form.
                // place.address_components are google.maps.GeocoderAddressComponent objects
                // which are documented at http://goo.gle/3l5i5Mr
                place.address_components.forEach((component) => {
                    var componentType = component.types[0];

                    switch (componentType) {
                        case "street_number":
                            {
                                address1 = `${component.long_name} ${address1}`;
                                break;
                            }

                        case "route":
                            {
                                address1 += component.short_name;
                                break;
                            }

                        case "postal_code":
                            {
                                postcode = `${component.long_name}${postcode}`;
                                break;
                            }

                        case "postal_code_suffix":
                            {
                                postcode = `${postcode}-${component.long_name}`;
                                break;
                            }
                        case "locality":
                            $("#MailingCity").val(component.long_name);
                            clearValidation("MailingCity");
                            break;
                        case "administrative_area_level_1":
                            {
                                $("#MailingStateProvince").val(component.short_name);
                                clearValidation("MailingStateProvince");
                                break;
                            }
                    }
                });

                $("#MailingStreet").val(address1);
                clearValidation("MailingStreet");
                $("#MailingPostalCode").val(postcode);
                clearValidation("MailingPostalCode");
                $("#MailingStreet").focus();
            };

            var clearValidation = (field) => {
                var theField = $('#' + field);
                if (theField.val()) {
                    theField.removeClass("input-validation-error").addClass("valid");
                    $("span[data-valmsg-for='" + field + "']").removeClass("field-validation-error").addClass("field-validation-valid");
                    $("span[data-valmsg-for='" + field + "']").empty();
                }
            };




            $('#DateOfBirth').datepicker({
                changeMonth: true,
                changeYear: true,
                yearRange: (new Date).getFullYear() - 110 + ':' + (new Date).getFullYear()
            });


            $('input[type=radio][name=PaymentOptionSelected]').change(function () {
                if (this.value == 'USECARDONFILE') {
                    $('#divCardFields').hide();
                }
                else {
                    $('#divCardFields').show();
                }
            });


            $('#AARAgree').change(function () {
                //if (this.checked && $("#TermsOfService").val() === "ACCEPTED") {
                if (this.checked) {
                    $('#btnEnroll').removeClass('afta-disable-button');
                    $('#btnEnroll').prop('disabled', false);

                } else {
                    $('#btnEnroll').addClass('afta-disable-button');
                    $('#btnEnroll').prop('disabled', true);
                }
            });


            $(".submitbuttonaftastudentschoolsubmit").click(function () {
                if (theForm.valid()) {
                    $('#bnSubmitSchool').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });

            var checkCardFields = () => {
                var isValid = false;

                if ($('#ShowStripeForm').val() === "True") {
                    var cardElement = document.querySelector('#card-element');

                    isValid = cardElement.classList.contains('StripeElement--complete');

                    var brand = $('#CardBrand').val();

                    if (isValid &&
                        (brand === 'visa' || brand === 'mastercard' || brand === 'amex' || brand === 'discover')) {
                        $('#card-errors').html('');
                    } else {
                        if ($('#card-errors').is(':empty')) {
                            $('#card-errors').html('Please check your credit card information.');
                        }
                    }

                } else {
                    isValid = true;
                }


                return isValid;
            }

            $(".submitbuttonaftastudent").click(function () {
                var ccValid = checkCardFields();
                var progressBar = new Sfpb_progressBar();


                if (theForm.valid() && ccValid) {
                    $('#btnEnroll').hide();
                    $(this).hide();
                    $('body').addClass('progressbar-modal');
                    progressBar.start();
                    $(".clsSubmitButton").append('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');

                    if ($('#ShowStripeForm').val() === "True") {
                        aspfStripe.createToken(aspfCardElement, { name: $("#CardName").val() })
                            .then(function(result) {
                                if (result.token !== null) {
                                    $("#CardToken").val(JSON.stringify(result.token));
                                    theForm.submit();
                                } else {
                                    if ($('#card-errors').is(':empty')) {
                                        $('#card-errors').html('Please check your credit card information.');
                                    }
                                    var spinner = document.getElementById('aopaSpinner');
                                    spinner.parentElement.removeChild(spinner);
                                    $(this).show();
                                    progressBar.kill();
                                    $('body').removeClass('progressbar-modal');
                                    $('#btnEnroll').show();
                                }
                            });

                    } else {
                        theForm.submit();
                    }
                } else {
                    var firstInstance = $('.input-validation-error').first();
                    var offsetTop = firstInstance.offset().top - 100;

                    $('html, body').animate({
                        scrollTop: offsetTop
                    }, 500);
                }
            });
        }
    });