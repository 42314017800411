﻿$(document).ready(function () {
    if ($('div#divAviatorShowcaseSignIn').length) {
        var rpForm = $('#frmAviatorShowcaseSignIn');
        var flagSearch = "Search";
        var flagCheckIn = "CheckIn";
        var flagCheckInAll = "CheckInAll";
        var flagClearSearch = "ClearSearch";
        var flagSearchReturn = "SearchReturn";
        var flagLoadCheckin = "LoadCheckin";
        
        //
        // Search click
        //
        $('#btnSearch').click(function () {
            $('#FormMode').val(flagSearch);

            if (rpForm.valid()) {
                rpForm.submit();
            }
        });

        //
        // Clear Search click
        //
        $('#btnClearSearch').click(function () {
            $('#FormMode').val(flagClearSearch);
           rpForm.submit();
        });

        //
        // Back to search
        //
        $('.back-to-search').click(function () {
            $('#FormMode').val(flagSearchReturn);

            if ($("div#divQuestionnaire").length) {
                $("#divQuestionnaire *").prop('disabled', true);
            }

            if ($("div#divCreateCustomer").length) {
                $("#divCreateCustomer *").prop('disabled', true);
            }

            rpForm.submit();
        });

        //
        // Goto checkin
        //
        $(document).on("click",
            ".view-checkin-button",
            function (e) {
                $('#FormMode').val(flagLoadCheckin);
                $('#CheckinMasterCustomerId').val($(this).data("mcid"));
                $('#CheckInSubCustomerId').val($(this).data("scid"));
                $('#CheckinOrderNumber').val($(this).data("ordnum"));
                $('#CheckinProductCode').val($('#RPEvent').val());
                rpForm.submit();
            });

        //
        // Process checkin
        //
        $(document).on("click",
            ".checkin-action-button",
            function (e) {
                $('#FormMode').val(flagCheckIn);
                $('#CheckInOrderLineNumber').val($(this).data("lineno"));
                $('#CheckInBadgeSequence').val($(this).data("badgeseq"));
                rpForm.submit();
            });

        // Process checkin
        //
        $(document).on("click",
            ".checkinall-action-button",
            function (e) {
                $('#FormMode').val(flagCheckInAll);
                $('#CheckinAllList').val($(this).data("cialllist"));
                rpForm.submit();
            });
    };
});