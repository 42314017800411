﻿$(document)
    .ready(function () {
        $(document).on('change', '.afCountrySelector', function () {
            var $theForm = $(this).parents("form");
            var $theSection = $(this).parents("section");


            var option = $(this).find('option:selected').val();

            $theSection.addClass("account-spinner");
            $.getJSON('/api/CountryState/GetStates/?countryCode=' + option)
                .done(function (data) {
                    var $states = $('#' + $theForm[0].id + ' #State');
                    $states.empty();

                    $.each(data,
                        function (key, item) {
                            var $option = $("<option />");
                            if (item.value === "") {
                                $option.attr("value", item.value).text("- Select -");
                            } else {
                                $option.attr("value", item.value).text(item.text);
                                if (item.value === $('#' + $theForm[0].id + ' #initState').val()) {
                                    $option.attr("selected", "selected");
                                }
                            }
                            $states.append($option);
                        });
                    $.getJSON('/api/AddressStructure/GetAddressStructure/?countryCode=' + option)
                        .done(function (data) {
                            if (!jQuery.isEmptyObject(data)) {
                                setAddressLine(data, $theForm, "ADDRESS_1", "Address1");
                                setAddressLine(data, $theForm, "ADDRESS_2", "Address2");
                                setAddressLine(data, $theForm, "ADDRESS_3", "Address3");
                                setAddressLine(data, $theForm, "ADDRESS_4", "Address4");
                                setAddressLine(data, $theForm, "CITY", "City");
                                setAddressLine(data, $theForm, "STATE", "State");
                                setAddressLine(data, $theForm, "COUNTY", "County");
                                setAddressLine(data, $theForm, "POSTAL_CODE", "PostalCode");
                                setAddressLine(data, $theForm, "COUNTRY_DESCR", "Country");
                            } else {
                                $.getJSON('/api/AddressStructure/GetAddressStructure/?countryCode=[ALL]')
                                    .done(function (data) {
                                        setAddressLine(data, $theForm, "ADDRESS_1", "Address1");
                                        setAddressLine(data, $theForm, "ADDRESS_2", "Address2");
                                        setAddressLine(data, $theForm, "ADDRESS_3", "Address3");
                                        setAddressLine(data, $theForm, "ADDRESS_4", "Address4");
                                        setAddressLine(data, $theForm, "CITY", "City");
                                        setAddressLine(data, $theForm, "STATE", "State");
                                        setAddressLine(data, $theForm, "COUNTY", "County");
                                        setAddressLine(data, $theForm, "POSTAL_CODE", "PostalCode");
                                        setAddressLine(data, $theForm, "COUNTRY_DESCR", "Country");
                                    })
                                    .fail(function (jqXHR, textStatus, err) {
                                    });
                            }
                        })
                        .fail(function (jqXHR, textStatus, err) {
                        });
                    $theSection.removeClass("account-spinner");
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#' + $theForm[0].id + ' #State').empty();
                    var $option = $("<option />");
                    $options.attr("value", "").text("- Error -");
                    $('#' + $theForm[0].id + ' #State').append($option);
                    $theSection.removeClass("account-spinner");
                });
        });

        function setAddressLine(data, theForm, line, field) {
            var theLine = $.grep(data,
                function (n, i) {
                    return n.name === line;
                });

            //
            // Get the Container
            //
            var conDiv;
            var conDivOffState;
            
            if (line === 'STATE') {
                if ($('#' + theForm[0].id + ' #' + field + ' option').length > 1) {
                    conDiv = $('#' + theForm[0].id).find('.af' + field + 'Dropdown');
                    conDivOffState = $('#' + theForm[0].id).find('.af' + field + 'Text');
                } else {
                    conDiv = $('#' + theForm[0].id).find('.af' + field + 'Text');
                    conDivOffState = $('#' + theForm[0].id).find('.af' + field + 'Dropdown');
                }
            } else {
                conDiv = $('#' + theForm[0].id).find('.af' + field);
            }

            

            var lbl = conDiv.children('label');
            if (line === 'COUNTRY_DESCR' || theLine[0].required) {
                lbl.html(theLine[0].label + "<span class=\"redCopy\">*</span>");
                if (line === 'STATE') {
                    if ($('#' + theForm[0].id + ' #' + field + ' option').length > 1) {
                        $('#' + theForm[0].id + ' #' + field + 'Required').val(theLine[0].required);
                        $('#' + theForm[0].id + ' #' + field + 'TextRequired').val('False');
                    } else {
                        $('#' + theForm[0].id + ' #' + field + 'Required').val('False');
                        $('#' + theForm[0].id + ' #' + field + 'TextRequired').val(theLine[0].required);
                    }
                } else {
                    $('#' + theForm[0].id + ' #' + field + 'Required').val('True');
                }
            } else {
                lbl.html(theLine[0].label);
                $('#' + theForm[0].id + '#' + field + 'Required').val('False');
                if (line === 'STATE') {
                    $('#' + theForm[0].id + ' #' + field + 'TextRequired').val('False');
                }
            }

            if (line !== 'COUNTRY_DESCR') {
                if (line === 'STATE') {
                    $('#' + theForm[0].id + ' #' + field + 'Text').attr('maxlength', theLine[0].length);
                } else {
                    $('#' + theForm[0].id + ' #' + field).attr('maxlength', theLine[0].length);
                }
            }

            if (theLine[0].show) {
                if (field !== 'COUNTRY_DESCR') {
                    conDiv.show();
                }

                if (line === 'STATE') {
                    if ($('#' + theForm[0].id + ' #' + field + ' option').length > 1) {
                        $('#' + theForm[0].id + ' #' + field + 'UsesDropDown').val('True');
                    } else {
                        $('#' + theForm[0].id + ' #' + field + 'UsesDropDown').val('False');
                    }
                    conDiv.show();
                    conDivOffState.hide();
                }
            } else {
                if (line !== 'COUNTRY_DESCR') {
                    conDiv.hide();
                    $('#' + theForm[0].id + ' #' + field).val("");
                }

                if (line === 'STATE') {
                    conDivOffState.hide();
                    $('#' + theForm[0].id + ' #State').empty();
                    var $option = $("<option />");
                    $option.attr("value", "0").text("- No State/Province -");
                    $('#' + theForm[0].id + ' #State').append($option);

                }
            }
        }
    });

