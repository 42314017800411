/**
 * Google Maps API proxy
 */
class GoogleService {
    constructor($http, $q) {
        this._initialized = false;
        this.$http = $http;
        this.$q = $q;
    }

    /**
     * Returns true if the service has been initialized.
     */
    get ready() {
        return this._initialized;
    }

    /**
     * Initializes the service. This method should only be called when the Google Maps API
     * has finished loading.
     */
    init(map) {
        if (typeof google === 'undefined') {
            throw new Error('Google Maps API not loaded.');
        }
        this._map = map;
        this._autocomplete = new google.maps.places.AutocompleteService();
        this._geocoder = new google.maps.Geocoder();
        this._initialized = true;
    }

    /**
     * Retrieves place autocomplete predictions based on the supplied autocomplete request.
     * @param {google.maps.places.AutocompletionRequest} query
     * @returns {Promise<google.maps.places.AutocompletePrediction[]>}
     */
    getPlacePredictions(query) {
        var d = this.$q.defer();
        this._autocomplete.getPlacePredictions(query, (results, status) => {
            if (status === 'ZERO_RESULTS') {
                return d.resolve([]);
            }
            if (status === 'OK') {
                d.resolve(results);
            } else d.reject(status);
        });
        return d.promise;
    }

    /**
     * Returns the geographical location of the given geocode query.
     * @param {google.maps.GeocoderRequest} query
     * @returns {Promise<GeocoderResult[]>}
     */
    geocode(query) {
        var d = this.$q.defer();
        this._geocoder.geocode(query, (results, status) => {
            if (status === 'ZERO_RESULTS') {
                return d.resolve([]);
            }
            if (status === 'OK') {
                d.resolve(results);
            } else d.reject(status);
        });
        return d.promise;
    }
}

GoogleService.$inject = ['$http', '$q'];

export default GoogleService;
