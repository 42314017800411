﻿$(document)
    .ready(function() {
        if ($('div#divHSSARegistrationForm').length) {
            var theForm = $('#frmHSSymposiumAttendeeRegistration');

            $(".btnCancel")
                .click(function () {
                    window.location.href = "https://www.aopa.org";
                });

            $(".hssacontinuebutton")
                .click(function () {
                    if (validateDiv($(this).data("thispage"))) {
                        var nextpage = $(this).data("nextpage");
                        $('#' + $(this).data("thispage")).hide();
                        $('#' + nextpage).show();
                        window.scrollTo(0, 0);
                    }
                });

            $(".hssabackbutton")
                .click(function () {
                    var prevpage = $(this).data("prevpage");
                    $('#' + $(this).data("thispage")).hide();
                    $('#' + prevpage).show();
                    window.scrollTo(0, 0);
                });



            $(".hssaRegisterButton").click(function () {
                if (theForm.valid()) {
                    $('#btnRegister').hide();
                    $('.btn_final_cancelback').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });



            $(".hssa-control-cb")
                .click(function () {
                    var cb = document.getElementById(this.id);
                    if (cb.checked) {
                        $('#' + $(this).data("reveal-div")).show();
                        calculateTotal(true);
                    } else {
                        $('#' + $(this).data("reveal-div")).hide();
                        calculateTotal(false);
                    }
                });


            function calculateTotal(addOptional) {
                var total = 0.0;
                total += parseFloat($('#AttendeePrice').val());
                if (addOptional) {
                    total += parseFloat($('#TourPrice').val());
                }

                $('#OrderTotal').val(total);
                $('.event-payment-total__price').text('$' + total.toFixed(2));
            }


            $('input[name=PayingBySelected]').on('change', function () {
                if ($('input[name=PayingBySelected]:checked').val() === "CREDIT") {
                    $('#hssaCCFields').show();
                    $('#hssaPOFields').hide();
                    if ($('input[name=CCDomainSelected]:checked').val() === "PERSONAL") {
                        $('#hssaCAFields').hide();
                    } else {
                        $('#hssaCAFields').show();
                    }
                } else {
                    $('#hssaCCFields').hide();
                    $('#hssaPOFields').show();
                    $('#hssaCAFields').show();
                }
            });

            $('input[name=CCDomainSelected]').on('change', function () {
                if ($('input[name=CCDomainSelected]:checked').val() === "PERSONAL") {
                    $('#hssaCCFields').show();
                    $('#hssaPOFields').hide();
                    $('#hssaCAFields').hide();
                } else {
                    if ($('input[name=PayingBySelected]:checked').val() === "CREDIT") {
                        $('#hssaCCFields').show();
                        $('#hssaPOFields').hide();
                    } else {
                        $('#hssaCCFields').hide();
                        $('#hssaPOFields').show();
                    }
                    $('#hssaCAFields').show();
                }
            });

            function validateDiv(div) {
                var validator = theForm.data('validator');
                var returnValue = true;

                $('#' + div + ' :input').each(function (e) {
                    if (!$(this).is(":button") && this.id.length) {
                        if (!validator.element($('#' + this.id))) returnValue = false;
                    }
                });

                return returnValue;
            }


            //
            // Reset the questionnaire fields on page load
            //
            if ($('#QuestionnaireData').val()) {
                var data = JSON.parse(decodeURIComponent($('#QuestionnaireData').val()));
                for (var i = 0; i < data.length; i++) {
                    switch (data[i].type) {
                        case "SELECT":
                            $('#MPQuestionSelect' + data[i].qid + ' option[value="' + data[i].qa + '"]').prop('selected', true);
                            break;
                        case "CB":
                            var answers = data[i].qa.split(',');
                            $.each(answers, function (index, obj) {
                                $('input.MPQuestionCB' + data[i].qid + '[value="' + obj + '"]').prop('checked', true);
                            });
                            break;
                        case "TEXTBOX":
                            $('#MPQuestionText' + data[i].qid).val(data[i].qa);
                            break;
                    }

                }
            }

            $('.MPQuestionSelect').change(function () {
                var data = JSON.parse(decodeURIComponent($('#QuestionnaireData').val()));
                var qid = this.id.replace('MPQuestionSelect', '');
                var idx = -1;
                var ct = 0;

                $.each(data, function () {
                    if (this.qid.toString() === qid) {
                        idx = ct;
                        return false;
                    }
                    ct++;
                });


                if (idx != -1) {
                    data[idx].qa = this.value;
                }

                $('#QuestionnaireData').val(encodeURIComponent(JSON.stringify(data)));
            });

            $('.MPQuestionCB').change(function () {
                var data = JSON.parse(decodeURIComponent($('#QuestionnaireData').val()));
                var cbid = this.id;
                var qid = this.name.replace('MPQuestionCB', '');
                var anslist = '';
                var idx = -1;
                var ct = 0;

                $.each(data, function () {
                    if (this.qid.toString() === qid) {
                        idx = ct;
                        return false;
                    }
                    ct++;
                });

                $('.' + cbid).each(function (index, obj) {
                    if (this.checked) {
                        anslist += this.value + ',';
                    }
                });
                anslist = anslist.slice(0, -1);


                if (idx != -1) {
                    data[idx].qa = anslist;
                }

                $('#QuestionnaireData').val(encodeURIComponent(JSON.stringify(data)));
            });


            $('.MPQuestionText').change(function () {
                var data = JSON.parse(decodeURIComponent($('#QuestionnaireData').val()));
                var qid = this.id.replace('MPQuestionText', '');
                var idx = -1;
                var ct = 0;

                $.each(data, function () {
                    if (this.qid.toString() === qid) {
                        idx = ct;
                        return false;
                    }
                    ct++;
                });


                if (idx != -1) {
                    data[idx].qa = this.value;
                }

                $('#QuestionnaireData').val(encodeURIComponent(JSON.stringify(data)));
            });

        }
    });