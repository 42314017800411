﻿/*
Custom script for required optional validation
*/
(function ($) {
    $.validator.addMethod("aopacccheck", function (value, element, params) {
        var isRequired = $('#' + params.requiredoptionalfield).val().toUpperCase();

        if (isRequired === 'TRUE' && value && !value.startsWith(params.bincheck)) {
            return false;
        }
        return true;
    });

    jQuery.validator.unobtrusive.adapters.add("aopacccheck", ["requiredoptionalfield", "bincheck"],
        function (options) {
            options.rules['aopacccheck'] = {
                requiredoptionalfield: options.params.requiredoptionalfield,
                bincheck: options.params.bincheck
            };
            options.messages['aopacccheck'] = options.message;
        });
}(jQuery));