/**
 * AOPA Event Service proxy.
 */
class EventService {
    constructor ($http, $q) {
        this.baseUrl = Aopa.AERIUM_API_URL;
        this.$http = $http;
        this.$q = $q;
    }

    /**
     * Returns all events from the API (note the results are paginated).
     * @returns {Array<Object>} Events
     */
    getEvents () {
        return this.$q((resolve, reject) => {
            try {
                this.$http.get(`${this.baseUrl}/events`)
                    .then(response => resolve(response.data))
                    .catch(e => reject(e));
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Returns all event categories from the API (note the results are paginated).
     * @returns {Array<Object>} Event categories
     */
    getEventCategories () {
        return this.$q((resolve, reject) => {
            try {
                this.$http.get(`${this.baseUrl}/event-categories`)
                    .then(response => resolve(response.data))
                    .catch(e => reject(e));
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Searches for events matching the specified parameters.
     * @param params {Object} Event search parameters. This should be an object that has one or more of
     *   the following properties:
     *      - name {String}: Event name
     *      - description {String}: Event description
     *      - content {String}: Pseudo-property representing either event name or description.
     *      - startDate {String}: Event start date
     *      - endDate {String}: Event end date
     *      - categories {Array<String>}: Event category IDs
     *      - states {Array<String>}: State codes
     *      - airports {Array<String>}: Airport identifiers
     *      - lat {Number}: Latitude. When specified, lon and radius are also required.
     *      - lon {Number}: Longitude. When specified, lat and radius are also required.
     *      - radius {Number}: Radius in miles. When specified, lat/lon are also required.
     *      - offset {Number}: Pagination offset
     *      - limit {Number}: Pagination limit
     * @returns {Array<Object>} Events matching the supplied search parameters
     */
    search (params) {
        return this.$q((resolve, reject) => {
            try {
                params.offset = params.offset || 0;
                params.limit = params.limit || 10;
                var {
                    lat,
                    lon,
                    radius
                } = params;
                if (Object.keys(params).every(k => !params[k])) {
                    throw new Error('At least one search parameter is required.');
                }
                if ((lat || lon || radius) && !(lat && lon && radius)) {
                    throw new Error('When lat, lon, or radius are specified, all three parameters are required.');
                }
                var query = this.encodeParams(params);
                this.$http.get(`${this.baseUrl}/events/search?${query}`)
                    .then(response => {
                        resolve({
                            total: response.headers('X-Pagination-Total'),
                            data: response.data
                        });
                    })
                    .catch(e => reject(e));
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Returns the event URL for the given event.
     */
    getEventUrl (event) {
        const { groupId, categoryId, id } = event;
        if (groupId) {
            return `https://hangar.aopa.org/groups/events/item/${groupId}/${categoryId}/${id}`;
        }
        return `https://hangar.aopa.org/events/item/${categoryId}/${id}`;
    }

    /**
     * Encodes an object's keys and values as a URL query parameter.
     */
    encodeParams (params) {
        let ret = [];
        for (let d in params) {
            const k = encodeURIComponent(d);
            const v = params[d];
            if (_.isArray(v)) {
                v.forEach(item => ret.push(k + '=' + encodeURIComponent(item)));
            } else {
                ret.push(k + '=' + encodeURIComponent(v));
            }
        }
        return ret.join('&');
    }
}

EventService.$inject = ['$http', '$q'];

export default EventService;
