﻿$(document)
    .ready(function () {
        if ($('div#divHSAsaOfferForm').length) {
            var theForm = $('#frmHSAsaOffer');


            $('input[name=PayingBySelected]').on('change', function () {
                if ($('input[name=PayingBySelected]:checked').val() === "CREDIT") {
                    $('#hswCCFields').show();
                    $('#hswPOFields').hide();
                } else {
                    $('#hswCCFields').hide();
                    $('#hswPOFields').show();
                }
            });


            $("#AddTeacherProduct")
                .click(function () {
                    var cb = document.getElementById(this.id);
                    if (cb.checked) {
                        $('#hsAsaTeacherProductRow').show();
                        calculateTotal(true);
                    } else {
                        $('#hsAsaTeacherProductRow').hide();
                        calculateTotal(false);
                    }
                });


            $('#StudentQuantity').change(function () {
                var ct = parseInt($('#StudentQuantity').val(), 10);
                if (ct !== NaN && ct >= 0) {
                    calculateTotal();
                } 
            });


            $('#SchoolSelected').on('change',
                function() {
                    var school = document.getElementById(this.id);
                    if (school.value === "") {
                        $('#BillingContactEmailAddress').val('');
                        $('#BillingContactName').val('');
                    } else {
                        var options = JSON.parse(decodeURIComponent($('#BillingOptions').val()));

                        $.each(options, function (i, v) {
                            if (v.Id === school.value) {
                                $('#BillingContactEmailAddress').val(v.BCEmail);
                                $('#BillingContactName').val(v.BCName);
                            }
                        });
                    }
                });

            function calculateTotal() {
                var total = 0.0;
                var tCb = document.getElementById("AddTeacherProduct");

                if ($('#ShowTeacherProduct').val() === "True" && tCb.checked) {
                    total += parseFloat($('#TeacherPrice').val());
                }
                var studentTotal = parseInt($('#StudentQuantity').val(), 10) * parseFloat($('#StudentPrice').val());
                $('#StudentTotal').val(studentTotal.toFixed(2));



                total += studentTotal;


                if (studentTotal > 0) {
                    $('#hsAsaStudentProductRow').show();
                } else {
                    $('#hsAsaStudentProductRow').hide();
                }
                

                $('#divStudentQuantity').text($('#StudentQuantity').val());
                $('#divStudentTotal').text('$' + studentTotal.toFixed(2));



                $('#divAsaSummaryTotal').text('$' + total.toFixed(2));
                $('#OrderTotal').val(total.toFixed(2));
                
                if (total > 0) {
                    $('#hsAsaPaymentSection').show();
                } else {
                    $('#hsAsaPaymentSection').hide();
                }


                var subButton = document.getElementById("btnAsaRegister");
                if (parseInt($('#StudentQuantity').val(), 10) > 0) {
                    $('#btnAsaRegister').removeClass('hs-asa-button--disable');
                    subButton.disabled = false;
                } else {
                    if ($('#ShowTeacherProduct').val() === "True" && tCb.checked) {
                        $('#btnAsaRegister').removeClass('hs-asa-button--disable');
                        subButton.disabled = false;
                    } else {
                        $('#btnAsaRegister').addClass('hs-asa-button--disable');
                        subButton.disabled = true;
                    }
                }
            }

            $(".hs-asa-button").click(function () {
                if (theForm.valid()) {
                    $('#btnAsaRegister').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });
        }
    });
