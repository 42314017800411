﻿$(document).ready(function (){
	//added timeout so that it not only loads after the dom is ready, 
	//but after other functions are ready that add the class of .at-share-btn
	//they also seem to load after the dom, but before this function
setTimeout(function(){ 
	$(".at-share-btn").click(function() {
			var imgurl = $("#ImageUrl").val();
			$("head").append("<meta property='og:image' content='" + imgurl + "' /> <meta property='og:image:width' content='500' />  <meta property='og:image:height' content='500' /> ");
	});
},1000);
});
