export default function clearall() {
	/* 
	 * Directive: Clear All
	 * Clears datepicker information
	 * Because datepicker is a jquery plugin, we need to be able to
	 *    clear its value using jquery. 
	 */
	 
	return {
		restrict: 'A',
		link : function (scope, element, attrs, ngModelCtrl) {
			$(function(){
				element.click(function() {
						var $datePickers = $(this).parents(".item-list__drawer").find(".datepicker");
						
						$datePickers.each(function() {
							if ($(this).datepicker( "getDate" )) {
								$(this).datepicker( "setDate", null );
							}
							$(this).datepicker("option", "minDate", null);
							$(this).datepicker("option", "maxDate", null);
						});
						
						var $radiusList = $(this).parents(".item-list__drawer").find(".js-event-radius");
						$radiusList.select2({
								data: {id:null, text: null}, 
								minimumResultsForSearch: -1,
								width: '100%'});
						
						$(this).parents(".item-list__drawer").find('#ang-app-airports').val("");
				});
			});
		}
	}
}