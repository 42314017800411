﻿(function ($) {
    $.validator.addMethod("radiotextcheck", function (value, element, params) {

        var rdFields = params.radiofield.split(',');
        var rv = "";

        // Iterate through each value
        for (var i = 0; i < rdFields.length; i++) {
            if ($('input[name=' + rdFields[i] + ']').is(":checked")){
                if ($('input[name=' + rdFields[i] + ']:checked').val() === params.radiovalue) {
                    rv = params.radiovalue;
                }
            }
        }

        if (rv === params.radiovalue && value === '') {
            return false;
        }

        if (rv === params.radiovalue && params.datacheck.trim()) {
            switch(params.datacheck) {
                case "Integer":
                    var theMin = parseInt($('#' + params.minimumamountfield).val(), 10);
                    var theValue = parseInt(value, 10);

                    if (isNaN(theMin) || isNaN(theValue) || theValue.toString() !== value || theMin.toString() !== $('#' + params.minimumamountfield).val() || theValue < theMin) {
                        return false;
                    }
                    break;
            } 
        }

        return true;
    });

    jQuery.validator.unobtrusive.adapters.add("radiotextcheck", ["radiofield", "radiovalue", "datacheck", "minimumamountfield"],
        function (options) {
            options.rules['radiotextcheck'] = {
                radiofield: options.params.radiofield,
                radiovalue: options.params.radiovalue,
                datacheck: options.params.datacheck,
                minimumamountfield: options.params.minimumamountfield
        };
            options.messages['radiotextcheck'] = options.message;
        });

}(jQuery));
