﻿$(document)
    .ready(function () {
        if ($('div#divAftaSchoolEmployeeAdminForm').length) {
            var theForm = $('#frmAftaSchoolEmployeeAdmin');

            $(".submitButtonAftaSchoolEmployee").click(function () {
                if (theForm.valid()) {
                    $('#btnEnroll').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });
        }
    });
