﻿$(document).ready(function () {
    if ($('div#divAOPAPPSMaintenance').length) {

        $('input[name=SelectedPpsRateCode]').on('change', function () {
            var ppsSel = $('input[name=SelectedPpsRateCode]:checked').val().split('|');

            $('#sProratedAmount').text('$' + parseFloat(ppsSel[3]).toFixed(2));
            $('#sProratedAmount2').text('$' + parseFloat(ppsSel[3]).toFixed(2));
        });

        $('input[name=SelectedPaymentOption]').on('change', function () {
            if ($('input[name=SelectedPaymentOption]:checked').val() === "UseCardOnFile") {
                $("#divPayment").css({ "visibility": "hidden" });
                $("#divPayment").css({ "display": "none" });
                $("#CCExpireDate").val("12/2100");
                var d = new Date();
                $("#SelectedCreditCardExpYear").val(d.getFullYear() + 5);
                $("#CcNumber").val("4111111111111111");
                $("#Cvv").val("123");
                $("#NameOnCard").val("xxxx");
            } else {
                $("#CCExpireDate").val("");
                $("#CcNumber").val("");
                $("#Cvv").val("");
                $("#NameOnCard").val("");
                $("#divPayment").css({ "visibility": "visible" });
                $("#divPayment").css({ "display": "block" });
            }
        });

        $(".joinButton").click(function () {
            var form = $(this).closest('form');
            if (form.valid()) {
                $(this).hide();
                $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
            }
        });
    }
});
