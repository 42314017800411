﻿$(document)
    .ready(function () {
        if ($('div#divMembershipUpgradeV3').length) {
            var theForm = $('#frmMembershipUpgradeV3');

            var ufStripe = Stripe($('#sapik').val());

            var ufElements = ufStripe.elements();
            var ufStyle = {
                base: {
                    backgroundColor: '#ffffff',
                    fontSize: '0.9375rem',
                    fontFamily: 'Roboto, Helvetica Neue, sans-serif',
                    lineHeight: '42px',
                    ':focus': {
                        border: '10px solid black'
                    }
                }
            };
            var ufCardElement = ufElements.create("card", { style: ufStyle, hidePostalCode: true, disableLink: true });
            ufCardElement.mount("#card-element");


            ufCardElement.on('change',
                function (event) {
                    var displayError = document.getElementById('card-errors');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }

                    $('#CardBrand').val(event.brand);
                });


            $('input[name=SelectedPpsOption]').on('change', function () {
                var ppsAnnualOptions = JSON.parse($('#divPpsAnnualOptions').text());

                var ppsSelected = $('input[name=SelectedPpsOption]:checked').val();

                var selectedPpsOption;

                ppsAnnualOptions.forEach((item) => {
                    if (item.productCode === ppsSelected) {
                        selectedPpsOption = item;
                    }
                });

                $('#divUpgradePpsSummaryText').html(selectedPpsOption.summaryDescription);
                $("#divUpgradePpsPrice").html('$' + selectedPpsOption.price.toFixed(2));
                $('#spDueToday').html('$' + selectedPpsOption.proratedPrice.toFixed(2));
                $('#OrderTotal').html('$' + selectedPpsOption.proratedPrice.toFixed(2));


                var total = 0.0;

                total += parseFloat($('#UpgradedCostNoPps').val(), 10);
                total += selectedPpsOption.price;
                $('#divRenewalTotal').html("$" + total.toFixed(2));

                $('.aopa-annual-membership-price').html("$" + total.toFixed(2));
                $('input[type="checkbox"][id="IncludeAar"]').prop("checked", false).change();
                });

            $('#ShowPayment').change(function () {
                if ($(this).is(":checked")) {
                    $("#divPayment").css({ "visibility": "visible" });
                    $("#divPayment").css({ "display": "block" });
                } else {
                    $("#divPayment").css({ "visibility": "hidden" });
                    $("#divPayment").css({ "display": "none" });
                }
            });

            $('#IncludeAar').change(function () {
                if (this.checked) {
                    $('#divRenewalAutoRenewal').html("YES");
                } else {
                    $('#divRenewalAutoRenewal').html("NO");
                }
            });

            var checkCardFields = () => {
                var isValid = false;

                if ($('#ShowPayment').val() === "True") {
                    var cardElement = document.querySelector('#card-element');

                    isValid = cardElement.classList.contains('StripeElement--complete');

                    var brand = $('#CardBrand').val();

                    if (isValid &&
                        (brand === 'visa' || brand === 'mastercard' || brand === 'amex' || brand === 'discover')) {
                        $('#card-errors').html('');
                    } else {
                        if ($('#card-errors').is(':empty')) {
                            $('#card-errors').html('Please check your credit card information.');
                        }
                    }

                } else {
                    isValid = true;
                }


                return isValid;
            }

            $(".submitButtonUFV3").click(function () {
                var ccValid = checkCardFields();


                var progressBar = new Sfpb_progressBar();

                if (theForm.valid() && ccValid) {
                    $(this).hide();
                    $('body').addClass('progressbar-modal');
                    progressBar.start();
                    $(".clsSubmitButton").append('<div class="spinner spinner--clockwise"></div>');


                    if ($('#ShowPayment').val() === "True") {
                        ufStripe.createToken(ufCardElement, { name: $("#CardName").val() })
                            .then(function (result) {
                                if (result.token !== null) {
                                    $("#CardToken").val(JSON.stringify(result.token));
                                    theForm.submit();
                                } else {
                                    if ($('#card-errors').is(':empty')) {
                                        $('#card-errors').html('Please check your credit card information.');
                                    }
                                    var spinner = document.getElementById('aopaSpinner');
                                    spinner.parentElement.removeChild(spinner);
                                    $(this).show();
                                    progressBar.kill();
                                    $('body').removeClass('progressbar-modal');
                                    $(this).show();
                                }
                            });
                    } else {
                        theForm.submit();
                    }
                } else {
                    var firstInstance = $('.input-validation-error').first();
                    var offsetTop = firstInstance.offset().top - 100;

                    $('html, body').animate({
                        scrollTop: offsetTop
                    }, 500);

                }
            });
        }
    });