﻿$(document)
    .ready(function () {
        if ($('div#divAopaCancellationForm').length) {
            var theForm = $('#frmAopaCancellation');
   



            $('#WantToCancel').change(function () {
                if (this.checked) {
                    $('#btnSubmitCancel').removeClass('aopa-disable-button');
                    $('#btnSubmitCancel').prop('disabled', false);
                    $("#divCancelInfo").show();

                } else {
                    $('#btnSubmitCancel').addClass('aopa-disable-button');
                    $('#btnSubmitCancel').prop('disabled', true);
                    $("#divCancelInfo").hide();
                }
            });


            $(".submitAopaCancel").click(function () {
                if (theForm.valid()) {
                    $("#divConfirmCheckBox").hide();
                    $('#btnSubmitCancel').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });

        }
    });