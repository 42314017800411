﻿$(document)
    .ready(function() {
        if ($("div#divButtonSubmit").length) {
            $(".submitButton").click(function () {
                var form = $(this).closest('form');
                if (form.valid()) {
                    $(this).hide();
                    $(".clsSubmitButton").append('<div class="spinner spinner--clockwise"></div>');
                }
            });
        }

        if ($("div#divButtonSubmit2").length) {
            $(".submitButton").click(function () {
                var form = $(this).closest('form');
                if (form.valid()) {
                    $(this).hide();
                    $('.backBtn').hide();
                    $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
                }
            });
        }


        // extend jquery range validator to work for required checkboxes
        var defaultRangeValidator = $.validator.methods.range;
        $.validator.methods.range = function (value, element, param) {
            if (element.type === 'checkbox') {
                // if it's a checkbox return true if it is checked
                return element.checked;
            } else {
                // otherwise run the default validation function
                return defaultRangeValidator.call(this, value, element, param);
            }
        }
    });