export default function select2watcher($timeout) {
/* 
 * Directive: select2watcher
 * Updates select 2 value
 */
	return function (scope, element, attrs) {
		scope.$watch("radii", function () {
			$timeout(function() {
				$('select').trigger('change');
			});
		});
	};
}