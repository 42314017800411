﻿/*
Custom script for validating CC Expiration Date
*/
(function ($) {
    $.validator.addMethod("ccnumbercheck", function (value, element, params) {

        var patt = new RegExp("^[2,3,4,5,6].*$");

        if (!patt.test(value)) {
              return false;
        }

        return true;
    });

    $.validator.unobtrusive.adapters.addBool("ccnumbercheck");
}(jQuery));