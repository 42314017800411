﻿/*
Custom script for validating CC Expiration Date
*/
(function($) {
    $.validator.addMethod("ccexpdatecheck", function (value, element, params) {
        var otherProp = $('#' + params);

        if (parseInt(value) == parseInt((new Date).getFullYear())) {
            if (parseInt(otherProp.val()) < ((new Date).getMonth() + 1)) {
                return false;
            }
        }
        return true;
    });

    $.validator.unobtrusive.adapters.addSingleVal("ccexpdatecheck", "expiremonth");
}(jQuery)); 