﻿$(document).ready(function() {
    if ($('div#divOneClickDonation').length) {

        if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
            $("#divOtherAmount").css({ "visibility": "hidden" });
            $("#divOtherAmount").css({ "display": "none" });
        } else {
            $("#divOtherAmount").css({ "visibility": "visible" });
            $("#divOtherAmount").css({ "display": "block" });
        }
        
        if ($('input[name=SelectedDonationAmount2]:checked').val() !== "OTHER|0") {
            $("#divOtherAmount2").css({ "visibility": "hidden" });
            $("#divOtherAmount2").css({ "display": "none" });
        } else {
            $("#divOtherAmount2").css({ "visibility": "visible" });
            $("#divOtherAmount2").css({ "display": "block" });
        }


        specialText();

        function specialText() {
            if ($('#GiftSpecialList').val() !== '') {
                var sel = $('input[name=SelectedDonationAmount]:checked').val().split("|");
                if ($('#GiftSpecialList').val().indexOf(sel[0]) >= 0) {
                    $("#divSpecialGift").css({ "visibility": "visible" });
                    $("#divSpecialGift").css({ "display": "block" });
                } else {
                    $("#divSpecialGift").css({ "visibility": "hidden" });
                    $("#divSpecialGift").css({ "display": "none" });
                }
            }
        }


        fixPremiums();

        $('input[name=AcceptPremium]').on('change', function () {
                fixPremiums();
        });

        function fixPremiums() {
            var $option;
            if ($('#HasPremiumsAvailable').val().toUpperCase() === "TRUE" && $('#ShowPremiumList').val().toUpperCase() === "TRUE") {
                if ($('input[name=AcceptPremium]:checked').val() !== "true") {
                    $("#divPremium").css({ "visibility": "hidden" });
                    $("#divPremium").css({ "display": "none" });
                    $('#SelectedPremiumItem').val($('#SelectedPremiumItem option:last-child').val());
                    $('#SelectedPremiumRateCode').empty();
                    $option = $("<option />");
                    $option.attr("value", "x").text('x');
                    $('#SelectedPremiumRateCode').append($option);
                    $('#SelectedPremiumRateCode').val('x');
                } else {
                    $("#divPremium").css({ "visibility": "visible" });
                    $("#divPremium").css({ "display": "block" });
                    $('#SelectedPremiumItem').val('');
                    $('#SelectedPremiumRateCode').empty();
                    $option = $("<option />");
                    $option.attr("value", "").text($('#PremiumRateSelect').val());
                    $('#SelectedPremiumRateCode').append($option);
                    $('#SelectedPremiumRateCode').val('');
                }
            }
        }

        $('#SelectedPremiumItem').change(function () {
            var option = $(this).find('option:selected').val().split("|");
            $.getJSON('/api/OneClickPremium/Get/?productId=' + option[0])
                .done(function (data) {
                    $('#SelectedPremiumRateCode').empty();
                    var $option = $("<option />");
                    $option.attr("value", "").text($('#PremiumRateSelect').val());
                    $('#SelectedPremiumRateCode').append($option);
                    $.each(data, function (key, item) {
                        var $option = $("<option />");
                        $option.attr("value", item.value).text(item.text);
                        $('#SelectedPremiumRateCode').append($option);
                    });
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#SelectedPremiumRateCode').empty();
                    var $option = $("<option />");
                    $options.attr("value", "").text("- Error -");
                    $('#SelectedPremiumRateCode').append($option);
                });
        });

        $(".donateButton").click(function () {
            var form = $(this).closest('form');
            if (form.valid()) {
                $(this).hide();
                $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
            }
        });

        $('#SelectedCountry').change(function () {
            var option = $(this).find('option:selected').val();
            $.getJSON('/api/CountryState/GetStates/?countryCode=' + option)
                .done(function (data) {
                    $('#SelectedState').empty();
                    $.each(data, function (key, item) {
                        var $option = $("<option />");
                        $option.attr("value", item.value).text(item.text);
                        $('#SelectedState').append($option);
                    });
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#SelectedState').empty();
                    var $option = $("<option />");
                    $options.attr("value", "").text("- Error -");
                    $('#SelectedState').append($option);
                });
        });

        $('#KeyCodeEntered').focusout(function () {
            $.getJSON('/api/DonationAppealOffer/Get/?keycode=' + $(this).val())
                .done(function (data) {
                    if (data.keyCode) {
                        $('#KeyCode').val(data.keyCode);
                        $('#ListCode').val(data.listCode);
                        $('#MarketCode').val(data.marketCode);

                        $('.divAppealPremiumItem' + i).hide();

                        if (data.premiums) {
                            $.each(data.premiums,
                                function (i, prem) {
                                    var x = i + 1;
                                    $('#OfferPremium' + x).prop('checked', true);
                                    $('#OfferPremium' + x + 'Disclaimer').val(prem.disclaimer);
                                    $('#OfferPremium' + x + 'Value').val(prem.productId);
                                    $('#OfferPremium' + x + 'QualValue').val(prem.qualifyingAmount);
                                    $('#OfferPremium' + x + 'Show').val('True');

                                    $('.spOfferPremium' + x + 'Text').html(prem.productName);
                                });
                            for (var i = 1; i <= 3; i++) {
                                if (!$('#OfferPremium' + i + 'Value').val()) {
                                    $('#OfferPremium' + i).prop('checked', false);
                                    $('#OfferPremium' + i + 'Disclaimer').val('');
                                    $('#OfferPremium' + i + 'Value').val('');
                                    $('#OfferPremium' + i + 'QualValue').val('');
                                    $('#OfferPremium' + i + 'Show').val('False');
                                    $('.spOfferPremium' + i + 'Text').html('');
                                }
                            }
                        } else {
                            for (var i = 1; i <= 3; i++) {
                                $('#OfferPremium' + i).prop('checked', false);
                                $('#OfferPremium' + i + 'Disclaimer').val('');
                                $('#OfferPremium' + i + 'Value').val('');
                                $('#OfferPremium' + i + 'QualValue').val('');
                                $('#OfferPremium' + i + 'Show').val('False');
                                $('.spOfferPremium' + i + 'Text').html('');
                            }
                        }
                        
                        appealPremiumsUpdate();
                    } else {
                        $('#KeyCodeEntered').val($('#KeyCode').val());
                    }
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#KeyCodeEntered').val($('#KeyCode').val());
                });
        });

        $('input[name=SelectedDonationAmount]').on('change', function () {
            if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
                if ($('#MinimumDonationAmount').length) {
                    $('#MinimumDonationAmount').val('');
                    $('#MinimumDonationAmount').valid();
                    $('#MinimumDonationAmount').prop("disabled", true);
                    $("#divOtherAmount").css({ "visibility": "hidden" });
                    $("#divOtherAmount").css({ "display": "none" });
                }
            } else {
                $('#MinimumDonationAmount').prop("disabled", false);
                $("#divOtherAmount").css({ "visibility": "visible" });
                $("#divOtherAmount").css({ "display": "block" });
            }
            calculateTotal();
            specialText();
            appealPremiumsUpdate();
        });

        $('input[name=SelectedDonationAmount2]').on('change', function () {
            if ($('input[name=SelectedDonationAmount2]:checked').val() !== "OTHER|0") {
                if ($('#MinimumDonationAmount2').length) {
                    $('#MinimumDonationAmount2').val('');
                    $('#MinimumDonationAmount2').valid();
                    $('#MinimumDonationAmount2').prop("disabled", true);
                    $("#divOtherAmount2").css({ "visibility": "hidden" });
                    $("#divOtherAmount2").css({ "display": "none" });
                }
            } else {
                $('#MinimumDonationAmount2').prop("disabled", false);
                $("#divOtherAmount2").css({ "visibility": "visible" });
                $("#divOtherAmount2").css({ "display": "block" });
            }
            calculateTotal();
            specialText();
            appealPremiumsUpdate();
        });


        $('input[name=SelectedRedirectAmount]').on('change', function () {
            var theValue = $('input[name=SelectedRedirectAmount]:checked').val();
            $('input[name=SelectedRedirectAmount]').prop('checked', false);
            if ($('#DonateRedirectForm').val().indexOf('?') >= 0) {
                window.location.href = $('#DonateRedirectForm').val() + '&dra=' + theValue;
            } else {
                window.location.href = $('#DonateRedirectForm').val() + '?dra=' + theValue;
            }
        });

        $('input[name=SelectedDonationFrequency]').on('change', function () {
            calculateTotal();
        });

        $('input[name=MinimumDonationAmount]').on('change', function () {
            if ($('input[name=SelectedDonationAmount]:checked').val() === "OTHER|0") {
                calculateTotal();
                appealPremiumsUpdate();
            }
        });

        $('input[name=MinimumDonationAmount2]').on('change', function () {
            if ($('input[name=SelectedDonationAmount2]:checked').val() === "OTHER|0") {
                calculateTotal();
                appealPremiumsUpdate();
            }
        });

        $('input[name=SelectedDonationSelector]').on('change', function () {
            var option = $('input[name=SelectedDonationSelector]:checked').val().split("|");
            if (option[0] == "1") {
                $("#donate1").css({ "visibility": "visible" });
                $("#donate1").css({ "display": "block" });
                $("#donate2").css({ "visibility": "hidden" });
                $("#donate2").css({ "display": "none" });
            } else {
                $("#donate1").css({ "visibility": "hidden" });
                $("#donate1").css({ "display": "none" });
                $("#donate2").css({ "visibility": "visible" });
                $("#donate2").css({ "display": "block" });
            }
            calculateTotal();
            appealPremiumsUpdate();
        });



        function calculateTotal() {
            var use1 = true;

            if ($('#ShowDonationSelector').val() === 'True') {
                var option = $('input[name=SelectedDonationSelector]:checked').val().split("|");
                if (option[0] == "2") {
                    use1 = false;
                }
            }

            
            if (use1) {
                if ($('#DonationType').val() !== "Pledge") {
                    if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
                        var amount = $('input[name=SelectedDonationAmount]:checked').val().split("|");
                        $('#PaymentTotal').val(parseFloat(amount[1], 10).toFixed(2));
                        $('#DonationTotal').val(parseFloat(amount[1], 10).toFixed(2));
                    } else {
                        if ($.isNumeric($('#MinimumDonationAmount').val())) {
                            $('#PaymentTotal').val(parseFloat($('#MinimumDonationAmount').val(), 10).toFixed(2));
                            $('#DonationTotal').val(parseFloat($('#MinimumDonationAmount').val(), 10).toFixed(2));
                        } else {
                            $('#PaymentTotal').val(0.00);
                            $('#DonationTotal').val(0.00);
                        }
                    }

                } else {
                    var selectedAmount = 0;

                    if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
                        var amount = $('input[name=SelectedDonationAmount]:checked').val().split("|");
                        selectedAmount = parseFloat(amount[1], 10).toFixed(2);
                    } else {
                        if ($.isNumeric($('#MinimumDonationAmount').val())) {
                            selectedAmount = parseFloat($('#MinimumDonationAmount').val(), 10).toFixed(2);
                        } else {
                            selectedAmount = 0.00;
                        }
                    }

                    $('#DonationTotal').val(parseFloat(selectedAmount, 10).toFixed(2));
                    
                    switch ($('input[name=SelectedDonationFrequency]:checked').val()) {
                        case 'IMMEDIATE':
                            $('#PaymentTotal').val(parseFloat(selectedAmount, 10).toFixed(2));
                            break;
                        case 'MONTHLY':
                            selectedAmount = selectedAmount / 12;
                            $('#PaymentTotal').val(parseFloat(selectedAmount, 10).toFixed(2));
                            break;
                        case 'QUARTERLY':
                            selectedAmount = selectedAmount / 4;
                            $('#PaymentTotal').val(parseFloat(selectedAmount, 10).toFixed(2));
                            break;
                    }

                    var totaltext = '$' + parseFloat(selectedAmount, 10).toFixed(2).toLocaleString();
                    $('#divPaymentAmount').html($.parseHTML(totaltext));
                }
            } else {
                if ($('input[name=SelectedDonationAmount2]:checked').val() !== "OTHER|0") {
                    var amount = $('input[name=SelectedDonationAmount2]:checked').val().split("|");
                    $('#PaymentTotal').val(parseFloat(amount[1], 10).toFixed(2));
                    $('#DonationTotal').val(parseFloat(amount[1], 10).toFixed(2));
                } else {
                    if ($.isNumeric($('#MinimumDonationAmount2').val())) {
                        $('#PaymentTotal').val(parseFloat($('#MinimumDonationAmount2').val(), 10).toFixed(2));
                        $('#DonationTotal').val(parseFloat(amount[1], 10).toFixed(2));
                    } else {
                        $('#PaymentTotal').val(parseFloat($('#MinimumDonationAmount2').val(), 10).toFixed(2));
                        $('#DonationTotal').val(0.00);
                    }
                }
            }

        }

        function appealPremiumsUpdate() {
            var total = parseFloat($('#DonationTotal').val(), 10);
            var hasQualPremiums = false;
            var qualVal = 0;


            for (var i = 1; i <= 3; i++) {
                if ($('#OfferPremium' + i + 'QualValue').val()) {
                    qualVal = parseFloat($('#OfferPremium' + i + 'QualValue').val(), 10);
                    if (qualVal <= total) {
                        hasQualPremiums = true;
                        $('.divAppealPremiumItem' + i).show();
                        $('#OfferPremium' + i + 'Show').val('True');
                    } else {
                        $('.divAppealPremiumItem' + i).hide();
                        $('#OfferPremium' + i + 'Show').val('False');
                    }
                } else {
                    $('.divAppealPremiumItem' + i).hide();
                    $('#OfferPremium' + i).prop('checked', false);
                    $('#OfferPremium' + i + 'Show').val('False');
                }
            }

            if (hasQualPremiums) {
                $('.divAppealPremium').show();

            } else {
                $('.divAppealPremium').hide();
            }
                
            $('#hasQualifiedOfferPremium').val(hasQualPremiums);
        }

    }
});
