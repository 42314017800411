export default function popcontent() {
	/* 
	 * Directive: popcontent
	 * Handle any popup content (media gallery)
	 */
	return {
		restrict: 'A',
		link : function (scope, element, attrs, ngModelCtrl) {
			$(function(){
				element.click(function(e) {
						
					$('body').trigger("ooyala-player:stop");
						
					var breakpoint = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
					var popcontent = $(this).find(".promo-grid__popcontent").html();
	
					if ((popcontent && !(breakpoint === "xx-small" || breakpoint === "x-small" || breakpoint === "small")) || popcontent.indexOf("<img") > -1)
					{
						
						e.stopPropagation();
						e.preventDefault();

						var $modal = $("#modal");
						var popteaser = $(this).find(".promo-grid__teaser").length > 0 ? $(this).find(".promo-grid__teaser")[0].outerHTML : "";
						var poptitle = $(this).find(".promo-grid__title").length > 0 ? $(this).find(".promo-grid__title")[0].outerHTML : "";


						if (!$modal.length)
						{
							$('body').append('<div id="modal"></div>');
							$modal = $("#modal");
						}

						$modal.addClass("modal--fade");

						$modal.html('<div class="modal__close"><svg><use xlink:href="/dist/assets/svg-sprite.svg#x"></use></svg></div><div class="modal__content-wrapper"><div class="modal__media-wrapper">' + popcontent + '</div><div class="modal__text-wrapper">' + poptitle + popteaser + "</div></div>");
						
						addthis.update('share', 'url', $('#modal .addthis_modal_sharing_toolbox').data('url')); 
						addthis.toolbox('.addthis_modal_sharing_toolbox');
					
						$(".modal__close").click(function() {
							$("#modal").removeClass("modal--fade");
							$("#modal").html("");
						});
						
						$(".modal__content-wrapper").mCustomScrollbar({
								theme: "light-thick"
						});
					}
				});
			});
		}
	}
}