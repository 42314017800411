
// Applies only to URLs within Training and Safety section    
if (window.location.href.indexOf("/training-and-safety") > 0) {
    // Excludes /cfis and /lapsed-pilots sections
	if ((window.location.href.indexOf("/cfis") == -1) && (window.location.href.indexOf("/lapsed-pilots") == -1)) {


// Begin PPS Modal --------------------------------------------------------- //

// Default constructor for AOPAModal class
function AOPAModal(params) {

    // Properties from params parameter
    var properties = jQuery.extend({
        id: null,
        title: null,
        bodyText: null,
        imageURL: null,
        customContent: null,
        customContentPages: [],
        widthPercent: null,
        maxWidthPixels: null,
        anchored: null,
        useNavButtons: false,
        ctaButtonText: null,
        ctaRedirectURL: null,
        ctaFunction: null,
        ctaLeaveOpen: false,
        cancelButtonText: null,
        ctaButtons: [],
        setupFunction: null,
        checkCookie: false,
        cookieName: null,
        cookieDuration: null,
        cookieSetAction: [],
        customData: null
    }, params);

    // Setup default properties
    this.id = properties.id;
    this.title = properties.title;
    this.bodyText = properties.bodyText;
    this.imageURL = properties.imageURL;
    this.customContent = properties.customContent;
    this.customContentPages = properties.customContentPages;
    this.widthPercent = properties.widthPercent;
    this.maxWidthPixels = properties.maxWidthPixels;
    this.anchored = properties.anchored;
    this.useNavButtons = properties.useNavButtons;
    this.ctaButtonText = properties.ctaButtonText;
    this.ctaRedirectURL = properties.ctaRedirectURL;
    this.ctaFunction = properties.ctaFunction;
    this.ctaLeaveOpen = properties.ctaLeaveOpen;
    this.cancelButtonText = properties.cancelButtonText;
    this.ctaButtons = properties.ctaButtons;
    this.setupFunction = properties.setupFunction;
    this.checkCookie = properties.checkCookie;
    this.cookieName = properties.cookieName;
    this.cookieDuration = properties.cookieDuration;    // int (Days)
    this.cookieSetAction = properties.cookieSetAction;
    this.customData = properties.customData;

    var anchored = (this.anchored) ? "aopaModalAnchored" : "aopaModalCentered";
    var title = (this.title) ? `<h3>${this.title}</h3>` : '';
    var navButtons = (this.useNavButtons) ? `<a class="aopaModal-backButton">&lt; Back</a><a class="aopaModal-nextButton">Next &gt;</a>` : '';
    var dataCookieName = (this.cookieName) ? `data-cookiename="${this.cookieName}"` : '';
    var dataCookieDuration = (this.cookieDuration) ? `data-cookieduration="${this.cookieDuration}"` : '';
    
    // Cookie Set Actions are: show, dismiss, cta, all
    var dataCookieSetAction = `data-cookiesetaction='[${arrayToDataString(this.cookieSetAction)}]'`;


    // Default HTML of modal window / additional elements will be appended below
    var modalDivHTML = `\
<div class="aopaModal-overlay" id="${this.id}" data-checkcookie="${this.checkCookie}" ${dataCookieName} ${dataCookieDuration} ${dataCookieSetAction}>\ 
<div class="aopaModal ${anchored}">\
    <div class="aopaModal-headerBar">\
        <div class="aopaModal-pageButtons">\
            ${navButtons}\
        </div>\
        <div class="aopaModal-close">\
            <svg id="x" viewBox="-291 253 56 56">\
                <path d="M-280.4,294.1l13.1-13.1l-13.1-13.1l4.4-4.4l13.1,13.1l13.1-13.1l4.4,4.4l-13.1,13.1l13.1,13.1l-4.4,4.4l-13.1-13.1  l-13.1,13.1L-280.4,294.1z"></path>\
            </svg>\
        </div>\
    </div>\
    <div class="aopaModal-main">\
        <div class="aopaModal-imageContainer"></div>\
        <div class="aopaModal-contentContainer">\
            ${title}\
        </div>\
        <div class="aopaModal-customContainer"></div>\
        <div class="aopaModal-actionContainer"></div>\
    </div>\
</div>\
</div>`;

    // Append modal div html (above) to HTML body, and hide
    var modalDiv = jQuery(modalDivHTML).appendTo("body").hide();
    $(modalDiv).data('setupobject', self);

    // Passable data container for "this" context, to handle passing to private methods
    var self = this;

    if (this.imageURL) {        // Image, if any, to display above heading
        jQuery(`#${this.id} .aopaModal-imageContainer`).append(`<img src="${this.imageURL}">`);
    }
    if (this.bodyText) {        // Body text of modal: default single <p> tag / use customContent below for longer content
        jQuery(`#${this.id} .aopaModal-contentContainer`).append(`<p>${this.bodyText}</p>`);
    }

    if (this.widthPercent) {    // Percent width modal will try to hit, at pixel sizes narrower than max-width (below)
        jQuery(`#${this.id} .aopaModal`).css("width", `${this.widthPercent}%`);
    }
    if (this.maxWidthPixels) {  // Max width in pixels, overrides widthPercent above, at larger screens
        jQuery(`#${this.id} .aopaModal`).css("max-width", `${this.maxWidthPixels}px`);
    }
    if (this.customContent) {   // If custom content HTML is provided, append that HTML to a div inside .aopaModal-customContainer
        //jQuery(`#${this.id} .aopaModal-customContainer`).append(`<div class="customContainer">${this.customContent}</div>`);
        page = { content: this.customContent };
        AddPage(self, page);
    }

    jQuery(".aopaModal").click(function (event) {
        event.stopPropagation();    // Stop clickthrough on modal content
    });

    // Close / Close-button click
    jQuery(`#${this.id} .aopaModal .aopaModal-close`).click(function () {
        //jQuery(`#${self.id}`).hide();
        hideAOPAModal(self.id);
    });

    // If a default button is provided by the constructor (ctaButtonText, ctaRedirectURL, ctaFunction), set it up here
    // (only ctaButtonText is required for this to be set up)
    if (this.ctaButtonText) {

        // Add a default button with this label
        defaultButton = {
            text: this.ctaButtonText,
            function: this.ctaFunction,
            url: this.ctaRedirectURL,
            //newWindow: true               // If link should open in new window
            leaveOpen: this.ctaLeaveOpen    // If clicking the CTA should not close the modal
        };
        // Add this button to the first index of this modal's ctaButtons array
        this.ctaButtons.unshift(defaultButton);

    }

    // Create a CTA button for each button parameter passed to the ctaButtons array in the modal constructor
    var i = 0;
    if (this.ctaButtons.length > 0) {
        this.ctaButtons.forEach(button => {
            CreateCTAButton(self, button, i);
            i++;
        });
    }

    // Create a cancel button if text is provided (closes the modal with no extra functions)
    if (this.cancelButtonText) {
        jQuery(`#${this.id} .aopaModal-actionContainer`).append(`<button class="aopaModal-action modal-cancelAction" data-aopaModal-id="${this.id}">${this.cancelButtonText}</button>`);
    }

    var j = 0;
    if (this.customContentPages.length > 0) {
        this.customContentPages.forEach(page => {
            AddPage(self, page);
            j++;
        });

        totalPageCount = $(`#${this.id} .aopaModal-customContainer .customContainer`).size();

        percent = 0;
        for (var i = 0; i < totalPageCount; i++) {
            var left = percent + (100 * i);
            $(`#${this.id} .aopaModal-customContainer .customContainer`).eq(i).css('left', `${left}%`).css('position', 'absolute').css('top', '0').attr('data-left', `${left}`).css('width', '100%').attr('data-modalpage', `${i}`).hide();
            if (i == 0)
                $(`#${this.id} .aopaModal-customContainer .customContainer`).eq(i).css('position', `relative`);
        }

        // Show first page by default
        $(`#${this.id} .aopaModal-customContainer .customContainer`).eq(0).show();
        $(`#${this.id} .aopaModal`).addClass('aopaModalPaginated');

    }

    jQuery(`#${this.id}`).attr("data-currentpage", 0);
    jQuery(`#${this.id}`).attr("data-totalpagecount", $(`#${this.id} .aopaModal-customContainer .customContainer`).size());

    // Close / Background click
    jQuery(`#${this.id}.aopaModal-overlay, .modal-cancelAction`).click(function () {
        //jQuery(`#${self.id}`).hide();
        hideAOPAModal(`${self.id}`);
    });

    // Call passed setup function while still inside the loop
    // Allows for data bindings, event handlers, etc., inside the setup context (after objects have been instantiated)
    if (this.setupFunction) {
        this.setupFunction();
    }

}

// Setup function for addition custom content pages
function AddPage(modalData, pageData) {
    jQuery(`#${modalData.id} .aopaModal-customContainer`).first().append(`<div class="customContainer">${pageData.content}</div>`);
}

// Setup function for modal CTA buttons
function CreateCTAButton(modalData, buttonData, i, hide = true) {

    // Create button element in .aopaModal-actionContainer
    jQuery(`#${modalData.id} .aopaModal-actionContainer`)
        .append(`<button class="aopaModal-action aopaModal-ctaButton" id="${modalData.id}-btn${i}" data-aopaModal-id="${modalData.id}" data-button-id="${i}">${buttonData.text}</button>`);

    // Set up click eventHandler
    jQuery(`#${modalData.id} .aopaModal #${modalData.id}-btn${i}`).click(function () {

        // Hide modal by default
        if (!buttonData.leaveOpen) {
            jQuery(`#${modalData.id}`).hide();
        }

        // ID of this particular button, from data-button-id attribute
        var buttonID = jQuery(this).attr("data-button-id");

        // Function and URL are optional parameters, will attempt both functions if both are present
        //  function:    Calls any function passed to this modal as a parameter in its constructor
        //  url:         Redirects window to url after calling function

        // Call any function attached
        if (modalData.ctaButtons[buttonID].function) {
            modalData.ctaButtons[buttonID].function();
            CheckCookieSetAction(modalData.id, 'cta');
        }

        // Redirect, if URL is not null
        if (modalData.ctaButtons[buttonID].url) {            
            // Open new window if "newWindow" was set to true
            if (modalData.ctaButtons[buttonID].newWindow) {
                window.open(modalData.ctaButtons[buttonID].url, '_blank');
            } else {
                window.location.href = modalData.ctaButtons[buttonID].url;
            }
        }

        // Console log error message if neither button function is attached
        if (!modalData.ctaButtons[buttonID].url && !modalData.ctaButtons[buttonID].function) {
            console.log("Warning: no CTA function or CTA Redirect URL provided");
        }

    });
}

// Go to AOPA Modal page by modal id, to provided page index
function AOPAModalGoToPage(modalID, pageIndex) {

    var pageContainers = $(`#${modalID} .aopaModal-customContainer .customContainer`);
    var pageCount = pageContainers.size();
    var page = pageContainers.eq(pageIndex);

    var pageCurrentOffset = page.attr('data-left');
    var pageDelta = 0 - pageCurrentOffset;

    var heights = new Array();
    for (var i = 0; i < pageCount; i++) {
        heights[i] = pageContainers.eq(i).height();
        pageContainers.eq(i).show();
    }
    pageContainers.parent().animate({
        height: `${page.height()}px`
    }, 350, "swing");

    $(`#${modalID} .aopaModal-main`).animate({
        scrollTop: 0
    }, 350, "swing"
    );

    var percent = 0;
    for (i = 0; i < pageCount; i++) {
        var thisPage = pageContainers.eq(i);
        var dataLeft = thisPage.attr('data-left');
        var end = parseInt(dataLeft) + pageDelta;
        thisPage.attr('data-left', end);
        thisPage.animate({
            left: `${end}%`
        }, {
            duration: 350,
            easing: "swing",
            done: function () {
                // Functionality after page transition
                if ($(this).attr('data-left') == 0) {
                    $(this).css('position', 'relative');
                } else {
                    $(this).hide().css('position', 'absolute');
                }
            }
        });
    }

    $(`#${modalID}`).attr("data-currentpage", pageIndex);
    if (pageIndex > 0) {
        $(`#${modalID} .aopaModal-backButton`).show();
    } else {
        $(`#${modalID} .aopaModal-backButton`).hide();
    }
}

// Next and previous pages, invoking AOPAModalGoToPage with gated increment/decrement
function AOPAModalNextPage(modalID) {

    // Current page and total page count, from the data-currentpage attr of the modal parent
    currentPage = parseInt($(`#${modalID}`).attr("data-currentpage"));
    totalPages = parseInt($(`#${modalID}`).attr("data-totalpagecount"));

    // Check range before advancing
    if (currentPage + 1 >= totalPages) return;
    AOPAModalGoToPage(modalID, currentPage + 1);

}

function AOPAModalPrevPage(modalID) {

    // Current page, from the data-currentpage attr of the modal parent
    currentPage = parseInt($(`#${modalID}`).attr("data-currentpage"));

    // Check range before reversing
    if (currentPage - 1 < 0) return;
    AOPAModalGoToPage(modalID, currentPage - 1);

}

// Show Modal, register Esc key eventlistener
function showAOPAModal(id) {

    // Set the vertical scroll value to a document style property, to return to
    // (For fixed background, below)
    var scrollY = `${window.scrollY}px`;

    // Check for existing cookie, exit if found
    if (CheckCookie(id)) {
        //console.log("Cookie already set.");
        return;
    }

    jQuery(`#${id}`).show();

    // Set explicit width for any customContainers, to prevent resizing during page transition
    //$('.aopaModal-customContainer .customContainer').outerWidth($('.aopaModal-customContainer .customContainer').outerWidth());

    // Hide modal on Esc press, unregister listener
    $(document).on('keyup', function (evt) {
        if (evt.keyCode == 27) {// Esc key event
            hideAOPAModal(id);
            // Unregister Esc key listener if pressed
            $(document).off('keyup');
        }
    });

    // Set fixed background on the page, while modal is active
    const body = document.body;
    body.style.position = 'fixed';
    body.style.width = '100%';
    body.style.paddingRight = "17px";
    body.style.top = `-${scrollY}`;

    CheckCookieSetAction(id, 'show');

}

// Check if cookie is set on client -- returns true (found) or false (not found)
function CheckCookie(id) {

    // Exit if data-checkcookie is false (not checking for cookies) / This is set up in the constructor
    var checkCookie = jQuery(`#${id}`).data('checkcookie');
    if (!checkCookie) return false;

    // Check for data-cookiename, set up in constructor / Exit if none is provided (cannot check)
    var cookieName = jQuery(`#${id}`).data('cookiename');
    if (!cookieName) return false;

    // Check for cookie and return found value (true) or false
    return ClientGetCookie(cookieName);

}

// Check if there is a cookieSetAction matching this keyword, and set cookie if found
// Keywords are 'show', 'dismiss', 'cta', and 'all' -- Every case passes if 'all' is provided
function CheckCookieSetAction(id, keyword) {

    var cookieSetAction = jQuery(`#${id}`).data('cookiesetaction');
    if (cookieSetAction.includes(keyword) || cookieSetAction.includes('all')) {
        SetCookie(id);
    }

}

// Set cookie by Modal ID
function SetCookie(id) {
    var cookieName = $(`#${id}`).data('cookiename');
    var duration = $(`#${id}`).data('cookieduration');;
    if (cookieName && duration) {
        ClientSetCookie(cookieName, duration, true);
    }
}

// Store cookie (string), duration(days), value (boolean)
function ClientSetCookie(cookiename, duration, val) {
    var expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (duration * 24 * 60 * 60 * 1000));
    expiryDate = expiryDate.toUTCString();
    console.log(`Cookie ${cookiename} set, expires: ${expiryDate}`);
    document.cookie = `${cookiename} = ${val}; expires = ${expiryDate}`;
}

// Retrieve cookie if set
function ClientGetCookie(cookieName) {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var decodedArray = decodedCookie.split(';');
    for (var i = 0; i < decodedArray.length; i++) {
        var decodedIndex = decodedArray[i];
        while (decodedIndex.charAt(0) == ' ') {
            decodedIndex = decodedIndex.substring(1);
        }
        if (decodedIndex.indexOf(name) == 0) {
            return decodedIndex.substring(name.length, decodedIndex.length);
        }
    }
    return false;
}

// Check for existing cookie by name (global)
function ClientCheckCookie(cookiename) {
    return ClientGetCookie(cookiename);
}

// Hide Modal, unset fixed scrolling, invoke "dismiss" cookie action if set
function hideAOPAModal(id) {

    jQuery(`#${id}`).hide();

    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    body.style.paddingRight = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    CheckCookieSetAction(id, 'dismiss');

}

function arrayToDataString(array) {
    var dataArray = new Array();
    for (var i = 0; i < array.length; i++) {
        dataArray.push(`"${array[i].toString()}"`);
    }
    return dataArray.join(',');
}

// End AOPA Modal ---------------------------------------------------------- //

var ycfHTML = `
<div id="ycf-form">\
    <div class="ebook-right">\
        <h2>You Can Fly!</h2>\
        <p class="ycf-specialissue-intro">Get instant access to Flight Training's special issue titled You Can Fly: Your Path to Become a Pilot. This beginning pilots' resource guide explains what you can expect from your introductory flight through initial training—and how to turn your dream of flying into reality. Simply enter your name and email address.</p>\
        <div class="overflow-container">\
            <img class="ycf-covers" src="/-/media/Images/You-Can-Fly/Promo-Images/YCF-Special-Issue-Covers.png">\
            <img class="ycf-sticker" src="/-/media/Images/You-Can-Fly/Promo-Images/YCF-Special-Issue-Sticker.png">\
        </div>\
        <div class="formcon">\
            <h4>Get Your Free Guide Here!</h4>\
            <form action="https://cl.s10.exct.net/DEManager.aspx" name="YCFSpecialEdForm" id="YCFSpecialEdForm" method="post">\
                <input type="hidden" name="_clientID" value="100020598">\
                <input type="hidden" name="_deExternalKey" value="F33DA1B0-06F3-4DBE-9C35-3708A2F0D7E1">\
                <input type="hidden" name="_action" value="add">\
                <input type="hidden" name="_returnXML" value="0">\
                <input type="hidden" name="_successURL" value="https://www.nxtbook.com/nxtbooks/aopa/ft_202004/">\
                <input type="hidden" name="_errorURL" value="https://www.aopa.org/landing-pages/flight-training-ycf-special-edition/flight-training-ycf-special-edition-error">\
                <input type="hidden" name="Source_Form" value="YCFSpecialEdForm">\
                <div class="formRow">\
                    <span>\
                        <label for="First_Name">First Name <sup>*</sup></label>\
                        <input type="text" name="First_Name" id="recipient_first_name" maxlength="50" minlength="2" style="background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px;" class="completed">\
                    </span>\
                    <span>\
                        <label for="Last_Name">Last Name <sup>*</sup></label>\
                        <input type="text" name="Last_Name" id="recipient_last_name" maxlength="50" minlength="2" class="completed">\
                    </span>\
                    <span>\
                        <label for="Email_Address">Email Address <sup>*</sup></label>\
                        <input type="email" name="Email_Address" id="recipient_email" maxlength="100" class="skipped completed">\
                    </span>\
                    <a class="ycf-submit button">Submit</a>\
                    <p class="privacypolicy">Please review AOPA’s <a href="https://www.aopa.org/privacy-policy" target="_blank">privacy policy</a> to learn more about how your information is used.</p>\
                </div>\
                <input style="display: none" type="submit" value="Read Now" name="set" class="button subbutton" onclick="dataLayer.push({'event':'GTMEvent','EventCategory':'Click Tracking | FT Special Edition','EventAction':'Get Your Free Guide Here','EventLabel':'Read Now |CTA'});">\
            </form>\
        </div>\
    </div>\
</div>\
`;

// YCF Modal
jQuery(document).ready(function () {

    function YCFModalSetupFunction() {        
        $(ycfHTML).appendTo('#aopaYCFModal .aopaModal-customContainer');
    }

    function ValidateForm(id) {
        var formID = $(`#${id} #ycf-form`);
        var formComplete = true;
        $(`#${id} #ycf-form input`).each( function( index ) {
            if ($(this).val()) {
                $(this).removeClass("aopaModal-form-input-error");
            } else {
                formComplete = false;
                $(this).addClass("aopaModal-form-input-error");
            }
        });
        if (!formComplete) {
            $(`#${id} .errorMessage`).show();
        } else {
            $(`#${id} .errorMessage`).hide();
        }
        return formComplete;
    }

    function SubmitYCFModalForm() {
        if (ValidateForm('aopaYCFModal')) {
            SetCookie('aopaYCF-special');
            $('#aopaYCFModal .aopaModal-customContainer form input[type=submit]').trigger('click');
            //alert("Would submit form here");
        }
    }

    var modalYCF = new AOPAModal(
        {
            id: 'aopaYCFModal',
            widthPercent: 80,
            maxWidthPixels: 900,
            anchored: true,
            checkCookie: 'true',
            cookieName: 'aopaYCF-special',
            cookieSetAction: ['all'],
            cookieDuration: 14,
            setupFunction: YCFModalSetupFunction
        }
    );

    $(`#aopaYCFModal .aopaModal-contentContainer`).css('padding','0');

    jQuery('#ycf-modal-test').click(function (event) {
        showAOPAModal('aopaYCFModal');
    });
    jQuery('#aopaYCFModal.aopaModal-customContainer form input[type=submit]').click(function (e) {
        e.preventDefault();
    });
    jQuery('.ycf-submit').click(function (event) {
        SubmitYCFModalForm();
    });

    jQuery('#clearCookie').click(function (event) {
        document.cookie = 'aopaYCF-special' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    });
    $( "#ycf-form input" ).keyup(function() {
        $(this).removeClass('aopaModal-form-input-error');
    });

    setTimeout(function() { showAOPAModal('aopaYCFModal'); }, 2000);

});

    } 
}
