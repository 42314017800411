﻿$(document)
    .ready(function() {
        if ($('div#divLspEventRegistrationForm').length) {

            //
            // Reset the dynamic fields on page load
            //
            var data = JSON.parse(decodeURIComponent($('#OptionalData').val()));
            for (var i = 0; i < data.length; i++) {
                if (!data[i].sel) {
                    $('#oicb' + data[i].pid).prop('checked', false);

                    $(".event-payment-oi__" + data[i].pid).hide();
                    if (data[i].hass) {
                        $("#oi__" + data[i].pid).attr('disabled', 'disabled');

                        $('#oi__' + data[i].pid + ' option[value="' + data[i].selr + '"]').prop('selected', 'selected');
                    }
                }
            }

            $('input.checkbox.oicb').change(function () {
                var data = JSON.parse(decodeURIComponent($('#OptionalData').val()));
                var pid = this.id.replace('oicb', '');
                var idx = -1;
                var ct = 0;

                $.each(data, function() {
                    if (this.pid.toString() === pid) {
                        idx = ct;
                        return false;
                    }
                    ct++;
                });


                if (idx != -1) {
                    if ($(this).is(':checked')) {
                        data[idx].sel = true;
                        $(".event-payment-oi__" + pid).show();
                        if (data[idx].hass) {
                            $("#oi__" + pid).removeAttr('disabled');
                        }
                    } else {
                        data[idx].sel = false;
                        $(".event-payment-oi__" + pid).hide();
                        if (data[idx].hass) {
                            $("#oi__" + pid).attr('disabled', 'disabled');
                        }
                    }
                }

                $('#OptionalData').val(encodeURIComponent(JSON.stringify(data)));
                setTotal();
            });

            $('.oisb').change(function () {
                var data = JSON.parse(decodeURIComponent($('#OptionalData').val()));
                var pid = this.id.replace('oi__', '');
                var idx = -1;
                var ct = 0;

                $.each(data, function () {
                    if (this.pid.toString() === pid) {
                        idx = ct;
                        return false;
                    }
                    ct++;
                });


                if (idx != -1) {
                    data[idx].selr = this.value;
                }

                $('#OptionalData').val(encodeURIComponent(JSON.stringify(data)));
            });

            function setTotal() {
                var data = JSON.parse(decodeURIComponent($('#OptionalData').val()));
                var total = parseFloat($('#PrimaryProductPrice').val());

                $.each(data, function () {
                    if (this.sel) {
                        total = total + this.pr;
                    }
                });

                $('.event-payment-total__price').html('$' + total.toFixed(2));
                $('#OrderTotal').val(total);

            }


            if ($("div#divLspRegisterButton").length) {
                $(".lspRegisterButton").click(function () {
                    var form = $(this).closest('form');
                    if (form.valid()) {
                        $(this).hide();
                        $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    }
                });
            }

        }
    });