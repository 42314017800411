export default () => {
  const primaryLinks = document.querySelectorAll(".primary-link");
  const secondaryLinks = document.querySelectorAll(".js-dropdownBtn");

  // Detect any click on the doc, and close all open Global Header menus if the click is outside an open menu
  document.addEventListener("click", (e) => {
    if (!e.target.closest(".primary-item") &&
     !e.target.closest(".secondary-item") &&
     !e.target.closest(".header__nav-main-list") &&
     !e.target.closest(".header__nav-sub-list") &&
     !e.target.closest(".js-dropdownBtn")) {
      closeOpenMenus();
    }
  });

  primaryLinks.forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();

      let wasOpen = el.classList.contains("primary-link--clicked");
      
      closeOpenMenus();

      if (!wasOpen) {
        el.classList.add("primary-link--clicked");
        el.parentElement.classList.add("primary-underline");
        el.nextElementSibling.classList.remove("hidden");
        el.querySelector(".primary-link > .primary-arrow").classList.add(
          "arrow-rotate"
        );
      }
    });
  });

  secondaryLinks.forEach((el) => {
    el.addEventListener("click", () => {
      let wasOpen = el.classList.contains("js-dropdownBtn--clicked");
      
      closeOpenMenus();

      if (!wasOpen) {
        el.classList.add("js-dropdownBtn--clicked");
        el.nextElementSibling.classList.add("js-dropdown--open");
        el.nextElementSibling.classList.remove("hidden");
        el.querySelector(".js-dropdownBtn > .dropdown-arrow").classList.add(
          "arrow-rotate"
        );
      }
    });
  });

  const closeOpenMenus = () => {
    primaryLinks.forEach((link) => {
      link.classList.remove("primary-link--clicked");
      link.parentElement.classList.remove("primary-underline");
      link.nextElementSibling.classList.add("hidden");
      link
        .querySelector(".primary-link > .primary-arrow")
        .classList.remove("arrow-rotate");
    });
    secondaryLinks.forEach((button) => {
      button.classList.remove("js-dropdownBtn--clicked");
      button.nextElementSibling.classList.remove("js-dropdown--open");
      button.nextElementSibling.classList.add("hidden");
      button
        .querySelector(".js-dropdownBtn > .dropdown-arrow")
        .classList.remove("arrow-rotate");
    });
  }
};
