export default function datepickerend() {
	/* 
	 * Directive: datepickerend
	 * Call datepicker on end date field
	 * This directive also ascertains that when an end date is picked, the
	 * start date cannot be later than the end date (onSelect function)
	 */
	 
	 return {
		restrict: 'A',
		require : 'ngModel',
		link : function (scope, element, attrs, ngModelCtrl) {
			$(function(){
				element.datepicker({
					nextText: '<svg class="aopa-icon-button"><use xlink:href="/dist/assets/svg-sprite.svg#righttriangle"></use></svg>',
					prevText: '<svg class="aopa-icon-button"><use xlink:href="/dist/assets/svg-sprite.svg#lefttriangle"></use></svg>',
					onSelect:function (date) {
						var maxDate = new Date(Date.parse(date));
						$(this).parents("label").siblings(".item-list__datepicker-start").find(".datepicker").datepicker("option", "maxDate", maxDate);
						ngModelCtrl.$setViewValue(date);
						scope.$apply();
					},
					onClose: function() {
						$(this).siblings("button").data("open", false);
					}
				});
			});
		}
	}
}