﻿/**
 * Mock weather service that returns dummy data for testing.
 */
class MockWeatherService {
    static getMetar () {
        return {
            ident: "KBOS",
            name: "General Edward Lawrence Logan International",
            city: "Boston",
            state: "MA",
            latitude: 42.37,
            longitude: -71.02,
            elevation_m: 6,
            metar_type: "METAR",
            observation_time: "2016-01-26T21:54:00Z",
            raw_text: "KBOS 262154Z 22016KT 10SM SCT130 BKN200 OVC280 08/02 A2983 RMK AO2 SLP099 T00830017",
            flight_category: "VFR",
            visibility: 10,
            ceiling: 7000,
            wind_speed: 16,
            wind_gust: 25,
            wind_dir_degrees: 220,
            altim_in_hg: 29.828741,
            temp_c: 8.3,
            temp_f: 47,
            dewpoint_c: 1.7,
            dewpoint_f: 35,
            wx_string: "-SN BR",
            wx_string_trans: "Light Snow, Mist",
            sky_conditions_trans: [{
                sky_cover: "Scattered clouds",
                cloud_base: "13000' AGL"
            }, {
                sky_cover: "Broken clouds",
                cloud_base: "20000' AGL"
            }, {
                sky_cover: "Overcast",
                cloud_base: "28000' AGL"
            }]
        };
    }
}

export default MockWeatherService;
