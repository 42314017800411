﻿$(document).ready(function () {
    if ($('div#divPacDonationRailForm').length) {

        if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
            $("#divOtherAmount").css({ "visibility": "hidden" });
            $("#divOtherAmount").css({ "display": "none" });
        } else {
            $("#divOtherAmount").css({ "visibility": "visible" });
            $("#divOtherAmount").css({ "display": "block" });
        }

        $('input[name=SelectedDonationAmount]').on('change', function () {
            if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
                if ($('#MinimumDonationAmount').length) {
                    $('#MinimumDonationAmount').val('');
                    $('#MinimumDonationAmount').valid();
                    $('#MinimumDonationAmount').prop("disabled", true);
                    $("#divOtherAmount").css({ "visibility": "hidden" });
                    $("#divOtherAmount").css({ "display": "none" });
                }
            } else {
                $('#MinimumDonationAmount').prop("disabled", false);
                $("#divOtherAmount").css({ "visibility": "visible" });
                $("#divOtherAmount").css({ "display": "block" });
            }
        });

        $(".donateButton").click(function () {
            var form = $(this).closest('form');
            if (form.valid()) {
                $(this).hide();
                $('#divDonationButton').append('<div class="spinner spinner--clockwise"></div>');
            }
        });

        $(".petitionButton").click(function() {
            $(this).hide();
            $('#divPetitionStatus').hide();
            $('#divPetitionButton').append('<div id="divPetitionSpinner" class="spinner spinner--clockwise"></div>');


            $.post('/api/PacPetitionSign/Sign',
                { "data": $('#PetitionInfo').val() },
                function () { }
            )
                .done(function (data) {
                    if (data) {
                        $('#divPetitionStatus').html('<center><span class="greenCopy">' + $('#SignatureAcknowledge').val() + '</span></center>');
                        $('#divPetitionStatus').show();
                        $('#divPetitionThankYou').show();
                        $('#divPetitionSpinner').remove();
                        $('#divPetitionButton').hide();
                    } else {
                        $('#divPetitionStatus').html('<center><span class="redCopy">' + $('#SignatureError').val() + '</span></center>');
                        $('#divPetitionStatus').show();
                        $('#divPetitionSpinner').remove();
                        $(".petitionButton").show();
                    }
                    $('#divDonate').show();

                    $('#divPetitionSpinner').remove();
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#divPetitionSpinner').remove();
                    $(".petitionButton").show();
                    $('#divPetitionStatus').html('<center><span class="redCopy">' + $('#SignatureError').val() + '</span></center>');
                    $('#divPetitionStatus').show();
                    $('#divDonate').show();
                });
        });

    }
});