﻿$(document)
    .ready(function () {
        if ($('div#divAftaSchooleEmployeePublicForm').length) {
            var theForm = $('#frmAftaSchoolEmployeePublic');
            
            $('.afta-fs-add-request').click(function() {
                $('#FormMode').val('SUBMITSCHOOL');
                $('#frmEnroll').hide();
                $('#frmSubmitSchool').show();
                $('#divfiInfoEnroll').hide();
                $('#divSchoolInfoSubmit').show();
            });


            $(".submitbuttonaftaschoolemployeepublic").click(function () {
                if (theForm.valid()) {
                    $('#btnEnroll').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                } else {
                    var firstInstance = $('.input-validation-error').first();
                    var offsetTop = firstInstance.offset().top - 100;

                    $('html, body').animate({
                        scrollTop: offsetTop
                    }, 500);
                }
            });

            $(".submitbuttonaftasep").click(function () {
                if (theForm.valid()) {
                    $('#btnSubmitSchool').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                } else {
                    var firstInstance = $('.input-validation-error').first();
                    var offsetTop = firstInstance.offset().top - 100;

                    $('html, body').animate({
                        scrollTop: offsetTop
                    }, 500);
                }
            });
        }
    });