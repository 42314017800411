﻿$(document)
    .ready(function() {
        if ($('div#divSignupSubscribeForm').length) {

            $('#SelectedCountry').change(function () {
                var option = $(this).find('option:selected').val();
                $.getJSON('/api/CountryState/GetStates/?countryCode=' + option)
                    .done(function (data) {
                        $('#SelectedState').empty();
                        $.each(data, function (key, item) {
                            var $option = $("<option />");
                            $option.attr("value", item.value).text(item.text);
                            $('#SelectedState').append($option);
                        });
                    })
                    .fail(function (jqXHR, textStatus, err) {
                        $('#SelectedState').empty();
                        var $option = $("<option />");
                        $options.attr("value", "").text("- Error -");
                        $('#SelectedState').append($option);
                    });
            });


            $(".joinButton").click(function () {
                var form = $(this).closest('form');
                if (form.valid()) {
                    $(this).hide();
                    $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
                }
            });
        }
    });
