/**
 * AOPA Airports API proxy.
 */
class AirportService {
    constructor ($http, $q) {
        this.baseUrl = Aopa.AIRPORTS_API_URL;
        this.$http = $http;
        this.$q = $q;
    }

    /**
     * Returns autocomplete suggestsions for airports
     * @param {String} query
     * @returns {Array<Object>} Airports
     */
    search (query) {
        return this.$q((resolve, reject) => {
            try {
                this.$http.get(`${this.baseUrl}/airportsautocomplete/${encodeURIComponent(query)}`)
                    .then(response => resolve(response.data))
                    .catch(e => reject(e));
            } catch (e) {
                reject(e);
            }
        });
    }
}

AirportService.$inject = ['$http', '$q'];

export default AirportService;
