﻿$(document).ready(function () {
    if ($('div#divGenericProductPurchase').length) {

        window.onload = function () {
            $("input.CheckBoxListHelper").prop('disabled', false);

            if ($('#ItemCount').val() === "1") {
                $("input.CheckBoxListHelper").prop('readonly', 'readonly');
            }
        }


        if ($('#ShowShippingAddress').val() === 'True') {
            if ($('#ShippingSameAsBilling')[0].checked) {
                $('#FirstNameRequired').val('False');
                $('#LastNameRequired').val('False');
                var sop = $('#State option:first-child');
                if (sop) {
                    sop.val("0");
                    sop.attr('selected', 'selected');
                }
            } else {
                $('#FirstNameRequired').val('True');
                $('#LastNameRequired').val('True');
                var sop = $('#State option:first-child');
                if (sop) {
                    sop.val("");
                    sop.attr('selected', 'selected');
                }
            }
        }
       
        $('#ShippingSameAsBilling').change(function() {
            if (this.checked === true) {
                $('#divShipperData').css({ "visibility": "hidden" });
                $('#divShipperData').css({ "display": "none" });
                $('#FirstNameRequired').val('False');
                $('#LastNameRequired').val('False');
                $('#Address1Required').val('False');
                $('#Address2Required').val('False');
                $('#Address3Required').val('False');
                $('#Address4Required').val('False');
                $('#CityRequired').val('False');
                $('#PostalCodeRequired').val('False');
                $('#CountyRequired').val('False');
                var sop = $('#State option:first-child');
                if (sop) {
                    sop.val("0");
                    sop.attr('selected', 'selected');
                }
            } else {
                $('#divShipperData').css({ "visibility": "visible" });
                $('#divShipperData').css({ "display": "block" });
                $('#FirstNameRequired').val('True');
                $('#LastNameRequired').val('True');
                var sop = $('#State option:first-child');
                if (sop) {
                    sop.val("");
                    sop.attr('selected', 'selected');
                }
                $('#Country').trigger('change');
            }
        });


        if ($('#ItemCount').val() === "1") {
            $("input.CheckBoxListHelper").prop('readonly', 'readonly');
        }

        $(document.body).delegate('[type="checkbox"][readonly="readonly"]', 'click', function (e) {
            e.preventDefault();
        });
       
        $("input[name^='ProductListItems']").change(function () {
            var count = 0;
            var total = 0.0;
            var productList = "";
            var summary = "";
            $('#divFormSummary').html('');

            $("input:checkbox[name^='ProductListItems']").each(function() {
                if (this.checked) {
                    var cb = this.name.split(".")[0];
                    var itemData = $('[id="' + cb + '.ID"]').val().split('|');

                    summary += '<div class="l-wrapper">';
                    summary += '<div class="l-column column-860 left-side">';
                    summary += $('[id="' + cb + '.Display"]').val();
                    summary += '</div>';
                    summary += '<div class="l-column column-300 right-side">';
                    summary += '$' + parseFloat(itemData[3]).toFixed(2);
                    summary += '</div>';
                    summary += '</div>';

                    total += parseFloat(itemData[3]);

                    productList += itemData[0] + '|' + itemData[2] + ',';
                }
            });

            if ($('#CalculateTax').val() === 'True') {
                if (productList.length > 0) {
                    $.getJSON('/api/OrderCalculateTax/Get/?validCode=' +
                            $('#ValidCode').val() +
                            '&productList=' +
                            productList.slice(0, -1) +
                            '&quantity=' + $('#Quantity').val())
                        .done(function(data) {
                            if (data.success && parseFloat(data.taxAmount) > 0) {
                                summary += '<div class="l-wrapper">';
                                summary += '<div class="l-column column-860 left-side">Tax</div>';
                                summary += '<div class="l-column column-300 right-side">';
                                summary += '$' + parseFloat(data.taxAmount).toFixed(2);
                                summary += '</div>';
                                summary += '</div>';

                                total += parseFloat(data.taxAmount);
                            }

                            $('#TaxTotal').val(0.0);
                            $('#OrderTotal').val(total.toFixed(2));
                            $('#divFormSummary').html(summary);
                            $('#divFormTotal').html('$' + total.toFixed(2));
                        })
                        .fail(function(jqXHR, textStatus, err) {
                            $('#TaxTotal').val(0.0);
                            $('#OrderTotal').val(total.toFixed(2));
                            $('#divFormSummary').html(summary);
                            $('#divFormTotal').html('$' + total.toFixed(2));
                        });
                } else {
                    $('#TaxTotal').val(0.0);
                    $('#OrderTotal').val(0.0);
                    $('#divFormSummary').html(summary);
                    $('#divFormTotal').html('$' + total.toFixed(2));
                }
            } else {
                $('#TaxTotal').val(0.0);
                $('#OrderTotal').val(total.toFixed(2));
                $('#divFormSummary').html(summary);
                $('#divFormTotal').html('$' + total.toFixed(2));
            }

            if (productList.length === 0) {
                $('#divProductSelectionError').css({ "visibility": "visible" });
                $('#divProductSelectionError').css({ "display": "block" });
                $('#btnFormSubmit').prop('disabled', true);
            } else {
                $('#divProductSelectionError').css({ "visibility": "hidden" });
                $('#divProductSelectionError').css({ "display": "none" });
                $('#btnFormSubmit').prop('disabled', false);
            }

        });


        $('#productQuantityUpdate').click(function () {
            var qty = parseInt($('#productQuantity').val());
            if (!isNaN(qty)) {
                if (qty < 1 || qty > 99) {
                    $('#productQuantity').val($('#Quantity').val());
                    qty = parseInt($('#Quantity').val());
                }
            } else {
                $('#productQuantity').val($('#Quantity').val());
                qty = parseInt($('#Quantity').val());
            }

            $('#Quantity').val(qty);


            var prod = $("input:checkbox[name^='ProductListItems']:checked:first");
            var cb = prod[0].name.split(".")[0];
            var itemData = $('[id="' + cb + '.ID"]').val().split('|');

            var lineTotal = qty * parseFloat(itemData[3]);

            $('#productTotal_' + itemData[0]).html('$' + lineTotal.toFixed(2));

            $('#OrderTotal').val(lineTotal.toFixed(2));

            $('#divFormTotal').html('$' + lineTotal.toFixed(2));

        });
    }
});