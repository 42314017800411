﻿$(document).ready(function () {
    if ($('div#divAddUpdatePps').length) {

        $('#SelectedPpsRateCode').change(function () {
            var ppsrate = $('#SelectedPpsRateCode').val().split("|");
           
            $.getJSON('/api/PpsProrate/Get/?ppsProductId=' + $('#PpsProductId').val() + '&rateCode=' + ppsrate[1] + '&cycleBeginDate=' + $('#NextCycleBeginDate').val())
                .done(function (data) {
                    $('.formSubmitButton').prop("disabled", false);
                    $('#ProratedAmount').val(data.toFixed(2));
                    $('#divProratedAmount').text("$" + data.toFixed(2));

                    if ($('#DiscountType').val() === "P") {
                        $('#ProratedDiscount').val((data.toFixed(2) * ($('#DiscountAmount').val() / 100)).toFixed(2));
                        $('#ProductDiscount').val(parseFloat(ppsrate[2]) * ($('#DiscountAmount').val() / 100).toFixed(2));

                    }

                    if ($('#AddAmounts').val() === "True" || $('#AddAmounts').val() === "true") {
                        $('#divPDA').text('-$' + parseFloat($('#ProductDiscount').val()).toFixed(2));
                        $('#divPrDA').text('-$' + parseFloat($('#ProratedDiscount').val()).toFixed(2));
                        var theRate1 = data + parseFloat(ppsrate[2]) - parseFloat($('#ProratedDiscount').val()) - parseFloat($('#ProductDiscount').val());
                        $('.pps-total strong').text('$' + theRate1.toFixed(2));
                    } else {
                        $('#divPrDA').text('-$' + parseFloat($('#ProratedDiscount').val()).toFixed(2));
                        var theRate2 = data - parseFloat($('#ProratedDiscount').val());
                        $('.pps-total strong').text('$' + theRate2.toFixed(2));
                    }
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('.formSubmitButton').prop("disabled", true);
                    $('#ProratedAmount').val("-.--");
                    $('#divProratedAmount').text("$-.--");
                    $('.pps-total strong').text("$-.--");
                });
        });

        $('input[name=SelectedPaymentOption]').on('change', function () {
            if ($('input[name=SelectedPaymentOption]:checked').val() === "UseCardOnFile") {
                $("#divPayment").css({ "visibility": "hidden" });
                $("#divPayment").css({ "display": "none" });
                $("#SelectedCreditCardType").val("VISA");
                $("#SelectedCreditCardExpMonth").val("12");
                var d = new Date();
                $("#SelectedCreditCardExpYear").val(d.getFullYear() + 5);
                $("#CcNumber").val("4111111111111111");
                $("#Cvv").val("123");
                $("#NameOnCard").val("xxxx");
            } else {
                $("#SelectedCreditCardType").val("");
                $("#SelectedCreditCardExpMonth").val("");
                $("#SelectedCreditCardExpYear").val("");
                $("#CcNumber").val("");
                $("#Cvv").val("");
                $("#NameOnCard").val("");
                $("#divPayment").css({ "visibility": "visible" });
                $("#divPayment").css({ "display": "block" });
            }
        });

    }
});