﻿$(document)
    .ready(function () {
        if ($('div#divAircraftShowcaseForm').length) {
            var theForm = $('#frmAircraftShowcase');

            //
            // Camping Handler
            //
            $("#Camping")
                .click(function () {
                    var cb = document.getElementById(this.id);
                    if (cb.checked) {
                        $('.sc-camping-section').show();
                    } else {
                        $('.sc-camping-section').hide();
                    }
                });


            //
            // Reset the questionnaire fields on page load
            //
            if ($('#ShowcaseQuestionnaireData').val()) {
                QuestionnaireReset('Showcase');
            }
            if ($('#CampingQuestionnaireData').val()) {
                QuestionnaireReset('Camping');
            }

            function QuestionnaireReset(product)
            {
                var data = JSON.parse(decodeURIComponent($('#' + product + 'QuestionnaireData').val()));
                for (var i = 0; i < data.length; i++) {
                    switch (data[i].type) {
                    case "Select":
                            $('#ScQuestionSelect' + data[i].qid + ' option[value="' + data[i].qa + '"]').prop('selected', true);
                        break;
                    case "CB":
                        var answers = data[i].qa.split(',');
                        $.each(answers, function (index, obj) {
                            $('input.ScQuestionCB' + data[i].qid + '[value="' + obj + '"]').prop('checked', true);
                        });
                        break;
                    }

                }
            }

            $('.ScQuestionSelect').change(function () {
                var instance = $(this).data("instance");
                var data = JSON.parse(decodeURIComponent($('#' + instance + 'QuestionnaireData').val()));
                var qid = this.id.replace('ScQuestionSelect', '');
                var idx = -1;
                var ct = 0;

                $.each(data, function () {
                    if (this.qid.toString() === qid) {
                        idx = ct;
                        return false;
                    }
                    ct++;
                });


                if (idx != -1) {
                    data[idx].qa = this.value;
                }

                $('#' + instance + 'QuestionnaireData').val(encodeURIComponent(JSON.stringify(data)));
            });

            $('.ScQuestionCB').change(function () {
                var instance = $(this).data("instance");
                var data = JSON.parse(decodeURIComponent($('#ScQuestionnaireData').val()));
                var cbid = this.id;
                var qid = this.name.replace(instance + 'QuestionCB', '');
                var anslist = '';
                var idx = -1;
                var ct = 0;

                $.each(data, function () {
                    if (this.qid.toString() === qid) {
                        idx = ct;
                        return false;
                    }
                    ct++;
                });

                $('.' + cbid).each(function (index, obj) {
                    if (this.checked) {
                        anslist += this.value + ',';
                    }
                });
                anslist = anslist.slice(0, -1);


                if (idx != -1) {
                    data[idx].qa = anslist;
                }

                $('#' + instance + 'QuestionnaireData').val(encodeURIComponent(JSON.stringify(data)));
            });




            $(".aircraftshowcasecontinuebutton")
                .click(function () {
                    if (validateDiv($(this).data("thispage"))) {
                        var btn = document.getElementById(this.id);
                        var nextpage = $(this).data("nextpage");

                        if (btn != null && btn.hasAttribute("data-nextpage-choice")) {
                            var cb = document.getElementById($(this).data("nextpage-choice"));
                            if (!cb.checked) {
                                nextpage = $(this).data("alt-nextpage");
                            }
                        }

                        $('#' + $(this).data("thispage")).hide();
                        $('#' + nextpage).show();
                        infoDivs(nextpage);
                        window.scrollTo(0, 0);
                    }
                });


            $(".aircraftshowcasebackbutton")
                .click(function () {
                    var btn = document.getElementById(this.id);
                    var prevpage = $(this).data("prevpage");

                    if (btn != null && btn.hasAttribute("data-prevpage-choice")) {
                        var cb = document.getElementById($(this).data("prevpage-choice"));
                        if (!cb.checked) {
                            prevpage = $(this).data("alt-prevpage");
                        }
                    }

                    $('#' + $(this).data("thispage")).hide();
                    $('#' + prevpage).show();
                    infoDivs(prevpage);
                    window.scrollTo(0, 0);
                });

            $("#VolunteerChoice")
                .click(function () {
                    var cb = document.getElementById(this.id);
                    if (cb.checked) {
                        if ($('#AttendeeCountSelected').val() == "2") {
                            $('#divGuestVolunteerQuestion').show();
                        } else {
                            $('#divGuestVolunteerQuestion').hide();
                            var cb = document.getElementById("GuestVolunteerChoice");
                            cb.checked = false;
                        }

                        $('.' + $(this).data("reveal-cls")).show();


                        SummaryVolunteerSection('Main', 'main', true);
                    } else {
                        $('.' + $(this).data("reveal-cls")).hide();

                        $('#divGuestVolunteerQuestion').hide();
                        var cb = document.getElementById("GuestVolunteerChoice");
                        cb.checked = false;

                        SummaryVolunteerSection('Main', 'main', false);
                        SummaryVolunteerSection('Guest', 'guest', false);
                    }
                });

            $("#GuestVolunteerChoice")
                .click(function () {
                    var cb = document.getElementById(this.id);
                    if (cb.checked) {
                        SummaryVolunteerSection('Guest', 'guest', true);
                    } else {
                        SummaryVolunteerSection('Guest', 'guest', false);
                    }
                });
            

            $('.aircraft-showcase-guest-u').change(function () {
                var cb = document.getElementById("VolunteerChoice");

                if ($(this).val() == "2") {
                    $('#divGuestInformation').show();
                    if (cb.checked) {
                        $('#divGuestVolunteerQuestion').show();
                    }
                } else {
                    $('#divGuestInformation').hide();
                    $('#divGuestVolunteerQuestion').hide();
                    var cb = document.getElementById("GuestVolunteerChoice");
                    cb.checked = false;
                }
                calculateTotal();
            });


            $('.aircraft-showcase-secondary-u').change(function() {
                if ($(this).val() !== "0") {
                    $('#divSecondaryRate').show();
                } else {
                    $('#divSecondaryRate').hide();
                }
                calculateTotal();
            });

            $("#VolunteerCertifiedPilot")
                .click(function () {
                    var cb = document.getElementById(this.id);
                    if (cb.checked) {
                        $('.hsse-main-volunteer-airside').show();
                    } else {
                        $('.hsse-main-volunteer-airside').hide();

                        if ($('input:radio[name=MainDay1VolunteerJobsSelected]:checked').val() && $('input:radio[name=MainDay1VolunteerJobsSelected]:checked').val().indexOf("AIRSIDE") >= 0) {
                            $('.' + $('input:radio[name=MainDay1VolunteerJobsSelected]:checked').data('summary-reveal')).hide();
                            $('input:radio[name=MainDay1VolunteerJobsSelected]:checked').prop('checked', false);
                            SummaryVolunteerSection('Main', 'main', true);
                        }

                        if ($('input:radio[name=MainDay2VolunteerJobsSelected]:checked').val() && $('input:radio[name=MainDay2VolunteerJobsSelected]:checked').val().indexOf("AIRSIDE") >= 0) {
                            $('.' + $('input:radio[name=MainDay2VolunteerJobsSelected]:checked').data('summary-reveal')).hide();
                            $('input:radio[name=MainDay2VolunteerJobsSelected]:checked').prop('checked', false);
                            SummaryVolunteerSection('Main', 'main', true);
                        }

                        if ($('input:radio[name=MainDay3VolunteerJobsSelected]:checked').val() && $('input:radio[name=MainDay3VolunteerJobsSelected]:checked').val().indexOf("AIRSIDE") >= 0) {
                            $('.' + $('input:radio[name=MainDay3VolunteerJobsSelected]:checked').data('summary-reveal')).hide();
                            $('input:radio[name=MainDay3VolunteerJobsSelected]:checked').prop('checked', false);
                            SummaryVolunteerSection('Main', 'main', true);
                        }
                    }
                });

            $("#GuestVolunteerCertifiedPilot")
                .click(function () {
                    var cb = document.getElementById(this.id);
                    if (cb.checked) {
                        $('.hsse-guest-volunteer-airside').show();
                    } else {
                        $('.hsse-guest-volunteer-airside').hide();

                        if ($('input:radio[name=GuestDay1VolunteerJobsSelected]:checked').val() && $('input:radio[name=GuestDay1VolunteerJobsSelected]:checked').val().indexOf("AIRSIDE") >= 0) {
                            $('.' + $('input:radio[name=GuestDay1VolunteerJobsSelected]:checked').data('summary-reveal')).hide();
                            $('input:radio[name=GuestDay1VolunteerJobsSelected]:checked').prop('checked', false);
                            SummaryVolunteerSection('Guest', 'guest', false);
                        }

                        if ($('input:radio[name=GuestDay2VolunteerJobsSelected]:checked').val() && $('input:radio[name=GuestDay2VolunteerJobsSelected]:checked').val().indexOf("AIRSIDE") >= 0) {
                            $('.' + $('input:radio[name=GuestDay2VolunteerJobsSelected]:checked').data('summary-reveal')).hide();
                            $('input:radio[name=GuestDay2VolunteerJobsSelected]:checked').prop('checked', false);
                            SummaryVolunteerSection('Guest', 'guest', false);
                        }

                        if ($('input:radio[name=GuestDay3VolunteerJobsSelected]:checked').val() && $('input:radio[name=GuestDay3VolunteerJobsSelected]:checked').val().indexOf("AIRSIDE") >= 0) {
                            $('.' + $('input:radio[name=GuestDay3VolunteerJobsSelected]:checked').data('summary-reveal')).hide();
                            $('input:radio[name=GuestDay3VolunteerJobsSelected]:checked').prop('checked', false);
                            SummaryVolunteerSection('Guest', 'guest', false);
                        }
                    }
                });


            $('.hss-volunteer-rb')
                .click(function () {
                    var rb = document.getElementById(this.id);

                    if (rb.checked) {
                        $('.' + $(this).data('clear-class')).hide();
                        $('.' + $(this).data('summary-reveal')).show();

                        var ty = $(this).data('summary-reveal').split('-')[1];

                        SummaryVolunteerSection(ty.charAt(0).toUpperCase() + ty.slice(1), ty, true);
                    }
                });


            $('#MainVolunteerShirtSizeSelected').change(function () {
                $('#divMainVolunteerShirtSummary').text('Volunteer Shirt (' + $('#MainVolunteerShirtSizeSelected option:selected').text() + ')');
            });

            $('#GuestVolunteerShirtSizeSelected').change(function () {
                $('#divGuestVolunteerShirtSummary').text('Volunteer Shirt (' + $('#GuestVolunteerShirtSizeSelected option:selected').text() + ')');
            });


            function SummaryVolunteerSection(section, cssclass, show) {
                if (show && ($('input:radio[name=' + section + 'Day1VolunteerJobsSelected]:checked').val() ||
                    $('input:radio[name=' + section + 'Day2VolunteerJobsSelected]:checked').val() ||
                    $('input:radio[name=' + section + 'Day3VolunteerJobsSelected]:checked').val())) {
                    $('.hsse-' + cssclass + '-volunteer-section').show();
                } else {
                    $('.hsse-' + cssclass + '-volunteer-section').hide();
                }
            }

            

            function validateDiv(div) {
                var validator = theForm.data('validator');
                var returnValue = true;

                $('#' + div + ' :input').each(function (e) {
                    if (!$(this).is(":button") && this.id.length) {
                        if (!validator.element($('#' + this.id))) returnValue = false;
                    }
                });

                return returnValue;
            }

            $(".asRegisterButton").click(function () {
                if (theForm.valid()) {
                    $('.btn_final_cancelback').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });
            
            function infoDivs(page) {
                switch (page) {
                    case 'asPage1':
                        $('#asProductName').show();
                        $('#asMainInfo').show();
                        $('#divEventSummary').show();
                        $('#asVolunteerInfo').show();
                        break;
                    case 'asPage2':
                        $('#asProductName').show();
                        $('#asMainInfo').show();
                        $('#divEventSummary').show();
                        $('#asVolunteerInfo').show();
                        break;
                    case 'asPage3':
                        $('#asProductName').show();
                        $('#asMainInfo').show();
                        $('#divEventSummary').show();
                        $('#asVolunteerInfo').show();
                        break;
                    case 'asPage4':
                        $('#asProductName').hide();
                        $('#asMainInfo').hide();
                        $('#divEventSummary').hide();
                        $('#asVolunteerInfo').hide();
                        break;
                }
            }

            function calculateTotal() {

                var total = 0.0;


                var primaryTotal = parseInt($('#AttendeeCountSelected').val(), 10) * parseFloat($('#TicketPrice').val());
                var secondaryTotal = parseInt($('#SecondaryCountSelected').val(), 10) * parseFloat($('#SecondaryPrice').val());


                total += primaryTotal + secondaryTotal;


                $('#divSummaryAttendeeCount').text($('#AttendeeCountSelected').val());
                $('#divSummaryLineTotal').text('$' + primaryTotal.toFixed(2));

                $('#divSummarySecondaryCount').text($('#SecondaryCountSelected').val());
                $('#divSummarySecondaryLineTotal').text('$' + secondaryTotal.toFixed(2));


                $('#OrderTotal').val(total);
                $('.event-payment-total__price').text('$' + total.toFixed(2));
            }

        }
    });
