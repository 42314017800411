﻿$(document).ready(function () {
    if ($('div#divRustyPilotSignIn').length) {
        var rpForm = $('#frmRustyPilotSignIn');
        var flagSearch = "Search";
        var flagCheckIn = "CheckIn";
        var flagClearSearch = "ClearSearch";
        var flagCreateCustomer = "CreateCustomer";
        var flagCreateOrder = "CreateOrder";
        var flagLoadCreateOrder = "LoadCreateOrder";
        var flagSearchReturn = "SearchReturn";
        var flagLoadCheckin = "LoadCheckin";
        var flagLoadCreateCustomer = "LoadCreateCustomer";
        
        //
        // Search click
        //
        $('#btnSearch').click(function () {
            $('#FormMode').val(flagSearch);

            if (rpForm.valid()) {
                rpForm.submit();
            }
        });

        //
        // Load Create New Customer click
        //
        $('#btnCreateNewCustomer').click(function () {
            $('#FormMode').val(flagLoadCreateCustomer);

            rpForm.submit();
        });

        //
        // Create New Customer click
        //
        $('#btnCreateCustomer').click(function () {
            $('#FormMode').val(flagCreateCustomer);

            if (rpForm.valid()) {
                rpForm.submit();
            }
        });

        //
        // Clear Search click
        //
        $('#btnClearSearch').click(function () {
            $('#FormMode').val(flagClearSearch);
           rpForm.submit();
        });

        //
        // Back to search
        //
        $('.back-to-search').click(function () {
            $('#FormMode').val(flagSearchReturn);

            if ($("div#divQuestionnaire").length) {
                $("#divQuestionnaire *").prop('disabled', true);
            }

            if ($("div#divCreateCustomer").length) {
                $("#divCreateCustomer *").prop('disabled', true);
            }

            rpForm.submit();
        });

        //
        // Goto checkin
        //
        $(document).on("click",
            ".view-checkin-button",
            function (e) {
                $('#FormMode').val(flagLoadCheckin);
                $('#CheckinMasterCustomerId').val($(this).data("mcid"));
                $('#CheckInSubCustomerId').val($(this).data("scid"));
                $('#CheckinOrderNumber').val($(this).data("ordnum"));
                $('#CheckinProductCode').val($('#RPEvent').val());
                rpForm.submit();
            });

        //
        // Process checkin
        //
        $(document).on("click",
            ".checkin-action-button",
            function (e) {
                $('#FormMode').val(flagCheckIn);
                $('#CheckInOrderLineNumber').val($(this).data("lineno"));
                rpForm.submit();
            });


        //
        // Goto create order
        //
        $(document).on("click",
            ".create-order-button",
            function (e) {
                $('#FormMode').val(flagLoadCreateOrder);
                $('#CreateOrderMasterCustomerId').val($(this).data("mcid"));
                $('#CreateOrderSubCustomerId').val($(this).data("scid"));
                $('#CreateOrderProductCode').val($('#RPEvent').val());
                rpForm.submit();
            });

        //
        // Create the order
        //
        $('.create-registration').click(function () {
            if (rpForm.valid()) {
                $('#FormMode').val(flagCreateOrder);
                rpForm.submit();
            }
        });


        //
        // Questionnaire fields
        //
        //
        // Reset the questionnaire fields on page load
        //
        if ($('#QuestionnaireData').val()) {
            var data = JSON.parse(decodeURIComponent($('#QuestionnaireData').val()));
            for (var i = 0; i < data.length; i++) {
                switch (data[i].type) {
                    case "Select":
                        $('#MPQuestionSelect' + data[i].qid + ' option[value="' + data[i].qa + '"]').prop('selected', true);
                        break;
                    case "CB":
                        var answers = data[i].qa.split(',');
                        $.each(answers, function (index, obj) {
                            $('input.MPQuestionCB' + data[i].qid + '[value="' + obj + '"]').prop('checked', true);
                        });
                        break;
                }

            }
        }

        $('.MPQuestionSelect').change(function () {
            var data = JSON.parse(decodeURIComponent($('#QuestionnaireData').val()));
            var qid = this.id.replace('MPQuestionSelect', '');
            var idx = -1;
            var ct = 0;

            $.each(data, function () {
                if (this.qid.toString() === qid) {
                    idx = ct;
                    return false;
                }
                ct++;
            });


            if (idx != -1) {
                data[idx].qa = this.value;
            }

            $('#QuestionnaireData').val(encodeURIComponent(JSON.stringify(data)));
        });

        $('.MPQuestionCB').change(function () {
            var data = JSON.parse(decodeURIComponent($('#QuestionnaireData').val()));
            var cbid = this.id;
            var qid = this.id.replace('MPQuestionCB', '');
            var anslist = '';
            var idx = -1;
            var ct = 0;

            $.each(data, function () {
                if (this.qid.toString() === qid) {
                    idx = ct;
                    return false;
                }
                ct++;
            });

            $('.' + cbid).each(function (index, obj) {
                if (this.checked) {
                    anslist += this.value + ',';
                }
            });
            anslist = anslist.slice(0, -1);


            if (idx != -1) {
                data[idx].qa = anslist;
            }

            $('#QuestionnaireData').val(encodeURIComponent(JSON.stringify(data)));
        });

    };
});