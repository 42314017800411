﻿$(document)
    .ready(function() {
        if ($(".aopa-subscription").length) {

            $(".aopa-subscribe-button").click(function () {
                var instance = $(this).data("instance");
                $(".subscription-msg").hide();

                $(`#btn${instance}Subscribe`).addClass("button--spinner");
                
                $.post('/api/SubscriptionApi/ProcessSubscription',
                        {
                            "data": $(`#hdn${instance}Data`).val(),
                            "status": true
                        },
                        function () { }
                    )
                    .done(function (data) {
                        $(`#btn${instance}SubcribeContainer`).hide();
                        $(`#btn${instance}UnsubscribeContainer`).show();
                        $(`#div${instance}SubscribeSucceed`).show();
                    })
                    .fail(function (jqXHR, textStatus, err) {
                        $(`#div${instance}SubscribeFailed`).show();
                    })
                    .always(function() {
                        $(`#btn${instance}Subscribe`).removeClass("button--spinner");
                        $(`#btn${instance}Subscribe`).prop('disabled', false);
                    });
            });


            $(".aopa-unsubscribe-button").click(function() {
                var instance = $(this).data("instance");
                $(".subscription-msg").hide();

                $(`#btn${instance}Unsubscribe`).addClass("button--spinner");

                $.post('/api/SubscriptionApi/ProcessSubscription',
                        {
                            "data": $(`#hdn${instance}Data`).val(),
                            "status": false
                        },
                        function () { }
                    )
                    .done(function (data) {
                        $(`#btn${instance}UnsubscribeContainer`).hide();
                        $(`#btn${instance}SubcribeContainer`).show();
                        $(`#div${instance}UnsubscribeSucceed`).show();
                    })
                    .fail(function (jqXHR, textStatus, err) {
                        $(`#div${instance}UnsubscribeFailed`).show();
                    })
                    .always(function () {
                        $(`#btn${instance}Unsubscribe`).removeClass("button--spinner");
                        $(`#btn${instance}Unsubscribe`).prop('disabled', false);
                    });
            });
        }
    });
