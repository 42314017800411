﻿$(document)
    .ready(function () {
        if ($('div#divCFIInformation').length) {
            var theForm = $('#frmCfiInfoUpdate');


            var cfiAutocomplete;

            var initAutocomplete = () => {
                // Create the autocomplete object, restricting the search predictions to
                cfiAutocomplete = new google.maps.places.Autocomplete(AddressLookup,
                    {
                        fields: ["address_components"],
                        types: ["address"],
                    });
                // When the user selects an address from the drop-down, populate the
                // address fields in the form.
                cfiAutocomplete.addListener("place_changed", fillInAddress);
            };

            initAutocomplete();

            function fillInAddress() {
                // Get the place details from the autocomplete object.
                var place = cfiAutocomplete.getPlace();
                var address1 = "";
                var postcode = "";

                $("#MailingStreet").val("");
                $("#MailingCity").val("");
                $("#MailingStateProvince").val("");
                $("#MailingPostalCode").val("");
                $("#MailingCountry").val("");

                // Get each component of the address from the place details,
                // and then fill-in the corresponding field on the form.
                // place.address_components are google.maps.GeocoderAddressComponent objects
                // which are documented at http://goo.gle/3l5i5Mr
                place.address_components.forEach((component) => {
                    var componentType = component.types[0];

                    switch (componentType) {
                        case "street_number":
                            {
                                address1 = `${component.long_name} ${address1}`;
                                break;
                            }

                        case "route":
                            {
                                address1 += component.short_name;
                                break;
                            }

                        case "postal_code":
                            {
                                postcode = `${component.long_name}${postcode}`;
                                break;
                            }

                        case "postal_code_suffix":
                            {
                                postcode = `${postcode}-${component.long_name}`;
                                break;
                            }
                        case "locality":
                            $("#MailingCity").val(component.long_name);
                            clearValidation("MailingCity");
                            break;
                        case "administrative_area_level_1":
                            {
                                $("#MailingStateProvince").val(component.short_name);
                                clearValidation("MailingStateProvince");
                                break;
                            }
                        case "country":
                            $("#MailingCountry").val(component.long_name);
                            if (component.short_name === "US") {
                                $("#rdoMailingCountryUS").prop("checked", true);
                                $("#divMailingCountry").hide();
                                $('#IsDomestic').val("True");
                                $('#IsInternational').val("False");
                            } else {
                                $("#rdoMailingCountryOther").prop("checked", true);
                                $("#divMailingCountry").show();
                                $('#IsDomestic').val("False");
                                $('#IsInternational').val("True");
                                $('#MembershipTerm').val("ANNUAL");
                                $('#btnAnnual').removeClass('joinv4-term-button__inactive');
                                $('#btnMonthly').addClass('joinv4-term-button__inactive');
                            }
                            clearValidation("MailingCountry");
                            break;

                    }
                });

                $("#MailingStreet").val(address1);
                clearValidation("MailingStreet");
                $("#MailingPostalCode").val(postcode);
                clearValidation("MailingPostalCode");
                $("#MailingStreet").focus();
                setAAR();
                setTotal();
            };

            var clearValidation = (field) => {
                var theField = $('#' + field);
                if (theField.val()) {
                    theField.removeClass("input-validation-error").addClass("valid");
                    $("span[data-valmsg-for='" + field + "']").removeClass("field-validation-error").addClass("field-validation-valid");
                    $("span[data-valmsg-for='" + field + "']").empty();
                }
            };

            $("input[type=radio][name=rdoMailingCountry]").change(function () {
                if ($('input[name="rdoMailingCountry"]:checked').val() === "US") {
                    $('#IsDomestic').val("True");
                    $('#IsInternational').val("False");
                    $("#divMailingCountry").hide();
                    $('#MailingCountry').val("United States");
                } else {
                    $('#IsDomestic').val("False");
                    $('#IsInternational').val("True");
                    $("#divMailingCountry").show();
                    $('#MailingCountry').val("");
                }
            });



            fixOther(false);

            $('input[name=SelectedCourseReason]').on('change', function () {
                fixOther(true);
            });

            function fixOther(clear) {
                if ($('#FormMode').val() === "EFIRC") {
                    if ($('input[name=SelectedCourseReason]:checked').val() === "OTHER") {
                        if (clear) {
                            $('#CourseReasonOther').val("");
                        }
                        $("#divOtherReason").css({ "visibility": "visible" });
                        $("#divOtherReason").css({ "display": "block" });
                    } else {
                        $("#divOtherReason").css({ "visibility": "hidden" });
                        $("#divOtherReason").css({ "display": "none" });
                        $('#CourseReasonOther').val("");
                    }
                }
            }

            $(".formSubmitButtonCFI").click(function () {
                if (theForm.valid()) {
                    $('#cancelButton').hide();
                    $(this).hide();
                    $(".clsSubmitButton").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                } else {
                    var firstInstance = $('.input-validation-error').first();
                    var offsetTop = firstInstance.offset().top - 100;

                    $('html, body').animate({
                        scrollTop: offsetTop
                    }, 500);

                }
            });
        }
    });