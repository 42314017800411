﻿$(document)
    .ready(function() {
        if ($('div#divIntlAddressFields').length) {
            $('#Country')
                 .change(function () {
                     var option = $(this).find('option:selected').val();
                     $.getJSON('/api/CountryState/GetStates/?countryCode=' + option)
                         .done(function (data) {
                             $('#State').empty();
                             $.each(data,
                                 function (key, item) {
                                     var $option = $("<option />");
                                     if (item.value === "") {
                                         $option.attr("value", item.value).text("- Select -");
                                     } else {
                                         $option.attr("value", item.value).text(item.text);
                                     }
                                     $('#State').append($option);
                                 });

                             $.getJSON('/api/AddressStructure/GetAddressStructure/?countryCode=' + option)
                                 .done(function (data) {
                                     if (!jQuery.isEmptyObject(data)) {
                                         setAddressLine(data, "ADDRESS_1", "Address1");
                                         setAddressLine(data, "ADDRESS_2", "Address2");
                                         setAddressLine(data, "ADDRESS_3", "Address3");
                                         setAddressLine(data, "ADDRESS_4", "Address4");
                                         setAddressLine(data, "CITY", "City");
                                         setAddressLine(data, "STATE", "State");
                                         setAddressLine(data, "COUNTY", "County");
                                         setAddressLine(data, "POSTAL_CODE", "PostalCode");
                                         setAddressLine(data, "COUNTRY_DESCR", "Country");
                                     } else {
                                         $.getJSON('/api/AddressStructure/GetAddressStructure/?countryCode=[ALL]')
                                             .done(function (data) {
                                                 setAddressLine(data, "ADDRESS_1", "Address1");
                                                 setAddressLine(data, "ADDRESS_2", "Address2");
                                                 setAddressLine(data, "ADDRESS_3", "Address3");
                                                 setAddressLine(data, "ADDRESS_4", "Address4");
                                                 setAddressLine(data, "CITY", "City");
                                                 setAddressLine(data, "STATE", "State");
                                                 setAddressLine(data, "COUNTY", "County");
                                                 setAddressLine(data, "POSTAL_CODE", "PostalCode");
                                                 setAddressLine(data, "COUNTRY_DESCR", "Country");
                                             })
                                             .fail(function (jqXHR, textStatus, err) {
                                             });
                                     }
                                 })
                                 .fail(function (jqXHR, textStatus, err) {
                                 });
                         })
                         .fail(function (jqXHR, textStatus, err) {
                             $('#State').empty();
                             var $option = $("<option />");
                             $options.attr("value", "").text("- Error -");
                             $('#State').append($option);
                         });
                 });

            function setAddressLine(data, line, field) {
                var theLine = $.grep(data,
                    function (n, i) {
                        return n.name === line;
                    });

                if (line === 'COUNTRY_DESCR' || theLine[0].required) {
                    $('#divCFI' + field + 'Label').html("<strong>" + theLine[0].label + "</strong><span class=\"redCopy\">*</span>");

                    if (line === 'STATE') {
                        if ($('#' + field + ' option').length > 1) {
                            $('#' + field + 'Required').val(theLine[0].required);
                            $('#' + field + 'TextRequired').val('False');
                        } else {
                            $('#' + field + 'Required').val('False');
                            $('#' + field + 'TextRequired').val(theLine[0].required);
                        }
                    } else {
                        $('#' + field + 'Required').val('True');
                    }
                } else {
                    $('#divCFI' + field + 'Label').html("<strong>" + theLine[0].label + "</strong>");
                    $('#' + field + 'Required').val('False');
                    if (line === 'STATE') {
                        $('#' + field + 'TextRequired').val('False');
                    }
                }
                if (line !== 'COUNTRY_DESCR') {
                    if (line === 'STATE') {
                        $('#' + field + 'Text').attr('maxlength', theLine[0].length);
                    } else {
                        $('#' + field).attr('maxlength', theLine[0].length);
                    }
                }

                if (theLine[0].show) {
                    if (field !== 'COUNTRY_DESCR') {
                        $('#div' + field).css({ "visibility": "visible" });
                        $('#div' + field).css({ "display": "block" });
                    }

                    if (line === 'STATE') {
                        if ($('#' + field + ' option').length > 1) {
                            $('#' + field + 'UsesDropDown').val('True');
                            $('#div' + field + 'DropDown').css({ "visibility": "visible" });
                            $('#div' + field + 'DropDown').css({ "display": "block" });
                            $('#div' + field + 'Text').css({ "visibility": "hidden" });
                            $('#div' + field + 'Text').css({ "display": "none" });
                        } else {
                            $('#' + field + 'UsesDropDown').val('False');
                            $('#div' + field + 'DropDown').css({ "visibility": "hidden" });
                            $('#div' + field + 'DropDown').css({ "display": "none" });
                            $('#div' + field + 'Text').css({ "visibility": "visible" });
                            $('#div' + field + 'Text').css({ "display": "block" });
                        }

                    }
                } else {
                    if (line !== 'COUNTRY_DESCR') {
                        $('#div' + field).css({ "visibility": "hidden" });
                        $('#div' + field).css({ "display": "none" });
                        $('#' + field).val("");
                    }

                    if (line === 'STATE') {
                        $('#State').empty();
                        var $option = $("<option />");
                        $option.attr("value", "0").text("- No State/Province -");
                        $('#State').append($option);

                    }
                }

                $('#' + field).valid();
            }
        }
    });

