
(function ($) {
    $(document).ready(function () {

        if ($(".divImageGallery").length) {

            // Lazy load
            // https://github.com/verlok/lazyload

            //    var lazyLoadInstance = new LazyLoad({
            //        elements_selector: ".lazyload",
            //        load_delay: 500
            //        // ... more custom settings?
            //    });

            // Lazy load
            // https://github.com/verlok/lazyload

            var lazyLoadInstance = new LazyLoad({
                elements_selector: ".lazyload"
            });



            // Need the height to position the caption depending on image height. 
            // todo get on load + update on resize
            var wHeight = $(window).height()
            // console.log( 'wHeight is ' + wHeight )


            // First click on an image 
            $("section img").click(function () {

                $(".masonry__lightbox").fadeIn(300).append("<img src='" + $(this).attr("src") + "' alt='" + $(this).attr("alt") + "' />");

                $(".masonry__lightbox .filter").css("background-image", "url(" + $(this).attr("src") + ")");

                $(".masonry__lightbox .caption").append("<p>" + $(this).attr("alt") + "</p>");
                $("html").css("overflow", "hidden");
                if ($(this).is(":last-child")) {
                    $(".masonry__lightbox .arrowr").css("display", "none");
                    $(".masonry__lightbox .arrowl").css("display", "block");
                } else if ($(this).is(":first-child")) {
                    $(".masonry__lightbox .arrowr").css("display", "block");
                    $(".masonry__lightbox .arrowl").css("display", "none");
                } else {
                    $(".masonry__lightbox .arrowr").css("display", "block");
                    $(".masonry__lightbox .arrowl").css("display", "block");
                }
            });

            // Closing the lightbox via click
            $(".close").click(function () {
                $(".masonry__lightbox").fadeOut(300);
                $(".masonry__lightbox .caption p").remove();
                $(".masonry__lightbox img").remove();
                $("html").css("overflow", "auto");
            });

            // Closing the lightbox using ESC
            $(document).keyup(function (e) {
                if (e.keyCode == 27) {
                    $(".masonry__lightbox").fadeOut(300);
                    $(".masonry__lightbox img").remove();
                    $(".masonry__lightbox .caption p").remove();
                    $("html").css("overflow", "auto");
                }
            });

            // Navigating the lightbox
            $(".masonry__lightbox .arrowr").click(function () {
                var imgSrc = $(".masonry__lightbox img").attr("src");
                var search = $("section").find("img[src$='" + imgSrc + "']");
                var newImage = search.next().attr("src");
                var newAlt = search.next().attr("alt");

                $(".masonry__lightbox img").attr("src", newImage);
                $(".masonry__lightbox .caption").html("<p>" + newAlt + "</p>");
                $(".masonry__lightbox .filter").css("background-image", "url(" + newImage + ")");

                // If the image is to tall, we need to reposition the caption
                var newImgHeight = $(".masonry__lightbox img")[0].clientHeight;
                if (newImgHeight > 0.8 * wHeight) {

                    $(".masonry__lightbox .caption").css("top", "98%");
                } else {
                    $(".masonry__lightbox .caption").css("top", "90%");
                }

                // Hiding arrows for last item  
                if (!search.next().is(":last-child")) {
                    $(".masonry__lightbox .arrowl").css("display", "block");
                } else {
                    $(".masonry__lightbox .arrowr").css("display", "none");
                }
            });

            $(".masonry__lightbox .arrowl").click(function () {
                var imgSrc = $(".masonry__lightbox img").attr("src");
                var search = $("section").find("img[src$='" + imgSrc + "']");
                var newImage = search.prev().attr("src");
                var newAlt = search.prev().attr("alt");

                $(".masonry__lightbox img").attr("src", newImage);
                $(".masonry__lightbox .caption").html("<p>" + newAlt + "</p>");
                $(".masonry__lightbox .filter").css("background-image", "url(" + newImage + ")");

                // If the image is to tall, we need to reposition the caption
                var newImgHeight = $(".masonry__lightbox img")[0].clientHeight;
                if (newImgHeight > 0.9 * wHeight) {
                    $(".masonry__lightbox .caption").css("top", "98%");
                } else {
                    $(".masonry__lightbox .caption").css("top", "90%");
                }

                // Hiding arrows for first item  
                if (!search.prev().is(":first-child")) {
                    $(".masonry__lightbox .arrowr").css("display", "block");
                } else {
                    $(".masonry__lightbox .arrowl").css("display", "none");
                }
            });
        }
    });


}(jQuery));

