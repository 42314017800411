﻿$(document).ready(function() {
    if ($('div#divDonationRsvp').length) {

        fixGuest();
        fixRates();

        if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
            $("#divOtherAmount").css({ "visibility": "hidden" });
            $("#divOtherAmount").css({ "display": "none" });
        } else {
            $("#divOtherAmount").css({ "visibility": "visible" });
            $("#divOtherAmount").css({ "display": "block" });
        }

        if ($('input[name=SelectedDonationAmount]:checked').val() === "NONE|0") {
            $("#divPaymentInfo").css({ "visibility": "hidden" });
            $("#divPaymentInfo").css({ "display": "none" });
            $("#SelectedCreditCardType").val("VISA");
            $("#SelectedCreditCardExpMonth").val("12");
            var d = new Date();
            $("#SelectedCreditCardExpYear").val(d.getFullYear() + 5);
            $("#CcNumber").val("4111111111111111");
            $("#Cvv").val("123");
            $("#NameOnCard").val("xxxx");
        } else {
            $("#SelectedCreditCardType").val("");
            $("#SelectedCreditCardExpMonth").val("");
            $("#SelectedCreditCardExpYear").val("");
            $("#CcNumber").val("");
            $("#Cvv").val("");
            $("#NameOnCard").val("");
            $("#divPaymentInfo").css({ "visibility": "visible" });
            $("#divPaymentInfo").css({ "display": "block" });
        }

        $(".donateButton").click(function () {
            var form = $(this).closest('form');
            if (form.valid()) {
                $(this).hide();
                $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
            }
        });

        $('#SelectedCountry').change(function () {
            var option = $(this).find('option:selected').val();
            $.getJSON('/api/CountryState/GetStates/?countryCode=' + option)
                .done(function (data) {
                    $('#SelectedState').empty();
                    $.each(data, function (key, item) {
                        var $option = $("<option />");
                        $option.attr("value", item.value).text(item.text);
                        $('#SelectedState').append($option);
                    });
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#SelectedState').empty();
                    var $option = $("<option />");
                    $options.attr("value", "").text("- Error -");
                    $('#SelectedState').append($option);
                });
        });

        $('#KeyCodeEntered').focusout(function () {
            $.getJSON('/api/OneClickKeyCode/Get/?keycode=' + $(this).val())
                .done(function (data) {
                    if (data.keyCode) {
                        $('#KeyCode').val(data.keyCode);
                        $('#ListCode').val(data.listCode);
                        $('#MarketCode').val(data.marketCode);
                    } else {
                        $('#KeyCodeEntered').val($('#UrlKeyCode').val());
                        $('#KeyCode').val($('#UrlKeyCode').val());
                        $('#ListCode').val($('#UrlListCode').val());
                        $('#MarketCode').val($('#UrlMarketCode').val());
                    }
                })
               .fail(function (jqXHR, textStatus, err) {
                   $('#KeyCodeEntered').val($('#UrlKeyCode').val());
                   $('#KeyCode').val($('#UrlKeyCode').val());
                   $('#ListCode').val($('#UrlListCode').val());
                   $('#MarketCode').val($('#UrlMarketCode').val());
               });
        });

        $('input[name=SelectedDonationAmount]').on('change', function () {
            if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
                if ($('#MinimumDonationAmount').length) {
                    $('#MinimumDonationAmount').val('');
                    $('#MinimumDonationAmount').valid();
                    $('#MinimumDonationAmount').prop("disabled", true);
                    $("#divOtherAmount").css({ "visibility": "hidden" });
                    $("#divOtherAmount").css({ "display": "none" });
                }
            } else {
                $('#MinimumDonationAmount').prop("disabled", false);
                $("#divOtherAmount").css({ "visibility": "visible" });
                $("#divOtherAmount").css({ "display": "block" });
            }
            if ($('input[name=SelectedDonationAmount]:checked').val() === "NONE|0") {
                $("#divPaymentInfo").css({ "visibility": "hidden" });
                $("#divPaymentInfo").css({ "display": "none" });
                $("#SelectedCreditCardType").val("VISA");
                $("#SelectedCreditCardExpMonth").val("12");
                var d = new Date();
                $("#SelectedCreditCardExpYear").val(d.getFullYear() + 5);
                $("#CcNumber").val("4111111111111111");
                $("#Cvv").val("123");
                $("#NameOnCard").val("xxxx");
            } else {
                $("#SelectedCreditCardType").val("");
                $("#SelectedCreditCardExpMonth").val("");
                $("#SelectedCreditCardExpYear").val("");
                $("#CcNumber").val("");
                $("#Cvv").val("");
                $("#NameOnCard").val("");
                $("#divPaymentInfo").css({ "visibility": "visible" });
                $("#divPaymentInfo").css({ "display": "block" });
            }
            calculateTotal();
        });

        $('input[name=SelectedDonationFrequency]').on('change', function () {
            calculateTotal();
        });

        $('input[name=MinimumDonationAmount]').on('change', function () {
            if ($('input[name=SelectedDonationAmount]:checked').val() === "OTHER|0") {
                calculateTotal();
            }
        });

        function calculateTotal() {
            if ($('#DonationType').val() !== "Pledge") {
                if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
                    var amount = $('input[name=SelectedDonationAmount]:checked').val().split("|");
                    $('#PaymentTotal').val(parseFloat(amount[1], 10).toFixed(2));
                } else {
                    if ($.isNumeric($('#MinimumDonationAmount').val())) {
                        $('#PaymentTotal').val(parseFloat($('#MinimumDonationAmount').val(), 10).toFixed(2));
                    } else {
                        $('#PaymentTotal').val(0.00);
                    }
                }

            } else {
                var selectedamount = ($('input[name=SelectedDonationAmount]:checked').val().split("|"))[1];

                switch ($('input[name=SelectedDonationFrequency]:checked').val()) {
                    case 'IMMEDIATE':
                        $('#PaymentTotal').val(parseFloat(selectedamount, 10).toFixed(2));
                        break;
                    case 'MONTHLY':
                        selectedamount = selectedamount / 12;
                        $('#PaymentTotal').val(parseFloat(selectedamount, 10).toFixed(2));
                        break;
                    case 'QUARTERLY':
                        selectedamount = selectedamount / 4;
                        $('#PaymentTotal').val(parseFloat(selectedamount, 10).toFixed(2));
                        break;
                }

                var totaltext = '$' + parseFloat(selectedamount, 10).toFixed(2).toLocaleString();
                $('#divPaymentAmount').html($.parseHTML(totaltext));
            }
        }

        $('input[name=SelectedGuestOption]').on('change', function () {
            fixGuest();
            fixRates();
        });

        function fixGuest() {
            if ($('input[name=SelectedGuestOption]:checked').val() === "YES") {
                $('#GuestFirstName').val("");
                $('#GuestLastName').val("");
                $("#divGuestName").css({ "visibility": "visible" });
                $("#divGuestName").css({ "display": "block" });
            } else {
                $("#divGuestName").css({ "visibility": "hidden" });
                $("#divGuestName").css({ "display": "none" });
                $('#GuestFirstName').val("abc");
                $('#GuestLastName').val("abc");
            }
        }

        function fixRates() {
            if ($('input[name=SelectedGuestOption]:checked').val() === "YES") {
                $('#SelectedDonationAmount0').attr('disabled',true);
                $('#SelectedDonationAmount1').prop('checked', true).trigger('change');
                $('#SelectedDonationAmount0').closest('div').hide();
            } else {
                $('#SelectedDonationAmount0').attr('disabled', false);
                $('#SelectedDonationAmount0').prop('checked',true).trigger('change');
                $('#SelectedDonationAmount0').closest('div').show();
            }
        }
    }
});
