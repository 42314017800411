﻿$(document).ready(function () {
    if ($('div#divTextMessageOptInOut').length) {
        var theForm = $('#frmMobileOptInOut');

        $("#btnOptIn").click(function () {
            if (theForm.valid()) {
                $("#btnOptIn").hide();
                $(".tm-optinout-buttons").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise tm-optinout-spinner"></div>');
                $(".tm-optinout-status").hide();

                var input = {
                    action: "OptIn",
                    formData: $('#FormData').val(),
                    mobilePhoneNumber: $("#MobilePhoneNumber").val(),
                    setPreferredPhone: $('#SetPreferredPhone').val()
                };

                $.ajax({
                    url: "/api/TextMessageOptInApi/ProcessOptInOut",
                    type: "post",
                    data: input,
                    success: function (data, status, jqXHR) {
                        if (jqXHR.status == 200 || jqXHR.status == 204) {
                            $('.tm-optinout-optinsuccess').show();
                            $("#btnOptOut").show();
                            $("#btnUpdateOnly").show();
                        }
                        else {
                            $('.tm-optinout-optinfailed').show();
                            $("#btnOptIn").show();
                        }
                    },
                    error: function (jqXHR, exception) {
                        $('.tm-optinout-optinfailed').show();
                        $("#btnOptIn").show();
                    },
                    complete: function (jqHXR, status) {
                        var spinner = document.getElementById('aopaSpinner');
                        spinner.parentElement.removeChild(spinner);
                    }

                });
            }
        });

        $("#btnOptOut").click(function () {
            $("#btnOptOut").hide();
            $("#btnUpdateOnly").hide();
            $(".tm-optinout-buttons").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise  tm-optinout-spinner"></div>');
            $(".tm-optinout-status").hide();

            var input = {
                action: "OptOut",
                formData: $('#FormData').val(),
            };

            $.ajax({
                url: "/api/TextMessageOptInApi/ProcessOptInOut",
                type: "post",
                data: input,
                success: function (data, status, jqXHR) {
                    if (jqXHR.status == 200 || jqXHR.status == 204) {
                        $('.tm-optinout-optoutsuccess').show();
                        $("#btnOptIn").show();
                    }
                    else {
                        $('.tm-optinout-optoutfailed').show();
                        $("#btnOptOut").show();
                        $("#btnUpdateOnly").show();

                    }
                },
                error: function (jqXHR, exception) {
                    $('.tm-optinout-optoutfailed').show();
                    $("#btnOptOut").show();
                    $("#btnUpdateOnly").show();
                },
                complete: function (jqHXR, status) {
                    var spinner = document.getElementById('aopaSpinner');
                    spinner.parentElement.removeChild(spinner);
                }

            });

        });

        $("#btnUpdateOnly").click(function () {
            if (theForm.valid()) {
                $("#btnOptOut").hide();
                $("#btnUpdateOnly").hide();
                $(".tm-optinout-buttons").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise  tm-optinout-spinner"></div>');
                $(".tm-optinout-status").hide();

                var input = {
                    action: "UpdateOnly",
                    formData: $('#FormData').val(),
                    mobilePhoneNumber: $("#MobilePhoneNumber").val(),
                    setPreferredPhone: $('#SetPreferredPhone').val()
                };

                $.ajax({
                    url: "/api/TextMessageOptInApi/ProcessOptInOut",
                    type: "post",
                    data: input,
                    success: function (data, status, jqXHR) {
                        if (jqXHR.status == 200 || jqXHR.status == 204) {
                            $('.tm-optinout-updatesuccess').show();
                            $("#btnOptOut").show();
                            $("#btnUpdateOnly").show();
                        }
                        else {
                            $('.tm-optinout-updatefailed').show();
                            $("#btnOptOut").show();
                            $("#btnUpdateOnly").show();
                        }
                    },
                    error: function (jqXHR, exception) {
                        $('.tm-optinout-updatefailed').show();
                        $("#btnOptOut").show();
                        $("#btnUpdateOnly").show();
                    },
                    complete: function (jqHXR, status) {
                        var spinner = document.getElementById('aopaSpinner');
                        spinner.parentElement.removeChild(spinner);
                    }

                });

            }
        });

    }
});