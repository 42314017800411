﻿$(document)
    .ready(function () {
        if ($('div#divEventRegistrationForm').length) {

            var selectedPaperApplicationMessage = 'Please select application preference.';
            var selectedCurrentMemberMessage = 'Please select if you are a member.';
            var selectedPilotMessage = 'Please select if you are a pilot.';
            var selectedTakeoffsLandingsMessage = 'Please select takeoff and landings currency.';
            var selectedHearAboutMessage = 'Please select how you heard about the seminar.';

            function getLastDayOfYearAndMonth(year, month) {
                return (new Date((new Date(year, month + 1, 1)) - 1)).getDate();
            }

            $('#CfiExpireDate').datepicker({
                beforeShowDay: function (date) {
                    if (date.getDate() === getLastDayOfYearAndMonth(date.getFullYear(), date.getMonth())) {
                        return [true, ''];
                    }
                    return [false, ''];
                },
                changeMonth: true,
                changeYear: true
            });


            $("#btnRsvp").click(function (e) {
                var form = $(this).closest('form');
                var isValid = true;

                if (form.valid()) {
                    if (!checkRadioButton('SelectedPaperApplication', selectedPaperApplicationMessage)) {
                        isValid = false;
                    }
                    if (!checkRadioButton('SelectedCurrentMember', selectedCurrentMemberMessage)) {
                        isValid = false;
                    }
                    if (!checkRadioButton('SelectedPilot', selectedPilotMessage)) {
                        isValid = false;
                    }
                    if (!checkRadioButton('SelectedTakeoffsLandings', selectedTakeoffsLandingsMessage)) {
                        isValid = false;
                    }
                    if (!checkRadioButton('SelectedHearAbout', selectedHearAboutMessage)) {
                        isValid = false;
                    }
                } else {
                    isValid = false;
                    checkRadioButton('SelectedPaperApplication', selectedPaperApplicationMessage);
                    checkRadioButton('SelectedCurrentMember', selectedCurrentMemberMessage);
                    checkRadioButton('SelectedPilot', selectedPilotMessage);
                    checkRadioButton('SelectedTakeoffsLandings', selectedTakeoffsLandingsMessage);
                    checkRadioButton('SelectedHearAbout', selectedHearAboutMessage);
                }

                if (isValid) {
                    $(this).hide();
                    $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
                } else {
                    e.preventDefault();
                }
            });

            $('input[name=SelectedPaperApplication]').on('change',
                function() {
                    checkRadioButton('SelectedPaperApplication', selectedPaperApplicationMessage);
                });
            $('input[name=SelectedCurrentMember]').on('change',
                function () {
                    checkRadioButton('SelectedCurrentMember', selectedCurrentMemberMessage);
                });
            $('input[name=SelectedPilot]').on('change',
                function () {
                    checkRadioButton('SelectedPilot', selectedPilotMessage);
                });
            $('input[name=SelectedTakeoffsLandings]').on('change',
                function () {
                    checkRadioButton('SelectedTakeoffsLandings', selectedTakeoffsLandingsMessage);
                });
            $('input[name=SelectedHearAbout]').on('change',
                function () {
                    checkRadioButton('SelectedHearAbout', selectedHearAboutMessage);
                });

            function checkRadioButton(mainSelector, message) {
                if ($('#' + mainSelector + '0').length) {
                    if ($('input[name=' + mainSelector + ']:checked').length === 0) {
                        $("span[data-valmsg-for='" + mainSelector + "']").removeClass("field-validation-valid");
                        $("span[data-valmsg-for='" + mainSelector + "']").addClass("field-validation-error");
                        $("span[data-valmsg-for='" + mainSelector + "']").html(message);
                        $("span[data-valmsg-for='" + mainSelector + "']").show();
                        return false;
                    } else {
                        $("span[data-valmsg-for='" + mainSelector + "']").addClass("field-validation-valid");
                        $("span[data-valmsg-for='" + mainSelector + "']").removeClass("field-validation-error");
                        $("span[data-valmsg-for='" + mainSelector + "']").html('');
                        $("span[data-valmsg-for='" + mainSelector + "']").hide();
                        return true;
                    }

                } else {
                    return true;
                }
            }
        }
    });