﻿$(document)
    .ready(function () {
        if ($('div#divAftaBusinessContactForm').length) {
            var theForm = $('#frmAftaBusinessContact');


            $('#QQHaveIpadSelected').change(function () {
                if ($('#QQHaveIpadSelected').val() === "QQHaveIpad|Yes") {
                    $('#divAftaQualQuestion2').show();
                } else {
                    $('#divAftaQualQuestion2').hide();
                    $('#divAftaQualQuestion3').hide();

                    $('#QQTypeOfTrainingSelected').val("").change();
                    $('#QQPart61or141Selected').val("").change();
                }
            });


            $('#QQPart61or141Selected').change(function() {
                if ($('#QQPart61or141Selected').val() === "QQPart61or141|Part 61") {
                    $('#divAftaQualQuestion3').show();
                } else {
                    $('#divAftaQualQuestion3').hide();
                    $('#QQTypeOfTrainingSelected').val("").change();
                }
            });



            $('.afta-qq-select').change(function() {
                var option = $(this).find('option:selected').val();

                $('.afta-qq-text[data-qq-code="' + $(this).data("qq-code") + '"]').hide();

                $('.afta-qq-text[data-qq-value="' + option + '"]').show();
            });


            $(".submitbuttonaftabizcontact").click(function () {
            if (theForm.valid()) {
                $('#btnEnroll').hide();
                $(this).hide();
                $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                theForm.submit();
            }
        });
    }
});