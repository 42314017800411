﻿$(document).ready(function() {
    if ($('div#divAopaRenewFormGSV2').length) {
        var theForm = $('#frmAopaRenewFormV2');

        $("#btnGetStarted").click(function () {
            var progressBar = new Sfpb_progressBar();
            $('#btnGetStarted').hide();
            $(this).hide();
            $(".clsSubmitButton").append('<div class="spinner spinner--clockwise"></div>');
            progressBar.start();
            theForm.submit();
        });
    }
});