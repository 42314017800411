﻿$(document).ready(function () {
    if ($('div#divVolunteerForm').length) {
        var volForm = $('#frmVolunteerForm');
        var flagSearch = "Search";
        var flagSearchReturn = "SearchReturn";
        var flagClearSearch = "ClearSearch";
        var flagLoadCreateCustomer = "LoadCreateCustomer";
        var flagCreateCustomer = "CreateCustomer";
        var flagLoadVolunteer = "LoadVolunteer";
        var flagVolunteer = "Volunteer";
        var flagProcessVolunteer = "ProcessVolunteer";

        //
        // Search click
        //
        $('#btnSearch').click(function () {
            $('#FormMode').val(flagSearch);

            if (volForm.valid()) {
                volForm.submit();
            }
        });

        //
        // Load Create New Customer click
        //
        $('#btnCreateNewCustomer').click(function () {
            $('#FormMode').val(flagLoadCreateCustomer);

            volForm.submit();
        });

        //
        // Create New Customer click
        //
        $('#btnCreateCustomer').click(function () {
            $('#FormMode').val(flagCreateCustomer);

            if (volForm.valid()) {
                volForm.submit();
            }
        });

        //
        // Clear Search click
        //
        $('#btnClearSearch').click(function () {
            $('#FormMode').val(flagClearSearch);
            volForm.submit();
        });

        //
        // Back to search
        //
        $('.back-to-search').click(function () {
            $('#FormMode').val(flagSearchReturn);

            if ($("div#divCreateCustomer").length) {
                $("#divCreateCustomer *").prop('disabled', true);
            }

            volForm.submit();
        });

        //
        // Goto Volunteer
        //
        $(document).on("click",
            ".view-checkin-button",
            function (e) {
                $('#FormMode').val(flagLoadVolunteer);
                $('#VolunteerMasterCustomerId').val($(this).data("mcid"));
                $('#VolunteerSubCustomerId').val($(this).data("scid"));
                volForm.submit();
            });

        //
        // Process Volunteer
        //
        $('#btnProcessVolunteer').click(function () {
            $('#FormMode').val(flagProcessVolunteer);

            if (volForm.valid()) {
                $('#btnSearch').hide();
                $('#btnCreateNewCustomer').hide();
                $('#btnClearSearch').hide();
                $('#btnBackToSearch1').hide();
                $('#btnBackToSearch3').hide();
                $('#btnCreateCustomer').hide();
                $('#btnProcessVolunteer').hide();
                $(".search-center").append('<div class="spinner spinner--clockwise"></div>');
                volForm.submit();
            }
        });


        //
        // Update existing job
        //
        $('.vol-job-selection-sel').on('change',
            function () {
                var job = document.getElementById(this.id);

                var jobid = parseInt($(this).data("vejid"), 10);

                var options = JSON.parse(decodeURIComponent($('#VolunteerData').val()));

                $.each(options, function (i, v) {
                    if (v.VolunteerEventJobId === jobid) {
                        v.AssignmentStatusCode = job.value;
                        v.Updated = true;
                    }
                });

                $('#VolunteerData').val(encodeURIComponent(JSON.stringify(options)));
            });

        //
        // Update Available Job
        //
        $('.vol-job-selection-cb').click(function() {
            var cb = document.getElementById(this.id);
            var jobid = parseInt($(this).data("vejid"), 10);

            var options = JSON.parse(decodeURIComponent($('#VolunteerData').val()));

            $.each(options,
                function(i, v) {
                    if (v.VolunteerEventJobId === jobid) {
                        if (cb.checked) {
                            v.AssignmentStatusCode = cb.value;
                            v.Updated = true;
                        } else {
                            v.AssignmentStatusCode = "";
                            v.Updated = false;
                        }
                    }
                });

            $('#VolunteerData').val(encodeURIComponent(JSON.stringify(options)));
        });

        $(".event-signin__search-button").click(function () {
            if (volForm.valid()) {
                $('#btnSearch').hide();
                $('#btnCreateNewCustomer').hide();
                $('#btnClearSearch').hide();
                $('#btnBackToSearch1').hide();
                $('#btnBackToSearch3').hide();
                $('#btnCreateCustomer').hide();
                $('#btnProcessVolunteer').hide();
                $(".search-center").append('<div class="spinner spinner--clockwise"></div>');
            }
        });

    };
});