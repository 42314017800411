﻿/*
Custom script for validation not US Address
*/
(function ($) {
    $.validator.addMethod("mvcforeignonly", function (value, element, params) {

        var testValue = value.toUpperCase().trim();
       
        if (testValue === "UNITED STATES" ||
            testValue === "UNITED STATES OF AMERICA" ||
            testValue === "US" ||
            testValue === "USA" ||
            testValue === "US OF A" ||
            testValue === "AMERICA" ||
            testValue === "ESTADOS UNIDOS" ||
            testValue === "ESTADOS UNIDOS DE AMERICA"
        ) {
            return false;
        }

        return true;
    });

    $.validator.unobtrusive.adapters.addBool("mvcforeignonly");
}(jQuery));
