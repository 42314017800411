﻿(function ($) {
    $.validator.addMethod("emailselectioncheck", function (value, element, params) {
        var valSelected = $('#' + params.selectfield).find('option:selected').val();

        if (!valSelected) {
            return true;
        }

        if (params.emailtype && valSelected === params.emailtype && !value) {
            return false;
        }

        return true;
    });

    jQuery.validator.unobtrusive.adapters.add("emailselectioncheck", ["selectfield", "emailtype"],
        function (options) {
            options.rules['emailselectioncheck'] = {
                selectfield: options.params.selectfield,
                emailtype: options.params.emailtype
            };
            options.messages['emailselectioncheck'] = options.message;
        });

}(jQuery));