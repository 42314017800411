"use strict";
var wxWidget = {};

$(document).ready(function() {
    
  // determine if we HAVE an airport code already
  wxWidget.airport_id = $.trim( $('#weatherwidgetdiv').text() );

  wxWidget.wxData = {};

  if ( wxWidget.airport_id ) {
    getLocationFromAirportId();
  } else {
    // getLocationFromCoords();
  }

  function getWxData ( data ) {
    var parsedMetar = parseMetar( data ),
        wxMappings = initWxMappings (),
        local_data_options = { hour: 'numeric', minute: 'numeric', hour12: true };
    var wxData = {};
        wxData.city = parsedMetar.city;
        wxData.state = parsedMetar.state;
        wxData.location_name = parsedMetar.name;
        wxData.location_ident = parsedMetar.ident;
        wxData.temp = parsedMetar.temp_c;
        wxData.dewpoint = parsedMetar.dewpoint_c;
        wxData.wind = formatWind( parsedMetar );
        wxData.ceiling = getCeiling( parsedMetar.sky_conditions_trans );
        wxData.viz = (parsedMetar.visibility) ? parsedMetar.visibility : 10;
        wxData.flight_category = parsedMetar.flight_category;
        wxData.wx_icon = getIcon( data );
        wxData.wx_icon_tooltip = getTooltip( data );
        wxData.obs_date = new Date( parsedMetar.observation_time );
        wxData.obs_date_local = new Date( parsedMetar.observation_time ).toLocaleString( 'en-US', local_data_options );

    console.dir( parsedMetar );
    return wxData;
  }
  function populate_widget_fields() {
    var wxData = wxWidget.wxData;
    $("#wx_location_name_ident").html( wxData.location_ident );
    $("#wx_location_city_state").html( wxData.city + ', ' + wxData.state );
    $("#wx_observation_time").html( '<strong>Obsv:</strong> ' + wxData.obs_date_local + ' (' + wxData.obs_date.getUTCHours() + '' + wxData.obs_date.getUTCMinutes() + 'Z)' );
    $("#wx_temperature_dew_point").html( '<strong>' + wxData.temp + '</strong>&deg;/<strong>' + wxData.dewpoint + '</strong>&deg;' );
    $("#wx_wind").html( '<strong>' + wxData.wind + '</strong>' );
    $("#wx_ceiling").html( '<strong>' + wxData.ceiling + '</strong>' );
    $("#wx_visibility").html( '<strong>' + wxData.viz + '</strong>SM'  );
    $(".wxIcon").addClass(getIcon (wxData)).addClass((wxData.flight_category).toLowerCase());
  }
  function dateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }
  function formatWind (data) {
    if (!data) {
        return "";
    }

    if (data.wind_speed) {
        if (data.wind_gust) {
            return data.wind_dir + "&deg; @ " + data.wind_speed + "-" + data.wind_gust + " kts";
        }

        return data.wind_dir + "&deg; @ " + data.wind_speed +" kts";
    }

    return 'CALM';
  }
  function getCeiling (skc) {
    if (!skc || skc.length < 1) {
        return "CLR";
    }

    // If vertical visibility is reported, use the vertical visibility value as the ceiling
    var vv = skc.find(layer => layer.sky_cover === 'Vertical Visibility');
    if (vv) {
        return parseInt(vv.cloud_base) + ' ft';
    }

    // Extract the altitudes of all broken and overcast layers
    var layers = skc
        .filter(layer => (layer.sky_cover === 'Overcast' || layer.sky_cover === 'Broken clouds'))
        .map(layer => parseInt(layer.cloud_base));
    if (layers.length < 1) {
        return "CLR";
    }

    // Return minimum altitude of broken/overcast layers
    return Math.min(...layers) + ' ft';
}
  function parseMetar (d) {
    return {
        ident: d.icao_code,
        name: d.name,
        city: d.city,
        state: d.state,
        latitude: d.latitude,
        longitude: d.longitude,
        elevation: d.elevation_m * 3.28084,
        observation_time: new Date(d.observation_time),
        raw_text: d.raw_text,
        flight_category: d.flight_category,
        visibility: d.visibility_statute_mi,
        ceiling: getCeiling(d.sky_conditions_trans),
        wind_speed: d.wind_speed_kt,
        wind_gust: d.wind_gust_kt,
        wind_dir: d.wind_dir_degrees,
        temp_c: d.temp_c,
        temp_f: d.temp_f,
        dewpoint_c: d.dewpoint_c,
        dewpoint_f: d.dewpoint_f,
        wx_string: d.wx_string,
        wx_string_trans: d.wx_string_trans,
        sky_conditions_trans: d.sky_conditions_trans
    };
  }
  function getIcon (data) {
    // Return neutral icon if no data is loaded
    if (!data) {
        return 'wi-na';
    }

    // If significant weather phenomena are present, return the icon associated with that
    // phenomena.
    var type = getWxType(data.wx_string);
    if (type != null) {
        return type.icon;
    }

    // Otherwise, return icon for cloudy, partly cloudy, or sunny depending on what cloud
    // layers are present.
    var skc = data.sky_conditions_trans;
    if (skc && skc.length >= 1) {
        var layers = skc.map(layer => layer.sky_cover);

        // Return cloudy if there is at least one overcast or broken cloud layer
        if (layers.indexOf('Overcast') >= 0 || layers.indexOf('Broken clouds') >= 0) {
            return 'wi-cloudy';
        }

        // Return partly sunny if there is at least one scattered/few cloud layer
        if (layers.indexOf('Scattered clouds') >= 0 || layers.indexOf('Few clouds') >= 0) {
            return 'wi-day-cloudy';
        }
    }

    // Otherwise, return sunny
    return 'wi-day-sunny';
  }
  function getTooltip (data) {
      var wx = translateWx(data.wx_string),
          clouds = getCloudLayersText(data.sky_conditions_trans);
      return [wx, clouds].filter(t => t).join(', ') || null;
  }
  function getLocationFromAirportId () {
    // TODO: Error handling on airport ident search
    toggleSpinner(true);
    // execute the REST API call
    $.ajax({
      url: "https://www.aopa.org/AirportsAPI/wx/widgetdata",
        data: {
          "query":"" + wxWidget.airport_id + ""
        },
    }).fail( function() {
      weatherFail();
    }).done( function( data ) {

      wxWidget.wxData = getWxData( data );
      populate_widget_fields();
      toggleSpinner(false);
    });
  }
  function getLocationFromCoords ( ) {

    // TODO: Error handling on coordinate location search
    toggleSpinner(true);
    navigator.geolocation.getCurrentPosition(  function processPosition(position) {

        $.ajax({
          url: "https://www.aopa.org/AirportsAPI/wx/widgetdata",
          data: {
            "latitude":"" +  position.coords.latitude + "",
			"longitude":"" + position.coords.longitude + ""
          },
        }).fail( function() {
          weatherFail();
        }).done( function( data ) {
          wxWidget.wxData = getWxData( data );
          populate_widget_fields();
          toggleSpinner(false);
        });
      });

  }
  function translateWx (wx_string) {
      var types = getWxTypes(wx_string);
      if (types && types.length > 0) {
          return types.map(t => t.text).join(', ');
      }

      return null;
  }
  function getWxType (wx_string) {
      if (!wx_string) {
          return null;
      }

      return getWxTypes(wx_string)[0] || null;
  }
  function getCloudLayersText (skc) {
      if (skc && skc.length >= 1) {
          var layers = skc.map(layer => {
              var text = layer.sky_cover;
              if (layer.cloud_base && layer.cloud_base !== 'None') {
                  text += ' ' + layer.cloud_base;
              }
              return {
                  alt: parseInt(layer.cloud_base),
                  text
              };
          });

          return layers
              .sort((a, b) => a.alt - b.alt)
              .map(layer => layer.text)
              .join(', ');
      }

      return null;
  }
  function getWxTypes (wx_string) {
      if (!wx_string) {
          return [];
      }

      var elems = wx_string.split(" ");

      var wxItems = initWxMappings();

      return wxItems
          .filter(t => {
              // See if entire wx_string matches one of the keys exactly (e.g., "RA SN" should
              // match the item with priority 25).
              if (t.keys.indexOf(wx_string) >= 0) {
                  return true;
              }

              // Otherwise, split on space and see if any of the individual elements (e.g., "RA"
              // or "SN") match one of the keys.
              if (elems.some(elem => t.keys.indexOf(elem) >= 0)) {
                  return true;
              }

              return false;
          })
          .sort((a, b) => a.priority - b.priority);
  }
  function initWxMappings () {

      var wxMappings = [{
          priority: 1,
          keys: ['FC', '+FC'],
          text: 'Funnel cloud',
          icon: 'wi-tornado'
      }, {
          priority: 2,
          keys: ['SQ'],
          text: 'Squall',
          icon: 'wi-strong-wind'
      }, {
          priority: 3,
          keys: ['FU'],
          text: 'Smoke',
          icon: 'wi-smoke'
      }, {
          priority: 3,
          keys: ['VA'],
          text: 'Volcanic ash',
          icon: 'wi-volcano'
      }, {
          priority: 5,
          keys: ['+TSGS', '+TSGR'],
          text: 'Thunderstorm / Heavy hail',
          icon: 'wi-thunderstorm'
      }, {
          priority: 6,
          keys: ['+TSRA'],
          text: 'Thunderstorm / Heavy rain',
          icon: 'wi-thunderstorm'
      }, {
          priority: 6,
          keys: ['+TSSN'],
          text: 'Thunderstorm / Heavy snow',
          icon: 'wi-thunderstorm'
      }, {
          priority: 6,
          keys: ['+TSPL'],
          text: 'Thunderstorm / Ice pellets',
          icon: 'wi-thunderstorm'
      }, {
          priority: 7,
          keys: ['TSGR', 'TSGS'],
          text: 'Thunderstorm / Hail',
          icon: 'wi-thunderstorm'
      }, {
          priority: 8,
          keys: ['TSRA'],
          text: 'Thunderstorm / Rain',
          icon: 'wi-thunderstorm'
      }, {
          priority: 8,
          keys: ['TSSN'],
          text: 'Thunderstorm / Snow',
          icon: 'wi-storm-showers'
      }, {
          priority: 8,
          keys: ['TSPL'],
          text: 'Thunderstorm / Ice pellets',
          icon: 'wi-storm-showers'
      }, {
          priority: 9,
          keys: ['PO', 'VCPO'],
          text: 'Dust/sand whirls',
          icon: 'wi-dust'
      }, {
          priority: 10,
          keys: ['+SS'],
          text: 'Heavy sandstorm',
          icon: 'wi-sandstorm'
      }, {
          priority: 10,
          keys: ['+DS'],
          text: 'Heavy dust storm',
          icon: 'wi-dust'
      }, {
          priority: 11,
          keys: ['SS'],
          text: 'Sandstorm',
          icon: 'wi-sandstorm'
      }, {
          priority: 11,
          keys: ['DS'],
          text: 'Dust storm',
          icon: 'wi-dust'
      }, {
          priority: 11,
          keys: ['DRSA'],
          text: 'Drifting sand',
          icon: 'wi-sandstorm'
      }, {
          priority: 11,
          keys: ['DRDU'],
          text: 'Drifting dust',
          icon: 'wi-dust'
      }, {
          priority: 12,
          keys: ['VCSS'],
          text: 'Sandstorm in vicinity',
          icon: 'wi-sandstorm'
      }, {
          priority: 12,
          keys: ['VCDS'],
          text: 'Dust storm in vicinity',
          icon: 'wi-dust'
      }, {
          priority: 13,
          keys: ['SA'],
          text: 'Sand',
          icon: 'wi-sandstorm'
      }, {
          priority: 13,
          keys: ['BLSA'],
          text: 'Blowing sand',
          icon: 'wi-sandstorm'
      }, {
          priority: 13,
          keys: ['VCBLSA'],
          text: 'Blowing sand in vicinity',
          icon: 'wi-sandstorm'
      }, {
          priority: 13,
          keys: ['BLDU'],
          text: 'Blowing dust',
          icon: 'wi-dust'
      }, {
          priority: 13,
          keys: ['VCBLDU'],
          text: 'Blowing dust in vicinity',
          icon: 'wi-dust'
      }, {
          priority: 13,
          keys: ['BLPY'],
          text: 'Blowing spray',
          icon: 'wi-dust'
      }, {
          priority: 14,
          keys: ['DU'],
          text: 'Dust',
          icon: 'wi-dust'
      }, {
          priority: 15,
          keys: ['GR', 'SHGR', '+GR', '+SHGR'],
          text: 'Hail',
          icon: 'wi-hail'
      }, {
          priority: 16,
          keys: ['GS', 'SHGS', '+GS', '+SHGS'],
          text: 'Hail / Snow pellets',
          icon: 'wi-hail'
      }, {
          priority: 17,
          keys: ['-GR', '-SHGR'],
          text: 'Light hail',
          icon: 'wi-hail'
      }, {
          priority: 18,
          keys: ['-GS', '-SHGS'],
          text: 'Light hail / Snow pellets',
          icon: 'wi-hail'
      }, {
          priority: 19,
          keys: ['SG'],
          text: 'Snow grains',
          icon: 'wi-snow'
      }, {
          priority: 20,
          keys: ['PL'],
          text: 'Ice pellets',
          icon: 'wi-sleet'
      }, {
          priority: 20,
          keys: ['PL', 'PE', 'SHPL', 'SHPE'],
          text: 'Ice pellets',
          icon: 'wi-sleet'
      }, {
          priority: 21,
          keys: ['FZDZ', '+FZDZ'],
          text: 'Freezing drizzle',
          icon: 'wi-sleet'
      }, {
          priority: 22,
          keys: ['-FZDZ'],
          text: 'Light freezing drizzle',
          icon: 'wi-sleet'
      }, {
          priority: 23,
          keys: ['FZRA', '+FZRA'],
          text: 'Freezing rain',
          icon: 'wi-sleet'
      }, {
          priority: 24,
          keys: ['-FZRA'],
          text: 'Light freezing rain',
          icon: 'wi-sleet'
      }, {
          priority: 25,
          keys: ['RA SN'],
          text: 'Rain / Snow',
          icon: 'wi-sleet'
      }, {
          priority: 25,
          keys: ['+RA SN'],
          text: 'Heavy rain / Snow',
          icon: 'wi-sleet'
      }, {
          priority: 25,
          keys: ['RA +SN'],
          text: 'Rain / Heavy snow',
          icon: 'wi-sleet'
      }, {
          priority: 25,
          keys: ['+RA +SN'],
          text: 'Heavy rain / Heavy snow',
          icon: 'wi-sleet'
      }, {
          priority: 25,
          keys: ['DZ SN'],
          text: 'Drizze / Snow',
          icon: 'wi-sleet'
      }, {
          priority: 25,
          keys: ['+DZ SN'],
          text: 'Heavy drizzle / Snow',
          icon: 'wi-sleet'
      }, {
          priority: 25,
          keys: ['DZ +SN'],
          text: 'Drizzle / Heavy snow',
          icon: 'wi-sleet'
      }, {
          priority: 25,
          keys: ['+DZ +SN'],
          text: 'Heavy drizzle / Snow',
          icon: 'wi-sleet'
      }, {
          priority: 26,
          keys: ['-RA -SN'],
          text: 'Light rain / Light snow',
          icon: 'wi-sleet'
      }, {
          priority: 26,
          keys: ['-RA SN'],
          text: 'Light rain / Snow',
          icon: 'wi-sleet'
      }, {
          priority: 26,
          keys: ['-DZ -SN'],
          text: 'Light drizzle / Light snow',
          icon: 'wi-sleet'
      }, {
          priority: 26,
          keys: ['-DZ SN'],
          text: 'Light drizzle / Snow',
          icon: 'wi-sleet'
      }, {
          priority: 27,
          keys: ['SHRA SN'],
          text: 'Rain showers / Snow',
          icon: 'wi-rain-mix'
      }, {
          priority: 27,
          keys: ['SHSN RA'],
          text: 'Snow showers / Rain',
          icon: 'wi-rain-mix'
      }, {
          priority: 27,
          keys: ['+SHRA SN'],
          text: 'Heavy rain showers / Snow',
          icon: 'wi-rain-mix'
      }, {
          priority: 27,
          keys: ['+SHSN RA'],
          text: 'Heavy snow showers / Rain',
          icon: 'wi-rain-mix'
      }, {
          priority: 28,
          keys: ['-SHRA SN'],
          text: 'Light rain showers / Snow',
          icon: 'wi-rain-mix'
      }, {
          priority: 28,
          keys: ['-SHSN RA'],
          text: 'Light snow showers / Rain',
          icon: 'wi-rain-mix'
      }, {
          priority: 28,
          keys: ['-SHRA -SN'],
          text: 'Light rain showers / Light snow',
          icon: 'wi-rain-mix'
      }, {
          priority: 28,
          keys: ['-SHSN -RA'],
          text: 'Light snow showers / Light rain',
          icon: 'wi-rain-mix'
      }, {
          priority: 29,
          keys: ['SHSN'],
          text: 'Snow showers',
          icon: 'wi-snow'
      }, {
          priority: 29,
          keys: ['+SHSN'],
          text: 'Heavy snow showers',
          icon: 'wi-snow'
      }, {
          priority: 30,
          keys: ['-SHSN'],
          text: 'Light snow showers',
          icon: 'wi-snow'
      }, {
          priority: 31,
          keys: ['IC'],
          text: 'Ice crystals',
          icon: 'wi-snowflake-cold'
      }, {
          priority: 32,
          keys: ['UP'],
          text: 'Unknown precipitation',
          icon: 'wi-sprinkle'
      }, {
          priority: 33,
          keys: ['TS'],
          text: 'Thunderstorm',
          icon: 'wi-thunderstorm'
      }, {
          priority: 34,
          keys: ['DZ RA'],
          text: 'Drizzle / Rain',
          icon: 'wi-rain-mix'
      }, {
          priority: 34,
          keys: ['+DZ RA'],
          text: 'Heavy drizzle / Rain',
          icon: 'wi-rain-mix'
      }, {
          priority: 34,
          keys: ['DZ +RA'],
          text: 'Drizzle / Heavy rain',
          icon: 'wi-rain-mix'
      }, {
          priority: 34,
          keys: ['+DZ +RA'],
          text: 'Heavy drizzle / Heavy rain',
          icon: 'wi-rain-mix'
      }, {
          priority: 35,
          keys: ['DZ -RA'],
          text: 'Drizzle / Light rain',
          icon: 'wi-rain-mix'
      }, {
          priority: 35,
          keys: ['-DZ RA'],
          text: 'Light drizzle / Rain',
          icon: 'wi-rain-mix'
      }, {
          priority: 35,
          keys: ['-DZ -RA'],
          text: 'Light drizzle / Light rain',
          icon: 'wi-rain-mix'
      }, {
          priority: 36,
          keys: ['+SN'],
          text: 'Heavy snow',
          icon: 'wi-snow'
      }, {
          priority: 37,
          keys: ['+RA'],
          text: 'Heavy rain',
          icon: 'wi-rain'
      }, {
          priority: 38,
          keys: ['SH'],
          text: 'Showers',
          icon: 'wi-showers'
      }, {
          priority: 38,
          keys: ['+SH'],
          text: 'Heavy showers',
          icon: 'wi-showers'
      }, {
          priority: 38,
          keys: ['SHRA'],
          text: 'Rain showers',
          icon: 'wi-showers'
      }, {
          priority: 38,
          keys: ['+SHRA'],
          text: 'Heavy rain showers',
          icon: 'wi-showers'
      }, {
          priority: 39,
          keys: ['+DZ'],
          text: 'Heavy drizzle',
          icon: 'wi-sleet'
      }, {
          priority: 40,
          keys: ['SN'],
          text: 'Snow',
          icon: 'wi-snow'
      }, {
          priority: 41,
          keys: ['RA'],
          text: 'Rain',
          icon: 'wi-rain'
      }, {
          priority: 42,
          keys: ['DZ'],
          text: 'Drizzle',
          icon: 'wi-sprinkle'
      }, {
          priority: 43,
          keys: ['-SN'],
          text: 'Light snow',
          icon: 'wi-snow'
      }, {
          priority: 44,
          keys: ['-RA'],
          text: 'Light rain',
          icon: 'wi-showers'
      }, {
          priority: 45,
          keys: ['-SH'],
          text: 'Light showers',
          icon: 'wi-showers'
      }, {
          priority: 45,
          keys: ['-SHRA'],
          text: 'Light rain showers',
          icon: 'wi-showers'
      }, {
          priority: 46,
          keys: ['-DZ'],
          text: 'Light drizzle',
          icon: 'wi-sprinkle'
      }, {
          priority: 47,
          keys: ['DRSN'],
          text: 'Drifting snow',
          icon: 'wi-sandstorm'
      }, {
          priority: 48,
          keys: ['BLSN'],
          text: 'Blowing snow',
          icon: 'wi-sandstorm'
      }, {
          priority: 48,
          keys: ['VCBLSN'],
          text: 'Blowing snow in vicinity',
          icon: 'wi-sandstorm'
      }, {
          priority: 49,
          keys: ['FZFG'],
          text: 'Freezing fog',
          icon: 'wi-fog'
      }, {
          priority: 50,
          keys: ['VCTS'],
          text: 'Thunderstorm in vicinity',
          icon: 'wi-thunderstorm'
      }, {
          priority: 51,
          keys: ['VCSH'],
          text: 'Showers in vicinity',
          icon: 'wi-showers'
      }, {
          priority: 52,
          keys: ['FG'],
          text: 'Fog',
          icon: 'wi-fog'
      }, {
          priority: 53,
          keys: ['PRFG'],
          text: 'Partial fog',
          icon: 'wi-fog'
      }, {
          priority: 54,
          keys: ['BCFG'],
          text: 'Patchy fog',
          icon: 'wi-fog'
      }, {
          priority: 55,
          keys: ['VCFG'],
          text: 'Fog in vicinity',
          icon: 'wi-fog'
      }, {
          priority: 56,
          keys: ['MIFG'],
          text: 'Shallow fog',
          icon: 'wi-fog'
      }, {
          priority: 57,
          keys: ['VIRGA'],
          text: 'Virga',
          icon: 'wi-rain-wind'
      }, {
          priority: 58,
          keys: ['BR'],
          text: 'Mist',
          icon: 'wi-fog'
      }, {
          priority: 59,
          keys: ['HZ'],
          text: 'Haze',
          icon: 'wi-fog'
      }];

    return wxMappings;
  }
  function toggleSpinner( state ) {

      var elementsToToggle = $("#airport_ident, #detect_location,#wx_location_city_state,#wx_location_name_ident,#wx_observation_time,#weather_metrics");
      var wxContainer = $("#airport_ident").parent("section");

      if (state) {
        elementsToToggle.css("transition","opacity .3s ease").css("opacity","0");
        wxContainer.prepend("<div class=\"wxLoading\"><img src=\"https://www.aopa.org/assets/images/preloader_dark.png\"></div>");
      } else {
        $(".wxLoading").remove();
        elementsToToggle.css("transition","opacity .3s ease").css("opacity","1");
      }


  }
  function weatherFail() {
      var wxContainer = $("#airport_ident").parent("section");
      $('#detect_location').css({opacity: '1'});
      $(".wxLoading").remove();
      wxContainer.prepend("<div class=\"wxError\">There was a problem retrieving your weather.</div>");
  }
  // Detect my location click handleRange
  $("#detect_location").click(function (){
  	$(".wxError").detach();
    getLocationFromCoords();
  });

});