export default function bookmarksController($scope, $http, $attrs) {

	$scope.isMarked = parseInt($attrs.inimarkstate);

	$scope.toggleBookmark = function(id) {
		$http.post("/api/bookmark/addupdatebookmarks?bookmarkid=" + id).then(function (response) {
		if (response) {
				$scope.isMarked = response.data.isMarked;
			}
		}, function (response) {
			
		});
	}

}