﻿$(document).ready(function () {
    if ($('div#divAopaJoinFormV4').length) {

        var autocomplete;

        var jfStripe = Stripe($('#sapik').val());

        var jfElements = jfStripe.elements();
        var jfStyle = {
            base: {
                backgroundColor: '#ffffff',
                fontSize: '0.9375rem',
                fontFamily: 'Roboto, Helvetica Neue, sans-serif',
                lineHeight: '42px',
                ':focus': {
                    border: '10px solid black'
                }
            }
        };
        var jfCardElement = jfElements.create("card", { style: jfStyle, hidePostalCode: true, disableLink: true });
        jfCardElement.mount("#card-element");


        jfCardElement.on('change',
            function (event) {
                var displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }

                $('#CardBrand').val(event.brand);
            });

        var initAutocomplete = () => {
            // Create the autocomplete object, restricting the search predictions to
            autocomplete = new google.maps.places.Autocomplete(AddressLookup,
                {
                    fields: ["address_components"],
                    types: ["address"],
                });
            // When the user selects an address from the drop-down, populate the
            // address fields in the form.
            autocomplete.addListener("place_changed", fillInAddress);
        };

        initAutocomplete();

        function fillInAddress() {
            // Get the place details from the autocomplete object.
            var place = autocomplete.getPlace();
            var address1 = "";
            var postcode = "";

            $("#MailingStreet").val("");
            $("#MailingCity").val("");
            $("#MailingStateProvince").val("");
            $("#MailingPostalCode").val("");
            $("#MailingCountry").val("");

            // Get each component of the address from the place details,
            // and then fill-in the corresponding field on the form.
            // place.address_components are google.maps.GeocoderAddressComponent objects
            // which are documented at http://goo.gle/3l5i5Mr
            place.address_components.forEach((component) => {
                var componentType = component.types[0];

                switch (componentType) {
                    case "street_number":
                        {
                            address1 = `${component.long_name} ${address1}`;
                            break;
                        }

                    case "route":
                        {
                            address1 += component.short_name;
                            break;
                        }

                    case "postal_code":
                        {
                            postcode = `${component.long_name}${postcode}`;
                            break;
                        }

                    case "postal_code_suffix":
                        {
                            postcode = `${postcode}-${component.long_name}`;
                            break;
                        }
                    case "locality":
                        $("#MailingCity").val(component.long_name);
                        clearValidation("MailingCity");
                        break;
                    case "administrative_area_level_1":
                        {
                            $("#MailingStateProvince").val(component.short_name);
                            clearValidation("MailingStateProvince");
                            break;
                        }
                    case "country":
                        $("#MailingCountry").val(component.long_name);
                        if (component.short_name === "US") {
                            $("#rdoMailingCountryUS").prop("checked", true);
                            $("#divMailingCountry").hide();
                            $('#IsDomestic').val("True");
                            $('#IsInternational').val("False");
                        } else {
                            $("#rdoMailingCountryOther").prop("checked", true);
                            $("#divMailingCountry").show();
                            $('#IsDomestic').val("False");
                            $('#IsInternational').val("True");
                            $('#MembershipTerm').val("ANNUAL");
                            $('#btnAnnual').removeClass('joinv4-term-button__inactive');
                            $('#btnMonthly').addClass('joinv4-term-button__inactive');
                        }
                        clearValidation("MailingCountry");
                        break;

                }
            });

            $("#MailingStreet").val(address1);
            clearValidation("MailingStreet");
            $("#MailingPostalCode").val(postcode);
            clearValidation("MailingPostalCode");
            $("#MailingStreet").focus();
            setAAR();
            setTotal();
        };

        var clearValidation = (field) => {
            var theField = $('#' + field);
            if (theField.val()) {
                theField.removeClass("input-validation-error").addClass("valid");
                $("span[data-valmsg-for='" + field + "']").removeClass("field-validation-error").addClass("field-validation-valid");
                $("span[data-valmsg-for='" + field + "']").empty();
            }
        };

        $("input[type=radio][name=rdoMailingCountry]").change(function () {
            if ($('input[name="rdoMailingCountry"]:checked').val() === "US") {
                $('#IsDomestic').val("True");
                $('#IsInternational').val("False");
                $("#divMailingCountry").hide();
                $('#MailingCountry').val("United States");
            } else {
                $('#IsDomestic').val("False");
                $('#IsInternational').val("True");
                $("#divMailingCountry").show();
                $('#MembershipTerm').val("ANNUAL");
                $('#btnAnnual').removeClass('joinv4-term-button__inactive');
                $('#btnMonthly').addClass('joinv4-term-button__inactive');
                $('#MailingCountry').val("");
            }
            setAAR();
            setTotal();
        });

        
        $("#BirthDate").datepicker({
            changeMonth: true,
            changeYear: true,
            yearRange: (new Date).getFullYear() - 110 + ':' + (new Date).getFullYear(),
            minDate: new Date(new Date().getFullYear() - 110, new Date().getMonth(), new Date().getDate()),
            maxDate: new Date()
        });

        $("#BirthDate").change(function () {

            if ($("#BirthDate").val()) {
                var bthDt = new Date($("#BirthDate").val())
                var dtNow = new Date();
                var age = dtNow.getFullYear() - bthDt.getFullYear();

                if ((dtNow.getMonth() < bthDt.getMonth()) ||
                    (dtNow.getMonth() === bthDt.getMonth() && dtNow.getDate() < bthDt.getDate())) {
                    age -= 1;
                }

                if (age >= 75) {
                    $("#Is75Plus").val("True");
                } else {
                    $("#Is75Plus").val("False");
                }
            } else {
                $("#Is75Plus").val("False");
            }
            setTotal();
        });


        $("#lnkKeyCode").click(function () {
            $('#divKeycodeForm').show();
        });

        $(".joinv4-term-button").click(function () {
            $('#MembershipTerm').val($(this).data('term'));
            setAAR();
            setTotal();
        });

        $("#btnJoinKeyCode").click(function () {
            var form = $(this).closest('form');
            if ($('#KeyCodeEntered').val() && $('#KeyCodeEntered').val().toUpperCase() !== $('#KeyCode').val().toUpperCase()) {
                $.getJSON('/api/JoinKeyCodeV4/Get/?keycode=' + $('#KeyCodeEntered').val())
                    .done(function (data) {
                        $('#KeyCode').val(data.keycode);
                        if (data.promoCodeActive) {
                            $('#PromoCodeId').val(data.promoCodeId);
                            $('#PromoCode').val(data.promoCode);
                            $('#DiscountProduct').val(data.productCode);
                            $('#DiscountPercentage').val(data.discount);
                      
                            if (data.productCode && data.discount == 0) {
                                $('#AarKeyCodeProductText').val(data.productName);
                                $('#AarKeyCodeProductCode').val(data.productCode);
                                $('#AarUseKeyCodeIncentive').val("True");
                                $('#divAarIncentiveText').html(decodeURIComponent($('#AarBaseIncentiveText').val().replace(/\+/g,' ').replace("**X**", data.productName)));
                                $('#divAarIncentiveSummaryText').html(data.productName);
                                document.getElementById("imgAarIncentive").src = decodeURIComponent($('#AarBasePremiumUrl').val().replace("**X**", data.productCode));
                            }
                            else {
                                $('#AarKeyCodeProductText').val("");
                                $('#AarKeyCodeProductCode').val("");
                                $('#AarUseKeyCodeIncentive').val("False");
                                $('#divAarIncentiveText').html(decodeURIComponent($('#AarDefaultIncentiveText').val().replace(/\+/g, ' ')));
                                $('#divAarIncentiveSummaryText').html(decodeURIComponent($('#AarDefaultIncentiveSummaryText').val()));
                                document.getElementById("imgAarIncentive").src = decodeURIComponent($('#AarDefaultIncentiveImgUrl').val());
                            }                            

                        } else {
                            $('#PromoCodeId').val("");
                            $('#PromoCode').val("");
                            $('#DiscountProduct').val("");
                            $('#DiscountPercentage').val("");
                            $('#AarKeyCodeProductText').val("");
                            $('#AarKeyCodeProductCode').val("");
                            $('#AarUseKeyCodeIncentive').val("False");
                            $('#divAarIncentiveText').html(decodeURIComponent($('#AarDefaultIncentiveText').val().replace(/\+/g, ' ')));
                            $('#divAarIncentiveSummaryText').html(decodeURIComponent($('#AarDefaultIncentiveSummaryText').val()));
                            document.getElementById("imgAarIncentive").src = decodeURIComponent($('#AarDefaultIncentiveImgUrl').val());
                        }
                        $('#divKeyCodeFail').hide();
                        $('#divKeyCodeSuccess').show();
                        setTotal();
                    })
                    .fail(function (jqXHR, textStatus, err) {
                        $('#divKeyCodeFail').show();
                        $('#divKeyCodeSuccess').hide();
                    });
            } else if ($('#KeyCodeEntered').val() && $('#KeyCodeEntered').val().toUpperCase() === $('#KeyCode').val().toUpperCase()) {
                $('#divKeyCodeFail').hide();
                $('#divKeyCodeSuccess').show();
            }
        });
        
        $('#SelectedMagazineOption').change(function () {
            $('#joinv4-form-data_summary_mag').html($("#SelectedMagazineOption option:selected").text() + " Magazine");

            setTotal();
        });

        $('#AarAnnualInterested').change(function () {
            setAAR();
        });

        $('#IncludeAnnualAar').change(function () {
            setAAR();
        });

        $('#AarIncentiveOptOut').change(function () {
            setAAR();
        });

        var setAAR = () => {
            if ($('#MembershipTerm').val() === "ANNUAL") {
                $('#divAAROptional').show();
                $('#divAARRequired').hide();

                if ($('#AarAnnualInterested').is(":checked")) {
                    $('#divAAROptionalDisplay').show();

                    if ($('#IncludeAnnualAar').is(":checked")) {
                        $('#joinv4-form-data_summary_aar').html("YES");

                        $('#divAarIncentiveOptOut').show();

                        if ($('#AarIncentiveShowOptOut').val() === "True") {
                            if ($('#AarIncentiveOptOut').is(":checked")) {
                                $('#joinv4-form-data_summary_aar_incentive_row').hide();
                            } else {
                                $('#joinv4-form-data_summary_aar_incentive_row').show();
                            }
                        } else {
                            $('#joinv4-form-data_summary_aar_incentive_row').show();

                        }
                    } else {
                        $('#joinv4-form-data_summary_aar').html("NO");
                        $('#divAarIncentiveOptOut').hide();
                        $('#joinv4-form-data_summary_aar_incentive_row').hide();
                    }
                } else {
                    $('#divAAROptionalDisplay').hide();
                    $('#joinv4-form-data_summary_aar').html("NO");
                    $('#divAarIncentiveOptOut').hide();
                    $('#joinv4-form-data_summary_aar_incentive_row').hide();
                }
            } else {
                $('#divAAROptional').hide();
                $('#divAARRequired').show();

                $('#joinv4-form-data_summary_aar').html("YES");
                $('#joinv4-form-data_summary_aar_incentive_row').hide();
            }
        }

        $('#IncludeMonthlyAar').change(function () {
            if (this.checked || $('#MembershipTerm').val() === "ANNUAL") {
                enableJoinButton();
            } else {
                disableJoinButton();
            }
        });


        var enableJoinButton = () => {
            $('#btnJoin').removeClass('join-disable-button');
            $('#btnJoin').prop('disabled', false);
        }

        var disableJoinButton = () => {
            $('#btnJoin').addClass('join-disable-button');
            $('#btnJoin').prop('disabled', true);
        }

        var checkCardFields = () => {
            var isValid = false;

            if ($('#ShowStripeForm').val() === "True") {
                var cardElement = document.querySelector('#card-element');

                isValid = cardElement.classList.contains('StripeElement--complete');

                var brand = $('#CardBrand').val();

                if (isValid &&
                    (brand === 'visa' || brand === 'mastercard' || brand === 'amex' || brand === 'discover')) {
                    $('#card-errors').html('');
                } else {
                    if ($('#card-errors').is(':empty')) {
                        $('#card-errors').html('Please check your credit card information.');
                    }
                }

            } else {
                isValid = true;
            }


            return isValid;
        }

        $('#SelectedPpsOption').change(function () {
            setTotal();
        });
        
        var setMem = (isAnnual, isMonthly, isDomestic, isInternational, isSenior, membershipOptions) => {
            var retVal = 0.0;
            var currAnnual;
            var currMonthly;

            if (isInternational) {
                currAnnual = membershipOptions.filter(o => {
                    return o.isInternational === true;
                })[0];

                currMonthly = null;
            } else {
                currAnnual = membershipOptions.filter(o => {
                    return o.isDomestic === true && o.isAnnual === true && o.isSenior === isSenior;
                })[0];

                currMonthly = membershipOptions.filter(o => {
                    return o.isDomestic === true && o.isMonthly === true && o.isSenior === isSenior;
                })[0];
            }

            if (currAnnual != null) {
                var discAnnAmount = Math.round((currAnnual.price * parseFloat($("#DiscountPercentage").val()) / 100) * 100) / 100;
                if (currAnnual.productCode === $('#DiscountProduct').val()) {
                    $('#btnAnnual').html('<span class="joinv4-form-data__strikethrough">$' + currAnnual.price.toFixed(2) + '</span> $' + (currAnnual.price - discAnnAmount).toFixed(2) + ' <span class="joinv4-term-text">Per Year</span>');
                } else {
                    $('#btnAnnual').html('$' + currAnnual.price.toFixed(2) + ' <span class="joinv4-term-text">Per Year</span>');
                }


                if (isAnnual && currAnnual.productCode === $('#DiscountProduct').val()) {
                    $("#DiscountAmount").val(discAnnAmount);
                    $("#DiscountAmountString").val("-$" + discAnnAmount.toFixed(2));
                    $("#joinv4-form-data_summary_discount_amount").html("-$" + discAnnAmount.toFixed(2));
                    $("#divSummmaryDiscountDescription").html("Discount");
                    $("#DiscountDescription").val("Discount");
                    $("#joinv4-form-data_summary_discount_wrapper").show();
                    retVal -= discAnnAmount;
                }
                if (isAnnual && currAnnual.productCode !== $('#DiscountProduct').val()) {
                    $("#DiscountAmount").val(0);
                    $("#DiscountAmountString").val("");
                    $("#joinv4-form-data_summary_discount_amount").html("");
                    $("#divSummmaryDiscountDescription").html("");
                    $("#DiscountDescription").val("");
                    $("#joinv4-form-data_summary_discount_wrapper").hide();
                }
            }


            if (isDomestic && currMonthly != null) {
                var discMonAmount = Math.round((currMonthly.price * parseFloat($("#DiscountPercentage").val()) / 100) * 100) / 100;
                if (currMonthly.productCode === $('#DiscountProduct').val()) {
                    $('#btnMonthly').html('<span class="joinv4-form-data__strikethrough">$' + currMonthly.price.toFixed(2) + '</span> $' + (currMonthly.price - discMonAmount).toFixed(2) + ' <span class="joinv4-term-text">Per Month</span>');
                } else {
                    $('#btnMonthly').html('$' + currMonthly.price.toFixed(2) + ' <span class="joinv4-term-text">Per Month</span>');
                }


                if (isMonthly && currMonthly.productCode === $('#DiscountProduct').val()) {
                    $("#DiscountAmount").val(discAnnAmount);
                    $("#DiscountAmountString").val("-$" + discAnnAmount.toFixed(2));
                    $("#joinv4-form-data_summary_discount_amount").html("-$" + discAnnAmount.toFixed(2));
                    $("#divSummmaryDiscountDescription").html("Discount");
                    $("#DiscountDescription").val("Discount");
                    $("#joinv4-form-data_summary_discount_wrapper").show();
                    retVal -= currMonthly;
                }
                if (isMonthly && currMonthly.productCode !== $('#DiscountProduct').val()) {
                    $("#DiscountAmount").val(0);
                    $("#DiscountAmountString").val("");
                    $("#joinv4-form-data_summary_discount_amount").html("");
                    $("#divSummmaryDiscountDescription").html("");
                    $("#DiscountDescription").val("");
                    $("#joinv4-form-data_summary_discount_wrapper").hide();
                }
            } else {
                $('#btnMonthly').html('$-.--');
            }


            if (isAnnual) {
                $("#joinv4-form-data_summary_membership").html(currAnnual.summaryDescription);
                $("#spHeaderMembership").html(currAnnual.summaryDescription);
                $("#joinv4-form-data_summary_membership_price").html('$' + currAnnual.price.toFixed(2));
                $("#MembershipBundleSelected").val(currAnnual.bundleCode);
                $("#MembershipProductSelected").val(currAnnual.productCode);
                $('.joinv4-form-data__promo-section').show();
                $('#btnAnnual').removeClass('joinv4-term-button__inactive');
                $('#btnMonthly').addClass('joinv4-term-button__inactive');
                retVal += currAnnual.price;
            }
            if (isMonthly) {
                $("#joinv4-form-data_summary_membership").html(currMonthly.summaryDescription);
                $("#spHeaderMembership").html(currMonthly.summaryDescription);
                $("#joinv4-form-data_summary_membership_price").html('$' + currMonthly.price.toFixed(2));
                $("#MembershipBundleSelected").val(currMonthly.bundleCode);
                $("#MembershipProductSelected").val(currMonthly.productCode);
                $('.joinv4-form-data__promo-section').hide();
                $('#btnAnnual').addClass('joinv4-term-button__inactive');
                $('#btnMonthly').removeClass('joinv4-term-button__inactive');
                $('#divFreeGift').hide();
                retVal += currMonthly.price;
            }

            if (isInternational) {
                $('#btnMonthlyWrapper').hide();

            } else {
                $('#btnMonthlyWrapper').show();
            }

            return retVal;
        };

        var setPps = (isAnnual, isMonthly, ppsAnnualOptions, ppsMonthlyOptions) => {
            var retVal = 0.0;

            var currentSelection = $('#SelectedPpsOption').val();
            var itemsToAdd;
            if (isAnnual) {
                if (currentSelection && currentSelection.startsWith("MON-")) {
                    currentSelection = currentSelection.replace("MON-", "");
                }
                itemsToAdd = ppsAnnualOptions;
            };
            if (isMonthly) {
                if (currentSelection && !currentSelection.startsWith("MON-")) {
                    currentSelection = "MON-" + currentSelection;
                }
                itemsToAdd = ppsMonthlyOptions;
            };

            $('#SelectedPpsOption').empty();

            $.each(itemsToAdd, function () {
                var option = document.createElement("option");

                option.value = this.productCode;
                option.text = this.dropDownText;
                if (option.value === currentSelection) {
                    option.selected = true;
                    retVal = Math.round(this.price * 100) / 100;
                    $("#joinv4-form-data_summary_pps").html(this.summaryDescription);
                    $("#joinv4-form-data_summary_pps_price").html('$' + retVal.toFixed(2));
                }

                $('#SelectedPpsOption').append(option);
            });

            if (currentSelection) {
                $("#joinv4-form-data_summary_pps_wrapper").show();
            } else {
                $("#joinv4-form-data_summary_pps_wrapper").hide();
            }

            return retVal;
        };

        var setIpf = (isDomestic) => {
            var retVal = 0.0;

            if (isDomestic) {
                $('#joinv4-form-data_summary_ipf_wrapper').hide();
            } else {
                if ($('#SelectedMagazineOption').val().indexOf("-PRINT-") >= 0) {
                    retVal = parseFloat($('#InternationalPrintFeeSummaryPrice').val());
                    $('#joinv4-form-data_summary_ipf_wrapper').show();
                } else {
                    $('#joinv4-form-data_summary_ipf_wrapper').hide();
                }
            }

            return retVal;
        };

        var updateTerms = (isAnnual, isInternational, membershipOptions, ppsAnnualOptions, ppsMonthlyOptions) => {
            var total = 0.0;
            var currMembership = membershipOptions.filter(o => {
                return o.bundleCode === $("#MembershipBundleSelected").val();
            })[0];

            total += currMembership.price;

            var currPps;
            if (isAnnual) {
                currPps = ppsAnnualOptions.filter(o => {
                    return o.productCode === $("#SelectedPpsOption").val();
                })[0];
            } else {
                currPps = ppsMonthlyOptions.filter(o => {
                    return o.productCode === $("#SelectedPpsOption").val();
                })[0];
            }
            total += currPps.price;

            if (isInternational && $('#SelectedMagazineOption').val().indexOf("-PRINT-") >= 0) {
                total += parseFloat($('#InternationalPrintFeeSummaryPrice').val());
            }

            if (isAnnual) {
                $('.aopa-annual-membership-price').html('$' + total.toFixed(2));
                $('.aopa-monthly-membership-price').html('--.--');
            } else {
                $('.aopa-annual-membership-price').html('--.--');
                $('.aopa-monthly-membership-price').html('$' + total.toFixed(2));
            }
        };


        var setTotal = () => {
            var oldTotal = parseFloat($("#OrderTotal").val());


            var membershipOptions = JSON.parse($('#divMembershipOptions').text());
            var ppsAnnualOptions = JSON.parse($('#divPpsAnnualOptions').text());
            var ppsMonthlyOptions = JSON.parse($('#divPpsMonthlyOptions').text());
            var isDomestic = ($('#IsDomestic').val().toUpperCase() === "TRUE");
            var isInternational = ($('#IsInternational').val().toUpperCase() === "TRUE");
            var isSenior = ($('#Is75Plus').val().toUpperCase() === "TRUE");
            var isAnnual = ($('#MembershipTerm').val() === "ANNUAL");
            var isMonthly = ($('#MembershipTerm').val() === "MONTHLY");
            
            var total = 0.0;
            total += setMem(isAnnual, isMonthly, isDomestic, isInternational, isSenior, membershipOptions);
            total += setIpf(isDomestic);
            total += setPps(isAnnual, isMonthly, ppsAnnualOptions, ppsMonthlyOptions);
            total = Math.round(total * 100) / 100;
            updateTerms(isAnnual, isInternational, membershipOptions, ppsAnnualOptions, ppsMonthlyOptions);

            if (total !== oldTotal) {
                $('input[type="checkbox"][id="IncludeAnnualAar"]').prop("checked", false).change();
                $('input[type="checkbox"][id="IncludeMonthlyAar"]').prop("checked", false).change();
            }


            $("#spHeaderPrice").html("$" + total.toFixed(2));
            $("#joinv4-form-data_summary_total").html("$" + total.toFixed(2));
            $("#OrderTotal").val(total);
        };

        $('#UseNewCard').change(function () {
            if ($('#UseNewCard').is(":checked")) {
                $('#ShowStripeForm').val("True");
                $('#divStripe').show();
                $('#divToken').hide();
            } else {
                $('#ShowStripeForm').val("False");
                $('#divStripe').hide();
                $('#divToken').show();
            }
        });


        $(".submitButtonJFV4").click(function () {
            var theForm = $(this).closest('form');

            var progressBar = new Sfpb_progressBar();

            var ccValid = checkCardFields();

            if (theForm.valid() && ccValid && $("#OrderTotal").val() > 0) {
                $('#btnJoin').hide();
                $(this).hide();
                $('body').addClass('progressbar-modal');
                progressBar.start();
                $(".clsSubmitButton").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');


                if ($('#ShowStripeForm').val() === "True") {
                    jfStripe.createToken(jfCardElement, { name: $("#CardName").val() })
                        .then(function(result) {
                            if (result.token !== null) {
                                $("#CardToken").val(JSON.stringify(result.token));
                                theForm.submit();
                            } else {
                                if ($('#card-errors').is(':empty')) {
                                    $('#card-errors').html('Please check your credit card information.');
                                }
                                var spinner = document.getElementById('aopaSpinner');
                                spinner.parentElement.removeChild(spinner);
                                $(this).show();
                                progressBar.kill();
                                $('body').removeClass('progressbar-modal');
                                $('#btnJoin').show();
                            }
                        });
                } else {
                    theForm.submit();
                }
            } else {
                var firstInstance = $('.input-validation-error').first();
                var offsetTop = firstInstance.offset().top - 100;

                $('html, body').animate({
                    scrollTop: offsetTop
                }, 500);

            }
        });
    }
});