﻿/*
Custom script for required optional validation
*/
(function ($) {
    $.validator.addMethod("requiredoptionalcheck", function (value, element, params) {
        var isRequired = $('#' + params).val().toUpperCase();
        
        if (isRequired === 'TRUE') {
            if (!value) {
                return false;
            }
        }
        return true;
    });

    $.validator.unobtrusive.adapters.addSingleVal("requiredoptionalcheck", "requiredoptionalfield");
}(jQuery));