﻿/*
Custom script for checking if one field or another is filled in
*/
(function ($) {
    $.validator.addMethod("eitherorequiredcheck", function (value, element, params) {
        var otherField = $('#' + params);

        if (!value.length && !otherField.val()) {
           return false;
        }
        return true;
    });

    $.validator.unobtrusive.adapters.addSingleVal("eitherorequiredcheck", "field2");
}(jQuery));
