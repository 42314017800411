﻿/*
Custom script for required optional validation
*/
(function ($) {
    $.validator.addMethod("attendancecountcheck", function (value, element, params) {

        var fValue = parseInt(value, 10);
        var oValue = parseInt($('#' + params).val(),10);


        if (fValue !== NaN && oValue !== NaN) {
            if (fValue + oValue <= 0) {
                return false;
            }
        } else {
            return false;
        }

        return true;
    });

    $.validator.unobtrusive.adapters.addSingleVal("attendancecountcheck", "additionalfield");
}(jQuery));