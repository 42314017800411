﻿$(document).ready(function () {
    if ($('div#divEFIRCPurchaseForm').length) {
        var theForm = $('#frmEFIRCPurchase');

        var cardElement = document.getElementById("card-number");

        if (cardElement !== null) {
            var efpStripe = Stripe($('#sapik').val());

            var efpElements = efpStripe.elements();
            var efpStyle = {
                base: {
                    backgroundColor: '#ffffff',
                    fontSize: '0.9375rem',
                    fontFamily: 'Roboto, Helvetica Neue, sans-serif',
                    lineHeight: '3.5rem',
                    ':focus': {
                        border: '10px solid black'
                    }
                }
            };
            var efpCardNumberElement = efpElements.create("cardNumber", { style: efpStyle, showIcon: true, placeholder: 'Card Number', disableLink: true });
            var efpCardExpiryElement = efpElements.create("cardExpiry", { style: efpStyle });
            var efpCardCvcElement = efpElements.create("cardCvc", { style: efpStyle });
            efpCardNumberElement.mount("#card-number");
            efpCardExpiryElement.mount("#card-expiry");
            efpCardCvcElement.mount("#card-cvc");

            efpCardNumberElement.on('change',
                function (event) {
                    var displayError = document.getElementById('card-number-errors');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }

                    $('#CardBrand').val(event.brand);

                    if ((event.brand !== 'visa' &&
                        event.brand !== 'mastercard' &&
                        event.brand !== 'amex' &&
                        event.brand !== 'discover')) {
                        if ($('#card-errors').is(':empty')) {
                            $('#card-errors').html('Please check your credit card information.');
                        }
                    } else {
                        $('#card-errors').html('');
                    }
                });

            efpCardExpiryElement.on('change',
                function (event) {
                    var displayError = document.getElementById('card-expiry-errors');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }
                });

            efpCardCvcElement.on('change',
                function (event) {
                    var displayError = document.getElementById('card-cvc-errors');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }
                });

            var checkCardFields = () => {
                var isValid = false;

                var cardElement = document.querySelector('#card-number');
                var expiryElement = document.querySelector('#card-expiry');
                var cvcElement = document.querySelector('#card-cvc');

                isValid = cardElement.classList.contains('StripeElement--complete') &&
                    expiryElement.classList.contains('StripeElement--complete') &&
                    cvcElement.classList.contains('StripeElement--complete');

                var brand = $('#CardBrand').val();

                if (isValid &&
                    (brand === 'visa' || brand === 'mastercard' || brand === 'amex' || brand === 'discover')) {
                    $('#card-errors').html('');
                } else {
                    if ((brand !== 'visa' && brand !== 'mastercard' && brand !== 'amex' && brand !== 'discover') || !isValid) {
                        isValid = false;
                        if ($('#card-errors').is(':empty')) {
                            $('#card-errors').html('Please check your credit card information.');
                        }
                    }
                }

                return isValid;
            }

        }


        $(".formSubmitButtonEFP").click(function () {
            if (cardElement !== null) {
                var progressBar = new Sfpb_progressBar();
                var ccValid = checkCardFields();

                if (theForm.valid() && ccValid) {


                    $(this).hide();
                    $('body').addClass('progressbar-modal');
                    progressBar.start();
                    $(".clsSubmitButton").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');

                    efpStripe.createToken(efpCardNumberElement, { name: $("#CardName").val() })
                        .then(function (result) {
                            if (result.token !== null) {
                                $("#CardToken").val(JSON.stringify(result.token));
                                theForm.submit();
                            } else {
                                if ($('#card-errors').is(':empty')) {
                                    $('#card-errors').html('Please check your credit card information.');
                                }
                                var spinner = document.getElementById('aopaSpinner');
                                spinner.parentElement.removeChild(spinner);
                                progressBar.kill();
                                $('body').removeClass('progressbar-modal');
                                $(this).show();
                            }
                        });
                }
            } else {
                if (theForm.valid()) {
                    $(this).hide();
                    progressBar.start();
                    $(".clsSubmitButton").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            }
        });
    }
});