import AirportService from "./services/AirportService";
import EventService from "./services/EventService";
import GoogleService from "./services/GoogleService";
import EventSearch from "./directives/EventSearch";
import Event from "./directives/Event";

(function(angular) {
    'use strict';

    let eventApp = angular.module('AopaEventSearch', [
        'ngMaterial',
        'ngMap',
        'ngMaterialDateRangePicker',
        'angularUtils.directives.dirPagination',
        'aopa.shared'
    ]);

    eventApp.service('airportService', AirportService);
    eventApp.service('eventService', EventService);
    eventApp.service('googleService', GoogleService);
    eventApp.directive('eventSearch', EventSearch.directiveFactory);
    eventApp.directive('event', Event.directiveFactory);
})(window.angular);
