﻿$(document)
    .ready(function() {
        if ($('div#divRustyPilotRegistrationForm').length) {

            var theForm = $("#btnRegister").closest('form');
            theForm.data('validator').settings.ignore = "";

            //
            // Reset the questionnaire fields on page load
            //
            if ($('#QuestionnaireData').val()) {
                var data = JSON.parse(decodeURIComponent($('#QuestionnaireData').val()));
                for (var i = 0; i < data.length; i++) {
                    switch (data[i].type) {
                        case "Select":
                            $('#MPQuestionSelect' + data[i].qid + ' option[value="' + data[i].qa + '"]').prop('selected', true);
                            break;
                        case "CB":
                            var answers = data[i].qa.split(',');
                            $.each(answers, function (index, obj) {
                                $('input.MPQuestionCB' + data[i].qid + '[value="' + obj + '"]').prop('checked', true);
                            });
                            break;
                    }
                   
                }
            }

            $('.MPQuestionSelect').change(function() {
                var data = JSON.parse(decodeURIComponent($('#QuestionnaireData').val()));
                var qid = this.id.replace('MPQuestionSelect', '');
                var idx = -1;
                var ct = 0;

                $.each(data, function () {
                    if (this.qid.toString() === qid) {
                        idx = ct;
                        return false;
                    }
                    ct++;
                });


                if (idx != -1) {
                    data[idx].qa = this.value;
                }

                $('#QuestionnaireData').val(encodeURIComponent(JSON.stringify(data)));
            });

            $('.MPQuestionCB').change(function () {
                var data = JSON.parse(decodeURIComponent($('#QuestionnaireData').val()));
                var cbid = this.id;
                var qid = this.name.replace('MPQuestionCB', '');
                var anslist = '';
                var idx = -1;
                var ct = 0;

                $.each(data, function () {
                    if (this.qid.toString() === qid) {
                        idx = ct;
                        return false;
                    }
                    ct++;
                });

                $('.' + cbid).each(function(index, obj) {
                    if (this.checked) {
                        anslist += this.value + ',';
                    }
                });
                anslist = anslist.slice(0, -1);


                if (idx != -1) {
                    data[idx].qa = anslist;
                }

                $('#QuestionnaireData').val(encodeURIComponent(JSON.stringify(data)));
            });


            $(".btnCancel")
                .click(function () {
                    window.location.href = "https://www.aopa.org";
                });


            $(".btnPage2Forward")
                .click(function () {
                    var form = $(this).closest('form');

                    $('#rprPage1').css({ "visibility": "hidden" });
                    $('#rprPage1').css({ "display": "none" });
                    $('#rprPage2').css({ "visibility": "visible" });
                    $('#rprPage2').css({ "display": "block" });
                    window.scrollTo(0, 0);
                });
            $(".btnPage3Forward")
                .click(function () {
                    var form = $(this).closest('form');

                    if (validateDiv(form, 'rprPage2')) {
                        $('#rprPage2').css({ "visibility": "hidden" });
                        $('#rprPage2').css({ "display": "none" });
                        $('#rprPage3').css({ "visibility": "visible" });
                        $('#rprPage3').css({ "display": "block" });
                        window.scrollTo(0, 0);
                    }

                });
            $(".btnPage4Forward")
                .click(function () {
                    var form = $(this).closest('form');

                    if (validateDiv(form, 'rprPage3')) {
                        $('#rprPage3').css({ "visibility": "hidden" });
                        $('#rprPage3').css({ "display": "none" });
                        $('#rprPage4').css({ "visibility": "visible" });
                        $('#rprPage4').css({ "display": "block" });
                        window.scrollTo(0, 0);
                    }
                });

            $(".btnPage1Back")
                .click(function () {
                    var form = $(this).closest('form');

                    $('#rprPage2').css({ "visibility": "hidden" });
                    $('#rprPage2').css({ "display": "none" });
                    $('#rprPage1').css({ "visibility": "visible" });
                    $('#rprPage1').css({ "display": "block" });
                    window.scrollTo(0, 0);
                });
            $(".btnPage2Back")
                .click(function () {
                    var form = $(this).closest('form');

                    $('#rprPage3').css({ "visibility": "hidden" });
                    $('#rprPage3').css({ "display": "none" });
                    $('#rprPage2').css({ "visibility": "visible" });
                    $('#rprPage2').css({ "display": "block" });
                    window.scrollTo(0, 0);
                });
            $(".btnPage3Back")
                .click(function () {
                    var form = $(this).closest('form');

                    $('#rprPage4').css({ "visibility": "hidden" });
                    $('#rprPage4').css({ "display": "none" });
                    $('#rprPage3').css({ "visibility": "visible" });
                    $('#rprPage3').css({ "display": "block" });
                    window.scrollTo(0, 0);
                });


            function validateDiv(form, div) {
                var validator = form.data('validator');
                var returnValue = true;

                $('#' + div + ' :input').each(function(e) {

                    //if ($('#' + this.id).is(":visible")) {
                    if (!$(this).is(":button")) {
                        if (!validator.element($('#' + this.id))) returnValue = false;
                    }
                });
                return returnValue;
            }



            $(".rpRegisterButton").click(function () {
                var form = $(this).closest('form');
                if (form.valid()) {
                    $('.btn_final_cancelback').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    form.submit();
                }
            });
        }
    });
