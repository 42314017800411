import jquery from "../../vendor/jquery-1.10.2.min.js";

export default () => {
  jquery(document).ready(function () {
    let breakpoint = {};
    let lastScrollPos = 0;
    breakpoint.refreshValue = function () {
      this.value = window
        .getComputedStyle(document.querySelector("body"), ":before")
        .getPropertyValue("content")
        .replace(/\"/g, "");
    };
    breakpoint.refreshValue();

    let navWrapper = document.querySelector(".js-refresh-nav");
    jquery(window).scroll(function () {
      if (
        breakpoint.value === "large" ||
        breakpoint.value === "x-large" ||
        breakpoint.value === "xx-large" ||
        breakpoint.value === "xxx-large"
      ) {
        
        let curScrollPos = window.scrollY;

        if(curScrollPos > lastScrollPos ) {
          navWrapper.classList.remove("is-scrolling-up");
        } else {
          navWrapper.classList.add("is-scrolling-up");
        }
        lastScrollPos = curScrollPos;

        if (jquery(window).scrollTop() >= 1) {
          navWrapper.classList.add("sticky-header");
        } else {
          navWrapper.classList.remove("sticky-header");
        }
      }
    });
  });
};
