(function ($) {
    'use strict';
    var airportId = 'KFDK';

    var options = {
        url: function (phrase) {
            return "https://webapp.aopa.org/AirportsAPI/airportsautocomplete/" + phrase;
        },
        getValue: function (element) {
            let toDisplay, airportId;
            let icaoId = $(element).prop("icaoId");
            let faaId = $(element).prop("faaId");
            if (icaoId) {
                airportId = icaoId;
            } else {
                airportId = faaId;
            }
            toDisplay = airportId + ": " + $(element).prop("name") + " - " + $(element).prop("city");
            return toDisplay;
        },
        requestDelay: 200,
        list: {
            onChooseEvent: function () {
                // when a user chooses an element from the list
                var selectedAirport = $("#searchAirport").getSelectedItemData();

                if (selectedAirport) {
                    var faaId = selectedAirport.faaId;
                    var icaoId = selectedAirport.icaoId;

                    if (icaoId) {
                        airportId = icaoId;
                    } else {
                        airportId = faaId;
                    }
                    updateAirportId();
                    $("#airportForm").submit();
                } else {
                    $("#searchAirport").val('');
                }
            },
            maxNumberOfElements: 10,
            sort: {
                enabled: false
            },
            match: {
                // show only the elements that match the query
                enabled: true
            },
            showAnimation: {
                type: "slide", //normal|slide|fade
                time: 200,
                callback: function () { }
            },
            hideAnimation: {
                type: "slide",
                time: 400,
                callback: function () { }
            }
        }

    }

    $('#searchAirport').easyAutocomplete(options);

    function updateAirportId() {
        var airportUrl = 'https://www.aopa.org/destinations/airports/' + airportId + '/details';
        airportUrl = encodeURI(airportUrl);

        var airportform = document.getElementById('airportForm');
        airportform.action = airportUrl;
    }

    $('#searchAirport').focus(function () {
        $("#searchAirport").val('');
    });

    // Atttempted to make the following 2 sections a function, 
    // but for whatever reason could never get the keydown and click events to call the function
    // so ended up giving up and doing a cut and paste, yuck
    // if hit enter but no selection send to first suggestion
    $('#searchAirport').keydown(function (e) {
        if (e.which == 13) {
            // pressed enter key
            if ($("#searchAirport").val().length === 0) {
                // is query is empty take them to destinations search page
                var airportform = document.getElementById('airportForm');
                airportform.method = "get";
                airportform.elements.namedItem("q").value = '';
                airportform.action = 'https://www.aopa.org/destinations/';
                $("#airportForm").submit();
            } else if ($(".easy-autocomplete-container .eac-item").length === 1) {
                // if only 1 airport returned in list, take them to that airport
                $(".easy-autocomplete-container ul li:first .eac-item").click();
            } else {
                // perform a search on the destinations map page using the query
                var airportform = document.getElementById('airportForm');
                airportform.method = "get";
                airportform.elements.namedItem("public").value = '0';
                airportform.elements.namedItem("q").value = $("#searchAirport").val();
                airportform.action = 'https://www.aopa.org/destinations/search';
                $("#airportForm").submit();
            }
        }
    });

    // if click search button but no selection send to first suggestion
    $('#airportSearchButton').click(function (e) {
        if ($("#searchAirport").val().length === 0) {
            // is query is empty take them to destinations search page
            var airportform = document.getElementById('airportForm');
            airportform.method = "get";
            airportform.elements.namedItem("q").value = '';
            airportform.action = 'https://www.aopa.org/destinations/';
            $("#airportForm").submit();
        } else if ($(".easy-autocomplete-container .eac-item").length === 1) {
            // if only 1 airport returned in list, take them to that airport
            $(".easy-autocomplete-container ul li:first .eac-item").click();
        } else {
            // perform a search on the destinations map page using the query
            var airportform = document.getElementById('airportForm');
            airportform.method = "get";
            airportform.elements.namedItem("public").value = '0';
            airportform.elements.namedItem("q").value = $("#searchAirport").val();
            // airportform.action = 'https://www.aopa.org/destinations/search?q=' + $("#searchAirport").val() + '&public=0';
            airportform.action = 'https://www.aopa.org/destinations/search';
            $("#airportForm").submit();
            // window.open(airportform.action, '_blank');
        }
    });

})(jQuery);