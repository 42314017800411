import ReadMore from "./directives/ReadMore";

(function(angular) {
    'use strict';

    let sharedModule = angular.module('aopa.shared', ['ngMaterial', 'angularUtils.directives.dirPagination'])
        .config(config);

    config.$inject = ['$provide', 'paginationTemplateProvider'];

    sharedModule.directive('readMore', ReadMore.directiveFactory);

    function config($provide, paginationTemplateProvider) {
        // Set pagination template
        paginationTemplateProvider.setPath('/js/aopa/shared/templates/paginator.html');

        // add additional functionality to md-autocomplete
        // https://codepen.io/jensbodal/post/extending-an-angular-ddo-directive-definition-object
        $provide.decorator(
            'mdAutocompleteDirective', 
            mdAutoCompleteDirectiveOverride
        );

        mdAutoCompleteDirectiveOverride.$inject = ['$delegate'];

        function mdAutoCompleteDirectiveOverride ($delegate) {
            // grab the directive
            var directive = $delegate[0];

            // need to append to base compile function
            var compile = directive.compile;

            // add our custom attribute to the directive's scope
            angular.extend(directive.scope, {
                menuContainerClass: '@?mdMenuContainerClass'
            });

            // recompile directive and add our class to the virtual container
            directive.compile = function(element, attr) {
                var template = compile.apply(this, arguments);
                var menuContainerClass = attr.mdMenuContainerClass ? attr.mdMenuContainerClass : '';
                var menuContainer = element.find('md-virtual-repeat-container');

                menuContainer.addClass(menuContainerClass);

                // recompile the template
                return function(e, a) {
                    template.apply(this, arguments);
                };
            };

            return $delegate;
        }
    }
})(window.angular);
