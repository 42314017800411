export default function contentWizardController($scope, $http, $window) {

	/* Get questions from server and reorganize them a little for ease of use */
	var iniQuestions = function() {
		$http.get("/api/mycontentwizard/GetUserWizardData").then(function (response) {
				var data = response.data;
				$scope.allData = data;
				$scope.page1 = data.wizardSelections.pilotTypeData ? data.wizardSelections.pilotTypeData : {};
				$scope.page1.id = "page1";
				$scope.page2 = data.wizardSelections.contentSelectionData ? data.wizardSelections.contentSelectionData : {};
				$scope.page2.id = "page2";
				$scope.page3 = data.wizardSelections.topicsPageData ? data.wizardSelections.topicsPageData : {};
				$scope.page3.id = "page3";
				$scope.page4 = data.wizardSelections.videoTopicsPageData ? data.wizardSelections.videoTopicsPageData : {};
				$scope.page4.id = "page4";
				$scope.thanks = data.wizardSelections.lastPageData ? data.wizardSelections.lastPageData : {};
				$scope.thanks.id = "thanks";
				$scope.bookmarks = data.bookmarks; // Is this needed?  Not seeing it in the tickets
				$scope.state = $scope.page1;
		}, function (response) {
				$scope.error = "There was a problem contacting the server.";
		});
	}

	// Send state back to server
	var saveState = function() {
		var postObj = $scope.allData;
		postObj.wizardSelections.pilotTypeData = $scope.page1;
		postObj.wizardSelections.contentSelectionData = $scope.page2;
		postObj.wizardSelections.topicsPageData = $scope.page3;
		postObj.wizardSelections.videoTopicsPageData = $scope.page4;
		postObj.wizardSelections.lastPageData = $scope.thanks;
		$http.post("/api/mycontentwizard/UpdateUserWizardData", postObj).then(
			function (response){
			}, function (response){
			});
	}

	/* Ini */
	$scope.allData = {};
	$scope.page1 = {};
	$scope.page2 = {};
	$scope.page3 = {};
	$scope.page4 = {};
	$scope.thanks = {};
	$scope.selected = {};
	iniQuestions();

	$scope.validateAirportCode = function() {
		if($scope.page2.textBasedQuestions[0].answer != ""){
		$http.get("/api/AirportFinder/GetAirportByCode?includePrivate=true&code=" + $scope.page2.textBasedQuestions[0].answer).then(function (response){
			if(!response.data){
				alert("Airport ID is not valid. Please enter valid airport ID");
			} else {
				$scope.continue('page3');
			}

		}, function (response){
			$scope.error = "Airport code not valid"
		});
		} else{
			$scope.continue('page3');
		}
	}

	$scope.continue = function(next) {
		$scope.state = $scope[next];
		$window.scrollTo(0, 0);
		saveState();
	};

	$scope.resetAll = function(toReset) {
		toReset = toReset.map(function(item) {
			item.isSelected = false;
			return item;
		});
	};
}
