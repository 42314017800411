﻿$(document)
    .ready(function () {
        if ($('div#divAftaFlightInstructorPublicForm').length) {
            var theForm = $('#frmAftaFlightInstructorPublic');


            var autocomplete;

            var initAutocomplete = () => {
                // Create the autocomplete object, restricting the search predictions to
                autocomplete = new google.maps.places.Autocomplete(AddressLookup,
                    {
                        fields: ["address_components"],
                        types: ["address"],
                    });
                autocomplete.setComponentRestrictions({
                    country: ["us"]
                });
                // When the user selects an address from the drop-down, populate the
                // address fields in the form.
                autocomplete.addListener("place_changed", fillInAddress);
            };

            initAutocomplete();


            function fillInAddress() {
                // Get the place details from the autocomplete object.
                var place = autocomplete.getPlace();
                var address1 = "";
                var postcode = "";

                $("#MailingStreet").val("");
                $("#MailingCity").val("");
                $("#MailingStateProvince").val("");
                $("#MailingPostalCode").val("");

                // Get each component of the address from the place details,
                // and then fill-in the corresponding field on the form.
                // place.address_components are google.maps.GeocoderAddressComponent objects
                // which are documented at http://goo.gle/3l5i5Mr
                place.address_components.forEach((component) => {
                    var componentType = component.types[0];

                    switch (componentType) {
                        case "street_number":
                            {
                                address1 = `${component.long_name} ${address1}`;
                                break;
                            }

                        case "route":
                            {
                                address1 += component.short_name;
                                break;
                            }

                        case "postal_code":
                            {
                                postcode = `${component.long_name}${postcode}`;
                                break;
                            }

                        case "postal_code_suffix":
                            {
                                postcode = `${postcode}-${component.long_name}`;
                                break;
                            }
                        case "locality":
                            $("#MailingCity").val(component.long_name);
                            clearValidation("MailingCity");
                            break;
                        case "administrative_area_level_1":
                            {
                                $("#MailingStateProvince").val(component.short_name);
                                clearValidation("MailingStateProvince");
                                break;
                            }
                    }
                });

                $("#MailingStreet").val(address1);
                clearValidation("MailingStreet");
                $("#MailingPostalCode").val(postcode);
                clearValidation("MailingPostalCode");
                $("#MailingStreet").focus();
            };

            var clearValidation = (field) => {
                var theField = $('#' + field);
                if (theField.val()) {
                    theField.removeClass("input-validation-error").addClass("valid");
                    $("span[data-valmsg-for='" + field + "']").removeClass("field-validation-error").addClass("field-validation-valid");
                    $("span[data-valmsg-for='" + field + "']").empty();
                }
            };
            

            $('#DateOfBirth').datepicker({

                changeMonth: true,
                changeYear: true,
                yearRange: (new Date).getFullYear() - 110 + ':' + (new Date).getFullYear()
            });


            $(".submitbuttonaftaflightinstructor").click(function () {
                if (theForm.valid()) {
                    $('#btnEnroll').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                } else {
                    var firstInstance = $('.input-validation-error').first();
                    var offsetTop = firstInstance.offset().top - 100;

                    $('html, body').animate({
                        scrollTop: offsetTop
                    }, 500);
                }
            });

            $(".submitbuttonaftafip").click(function () {
                if (theForm.valid()) {
                    $('#btnSubmitSchool').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                } else {
                    var firstInstance = $('.input-validation-error').first();
                    var offsetTop = firstInstance.offset().top - 100;

                    $('html, body').animate({
                        scrollTop: offsetTop
                    }, 500);
                }
            });
        }
    });