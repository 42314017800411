;(function ($) {

    "use strict";

    $(document).ready(function () {
        
        if ( $( ".statenetContainer" ).length ) {

            $.ajax({
                type: "GET",
                url: "/landing%20pages/statenet/lexisnexis.xml",
                dataType: "xml"
            })

            .done(function (xml) {

                var stateResults = [],
                    messageStr = "",
                    resultCount = 0;

                $(xml).find('bill_profile').each(function () {

                    if($(this).find('state').text() == state) {

                        stateResults.push({
                            bill_number: $(this).find('bill_number').text(),
                            legtype: $(this).find('legtype').text(),
                            bill_name: $(this).find('bill_title').text(),
                            author_text: $(this).find('authors author name').text(),
                            intro_date: $(this).find('intro_date').text(),
                            summary: $(this).find('bill_summary').text(),
                            aopa_pos: $(this).find('heading:contains("AOPA_Position")').next("tag").text(),
                        });

                    }

                });

                if(resultCount = stateResults.length) {

                    messageStr = 'There is currently ' + resultCount + ' bill affecting your state.';
                    if(resultCount > 1) {
                        messageStr = messageStr.replace("is","are").replace("bill","bills");
                    } 

                    $(stateResults).each( function() {

                        $('<div class="bill"></div>')
                        .html('<div class="billId">' + state + $( this )[0].legtype + $( this )[0].bill_number + '</div><h1 class="bill_name">' + $( this )[0].bill_name + '</h1><div class="details"><div><span>Introduced:</span>' + $( this )[0].intro_date + '</div><div><span>Author:</span>' + $( this )[0].author_text + '</div><div><span>AOPA Position:</span>' + $( this )[0].aopa_pos + '</div></div><div class="summary">' + $( this )[0].summary + '</div>')
                        .appendTo('#update-target');
                    });

                } else {

                    messageStr = 'Currently, there is no bill activity affecting your state.';

                }

                $('<p></p>').html(messageStr).prependTo('#update-target');

            })


            .fail(function () {
                $('<p></p>').html('There was a problem loading the data feed.').prependTo('#update-target');
            });

        }
        
    });

}(jQuery));