﻿$(document).ready(function () {
    if ($('div#divAccountContactInfo').length) {

        // 'edit' section click handler
        $(document).on("click", ".my_account_content a.edit", function (e) {
            e.preventDefault();
            e.stopPropagation();

            var $theButton = $(this);
            var $theSection = $theButton.parents("section");
            var $theForm = $theSection.children("form");

            if ($theForm[0].id.startsWith("fmPersonalInfo")) {
                editPersonal($theForm);
            }

            if ($('#formOpen').val() === "false") {

                // set the section to be editable
                $theSection.addClass("wip");
                $('#formOpen').val($theForm[0].id);
                window.onbeforeunload = confirmOnPageExit;
            } else {
                alert('Another form is already open.\n\nPlease Save or Cancel before proceeding.');
            }
        });


        // 'save' section click handler
        $(document).on("click", ".my_account_content a.save", function (e) {
            e.preventDefault();
            e.stopPropagation();

            var $theButton = $(this);
            var $theSection = $theButton.parents("section");
            var $theForm = $theSection.children("form");

            if ($theForm.valid()) {
                if ($theForm[0].id.startsWith("fmPersonalInfo")) {
                    savePersonal($theForm, $theSection);
                } 
            }
        });

        // 'cancel' section click handler
        $(document).on("click", ".my_account_content a.cancel", function (e) {
            e.preventDefault();
            e.stopPropagation();

            var $theButton = $(this);
            var $theSection = $theButton.parents("section");
            var $theForm = $theSection.children("form");

            if ($theForm[0].id.startsWith("fmPersonalInfo")) {
                cancelPersonal($theForm);
            }

            var validator = $theForm.validate();
            validator.hideErrors();
            validator.resetForm();
            validator.reset();

            //reset unobtrusive field level, if it exists
            $theForm.find("[data-valmsg-replace]")
                .removeClass("input-validation-error")
                .removeClass("field-validation-error")
                .addClass("field-validation-valid")
                .empty();

            // set the section to be non-editable
            $theSection.removeClass("wip");

            $('#formOpen').val("false");
            window.onbeforeunload = null;
        });


        // 'delete' section click handler
        $(document).on("click", ".my_account_content a.delete", function (e) {
            e.preventDefault();
            e.stopPropagation();

            var $theButton = $(this);
            var $theSection = $theButton.parents("section");
            var $theForm = $theSection.children("form");

            if ($('#formOpen').val() === "false") {
            } else {
                alert('Another form is already open.\n\nPlease Save or Cancel before proceeding.');
            }
        });

        function cancelPersonal(theForm) {
            $("#divPersonalInfoConfirm").hide();
            $("#divPersonalInfoAlert").hide();
            $("#" + theForm[0].id + " #SelectedPrefix").prop('disabled', true);
            $("#" + theForm[0].id + " #SelectedSuffix").prop('disabled', true);
            $("#" + theForm[0].id + " #SelectedGender").prop('disabled', true);
            $("#" + theForm[0].id + " #SelectedPreferredCommunication").prop('disabled', true);
            $("#" + theForm[0].id + " #SelectedMailingAddressType").prop('disabled', true);
            $("#" + theForm[0].id + " #SelectedMailingAddressType").removeClass('input-validation-error');
            $("#" + theForm[0].id + " #PreferredEmailSelected").prop('disabled', true);
            $("#" + theForm[0].id + " #PreferredEmailSelected").removeClass('input-validation-error');
            $("#" + theForm[0].id + " #PreferredPhoneSelected").prop('disabled', true);
            $("#" + theForm[0].id + " #AllowEmailFlag").prop('disabled', true);
            $("#" + theForm[0].id + " #AllowLabelSalesFlag").prop('disabled', true);
            $("#" + theForm[0].id + " #AllowPhoneFlag").prop('disabled', true);
            resetFields(theForm);
        }

        function editPersonal(theForm) {
            $("#divPersonalInfoConfirm").hide();
            $("#divPersonalInfoAlert").hide();
            $("#" + theForm[0].id + " #SelectedPrefix").prop('disabled', false);
            $("#" + theForm[0].id + " #SelectedSuffix").prop('disabled', false);
            $("#" + theForm[0].id + " #SelectedGender").prop('disabled', false);
            $("#" + theForm[0].id + " #SelectedPreferredCommunication").prop('disabled', false);
            $("#" + theForm[0].id + " #SelectedMailingAddressType").prop('disabled', false);
            $("#" + theForm[0].id + " #PreferredEmailSelected").prop('disabled', false);
            $("#" + theForm[0].id + " #PreferredPhoneSelected").prop('disabled', false);
            $("#" + theForm[0].id + " #AllowEmailFlag").prop('disabled', false);
            $("#" + theForm[0].id + " #AllowLabelSalesFlag").prop('disabled', false);
            $("#" + theForm[0].id + " #AllowPhoneFlag").prop('disabled', false);
        }

        function savePersonal(theForm, theSection) {
            $("#divPersonalInfoConfirm").hide();
            $("#divPersonalInfoAlert").hide();

            theSection.addClass("account-spinner");

            $.post('/api/ContactPersonal/ProcessPersonal',
                {
                    "FormData": $("#FormData").val(),
                    "Action": "U",
                    "Prefix": $("#" + theForm[0].id + " #SelectedPrefix").val(),
                    "FirstName": $("#" + theForm[0].id + " #FirstName").val(),
                    "MiddleName": $("#" + theForm[0].id + " #MiddleName").val(),
                    "LastName": $("#" + theForm[0].id + " #LastName").val(),
                    "Suffix": $("#" + theForm[0].id + " #SelectedSuffix").val(),
                    "NickName": $("#" + theForm[0].id + " #NickName").val(),
                    "Gender": $("#" + theForm[0].id + " #SelectedGender").val(),
                    "BirthDate": $("#" + theForm[0].id + " #BirthDate").val(),
                    "AllowEmail": $("#" + theForm[0].id + " #AllowEmailFlag")[0].checked,
                    "AllowPostal": $("#" + theForm[0].id + " #AllowLabelSalesFlag")[0].checked,
                    "AllowPhone": $("#" + theForm[0].id + " #AllowPhoneFlag")[0].checked,
                    "PreferredComm": $("#" + theForm[0].id + " #SelectedPreferredCommunication").val(),
                    "MailingAddressType": $("#" + theForm[0].id + " #SelectedMailingAddressType").val(),
                    "MailingStreet": $("#" + theForm[0].id + " #MailingStreet").val(),
                    "MailingCity": $("#" + theForm[0].id + " #MailingCity").val(),
                    "MailingStateProvince": $("#" + theForm[0].id + " #MailingStateProvince").val(),
                    "MailingPostalCode": $("#" + theForm[0].id + " #MailingPostalCode").val(),
                    "MailingCountry": $("#" + theForm[0].id + " #MailingCountry").val(),
                    "HomeEmail": $("#" + theForm[0].id + " #HomeEmail").val(),
                    "WorkEmail": $("#" + theForm[0].id + " #WorkEmail").val(),
                    "AlternateEmail": $("#" + theForm[0].id + " #AlternateEmail").val(),
                    "PreferredEmail": $("#" + theForm[0].id + " #PreferredEmailSelected").val(),
                    "HomePhone": $("#" + theForm[0].id + " #HomePhone").val(),
                    "MobilePhone": $("#" + theForm[0].id + " #MobilePhone").val(),
                    "WorkPhone": $("#" + theForm[0].id + " #WorkPhone").val(),
                    "OtherPhone": $("#" + theForm[0].id + " #OtherPhone").val(),
                    "PrimaryPhoneLocationCode": $("#" + theForm[0].id + " #PreferredPhoneSelected").val()
                },
                function () { }
            )
                .done(function (data) {
                    if (data.personalSuccess) {
                        $("#divPersonalInfoConfirm").show();

                        updateFields(theForm);

                        theSection.removeClass("wip");

                        $('#formOpen').val("false");
                        window.onbeforeunload = null;
                    } else {
                        $("#divPersonalInfoAlert").show();
                    }
                    $("#" + theForm[0].id + " #SelectedPrefix").prop('disabled', true);
                    $("#" + theForm[0].id + " #SelectedSuffix").prop('disabled', true);
                    $("#" + theForm[0].id + " #SelectedGender").prop('disabled', true);
                    $("#" + theForm[0].id + " #SelectedPreferredCommunication").prop('disabled', true);
                    $("#" + theForm[0].id + " #SelectedMailingAddressType").prop('disabled', true);
                    $("#" + theForm[0].id + " #AllowEmailFlag").prop('disabled', true);
                    $("#" + theForm[0].id + " #AllowLabelSalesFlag").prop('disabled', true);
                    $("#" + theForm[0].id + " #AllowPhoneFlag").prop('disabled', true);
                    $("#" + theForm[0].id + " #PreferredEmailSelected").prop('disabled', true);
                    $("#" + theForm[0].id + " #PreferredPhoneSelected").prop('disabled', true);
                    theSection.removeClass("account-spinner");
                })
                .fail(function (jqXHR, textStatus, err) {
                    $("#divPersonalInfoAlert").show();
                    theSection.removeClass("account-spinner");
                });
        }

        function fieldIsEmpty(theField, theParent) {

            var cont = theField.parents('.' + theParent);

            if (theField.val().trim().length) {
                cont[0].classList.remove("afEmpty");
            } else {
                cont[0].classList.add("afEmpty");
            }
        }

        function resetFields(theForm) {
            theForm.find('input,select,textarea').not("input[type='hidden']").each(function () {
                if (this.type === "text" || this.type === "textarea" || this.type === "select-one") {
                    var elem = theForm[0].elements['init' + this.id];

                    $("#" + theForm[0].id + " #" + this.id).val($("#" + theForm[0].id + " #" + elem.id).val());
                } else if (this.type === "checkbox") {
                    this.checked = ($('#' + theForm[0].id + ' #init' + this.id).val() === "true");
                }
            });
        }

        function updateFields(theForm) {
            theForm.find('input,select').not("input[type='hidden']").each(function () {
                if (this.type === "text" || this.type === "select-one") {
                    var elem = theForm[0].elements['init' + this.id];

                    $("#" + theForm[0].id + " #" + elem.id).val($("#" + theForm[0].id + " #" + this.id).val());
                } else if (this.type === "checkbox") {
                    $('#' + theForm[0].id + ' #init' + this.id).val(this.checked);
                }
            });
        }

        function updateField(theForm, theField) {
            if (theField.type === "text" || this.type === "select-one") {
                $("#" + theForm[0].id + " #init" + theField).val($("#" + theForm[0].id + " #" + theField).val());
            } else if (theField.type === "checkbox") {
                $("#" + theForm[0].id + " #init" + theField).val(theField.checked);
            }
        }

        var confirmOnPageExit = function (e) {
            // If we haven't been passed the event get the window.event
            e = e || window.event;

            var message = 'A form is open. Please Save or Cancel before proceeding.';

            // For IE6-8 and Firefox prior to version 4
            if (e) {
                e.returnValue = message;
            }

            // For Chrome, Safari, IE8+ and Opera 12+
            return message;
        };
    }
});
