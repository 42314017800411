﻿/**
 * AOPA Weather Service proxy.
 */
class WeatherService {
    constructor ($http, $q) {
        this.baseUrl = "https://www.aopa.org/webservices/wx/v1/index.cfm";
        this.$http = $http;
        this.$q = $q;
        this.initWxMappings();
    }

    /**
     * Returns the METAR weather report for the given airport identifier.
     * @param {String} ident Airport identifier
     * @returns {Object} Object containing latest METAR weather data for the given airport.
     */
    getMetarByIdent (ident) {
        return this.$q((resolve, reject) => {
            if (!ident) {
                reject({
                    error: 'MISSING_LOCATION',
                    message: "Could not retrieve weather (missing airport identifier)."
                });
                return;
            }
            this.$http.get(this.baseUrl + `/metar?body={"identifier":"${ident}"}`)
                .then(response => resolve(this.parseMetar(response.data)))
                .catch(response => reject(this.handleError(response, {ident})));
        });
    }

    /**
     * Returns the METAR weather report for the nearest airport based on location.
     * @param {Double} lat Latitude
     * @param {Double} lon Longitude
     * @returns {Object} Object containing latest METAR weather data for the nearest
     * airport.
     */
    getMetarByLatLon (lat, lon) {
        return this.$q((resolve, reject) => {
            if (!lat || !lon) {
                reject({
                    error: 'MISSING_LOCATION',
                    message: "Could not retrieve weather (missing location)."
                });
                return;
            }
            this.$http.get(this.baseUrl + `/metar?body={"latitude":${lat},"longitude":${lon}}`)
                .then(response => resolve(this.parseMetar(response.data)))
                .catch(response => reject(this.handleError(response, {lat, lon})));
        });
    }

    handleError (response, { ident=null, lat=null, lon=null } = {}) {
        switch (response.status) {
            case 404:
                let message;
                if (ident !== null) {
                    message = `Could not retrieve weather for identifier ${ident}. The identifier may be invalid, or the station does not report weather.`;
                } else if (lat !== null && lon !== null) {
                    message = `Could not retrieve weather based on geographic position (latitude: ${lat}, longitude: ${lon}). The service returned "Not Found".`;
                } else {
                    message = "Could not retrieve weather for the specified location.";
                }
                return {
                    error: 'INVALID_LOCATION',
                    message
                };
        }

        return {
            error: 'UNKNOWN',
            message: "An error occurred retrieving the weather. Please try again later."
        };
    }

    parseMetar (d) {
        return {
            ident: d.icao_code,
            name: d.name,
            city: d.city,
            state: d.state,
            latitude: d.latitude,
            longitude: d.longitude,
            elevation: d.elevation_m * 3.28084,
            observation_time: new Date(d.observation_time),
            raw_text: d.raw_text,
            flight_category: d.flight_category,
            visibility: d.visibility_statute_mi,
            ceiling: this.getCeiling(d.sky_conditions_trans),
            wind_speed: d.wind_speed_kt,
            wind_gust: d.wind_gust_kt,
            wind_dir: d.wind_dir_degrees,
            temp_c: d.temp_c,
            temp_f: d.temp_f,
            dewpoint_c: d.dewpoint_c,
            dewpoint_f: d.dewpoint_f,
            wx_string: d.wx_string,
            wx_string_trans: d.wx_string_trans,
            sky_conditions_trans: d.sky_conditions_trans
        };
    }

    /**
     * Returns the AGL altitude of the lowest broken or overcast cloud layer based on the given
     * sky conditions array.
     * @param skc Sky condition array as returned by the AOPA weather service API.
     * @returns {Number|null} Ceiling altitude in feet AGL, or null.
     */
    getCeiling (skc) {
        if (!skc || skc.length < 1) {
            return null;
        }

        // If vertical visibility is reported, use the vertical visibility value as the ceiling
        let vv = skc.find(layer => layer.sky_cover === 'Vertical Visibility');
        if (vv) {
            return parseInt(vv.cloud_base);
        }

        // Extract the altitudes of all broken and overcast layers
        let layers = skc
            .filter(layer => (layer.sky_cover === 'Overcast' || layer.sky_cover === 'Broken clouds'))
            .map(layer => parseInt(layer.cloud_base));
        if (layers.length < 1) {
            return null;
        }

        // Return minimum altitude of broken/overcast layers
        return Math.min(...layers);
    }

    /**
     * Returns highest priority weather type (icon/text) based on raw weather string
     * @param {String} wx_string Raw weather string (e.g., "TSRA BR")
     * @returns {Object} Object containing the icon and tooltip text that should be displayed
     * in the widget. The icon and text should correspond to the highest priority weather
     * condition present in the weather string (i.e., thunderstorm should take priority over
     * mist).
     */
    getWxType (wx_string) {
        if (!wx_string) {
            return null;
        }

        return this.getWxTypes(wx_string)[0] || null;
    }

    /**
     * Returns a textual description of all weather phenomena present based on raw
     * weather string. Each weather phenomenon will appear in order of descending priority and
     * will be separated by a comma.
     * @param {String} wx_string Raw weather string (e.g., "+TSRA BR")
     * @returns {String} Textual weather description (e.g., "Thunderstorm / Heavy rain, Mist")
     */
    translateWx (wx_string) {
        let types = this.getWxTypes(wx_string);
        if (types && types.length > 0) {
            return types.map(t => t.text).join(', ');
        }

        return null;
    }

    /**
     * Returns a textual description of all cloud layers, in order of ascending altitude.
     * @param skc Sky condition array as returned by the AOPA weather service API.
     * @returns {String} Textual description of cloud layers
     */
    getCloudLayersText (skc) {
        if (skc && skc.length >= 1) {
            let layers = skc.map(layer => {
                let text = layer.sky_cover;
                if (layer.cloud_base && layer.cloud_base !== 'None') {
                    text += ' ' + layer.cloud_base;
                }
                return {
                    alt: parseInt(layer.cloud_base),
                    text
                };
            });

            return layers
                .sort((a, b) => a.alt - b.alt)
                .map(layer => layer.text)
                .join(', ');
        }

        return null;
    }

    /**
     * Returns all matched weather type items (icon, text description, priority) based on raw
     * weather string, in order of priority.
     * @param {String} wx_string Raw weather string (e.g., "TSRA BR")
     * @returns {Object} Array of objects, each containing the icon and tooltip text associated
     * with that weather phenomenon.
     */
    getWxTypes (wx_string) {
        if (!wx_string) {
            return [];
        }

        let elems = wx_string.split(" ");

        return this.wxMappings
            .filter(t => {
                // See if entire wx_string matches one of the keys exactly (e.g., "RA SN" should
                // match the item with priority 25).
                if (t.keys.indexOf(wx_string) >= 0) {
                    return true;
                };

                // Otherwise, split on space and see if any of the individual elements (e.g., "RA"
                // or "SN") match one of the keys.
                if (elems.some(elem => t.keys.indexOf(elem) >= 0)) {
                    return true;
                }

                return false;
            })
            .sort((a, b) => a.priority - b.priority);
    }

    initWxMappings () {
        // Initializes the mapping from METAR weather codes (e.g., "RA") to the associated icon
        // from the weather icon font. Also stores the priority for each weather condition so we
        // can determine which icon should be displayed in the event multiple weather conditions
        // are present (more severe weather types have higher priority).
        //
        // Priorities are derived from the ADDS document titled "Present Weather (METAR
        // text-to-symbol matching)", which can be located here:
        // https://aviationweather.gov/static/adds/docs/metars/wxSymbols_anno2.pdf
        //
        // Note: We are only using the above document for how they define priorities of various
        // weather phenomena, and not for the specific icons themselves.
        
        this.wxMappings = [{
            priority: 1,
            keys: ['FC', '+FC'],
            text: 'Funnel cloud',
            icon: 'wi-tornado'
        }, {
            priority: 2,
            keys: ['SQ'],
            text: 'Squall',
            icon: 'wi-strong-wind'
        }, {
            priority: 3,
            keys: ['FU'],
            text: 'Smoke',
            icon: 'wi-smoke'
        }, {
            priority: 3,
            keys: ['VA'],
            text: 'Volcanic ash',
            icon: 'wi-volcano'
        }, {
            priority: 5,
            keys: ['+TSGS', '+TSGR'],
            text: 'Thunderstorm / Heavy hail',
            icon: 'wi-thunderstorm'
        }, {
            priority: 6,
            keys: ['+TSRA'],
            text: 'Thunderstorm / Heavy rain',
            icon: 'wi-thunderstorm'
        }, {
            priority: 6,
            keys: ['+TSSN'],
            text: 'Thunderstorm / Heavy snow',
            icon: 'wi-thunderstorm'
        }, {
            priority: 6,
            keys: ['+TSPL'],
            text: 'Thunderstorm / Ice pellets',
            icon: 'wi-thunderstorm'
        }, {
            priority: 7,
            keys: ['TSGR', 'TSGS'],
            text: 'Thunderstorm / Hail',
            icon: 'wi-thunderstorm'
        }, {
            priority: 8,
            keys: ['TSRA'],
            text: 'Thunderstorm / Rain',
            icon: 'wi-thunderstorm'
        }, {
            priority: 8,
            keys: ['TSSN'],
            text: 'Thunderstorm / Snow',
            icon: 'wi-storm-showers'
        }, {
            priority: 8,
            keys: ['TSPL'],
            text: 'Thunderstorm / Ice pellets',
            icon: 'wi-storm-showers'
        }, {
            priority: 9,
            keys: ['PO', 'VCPO'],
            text: 'Dust/sand whirls',
            icon: 'wi-dust'
        }, {
            priority: 10,
            keys: ['+SS'],
            text: 'Heavy sandstorm',
            icon: 'wi-sandstorm'
        }, {
            priority: 10,
            keys: ['+DS'],
            text: 'Heavy dust storm',
            icon: 'wi-dust'
        }, {
            priority: 11,
            keys: ['SS'],
            text: 'Sandstorm',
            icon: 'wi-sandstorm'
        }, {
            priority: 11,
            keys: ['DS'],
            text: 'Dust storm',
            icon: 'wi-dust'
        }, {
            priority: 11,
            keys: ['DRSA'],
            text: 'Drifting sand',
            icon: 'wi-sandstorm'
        }, {
            priority: 11,
            keys: ['DRDU'],
            text: 'Drifting dust',
            icon: 'wi-dust'
        }, {
            priority: 12,
            keys: ['VCSS'],
            text: 'Sandstorm in vicinity',
            icon: 'wi-sandstorm'
        }, {
            priority: 12,
            keys: ['VCDS'],
            text: 'Dust storm in vicinity',
            icon: 'wi-dust'
        }, {
            priority: 13,
            keys: ['SA'],
            text: 'Sand',
            icon: 'wi-sandstorm'
        }, {
            priority: 13,
            keys: ['BLSA'],
            text: 'Blowing sand',
            icon: 'wi-sandstorm'
        }, {
            priority: 13,
            keys: ['VCBLSA'],
            text: 'Blowing sand in vicinity',
            icon: 'wi-sandstorm'
        }, {
            priority: 13,
            keys: ['BLDU'],
            text: 'Blowing dust',
            icon: 'wi-dust'
        }, {
            priority: 13,
            keys: ['VCBLDU'],
            text: 'Blowing dust in vicinity',
            icon: 'wi-dust'
        }, {
            priority: 13,
            keys: ['BLPY'],
            text: 'Blowing spray',
            icon: 'wi-dust'
        }, {
            priority: 14,
            keys: ['DU'],
            text: 'Dust',
            icon: 'wi-dust'
        }, {
            priority: 15,
            keys: ['GR', 'SHGR', '+GR', '+SHGR'],
            text: 'Hail',
            icon: 'wi-hail'
        }, {
            priority: 16,
            keys: ['GS', 'SHGS', '+GS', '+SHGS'],
            text: 'Hail / Snow pellets',
            icon: 'wi-hail'
        }, {
            priority: 17,
            keys: ['-GR', '-SHGR'],
            text: 'Light hail',
            icon: 'wi-hail'
        }, {
            priority: 18,
            keys: ['-GS', '-SHGS'],
            text: 'Light hail / Snow pellets',
            icon: 'wi-hail'
        }, {
            priority: 19,
            keys: ['SG'],
            text: 'Snow grains',
            icon: 'wi-snow'
        }, {
            priority: 20,
            keys: ['PL'],
            text: 'Ice pellets',
            icon: 'wi-sleet'
        }, {
            priority: 20,
            keys: ['PL', 'PE', 'SHPL', 'SHPE'],
            text: 'Ice pellets',
            icon: 'wi-sleet'
        }, {
            priority: 21,
            keys: ['FZDZ', '+FZDZ'],
            text: 'Freezing drizzle',
            icon: 'wi-sleet'
        }, {
            priority: 22,
            keys: ['-FZDZ'],
            text: 'Light freezing drizzle',
            icon: 'wi-sleet'
        }, {
            priority: 23,
            keys: ['FZRA', '+FZRA'],
            text: 'Freezing rain',
            icon: 'wi-sleet'
        }, {
            priority: 24,
            keys: ['-FZRA'],
            text: 'Light freezing rain',
            icon: 'wi-sleet'
        }, {
            priority: 25,
            keys: ['RA SN'],
            text: 'Rain / Snow',
            icon: 'wi-sleet'
        }, {
            priority: 25,
            keys: ['+RA SN'],
            text: 'Heavy rain / Snow',
            icon: 'wi-sleet'
        }, {
            priority: 25,
            keys: ['RA +SN'],
            text: 'Rain / Heavy snow',
            icon: 'wi-sleet'
        }, {
            priority: 25,
            keys: ['+RA +SN'],
            text: 'Heavy rain / Heavy snow',
            icon: 'wi-sleet'
        }, {
            priority: 25,
            keys: ['DZ SN'],
            text: 'Drizze / Snow',
            icon: 'wi-sleet'
        }, {
            priority: 25,
            keys: ['+DZ SN'],
            text: 'Heavy drizzle / Snow',
            icon: 'wi-sleet'
        }, {
            priority: 25,
            keys: ['DZ +SN'],
            text: 'Drizzle / Heavy snow',
            icon: 'wi-sleet'
        }, {
            priority: 25,
            keys: ['+DZ +SN'],
            text: 'Heavy drizzle / Snow',
            icon: 'wi-sleet'
        }, {
            priority: 26,
            keys: ['-RA -SN'],
            text: 'Light rain / Light snow',
            icon: 'wi-sleet'
        }, {
            priority: 26,
            keys: ['-RA SN'],
            text: 'Light rain / Snow',
            icon: 'wi-sleet'
        }, {
            priority: 26,
            keys: ['-DZ -SN'],
            text: 'Light drizzle / Light snow',
            icon: 'wi-sleet'
        }, {
            priority: 26,
            keys: ['-DZ SN'],
            text: 'Light drizzle / Snow',
            icon: 'wi-sleet'
        }, {
            priority: 27,
            keys: ['SHRA SN'],
            text: 'Rain showers / Snow',
            icon: 'wi-rain-mix'
        }, {
            priority: 27,
            keys: ['SHSN RA'],
            text: 'Snow showers / Rain',
            icon: 'wi-rain-mix'
        }, {
            priority: 27,
            keys: ['+SHRA SN'],
            text: 'Heavy rain showers / Snow',
            icon: 'wi-rain-mix'
        }, {
            priority: 27,
            keys: ['+SHSN RA'],
            text: 'Heavy snow showers / Rain',
            icon: 'wi-rain-mix'
        }, {
            priority: 28,
            keys: ['-SHRA SN'],
            text: 'Light rain showers / Snow',
            icon: 'wi-rain-mix'
        }, {
            priority: 28,
            keys: ['-SHSN RA'],
            text: 'Light snow showers / Rain',
            icon: 'wi-rain-mix'
        }, {
            priority: 28,
            keys: ['-SHRA -SN'],
            text: 'Light rain showers / Light snow',
            icon: 'wi-rain-mix'
        }, {
            priority: 28,
            keys: ['-SHSN -RA'],
            text: 'Light snow showers / Light rain',
            icon: 'wi-rain-mix'
        }, {
            priority: 29,
            keys: ['SHSN'],
            text: 'Snow showers',
            icon: 'wi-snow'
        }, {
            priority: 29,
            keys: ['+SHSN'],
            text: 'Heavy snow showers',
            icon: 'wi-snow'
        }, {
            priority: 30,
            keys: ['-SHSN'],
            text: 'Light snow showers',
            icon: 'wi-snow'
        }, {
            priority: 31,
            keys: ['IC'],
            text: 'Ice crystals',
            icon: 'wi-snowflake-cold'
        }, {
            priority: 32,
            keys: ['UP'],
            text: 'Unknown precipitation',
            icon: 'wi-sprinkle'
        }, {
            priority: 33,
            keys: ['TS'],
            text: 'Thunderstorm',
            icon: 'wi-thunderstorm'
        }, {
            priority: 34,
            keys: ['DZ RA'],
            text: 'Drizzle / Rain',
            icon: 'wi-rain-mix'
        }, {
            priority: 34,
            keys: ['+DZ RA'],
            text: 'Heavy drizzle / Rain',
            icon: 'wi-rain-mix'
        }, {
            priority: 34,
            keys: ['DZ +RA'],
            text: 'Drizzle / Heavy rain',
            icon: 'wi-rain-mix'
        }, {
            priority: 34,
            keys: ['+DZ +RA'],
            text: 'Heavy drizzle / Heavy rain',
            icon: 'wi-rain-mix'
        }, {
            priority: 35,
            keys: ['DZ -RA'],
            text: 'Drizzle / Light rain',
            icon: 'wi-rain-mix'
        }, {
            priority: 35,
            keys: ['-DZ RA'],
            text: 'Light drizzle / Rain',
            icon: 'wi-rain-mix'
        }, {
            priority: 35,
            keys: ['-DZ -RA'],
            text: 'Light drizzle / Light rain',
            icon: 'wi-rain-mix'
        }, {
            priority: 36,
            keys: ['+SN'],
            text: 'Heavy snow',
            icon: 'wi-snow'
        }, {
            priority: 37,
            keys: ['+RA'],
            text: 'Heavy rain',
            icon: 'wi-rain'
        }, {
            priority: 38,
            keys: ['SH'],
            text: 'Showers',
            icon: 'wi-showers'
        }, {
            priority: 38,
            keys: ['+SH'],
            text: 'Heavy showers',
            icon: 'wi-showers'
        }, {
            priority: 38,
            keys: ['SHRA'],
            text: 'Rain showers',
            icon: 'wi-showers'
        }, {
            priority: 38,
            keys: ['+SHRA'],
            text: 'Heavy rain showers',
            icon: 'wi-showers'
        }, {
            priority: 39,
            keys: ['+DZ'],
            text: 'Heavy drizzle',
            icon: 'wi-sleet'
        }, {
            priority: 40,
            keys: ['SN'],
            text: 'Snow',
            icon: 'wi-snow'
        }, {
            priority: 41,
            keys: ['RA'],
            text: 'Rain',
            icon: 'wi-rain'
        }, {
            priority: 42,
            keys: ['DZ'],
            text: 'Drizzle',
            icon: 'wi-sprinkle'
        }, {
            priority: 43,
            keys: ['-SN'],
            text: 'Light snow',
            icon: 'wi-snow'
        }, {
            priority: 44,
            keys: ['-RA'],
            text: 'Light rain',
            icon: 'wi-showers'
        }, {
            priority: 45,
            keys: ['-SH'],
            text: 'Light showers',
            icon: 'wi-showers'
        }, {
            priority: 45,
            keys: ['-SHRA'],
            text: 'Light rain showers',
            icon: 'wi-showers'
        }, {
            priority: 46,
            keys: ['-DZ'],
            text: 'Light drizzle',
            icon: 'wi-sprinkle'
        }, {
            priority: 47,
            keys: ['DRSN'],
            text: 'Drifting snow',
            icon: 'wi-sandstorm'
        }, {
            priority: 48,
            keys: ['BLSN'],
            text: 'Blowing snow',
            icon: 'wi-sandstorm'
        }, {
            priority: 48,
            keys: ['VCBLSN'],
            text: 'Blowing snow in vicinity',
            icon: 'wi-sandstorm'
        }, {
            priority: 49,
            keys: ['FZFG'],
            text: 'Freezing fog',
            icon: 'wi-fog'
        }, {
            priority: 50,
            keys: ['VCTS'],
            text: 'Thunderstorm in vicinity',
            icon: 'wi-thunderstorm'
        }, {
            priority: 51,
            keys: ['VCSH'],
            text: 'Showers in vicinity',
            icon: 'wi-showers'
        }, {
            priority: 52,
            keys: ['FG'],
            text: 'Fog',
            icon: 'wi-fog'
        }, {
            priority: 53,
            keys: ['PRFG'],
            text: 'Partial fog',
            icon: 'wi-fog'
        }, {
            priority: 54,
            keys: ['BCFG'],
            text: 'Patchy fog',
            icon: 'wi-fog'
        }, {
            priority: 55,
            keys: ['VCFG'],
            text: 'Fog in vicinity',
            icon: 'wi-fog'
        }, {
            priority: 56,
            keys: ['MIFG'],
            text: 'Shallow fog',
            icon: 'wi-fog'
        }, {
            priority: 57,
            keys: ['VIRGA'],
            text: 'Virga',
            icon: 'wi-rain-wind'
        }, {
            priority: 58,
            keys: ['BR'],
            text: 'Mist',
            icon: 'wi-fog'
        }, {
            priority: 59,
            keys: ['HZ'],
            text: 'Haze',
            icon: 'wi-fog'
        }];
    }
}

WeatherService.$inject = ['$http', '$q'];

export default WeatherService;
