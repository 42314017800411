﻿import WeatherService from "./services/WeatherService";
import WeatherWidget from "./directives/WeatherWidget";

(function (angular) {
    'use strict';

    let weatherApp = angular.module('AopaWeatherWidget', ['ngMaterial']);

    weatherApp.service('weatherService', WeatherService);
    weatherApp.directive('weatherWidget', WeatherWidget.directiveFactory);
})(window.angular);
