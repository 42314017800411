﻿/*
Custom script for validating CC Expiration Date
*/
(function ($) {
    $.validator.addMethod("aopacheckboxlistreq", function (value, element, params) {

        if ($('.' + element.id + ':checked').length === 0 ){
            return false;
        }

        return true;

    });

    $.validator.unobtrusive.adapters.addBool("aopacheckboxlistreq");
}(jQuery));

