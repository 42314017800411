﻿$(document)
    .ready(function() {
        if ($('div#divHSSERegistrationForm').length) {
            var theForm = $('#frmHSSymposiumExhibitorRegistration');

            $(".btnCancel")
                .click(function () {
                    window.location.href = "https://www.aopa.org";
                });

            $(".hssecontinuebutton")
                .click(function () {
                    if (validateDiv($(this).data("thispage"))) {
                        var nextpage = $(this).data("nextpage");
                        $('#' + $(this).data("thispage")).hide();
                        $('#' + nextpage).show();
                        window.scrollTo(0, 0);
                    }
                });

            $(".hssebackbutton")
                .click(function () {
                    var prevpage = $(this).data("prevpage");
                    $('#' + $(this).data("thispage")).hide();
                    $('#' + prevpage).show();
                    window.scrollTo(0, 0);
                });



            $(".hsseRegisterButton").click(function () {
                if (theForm.valid()) {
                    $('#btnRegister').hide();
                    $('.btn_final_cancelback').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });



            $('input[name=PayingBySelected]').on('change', function () {
                if ($('input[name=PayingBySelected]:checked').val() === "CREDIT") {
                    $('#hsseCCFields').show();
                    $('#hssePOFields').hide();
                } else {
                    $('#hsseCCFields').hide();
                    $('#hssePOFields').show();
                }
            });

            $('input[name=BoothTypeSelected]').on('change', function () {
                var elem = document.getElementById(this.id);

                if (elem.checked) {
                    var rcds = JSON.parse(decodeURIComponent($('#RateDescriptions').val()));

                    $.each(rcds, function (i, v) {
                        if (v.rateCode === elem.value) {
                            $('#hsseBoothDesc').html(v.shortDescription);
                            $('#hsseBoothPrice').html(v.priceString);
                            $('#hsseOrderTotal').html(v.priceString);
                            $('#OrderTotal').val(v.price);
                        }
                    });

                }
            });


            function validateDiv(div) {
                var validator = theForm.data('validator');
                var returnValue = true;

                $('#' + div + ' :input').each(function (e) {
                    if (!$(this).is(":button") && this.id.length) {
                        if (!validator.element($('#' + this.id))) returnValue = false;
                    }
                });

                return returnValue;
            }
        }
    });