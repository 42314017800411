import itemListController from "./controllers/itemListController";
import typeAhead from "./controllers/typeAhead";

import clearall from "./directives/clearall";
import datepickerbutton from "./directives/datepickerbutton";
import datepickerend from "./directives/datepickerend";
import datepickerstart from "./directives/datepickerstart";
import popcontent from "./directives/popcontent";
import select2watcher from "./directives/select2watcher";
import showlist from "./directives/showlist";
import showgrid from "./directives/showgrid";
import svgwatcher from "./directives/svgwatcher";
import slickSlider from "./directives/slickSlider";

import sanitize from "./filters/sanitize";
	
(function (angular) {
	'use strict';
	
	var itemListApp = angular.module('AopaItemList', ['ngMaterial']);
	
	itemListApp.controller('itemListController', ['$scope', '$http', '$attrs', itemListController]);
	itemListApp.controller('typeAhead', ['$timeout', '$q', '$log', '$attrs', '$scope', typeAhead]);
	
	itemListApp.directive('clearall', clearall);
	itemListApp.directive('datepickerbutton', datepickerbutton);
	itemListApp.directive('datepickerend', datepickerend);
	itemListApp.directive('datepickerstart', datepickerstart);
	itemListApp.directive('popcontent', popcontent);
	itemListApp.directive('select2watcher', ['$timeout', select2watcher]);
	itemListApp.directive('showlist', showlist);
	itemListApp.directive('showgrid', showgrid);
	itemListApp.directive('svgwatcher', svgwatcher);
    itemListApp.directive('slickSlider', slickSlider);
	
	
	itemListApp.filter('sanitize', ['$sce', sanitize]);
})(window.angular);