export default function itemListApp($scope, $http, $attrs) {
	/*
	 * Controller: itemListController
	 * Primary item list and media gallery controller
	 */

    $scope.rendering = JSON.parse($attrs.rendering); /* Initial search values */

    if (!$scope.rendering.api) {
        $scope.rendering.api = "ItemList";
    }

    var baseDataUrl = '/api/' + $scope.rendering.api + '/Results';
    var airportsUrl = "/api/AirportFinder/GetAirports?name=";
    var locationsUrl = "/api/EventListingLocation/Results";

    if ($attrs.testurl) {
        baseDataUrl = $attrs.testurl;
    }

    if ($attrs.airportsurl) {
        airportsUrl = $attrs.airportsurl;
    }

    if ($attrs.locationsurl) {
        locationsUrl = $attrs.locationsurl;
    }

    $scope.rawrendering = $scope.rendering;
    $scope.displaynum = Number($attrs.displaynum);
    $scope.allTags = [];
    $scope.toggle = ($scope.rendering.api == "EventListing") ? false : true;
    $scope.currSort = ($scope.rendering.api == "EventListing") ? "asc" : "desc";
    $scope.currStart = 1;
    $scope.currEnd = $scope.displaynum;
    $scope.currDate = "";
    $scope.currEndDate = "";
    $scope.dontShowLoading = false;
    $scope.event = { limit: false };
    $scope.special = {
        locations: { items: new Array() },
        airports: { items: new Array() },
        airport: "",
        radius: ""
    };
    $scope.disableRadDrop = true;

    $scope.radii = [{ label: "< 50 miles", value: 50 }, { label: "100 miles", value: 100 }, { label: "200 miles", value: 200 }];

    $scope.special.radius = $scope.radii[0];

    /* Initial content load */
    postHelper($scope, $http, baseDataUrl, collapseParams($scope));

    $scope.tags = [];




	/*
	 * addAirport
	 * adds airport to post and updates content
	 */
    $scope.addAirport = function (newAirport) {
        $scope.disableRadDrop = false;
        $scope.special.airport = newAirport;
        if ($scope.special.radius !== "") {
            postHelper($scope, $http, baseDataUrl, collapseParams($scope));
        }
    };


	/*
	 * addDate
	 * updates current start and end dates and updates content
	 */
    $scope.addDate = function () {
        $scope.currDate = $scope.filter.date.date;
        $scope.currEndDate = $scope.filter.date.enddate;
        postHelper($scope, $http, baseDataUrl, collapseParams($scope));
    };

	/*
	 * addTag
	 * adds a tag to the visual listing and updates content
	 */
    $scope.addTag = function (newTag) {
        if (newTag) {
            if (tagIndex($scope.allTags, newTag.guid) === -1) {
                $scope.allTags.push(newTag);
            }
            $scope.tags = $scope.allTags.sort(tagCompare);
            postHelper($scope, $http, baseDataUrl, collapseParams($scope));
        }

    };

	/*
	 * clearAll
	 * clears all user updated content and updates content
	 */
    $scope.clearAll = function () {
        if ($scope.rendering.filters != null) {
            $scope.rendering.filters.forEach(function (filter) {
                if (filter.style == "checkbox") {
                    filter.items.forEach(function (item) {
                        item.checked = false;
                    });
                }
                else if (filter.style == "radio") {
                    filter.checkbox = {};
                }
            });
        }

        $scope.event.limit = false;
        $scope.special.airport = "";
        $scope.special.airports = { items: [] };
        $scope.special.locations = { items: [] };
        $scope.special.radius = $scope.radii[0];
        $scope.disableRadDrop = true;
        $scope.allTags = [];
        $scope.currDate = "";
        $scope.tags = [];

        postHelper($scope, $http, baseDataUrl, collapseParams($scope));
    };

	/*
	 * disableRadius
	 */
    $scope.disableRadius = function () {
        return $scope.disableRadDrop;
    }

	/*
	 * getAirports
	 */
    $scope.getAirports = function (searchtext) {
        if (searchtext.length >= 3) {
            $http.get(airportsUrl + searchtext).then(function (response) {
                $scope.special.airports.items = response.data.items.map(function (item) {
                    return {
                        guid: item.guid,
                        name: item.name,
                        type: item.type,
                        value: angular.lowercase(item.name)
                    };
                });
            }, function (response) {
                $scope.error = "There was a problem contacting the server.";
            });
        }
    }

	/*
	 * getLocations
	 */
    $scope.getLocations = function () {
        if ($scope.special.locations.items.length < 1) {
            $http.post(locationsUrl).then(function (response) {
                $scope.special.locations.items = response.data.items.map(function (item) {
                    return {
                        guid: item.guid,
                        name: item.name,
                        type: item.type,
                        value: angular.lowercase(item.name)
                    };
                });
            }, function (response) {
                $scope.error = "There was a problem contacting the server.";
            });
        }
    }

	/*
	 * removeTag
	 * removes single tag from visual list and updates content
	 */
    $scope.removeTag = function (remTag, post = true) {
        remTag.checked = false;

        if ($scope.rendering.filters != null) {
            $scope.rendering.filters.forEach(function (filter) {
                if (filter.style == "radio") {
                    filter.items.forEach(function (item) {
                        if (remTag.guid === item.guid) {
                            filter.checkbox = {};
                        }
                    });
                }
            });
        }

        $scope.allTags.splice(tagIndex($scope.allTags, remTag.guid), 1);
        $scope.tags = $scope.allTags;

        if (post) {
            postHelper($scope, $http, baseDataUrl, collapseParams($scope));
        }
    };

	/*
	 * showMore
	 * loads an additional "page" of content
	 */
    $scope.showMore = function () {
        /* Can't use the helper here because of the data concat */
        $scope.dontShowLoading = false;
        $http.post(baseDataUrl, collapseParams($scope, "more")).then(function (response) {
            var data = response.data;
            $scope.error = "";
            $scope.items = $scope.items.concat(data.items);
            $scope.shown = $scope.items.length;
            $scope.shown = $scope.shown <= data.total ? $scope.shown : data.total;
            $scope.total = data.total;
            $scope.dontShowLoading = true;
        }, function (response) {
            $scope.error = response;
            $scope.dontShowLoading = true;
        });
    };

    $scope.showMoreSlick = function () {

    }

    $scope.showMoreSlick = function() {

    }

	/*
	 * showFilters
	 * counts up visible filters to see if we have any
	 */

    $scope.showFilters = function () {
        var countVisible = 0;

        if ($scope.rendering.filters != null) {
            $scope.rendering.filters.forEach(function (filter) {
                if (filter.items.length > 1) {
                    countVisible += filter.items.length;
                }
            });
        }

        if (countVisible > 0) {
            return true;
        }

        return false;
    }


	/*
	 * toggleDateOrder
	 * Swaps sort order values and makes post call
	 */
    $scope.toggleDateOrder = function (toggle) {
        $scope.currSort = toggle ? 'desc' : 'asc';

        postHelper($scope, $http, baseDataUrl, collapseParams($scope));
    };

	/*
	 * toggleType
	 * Handles adding and removing of new checkbox tags
	 * Determines checkbox state and calls add or remove function
	 * Also handles visual show and clear
	 */
    $scope.toggleType = function (newTag) {
        if (tagIndex($scope.allTags, newTag.guid) === -1) {
            newTag.checked = true;
            $scope.allTags.push(newTag);
        }
        else {
            $scope.removeTag(newTag);
        }
        $scope.tags = $scope.allTags;
        postHelper($scope, $http, baseDataUrl, collapseParams($scope));
    };


	/*
	 * toggleRadio
	 * Handles adding and removing of new radio tags
	 * Determines radio state and calls add or remove function for appropriate tags
	 */
    $scope.toggleRadio = function (tagParent, newTag) {
        tagParent.items.forEach(function (item) {
            if (item.guid === newTag.guid) {
                newTag.checked = true;
                $scope.allTags.push(newTag);
            }
            else {
                item.checked = false;

                if (tagIndex($scope.allTags, item.guid) > -1) {
                    $scope.allTags.splice(tagIndex($scope.allTags, item.guid), 1);
                }
            }
        });

        $scope.tags = $scope.allTags;

        postHelper($scope, $http, baseDataUrl, collapseParams($scope));
    };

	/*
	 * setLimit
	 */
    $scope.setLimit = function () {
        $scope.event.limit = !$scope.event.limit;
        postHelper($scope, $http, baseDataUrl, collapseParams($scope));
    }

	/*
	 * setRadius
	 */
    $scope.setRadius = function () {
        if ($scope.special.airport !== "") {
            postHelper($scope, $http, baseDataUrl, collapseParams($scope));
        }
    }
}



/* Helper Functions */

/*
 * collapseParams
 * fold all parameters into a single object to pass through post
 */
var collapseParams = function ($scope, loadtype = "") {
    var adjStart = $scope.currStart,
        adjEnd = $scope.currEnd;

    if (loadtype === "more") {
        adjStart = adjEnd + 1;
        adjEnd = adjEnd + $scope.displaynum;
    }

    $scope.currEnd = adjEnd;

    var sDate = (!isNaN(new Date($scope.currDate))) ? (new Date($scope.currDate)).toJSON() : "",
        eDate = (!isNaN(new Date($scope.currEndDate))) ? (new Date($scope.currEndDate)).toJSON() : "";

    var paramObj = {};
    paramObj.rendering = $scope.rawrendering;
    paramObj.sort = $scope.currSort;
    paramObj.start = adjStart;
    paramObj.end = adjEnd;
    paramObj.startdate = sDate;
    paramObj.enddate = eDate;
    paramObj.tags = $scope.allTags;
    paramObj.limit = $scope.event.limit;
    paramObj.radius = "";
    paramObj.airport = "";
    if ($scope.special.radius != "" && $scope.special.airport != "") {
        paramObj.radius = $scope.special.radius;
        paramObj.airport = $scope.special.airport;
    }

    return paramObj;
}

/*
 * postHelper
 * Make all post calls (except loadMore, which appends rather than replacing the data)
 */
var postHelper = function ($scope, $http, dataUrl, dataObj) {
    $http.post(dataUrl, dataObj).then(function (response) {
        var data = response.data;
        $scope.error = "";
        $scope.items = data.items;
        $scope.shown = data.items.length;
        $scope.total = data.total;
        $scope.dontShowLoading = true;
    }, function (response) {
        $scope.error = "There was a problem contacting the server.";
    });
}

/*
 * tagCompare
 * Sort behavior for tags
 */
var tagCompare = function (a, b) {
    return a.name.localeCompare(b.name);
}

/*
 * tagIndex
 * Indicate if tag exists in array of tags
 * checks on guid
 * returns location if found, -1 otherwise
 */
var tagIndex = function (tagArray, searchTag) {
    for (var i = 0; i < tagArray.length; i++) {
        if (tagArray[i].guid === searchTag) return i;
    }
    return -1;
}
