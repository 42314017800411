﻿$(document).ready(function () {
    if ($('div#divFreeMembeshipForm').length) {

        var autocomplete;

        var jfStripe = Stripe($('#sapik').val());

        var jfElements = jfStripe.elements();
        var jfStyle = {
            base: {
                backgroundColor: '#eeeded',
                fontSize: '15px',
                fontFamily: 'Roboto, Helvetica Neue, sans-serif',
                lineHeight: '42px',
                ':focus': {
                    border: '1px solid #c1c2c3'
                }
            }
        };
        var jfCardElement = jfElements.create("card", { style: jfStyle, hidePostalCode: true, disableLink: true });
        jfCardElement.mount("#card-element");

        jfCardElement.on('change',
            function (event) {
                var displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }

                $('#CardBrand').val(event.brand);
            });
        
        var initAutocomplete = () => {
            // Create the autocomplete object, restricting the search predictions to
            autocomplete = new google.maps.places.Autocomplete(AddressLookup,
                {
                    fields: ["address_components"],
                    types: ["address"],
                });
            autocomplete.setComponentRestrictions({
                country: ["us"]
            });
            // When the user selects an address from the drop-down, populate the
            // address fields in the form.
            autocomplete.addListener("place_changed", fillInAddress);
        };

        initAutocomplete();


        function fillInAddress() {
            // Get the place details from the autocomplete object.
            var place = autocomplete.getPlace();
            var address1 = "";
            var postcode = "";

            $("#MailingStreet").val("");
            $("#MailingCity").val("");
            $("#MailingStateProvince").val("");
            $("#MailingPostalCode").val("");

            // Get each component of the address from the place details,
            // and then fill-in the corresponding field on the form.
            // place.address_components are google.maps.GeocoderAddressComponent objects
            // which are documented at http://goo.gle/3l5i5Mr
            place.address_components.forEach((component) => {
                var componentType = component.types[0];

                switch (componentType) {
                    case "street_number":
                        {
                            address1 = `${component.long_name} ${address1}`;
                            break;
                        }

                    case "route":
                        {
                            address1 += component.short_name;
                            break;
                        }

                    case "postal_code":
                        {
                            postcode = `${component.long_name}${postcode}`;
                            break;
                        }

                    case "postal_code_suffix":
                        {
                            postcode = `${postcode}-${component.long_name}`;
                            break;
                        }
                    case "locality":
                        $("#MailingCity").val(component.long_name);
                        clearValidation("MailingCity");
                        break;
                    case "administrative_area_level_1":
                        {
                            $("#MailingStateProvince").val(component.short_name);
                            clearValidation("MailingStateProvince");
                            break;
                        }
                }
            });

            $("#MailingStreet").val(address1);
            clearValidation("MailingStreet");
            $("#MailingPostalCode").val(postcode);
            clearValidation("MailingPostalCode");
            $("#MailingStreet").focus();
        };

        var clearValidation = (field) => {
            var theField = $('#' + field);
            if (theField.val()) {
                theField.removeClass("input-validation-error").addClass("valid");
                $("span[data-valmsg-for='" + field + "']").removeClass("field-validation-error").addClass("field-validation-valid");
                $("span[data-valmsg-for='" + field + "']").empty();
            }
        };


        $("#BirthDate").datepicker({
            changeMonth: true,
            changeYear: true,
            minDate: new Date(new Date().getFullYear() - 21, new Date().getMonth(), new Date().getDate() + 1),
            maxDate: "-13Y"
        });

        $("#BirthDateFTF").datepicker({
            changeMonth: true,
            changeYear: true,
            yearRange: (new Date).getFullYear() - 110 + ':' + (new Date).getFullYear(),
            minDate: new Date(new Date().getFullYear() - 110, new Date().getMonth(), new Date().getDate() ),
            maxDate: new Date()
        });


        $('#IncludeAar').change(function () {
            if (this.checked) {
                $('#divFinalize').show();

            } else {
                $('#divFinalize').hide();
            }
        });


        $(".backBtn").click(function () {
            var form = $(this).closest('form');

            if (page1Valid(form)) {
                $('#freejoinPage2').css({ "visibility": "hidden" });
                $('#freejoinPage2').css({ "display": "none" });
                $('#freejoinPage1').css({ "visibility": "visible" });
                $('#freejoinPage1').css({ "display": "block" });
                $('#LoadPage').val("1");
            }
        });

        $(".btnContinue").click(function () {
            var form = $(this).closest('form');

            if (page1Valid(form)) {

                if ($('#FormMode').val() === 'FTFREE' && $("#IsAuthenticated").val() === "False" && $("#IsHkey").val() === "False") {
                    $.post('/api/FormTracking/ProcessTracking',
                        {
                            "recordTypeName": $('#ltype').val(),
                            "leadSource": $('#lsource').val(),
                            "leadId": $('#lid').val(),
                            "status": "New",
                            "firstName": $('#FirstName').val(),
                            "lastName": $('#LastName').val(),
                            "emailAddress": $('#EmailAddress').val(),
                            "preferredEmail": $('#lemail').val(),
                            "description": "Step 1 Complete"
                        },
                        function () { }
                    )
                        .done(function (data) {
                            $('#lid').val(data)
                        })
                        .fail(function (jqXHR, textStatus, err) {
                        });
                }

                $('#freejoinPage1').css({ "visibility": "hidden" });
                $('#freejoinPage1').css({ "display": "none" });
                $('#freejoinPage2').css({ "visibility": "visible" });
                $('#freejoinPage2').css({ "display": "block" });
                $('#LoadPage').val("2");

            }
        });

        var checkCardFields = () => {
            var isValid = false;

            if ($('#ShowStripeForm').val() === "True") {
                var cardElement = document.querySelector('#card-element');

                isValid = cardElement.classList.contains('StripeElement--complete');

                var brand = $('#CardBrand').val();

                if (isValid &&
                    (brand === 'visa' || brand === 'mastercard' || brand === 'amex' || brand === 'discover')) {
                    $('#card-errors').html('');
                } else {
                    if ($('#card-errors').is(':empty')) {
                        $('#card-errors').html('Please check your credit card information.');
                    }
                }

            } else {
                isValid = true;
            }


            return isValid;
        }

        $(".submitButtonFMF").click(function () {
            var form = $(this).closest('form');

            var progressBar = new Sfpb_progressBar();

            if (page1Valid(form) && page2Valid(form)) {


                if ($('#FormMode').val() === 'FTFREE') {

                    var ccValid = checkCardFields();

                    if (ccValid) {
                        $('#btnJoin').hide();
                        $(this).hide();
                        $(".backBtn").hide();
                        $('body').addClass('progressbar-modal');
                        progressBar.start();
                        $(".clsSubmitButton").append('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');


                        $.post('/api/FormTracking/ProcessTracking',
                                {
                                    "recordTypeName": $('#ltype').val(),
                                    "leadSource": $('#lsource').val(),
                                    "leadId": $('#lid').val(),
                                    "status": "New",
                                    "firstName": $('#FirstName').val(),
                                    "lastName": $('#LastName').val(),
                                    "emailAddress": $('#EmailAddress').val(),
                                    "preferredEmail": $('#lemail').val(),
                                    "description": "Step 2 Complete"
                                },
                                function() {}
                            )
                            .done(function(data) {
                            })
                            .fail(function(jqXHR, textStatus, err) {
                            });



                        if ($('#ShowStripeForm').val() === "True") {
                            jfStripe.createToken(jfCardElement, { name: $("#CardName").val() })
                                .then(function (result) {
                                    if (result.token !== null) {
                                        $("#CardToken").val(JSON.stringify(result.token));
                                        form.submit();
                                    } else {
                                        if ($('#card-errors').is(':empty')) {
                                            $('#card-errors').html('Please check your credit card information.');
                                        }
                                        var spinner = document.getElementById('aopaSpinner');
                                        spinner.parentElement.removeChild(spinner);
                                        $(this).show();
                                        $(".backBtn").show();
                                        progressBar.kill();
                                        $('body').removeClass('progressbar-modal');
                                        $('#btnJoin').show();
                                    }
                                });

                        } else {
                            form.submit();
                        }
                    }
                } else {
                    $('#btnJoin').hide();
                    $(this).hide();
                    $(".backBtn").hide();
                    $('body').addClass('progressbar-modal');
                    progressBar.start();
                    $(".clsSubmitButton").append('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');
                    form.submit();
                }
            }
        });

        function page1Valid(form) {
            var validator = form.data('validator');
            var returnValue = true;

            if ($("#IsAuthenticated").val() === "False" && $("#IsHkey").val() === "False" && !$("#MasterCustomerId").val()) {
                if (!validator.element($("#SelectedRateCode"))) returnValue = false;
                if (!validator.element($("#EmailAddress"))) returnValue = false;
                if (!validator.element($("#UserName"))) returnValue = false;
                if (!validator.element($("#Password"))) returnValue = false;
                if (!validator.element($("#ConfirmPassword"))) returnValue = false;
                if ($("#FormMode").val() === "FTFREE") {
                    if (!validator.element($("#FirstName"))) returnValue = false;
                    if (!validator.element($("#LastName"))) returnValue = false;
                } else if ($("#FormMode").val() === "AV8RS") {
                    if (!validator.element($("#BirthDate"))) returnValue = false;
                }
            }
            return returnValue;

        }

        function page2Valid(form) {
            var validator = form.data('validator');
            var returnValue = true;

            if ($("#IsAuthenticated").val() === "False" && $("#IsHkey").val() === "False") {
                if ($("#FormMode").val() !== "FTFREE") {
                    if (!validator.element($("#FirstName"))) returnValue = false;
                    if (!validator.element($("#LastName"))) returnValue = false;
                }
                if (!validator.element($("#MailingStreet"))) returnValue = false;
                if (!validator.element($("#MailingCity"))) returnValue = false;
                if (!validator.element($("#MailingStateProvince"))) returnValue = false;
                if (!validator.element($("#MailingPostalCode"))) returnValue = false;
                if (!validator.element($("#NameOnCard"))) returnValue = false;

            }
            return returnValue;
        }
    }
});
