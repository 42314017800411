export default function typeAhead($timeout, $q, $log, $attrs, $scope) {
	/* 
	 * controller: typeAhead
	 * Manages typeahead function with md-autocomplete
	 */
	 
	var self = this;
	self.simulateQuery = false;
	self.isDisabled = false;
	self.querySearch = querySearch;

	/* 
	 * querySearch
	 * Builds typeahead list
	 */
	function querySearch (query, allterms) {
		var results = query ? allterms.filter( createFilterFor(query) ) : allterms, deferred;
		return results;
	}

	/* 
	 * typeAheadInit
	 * normalize values of typeahead for ease of comparison
	 */
	$scope.typeAheadInit = function(initValue) {
		return initValue.items.map( function (item) {
				item.value = item.name.toLowerCase();
				return item;
		});
	}

	/* 
	 * createFilterFor
	 * Filter function for typeahead list
	 */
	function createFilterFor(query) {
		var lowercaseQuery = angular.lowercase(query);
		return function filterFn(item) {
			return (item.value.indexOf(lowercaseQuery) !== -1);
		};
	}

}