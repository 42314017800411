$("#AirportId").each(function(i) {
     $('this').change(function() {
         $.getJSON('/api/AirportFinder/GetAirportByCode?includePrivate=true&code='+$(this).val())
        .done(function (data) {
         if (!data)  {
                // remove invalid airport id and set focus on airport id field
                 $('#AirportId').val('');
                  $('#AirportId').focus();
                alert('Airport ID is not valid. Please enter valid Airport ID');
                 
            }
        })
        .fail(function (xhr, status, error) {
            console.error('error:', xhr, status, error)
        });
});
});
$("#StartDate").each(function(i) {
$(this).datetimepicker({
                controlType: 'select',
                timeFormat: 'hh:mm tt'
});
});
$("#EndDate").each(function(i) {
$(this).datetimepicker({
                controlType: 'select',
                timeFormat: 'hh:mm tt'
});
});    