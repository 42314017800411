﻿$(document).ready(function () {
    if ($('div#divDonationRailForm').length) {

        if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
            $("#divOtherAmount").css({ "visibility": "hidden" });
            $("#divOtherAmount").css({ "display": "none" });
        } else {
            $("#divOtherAmount").css({ "visibility": "visible" });
            $("#divOtherAmount").css({ "display": "block" });
        }

        fixPremiumsDRF();

        $('input[name=AcceptPremium]').on('change', function () {
            fixPremiumsDRF();
        });

        $('input[name=SelectedDonationAmount]').on('change', function () {
            if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
                if ($('#MinimumDonationAmount').length) {
                    $('#MinimumDonationAmount').val('');
                    $('#MinimumDonationAmount').valid();
                    $('#MinimumDonationAmount').prop("disabled", true);
                    $("#divOtherAmount").css({ "visibility": "hidden" });
                    $("#divOtherAmount").css({ "display": "none" });
                }
            } else {
                $('#MinimumDonationAmount').prop("disabled", false);
                $("#divOtherAmount").css({ "visibility": "visible" });
                $("#divOtherAmount").css({ "display": "block" });
            }
            calculateTotal();
            appealPremiumsUpdate();
        });

        $('#SelectedPremiumItem').change(function () {
            var option = $(this).find('option:selected').val().split("|");
            $.getJSON('/api/OneClickPremium/Get/?productId=' + option[0])
                .done(function (data) {
                    $('#SelectedPremiumRateCode').empty();
                    var $option = $("<option />");
                    $option.attr("value", "").text($('#PremiumRateSelect').val());
                    $('#SelectedPremiumRateCode').append($option);
                    $.each(data, function (key, item) {
                        var $option = $("<option />");
                        $option.attr("value", item.value).text(item.text);
                        $('#SelectedPremiumRateCode').append($option);
                    });
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#SelectedPremiumRateCode').empty();
                    var $option = $("<option />");
                    $options.attr("value", "").text("- Error -");
                    $('#SelectedPremiumRateCode').append($option);
                });
        });


        function fixPremiumsDRF() {
            var $option;
            if ($('#HasPremiumsAvailable').val().toUpperCase() === "TRUE" && $('#ShowPremiumList').val().toUpperCase() === "TRUE") {
                if ($('input[name=AcceptPremium]:checked').val() !== "true") {
                    $("#divPremium").css({ "visibility": "hidden" });
                    $("#divPremium").css({ "display": "none" });
                    $('#SelectedPremiumItem').val($('#SelectedPremiumItem option:last-child').val());
                    $('#SelectedPremiumRateCode').empty();
                    $option = $("<option />");
                    $option.attr("value", "x").text('x');
                    $('#SelectedPremiumRateCode').append($option);
                    $('#SelectedPremiumRateCode').val('x');
                } else {
                    $("#divPremium").css({ "visibility": "visible" });
                    $("#divPremium").css({ "display": "block" });
                    $('#SelectedPremiumItem').val('');
                    $('#SelectedPremiumRateCode').empty();
                    $option = $("<option />");
                    $option.attr("value", "").text($('#PremiumRateSelect').val());
                    $('#SelectedPremiumRateCode').append($option);
                    $('#SelectedPremiumRateCode').val('');
                }
            }
        }

        $('#SelectedCountry').change(function () {
            var option = $(this).find('option:selected').val();
            $.getJSON('/api/CountryState/GetStates/?countryCode=' + option)
                .done(function (data) {
                    $('#SelectedState').empty();
                    $.each(data, function (key, item) {
                        var $option = $("<option />");
                        $option.attr("value", item.value).text(item.text);
                        $('#SelectedState').append($option);
                    });
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#SelectedState').empty();
                    var $option = $("<option />");
                    $options.attr("value", "").text("- Error -");
                    $('#SelectedState').append($option);
                });
        });

        $(".donateButton").click(function () {
            var form = $(this).closest('form');
            if (form.valid()) {
                $(this).hide();
                $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
            }
        });

        $('input[name=SelectedDonationFrequency]').on('change', function () {
            calculateTotal();
        });

        $('input[name=MinimumDonationAmount]').on('change', function () {
            if ($('input[name=SelectedDonationAmount]:checked').val() === "OTHER|0") {
                calculateTotal();
                appealPremiumsUpdate();
            }
        });

        function calculateTotal() {            
            if ($('#IsPledge').val() === 'True') {

                var selectedAmount = 0;
                if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
                    var amount = $('input[name=SelectedDonationAmount]:checked').val().split("|");
                    selectedAmount = parseFloat(amount[1], 10).toFixed(2);
                    $('#DonationTotal').val(parseFloat(amount[1], 10).toFixed(2));

                } else {
                    if ($.isNumeric($('#MinimumDonationAmount').val())) {
                        selectedAmount = parseFloat($('#MinimumDonationAmount').val(), 10).toFixed(2);
                        $('#DonationTotal').val(parseFloat($('#MinimumDonationAmount').val(), 10).toFixed(2));
                    } else {
                        selectedAmount = 0.00;
                        $('#DonationTotal').val(0.00);
                    }
                }

                switch ($('input[name=SelectedDonationFrequency]:checked').val()) {
                    case 'IMMEDIATE':
                        $('#PaymentTotal').val(parseFloat(selectedAmount, 10).toFixed(2));
                        break;
                    case 'MONTHLY':
                        selectedAmount = selectedAmount / 12;
                        $('#PaymentTotal').val(parseFloat(selectedAmount, 10).toFixed(2));
                        break;
                    case 'QUARTERLY':
                        selectedAmount = selectedAmount / 4;
                        $('#PaymentTotal').val(parseFloat(selectedAmount, 10).toFixed(2));
                        break;
                }

                var totaltext = '$' + parseFloat(selectedAmount, 10).toFixed(2).toLocaleString();
                $('#divPaymentAmount').html($.parseHTML(totaltext));
            }

            if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER|0") {
                var amount = $('input[name=SelectedDonationAmount]:checked').val().split("|");
                $('#DonationTotal').val(parseFloat(amount[1], 10).toFixed(2));

            } else {
                if ($.isNumeric($('#MinimumDonationAmount').val())) {
                    $('#DonationTotal').val(parseFloat($('#MinimumDonationAmount').val(), 10).toFixed(2));
                } else {
                    $('#DonationTotal').val(0.00);
                }
            }
        }
        
        function appealPremiumsUpdate() {
            var total = parseFloat($('#DonationTotal').val(), 10);
            var hasQualPremiums = false;
            var qualVal = 0;


            for (var i = 1; i <= 3; i++) {
                if ($('#OfferPremium' + i + 'QualValue').val()) {
                    qualVal = parseFloat($('#OfferPremium' + i + 'QualValue').val(), 10);
                    if (qualVal <= total) {
                        hasQualPremiums = true;
                        $('.divAppealPremiumItem' + i).show();
                        $('#OfferPremium' + i + 'Show').val('True');
                    } else {
                        $('.divAppealPremiumItem' + i).hide();
                        $('#OfferPremium' + i + 'Show').val('False');
                    }
                } else {
                    $('.divAppealPremiumItem' + i).hide();
                    $('#OfferPremium' + i).prop('checked', false);
                    $('#OfferPremium' + i + 'Show').val('False');
                }
            }

            if (hasQualPremiums) {
                $('.divAppealPremium').show();

            } else {
                $('.divAppealPremium').hide();
            }

            $('#hasQualifiedOfferPremium').val(hasQualPremiums);
        }

    }
});