﻿/*
Custom script for validating CFI Expiration Date
*/
(function ($) {
    $.validator.addMethod("cfiexpiredatecheck", function (value, element, params) {
        var expYear = value;
        var expMonth = $('#SelectedExpireDateMonth').val();
        var crSelected = $('input[name=' + params.coursereason + ']:checked').val();

        if (crSelected) {
            if (params.requiredreasons.indexOf(crSelected) !== -1) {
                if (!isNaN(expYear) && !isNaN(expMonth)) {
                    var expDate = new Date(expYear * 1, (expMonth * 1), 0);
                    var now = new Date();
                    now.setHours(0, 0, 0, 0);
                    if (expDate <= now) {
                        return false;
                    }
                } else {
                    return false;
                }
            } 
        }


        return true;
    });

    jQuery.validator.unobtrusive.adapters.add("cfiexpiredatecheck", ["coursereason", "requiredreasons"],
        function (options) {
            options.rules['cfiexpiredatecheck'] = {
                coursereason: options.params.coursereason,
                requiredreasons: options.params.requiredreasons,
                expiremonth: options.params.expiremonth,
            };
            options.messages['cfiexpiredatecheck'] = options.message;
        });
}(jQuery));