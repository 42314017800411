﻿/*
Custom script for required optional validation
*/
(function ($) {
    $.validator.addMethod("optionalselectcheck", function (value, element, params) {
        var isRequired = $('#' + params).is(':checked');
        
        
        if (isRequired) {
            if (!value) {
                return false;
            }
        }
        return true;
    });

    $.validator.unobtrusive.adapters.addSingleVal("optionalselectcheck", "checkboxfield");
}(jQuery));