﻿/*
Custom script for validating CC Expiration Date
*/
(function ($) {
    $.validator.addMethod("phonenumbercheck", function (value, element, params) {

        var patt = new RegExp("^[0-9]{3}-?[0-9]{3}-?[0-9]{4}$");

        if (value) {
            if (!patt.test(value)) {
                return false;
            }
        }


        return true;
    });

    $.validator.unobtrusive.adapters.addBool("phonenumbercheck");
}(jQuery));

