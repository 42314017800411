﻿$(document)
    .ready(function() {
        if ($('div#divPilotWorkshopsForm').length) {
            var theForm = $('#frmPilotWorkShops');

            $(document.body).delegate('[type="checkbox"][readonly="readonly"]', 'click', function (e) {
                e.preventDefault();
            });


            $(".pwscontinuebutton")
                .click(function () {
                    if (validateDiv($(this).data("thispage"))) {
                        var btn = document.getElementById(this.id);
                        var nextpage = $(this).data("nextpage");

                        if (btn != null && btn.hasAttribute("data-nextpage-choice")) {
                            var cb = document.getElementById($(this).data("nextpage-choice"));
                            if (cb.checked) {
                                nextpage = $(this).data("alt-nextpage");
                            }
                        }

                        $('#' + $(this).data("thispage")).hide();
                        $('#' + nextpage).show();
                        window.scrollTo(0, 0);
                    }
                });

            $(".btnCancel")
                .click(function () {
                    window.location.href = "https://www.aopa.org";
                });

            $(".pwsbackbutton")
                .click(function () {
                    var btn = document.getElementById(this.id);
                    var prevpage = $(this).data("prevpage");

                    if (btn != null && btn.hasAttribute("data-prevpage-choice")) {
                        var cb = document.getElementById($(this).data("prevpage-choice"));
                        if (cb.checked) {
                            prevpage = $(this).data("alt-prevpage");
                        }
                    }

                    $('#' + $(this).data("thispage")).hide();
                    $('#' + prevpage).show();
                    window.scrollTo(0, 0);
                });




            $(".pwsSubmitButton").click(function () {
                if (theForm.valid()) {
                    $('#btnRegister').hide();
                    $('.btn_final_cancelback').hide();
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });

            $('#State')
                .change(function () {
                    SetTotal();
                });

            $('#ShippingState')
                .change(function () {
                    SetTotal();
                });

            $('#ShippingAddressSame')
                .change(function () {
                    SetTotal();
                });

            function SetTotal() {
                var st = document.getElementById('State').value;
                var shipSt = document.getElementById('ShippingState').value;
                var same = document.getElementById('ShippingAddressSame');

                var total = 0.0;
                total += parseFloat($('#ProductPrice').val());
                total += parseFloat($('#ShippingAmount').val());

                if (same.checked) {
                    if (st === "TN") {
                        //total += parseFloat($('#TennesseTax').val());
                        //$('#divTennTax').show();
                        $('.pws-payment-section').hide();
                        $('.pws-tennessee-restrict').show();
                        $('.pwsSubmitButton').hide();
                    } else {
                        //$('#divTennTax').hide();
                        $('.pws-payment-section').show();
                        $('.pws-tennessee-restrict').hide();
                        $('.pwsSubmitButton').show();
                    }
                } else {
                    if (shipSt === "TN") {
                        //total += parseFloat($('#TennesseTax').val());
                        //$('#divTennTax').show();
                        $('.pws-payment-section').hide();
                        $('.pws-tennessee-restrict').show();
                        $('.pwsSubmitButton').hide();
                    } else {
                        //$('#divTennTax').hide();
                        $('.pws-payment-section').show();
                        $('.pws-tennessee-restrict').hide();
                        $('.pwsSubmitButton').show();
                    }
                }
                $('#OrderTotal').val(total);
                $('.form-payment-total__price').text('$' + total.toFixed(2));
            }




            function validateDiv(div) {
                var validator = theForm.data('validator');
                var returnValue = true;

                $('#' + div + ' :input').each(function (e) {
                    if (!$(this).is(":button") && this.id.length) {
                        if (!validator.element($('#' + this.id))) returnValue = false;
                    }
                });

                return returnValue;
            }
        }
    });