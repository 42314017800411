﻿$(document).ready(function () {
    if ($('div#divAOPAOptInOutForm').length) {

        $(":checkbox").change(function () {
            if (this.closest('form').action.toLowerCase().indexOf('/account/optinoutpreferences') >= 0) {

                $("input[type='checkbox']").attr('disabled', 'disabled');

                $(this).closest("label").addClass("theActive");
                $(this).closest("label").css("color", "blue");

                $.getJSON('/api/OptInOut/Get/?input=' + this.name + '&ischecked=' + this.checked + '&validcode=' + $('#ValidCode').val())
                    .done(function (data) {
                        $("input[type='checkbox']").removeAttr('disabled');
                        $("label.theActive").css("color", "green");
                        $("label.theActive").removeClass("theActive");
                    })
                    .fail(function (jqXHR, textStatus, err) {
                        $("input[type='checkbox']").removeAttr('disabled');
                        $("label.theActive").css("color", "red");
                        $("label.theActive").removeClass("theActive");
                    });
            }
        });
    }
});


