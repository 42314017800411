// JavaScript Document

//old banner dropdown	
 $(".expanding").mouseenter(function(){
 $(".hideAd").fadeIn(300);
})
$(".adClose").click(function(){
 $(".hideAd").fadeOut(300);
})

//sidebar dropdown
$(".hideAdHover").each(function(i){
	$(this).position({
		my: "right top",
		at: "right top",
		of: $(this).parent("div.expandingHover"),
		collision: "none"
	});
});

$("div.expandingHover").each(function(i){
	$(this).bind({
		mouseenter: function(){
			$(".hideAdHover").stop(true,true).fadeIn(400).removeClass("hiddenAd");
			return false;
		},
		
		mouseleave: function(){
			$(".hideAdHover").stop(true,true).fadeOut(400).addClass("hiddenAd");
			return false;
		}
	});
});

//Banner Dropdown
$('#banner-expand').mouseenter(function() {
      $('#expanded-ad').stop(true, true).slideDown();
});
$('#expanded-ad').mouseleave(function() {
      $(this).stop(true, true).slideUp();
});