class EventSearch {
    constructor ($q, $http, $timeout, $filter, airportService, eventService, googleService, NgMap) {
        this.$q = $q;
        this.$http = $http;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.airportService = airportService;
        this.eventService = eventService;
        this.googleService = googleService;
        this.NgMap = NgMap;
        this.templateUrl = '/js/aopa/eventsearch/templates/event-search.html';
        this.restrict = 'E';
        this.scope = {
            perPage: '='
        };
        this.controller = ['$scope', $scope => {
            // Initialize scope variables
            $scope.query = '';
            $scope.googleMapsUrl = `https://maps.googleapis.com/maps/api/js?key=${Aopa.GOOGLE_MAPS_API_KEY}&libraries=places`;
            $scope.states = [];
            $scope.selectedStates = [];
            $scope.selectedAirports = [];
            $scope.location = null;
            $scope.RADIUS = 100;
            $scope.currentPage = 1;
            $scope.pageSize = 10;
            $scope.totalResults = 0;

            // Initialize scope methods
            $scope.onSearchKeyUp = ($event) => this.onSearchKeyUp($scope, $event);
            $scope.onSearchBlur = () => this.onSearchBlur($scope);
            $scope.onClickSearch = () => this.onClickSearch($scope);
            $scope.selectedItemChange = (item) => this.selectedItemChange($scope, item);
            $scope.querySearch = (query) => this.querySearch($scope, query);
            $scope.onSelectState = (state) => this.onSelectState($scope, state);
            $scope.onDeselectState = (state) => this.onDeselectState($scope, state);
            $scope.stateSearch = (query) => this.stateSearch($scope, query);
            $scope.onSelectAirport = (item) => this.onSelectAirport($scope, item);
            $scope.onDeselectAirport = (item) => this.onDeselectAirport($scope, item);
            $scope.airportSearch = (query) => this.airportSearch($scope, query);
            $scope.onToggleRegion = (region) => this.onToggleRegion($scope, region);
            $scope.onToggleCategory = (category) => this.onToggleCategory($scope, category);
            $scope.onSelectDateRange = () => this.onSelectDateRange($scope);
            $scope.clearDateRange = () => this.clearDateRange($scope);
            $scope.pageChanged = (newPage) => this.pageChanged($scope, newPage);
            $scope.onMarkerClick = (clickEvent, event) => this.onMarkerClick($scope, clickEvent, event);

            // Initialize regions
            this.initRegions($scope);

            // Initialize event categories
            this.initCategories($scope);

            // Initialize date range
            $scope.dateRange = { dateStart: null, dateEnd: null };
            $scope.isDisabledDate = function (d) {
                // Prevent selecting dates before today
                return new Date(d.toDateString()) < new Date(new Date().toDateString());
            }

            // Initialize Google Maps
            var d = $q.defer();
            NgMap.getMap()
                .then(map => {
                    d.resolve(map);
                    this.googleService.init(map);
                    this.initMap($scope, map);
                    this.load($scope);
                })
                .catch(err => {
                    d.reject(err);
                    $scope.error = 'An error occurred initializing the map. Please try again later.';
                });
            $scope.mapPromise = d.promise;

            // Initialize states
            $scope.statePromise = $http.get('/api/CountryState/GetStates?countryCode=USA')
                .then(states => {
                    $scope.states = states.data.slice(1);
                    return $scope.states;
                });

            // TEMP: Watch for query changes
            $scope.$watch('query', q => this.onQueryChanged($scope));
        }];
    }

    static directiveFactory ($q, $http, $timeout, $filter, airportService, eventService, googleService, NgMap) {
        EventSearch.instance = new EventSearch($q, $http, $timeout, $filter, airportService, eventService, googleService, NgMap);
        return EventSearch.instance;
    }

    link ($scope, $element, $attributes) { }

    clear ($scope) {
        $scope.events = null;
        $scope.error = null;
        $scope.loading = false;
    }

    initRegions ($scope) {
        $scope.regions = null;
        $scope.regionsError = false;
        $scope.regionsLoading = true;
        $scope.regionsPromise = this.$http.get('/api/CountryState/GetRegionStates')
            .then(response => {
                $scope.regions = _.chain(response.data)
                    .groupBy('regionId')
                    .map(g => {
                        return {
                            name: g[0].regionName,
                            states: _.chain(g)
                                .map(state => {
                                    return {
                                        code: state.stateCode,
                                        name: state.stateName
                                    };
                                })
                                .sortBy('name')
                                .value()
                        };
                    })
                    .sortBy('name')
                    .value();
            })
            .catch(e => {
                console.error('Error loading region states', e);
                $scope.regionsError = true;
            })
            .finally(() => {
                $scope.regionsLoading = false;
            });
        return $scope.regionsPromise;
    }

    initCategories ($scope) {
        $scope.eventCategories = null;
        $scope.eventCategoriesError = false;
        $scope.eventCategoriesLoading = true;
        return this.eventService.getEventCategories()
            .then(categories => {
                $scope.eventCategories = categories;
            })
            .catch(e => {
                console.error('Error loading event categories', e);
                $scope.eventCategoriesError = true;
            })
            .finally(() => {
                $scope.eventCategoriesLoading = false;
            });
    }

    initMap ($scope, map) {
        $scope.map = map;
        $scope.autocomplete = new google.maps.places.AutocompleteService();
    }

    load ($scope) {
        this.clear($scope);
        this.search($scope);
    }

    onQueryChanged ($scope) {
        if (!this.googleService.ready || !$scope.query) {
            return;
        }
    }

    onSearchKeyUp ($scope, $event) {
        if ($event.keyCode === 13) {
            this.search($scope);
        }
    }

    onSearchBlur($scope) {
        this.search($scope);
    }


    onClickSearch ($scope) {
        const query = ($scope.query || '').trim();
        if (!query) {
            var searchField = document.getElementById('event-search-query');
            $(searchField).find('input').focus();
            return;
        }
        this.search($scope);
    }

    selectedItemChange ($scope, item) {
        if (!item) {
            return;
        }
        if (item.type !== 'result') {
            this.clearSelectedItem($scope);
            return;
        }
        if (item.subtype === 'event') {
            this.clearSelectedItem($scope);
            const url = this.getEventUrl(item.event);
            if (url) {
                window.location.href = url;
            }
        }
        if (item.subtype === 'airport') {
            this.clearSelectedItem($scope);
            $scope.location = null;
            $scope.selectedAirports = [];
            $scope.selectedStates = [];
            this.onSelectAirport($scope, item);
        }
        if (item.subtype === 'location') {
            this.clearSelectedItem($scope);
            this.googleService.geocode({ placeId: item.location.place_id })
                .then(results => {
                    if (results && results.length) {
                        var result = results[0];
                        // If result is a US state, then populate it as a selected state in the filters area
                        if (result.types
                            && result.types.some(v => v === 'administrative_area_level_1')
                            && result.address_components
                            && result.address_components.length) {
                            var cmp = result.address_components[0];
                            var stateCode = cmp.short_name;
                            var stateName = cmp.long_name;
                            if (stateCode) {
                                $scope.selectedAirports = [];
                                $scope.location = null;
                                this.onSelectState($scope, {
                                    value: stateCode,
                                    text: stateName
                                });
                                return;
                            }
                        }
                        // Otherwise, perform a radius search around the geocoded lat/lon of the selected result.
                        if (result.geometry && result.geometry.location) {
                            var location = result.geometry.location;
                            $scope.location = {
                                lat: location.lat(),
                                lon: location.lng()
                            };
                            $scope.selectedAirports = [];
                            $scope.selectedStates = [];
                            this.search($scope);
                        }
                    }
                });
        }
    }

    zoomEvents ($scope, events) {
        if (!events || events.length < 1) {
            return;
        }

        // Calculate the minimum extent that fits all event locations
        var bounds = new google.maps.LatLngBounds();
        events.forEach(event => {
            var loc = event.location;
            if (loc) {
                var pos = new google.maps.LatLng(loc.lat, loc.lon);
                bounds.extend(pos);
            }
        })

        // Add an offset around the center so as to establish a maximum zoom
        var offset = 0.002;
        var center = bounds.getCenter();
        bounds.extend(new google.maps.LatLng(center.lat() + offset, center.lng() + offset));
        bounds.extend(new google.maps.LatLng(center.lat() - offset, center.lng() - offset));

        // Apply the bounds to the map view
        $scope.map.fitBounds(bounds);
    }

    clearSelectedItem ($scope) {
        $scope.query = '';
        $scope.selectedItem = undefined;
        // Close autocomplete menu by focusing the search button. This causes the search field itself
        // to lose focus, causing the autocomplete menu to close. A bit of a hack, but couldn't find a
        // straightforward way to do this that actually worked...
        this.$timeout(() => {
            $('#event-search-btn').focus();
        }, 100);
    }

    search ($scope, resetPage=true) {
        Promise.all([
            $scope.statePromise,
            $scope.regionsPromise
        ]).then(() => {
            if (resetPage) {
                $scope.currentPage = 1;
            }
            const dateFormat = 'yyyy-MM-dd';
            const query = {
                content: $scope.query || '',
                states: this.getStateQuery($scope) || [],
                airports: this.getAirportQuery($scope) || [],
                categories: ($scope.eventCategories || []).filter(c => c.selected).map(c => c.id) || [],
                offset: ($scope.currentPage - 1) * $scope.pageSize,
                limit: $scope.pageSize
            };
            if ($scope.location) {
                query.lat = $scope.location.lat;
                query.lon = $scope.location.lon;
                query.radius = $scope.RADIUS;
            }
            if ($scope.dateRange.dateStart) {
                query.startDate = this.$filter('date')($scope.dateRange.dateStart, dateFormat);
            }
            if ($scope.dateRange.dateEnd) {
                query.endDate = this.$filter('date')($scope.dateRange.dateEnd, dateFormat);
            }
            console.log('Search:', query);
            $scope.loading = true;
            $scope.error = false;
            this.eventService.search(query)
                .then(result => {
                    $scope.totalResults = result.total;
                    $scope.events = result.data;
                    this.zoomEvents($scope, $scope.events);
                })
                .catch(err => {
                    $scope.error = true;
                })
                .finally(() => {
                    $scope.loading = false;
                });
        });
    }

    getStateQuery ($scope) {
        if ($scope.selectedStates && $scope.selectedStates.length) {
            return $scope.selectedStates.map(s => s.value);
        }
        const selectedRegions = $scope.regions.filter(r => r.selected);
        return _.flatten(selectedRegions.map(r => r.states.map(s => s.code)));
    }

    getAirportQuery ($scope) {
        let airports = [];
        $scope.selectedAirports.forEach(item => {
            const { icaoId, faaId } = item.airport;
            if (icaoId) {
                airports.push(icaoId);
            }
            if (faaId) {
                airports.push(faaId);
            }
        });
        return airports;
    }

    querySearch ($scope, query) {
        if (!this.googleService.ready || !query || query.length < 3) {
            return [];
        }
        return this.allSettled([
            this.eventService.search({ content: query }),
            this.airportService.search(query),
            this.googleService.getPlacePredictions({
                input: query,
                types: ['geocode'],
                componentRestrictions: {
                    country: 'us'
                }
            })
        ]).then(([eventResult, airportResult, googleResult]) => {
            return [{
                    id: null,
                    type: 'category',
                    category: 'Events:',
                    display: ''
                }]
                .concat(this.convertEventResults(eventResult))
                .concat({
                    id: null,
                    type: 'category',
                    category: 'Airports:',
                    display: ''
                })
                .concat(this.convertAirportResults(airportResult, 5))
                //.concat({
                //    id: null,
                //    type: 'category',
                //    category: 'Locations:',
                //    display: '',
                //    attribution: 'Powered by Google',
                //    attributionUrl: '/dist/images/google-attribution/desktop/powered_by_google_on_white.png'
                //})
                //.concat(this.convertGoogleResults(googleResult));
        });
    }

    allSettled (promises) {
        var mapValues = function(obj, callback) {
            if (angular.isArray(obj)) {
                return obj.map(callback);
            }
            var ret = {};
            Object.keys(obj).forEach(function(key, val) {
                ret[key] = callback(obj[key], key);
            });
            return ret;
        }

        return this.$q.all(mapValues(promises, function(promiseOrValue) {
            if (! promiseOrValue.then) {
                return { state: 'fulfilled', value: promiseOrValue };
            }
            return promiseOrValue.then(function(value) {
                return { state: 'fulfilled', value: value };
            }, function(reason) {
                return { state: 'rejected', reason: reason };
            });
        }));
    }

    convertEventResults (result) {
        if (result.state === 'fulfilled') {
            var events = result.value.data;
            if (events && events.length) {
                return events.slice(0, 5).map(event => {
                    var display = event.name;
                    var address = event.address;
                    if (address && address.city && address.state) {
                        display = display + ' (' + address.city + ', ' + address.state + ')';
                    }
                    return {
                        id: event.id,
                        type: 'result',
                        subtype: 'event',
                        event: event,
                        display
                    };
                });
            }
            return {
                id: null,
                type: 'placeholder',
                message: 'No events found matching your query.'
            };
        } else {
            console.error('Event search failed:', result);
            return {
                id: null,
                type: 'error',
                message: 'An error occurred searching events. Please try again later.'
            };
        }
    }

    convertAirportResults (result, maxAirports) {
        if (result.state === 'fulfilled') {
            var airports = result.value;
            if (airports && airports.length) {
                return airports.slice(0, maxAirports).map(airport => {
                    const display = (airport.icaoId || airport.faaId || '')
                        + ': ' + airport.name
                        + ` (${airport.city}, ${airport.stateProvince})`;
                    return {
                        id: airport.airportId,
                        type: 'result',
                        subtype: 'airport',
                        airport: airport,
                        display: display
                    };
                });
            }
            return {
                id: null,
                type: 'placeholder',
                message: 'No airports found matching your query.'
            };
        } else {
            console.error('Airport search failed:', result);
            return {
                id: null,
                type: 'error',
                message: 'An error occurred searching airports. Please try again later.'
            };
        }
    }

    convertGoogleResults (result) {
        if (result.state === 'fulfilled') {
            var locations = result.value;
            if (locations && locations.length) {
                return locations.slice(0, 5).map(r => {
                    return {
                        id: r.id,
                        type: 'result',
                        subtype: 'location',
                        location: r,
                        display: r.description
                    };
                });
            }
            return {
                id: null,
                type: 'placeholder',
                message: 'No locations found matching your query.'
            };
        } else {
            console.error('Google search failed:', result);
            return {
                id: null,
                type: 'error',
                message: 'An error occurred searching locations. Please try again later.'
            };
        }
    }

    getEventUrl (event) {
        if (!event) {
            return null;
        }
        const baseUrl = 'https://hangar.aopa.org/events/item';
        if (event.groupId) {
            return `${baseUrl}/${event.groupId}/${event.categoryId}/${event.id}`;
        }
        return `${baseUrl}/${event.categoryId}/${event.id}`;
    }

    onSelectState ($scope, state) {
        if (state && $scope.selectedStates.indexOf(state) === -1) {
            $scope.selectedAirports = [];
            $scope.airportQuery = '';
            this.clearRegions($scope);
            state.selected = true;
            $scope.selectedStates.push(state);
            this.search($scope);
        }
    }

    onDeselectState ($scope, state) {
        state.selected = false;
        const i = $scope.selectedStates.indexOf(state);
        if (i >= 0) {
            $scope.selectedStates.splice(i, 1);
            this.search($scope);
        }
    }

    stateSearch ($scope, query) {
        return $scope.statePromise
            .then(states => {
                if (query) {
                    const lowercaseQuery = angular.lowercase(query);
                    return states.filter(state => {
                        const code = (state.value || '').toLowerCase();
                        const name = code ? (state.text || '').toLowerCase() : '';
                        return (code.indexOf(lowercaseQuery) === 0) || (name.indexOf(lowercaseQuery) === 0);
                    });
                }
                return $scope.states;
            });
    }

    onSelectAirport ($scope, item) {
        if (item && $scope.selectedAirports.indexOf(item) === -1) {
            $scope.selectedStates = [];
            $scope.stateQuery = '';
            this.clearRegions($scope);
            item.selected = true;
            $scope.selectedAirports.push(item);
            this.search($scope);
        }
    }

    onDeselectAirport ($scope, item) {
        item.selected = false;
        const i = $scope.selectedAirports.indexOf(item);
        if (i >= 0) {
            $scope.selectedAirports.splice(i, 1);
            this.search($scope);
        }
    }

    airportSearch ($scope, query) {
        if (!query || query.length < 3) {
            return [];
        }
        return this.airportService.search(query)
            .then(airportResults => {
                return this.convertAirportResults({ state: 'fulfilled', value: airportResults }, 10);
            });
    }

    clearRegions ($scope) {
        $scope.regions.forEach(region => region.selected = false);
    }

    onToggleRegion ($scope, region) {
        if (region.selected) {
            $scope.selectedAirports = [];
            $scope.airportQuery = '';
            $scope.selectedStates = [];
            $scope.stateQuery = '';
        }
        this.search($scope);
    }

    onToggleCategory ($scope, category) {
        this.search($scope);
    }

    onSelectDateRange ($scope) {
        this.search($scope);
    }

    clearDateRange ($scope) {
        $scope.dateRange.selectedTemplate = null;
        $scope.dateRange.selectedTemplateName = null;
        $scope.dateRange.dateStart = null;
        $scope.dateRange.dateEnd = null;
        this.search($scope);
    }

    pageChanged ($scope, newPage) {
        $scope.currentPage = newPage;
        this.search($scope, false);
    }

    onMarkerClick ($scope, clickEvent, event) {
        var url = this.eventService.getEventUrl(event);
        if (url) {
            window.open(url);
        }
    }
}

EventSearch.directiveFactory.$inject = ['$q', '$http', '$timeout', '$filter', 'airportService', 'eventService', 'googleService', 'NgMap'];

export default EventSearch;
