﻿$(document).ready(function () {
    if ($('div#divAafComplimentaryMembership').length) {
        var aafForm = $('#frmAAFCMF');
        var flagSearch = "Search";
        var flagClearSearch = "ClearSearch";
        var flagCreateCustomer = "CreateCustomer";
        var flagCreateOrder = "CreateOrder";
        var flagLoadCreateOrder = "LoadCreateOrder";
        var flagSearchReturn = "SearchReturn";
        var flagLoadCreateCustomer = "LoadCreateCustomer";

        function doTheSpinner() {
            $('#divButtons').hide();
            $('#divButtonContainer').append('<div class="spinner spinner--clockwise" style="position: relative;"></div>');
        }

        //
        // Search click
        //
        $('#btnSearch').click(function () {
            $('#FormMode').val(flagSearch);

            if (aafForm.valid()) {
                doTheSpinner();
                aafForm.submit();
            }
        });

        //
        // Load Create New Customer click
        //
        $('#btnCreateNewCustomer').click(function () {
            $('#FormMode').val(flagLoadCreateCustomer);
            doTheSpinner();
            aafForm.submit();
        });

        //
        // Create New Customer click
        //
        $('#btnCreateCustomer').click(function () {
            $('#FormMode').val(flagCreateCustomer);

            if (aafForm.valid()) {
                doTheSpinner();
                aafForm.submit();
            }
        });

        //
        // Clear Search click
        //
        $('#btnClearSearch').click(function () {
            $('#FormMode').val(flagClearSearch);
            doTheSpinner();
           aafForm.submit();
        });

        //
        // Back to search
        //
        $('.back-to-search').click(function () {
            $('#FormMode').val(flagSearchReturn);

            if ($("div#divCreateCustomer").length) {
                $("#divCreateCustomer *").prop('disabled', true);
            }

            $('#divCreateCustomerSection').hide();
            $('#divCheckSection').hide();
            $('#divOrderData').hide();
            $('.event-signin__process-message').hide();
            $('#divSpinner').show();
            aafForm.submit();
        });

        //
        // Goto create order
        //
        $(document).on("click",
            ".create-order-button",
            function (e) {
                $('#FormMode').val(flagLoadCreateOrder);
                $('#CreateOrderMasterCustomerId').val($(this).data("mcid"));
                $('#CreateOrderSubCustomerId').val($(this).data("scid"));
                doTheSpinner();
                aafForm.submit();
            });

        //
        // Create the order
        //
        $('.create-registration').click(function () {
            if (aafForm.valid()) {
                $('#FormMode').val(flagCreateOrder);
                doTheSpinner();
                aafForm.submit();
            }
        });
    };
});