﻿export default function slickSlider() {
    return {
        restrict: 'A',
        scope: { 'data': '=' },
        link: function (scope, element, attrs) {
            var isInitialized = false;
            scope.$watch('data',
                function (newVal, oldVal) {
                    if (newVal.length > 0 && !isInitialized) {
                        $(element).slick(scope.$eval(attrs.slickSlider));
                        isInitialized = true;
                    }
                });
        }
    }
};