﻿$(document).ready(function() {
    if ($('div#divFormAccountCreate').length) {
        var theForm = $('#frmFormAccountCreate');

        $(".actionSubmitButton").click(function () {

            var progressBar = new Sfpb_progressBar();

            if (theForm.valid()) {
                $('#btnAction').hide();
                $(this).hide();
                $('body').addClass('progressbar-modal');
                progressBar.start();
                $(".clsSubmitButton").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');
                theForm.submit();
            } else {
                var firstInstance = $('.input-validation-error').first();
                var offsetTop = firstInstance.offset().top - 100;

                $('html, body').animate({
                    scrollTop: offsetTop
                }, 500);

            }
        });

    }
});
