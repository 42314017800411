﻿$(document)
    .ready(function () {
        if ($('div#divSsoAccountCreate').length) {
            var theForm = $('#frmSsoAccountCreate');


            $(".ssoRegisterButton").click(function () {
                if (theForm.valid()) {
                    $(this).hide();
                    $(".clsSubmit").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
                else {
                    var firstInstance = $('.input-validation-error').first();
                    var offsetTop = firstInstance.offset().top - 100;

                    $('html, body').animate({ scrollTop: offsetTop }, 500);
                }
            });

        }
    });
