﻿(function ($) {
    $.validator.addMethod("dropdowntextcheck", function (value, element, params) {
        var valSelected = $('#' + params.dropdownfield).find('option:selected').val();

        if (valSelected === params.dropdownvalue && value === '') {
            return false;
        }

        if (valSelected === params.dropdownvalue && params.datacheck.trim()) {
            switch(params.datacheck) {
                case "Integer":
                    var theMin = parseInt($('#' + params.minimumamountfield).val(), 10);
                    var theValue = parseInt(value, 10);

                    if (isNaN(theMin) || isNaN(theValue) || theValue.toString() !== value || theMin.toString() !== $('#' + params.minimumamountfield).val() || theValue < theMin) {
                        return false;
                    }
                    break;
            } 
        }

        return true;
    });

    jQuery.validator.unobtrusive.adapters.add("dropdowntextcheck", ["dropdownfield", "dropdownvalue", "datacheck", "minimumamountfield"],
        function (options) {
            options.rules['dropdowntextcheck'] = {
                dropdownfield: options.params.dropdownfield,
                dropdownvalue: options.params.dropdownvalue,
                datacheck: options.params.datacheck,
                minimumamountfield: options.params.minimumamountfield
        };
            options.messages['dropdowntextcheck'] = options.message;
        });

}(jQuery));
