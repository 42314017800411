﻿import MockWeatherService from '../services/MockWeatherService';

class WeatherWidget {
    constructor ($q, weatherService) {
        this.$q = $q;
        this.weatherService = weatherService;
        this.templateUrl = '/js/aopa/weather/templates/weather.html';
        this.restrict = 'E';
        this.scope = {
            mock: '=',
            ident: '@',
            lat: '=?',
            lon: '=?'
        };
        this.controller = ['$scope', $scope => {
            $scope.formatWind = () => this.formatWind($scope);
            $scope.getIcon = () => this.getIcon($scope);
            $scope.getCategoryIcon = () => this.getCategoryIcon($scope);
            $scope.getTooltip = () => this.getTooltip($scope);
            $scope.geolocationEnabled = "geolocation" in navigator;
            $scope.getLocation = () => this.getLocation($scope);
            this.load($scope);
        }];
    }

    static directiveFactory ($q, weatherService) {
        WeatherWidget.instance = new WeatherWidget($q, weatherService);
        return WeatherWidget.instance;
    }

    link ($scope, $element, $attributes) {}

    clear ($scope) {
        $scope.data = null;
        $scope.error = null;
        $scope.loading = false;
        $scope.iconCls = "wi-na";
        $scope.tooltip = null;
    }

    load ($scope) {
        this.clear($scope);

        if ($scope.mock) {
            $scope.data = MockWeatherService.getMetar();
            $scope.iconCls = this.getIcon($scope);
            $scope.tooltip = this.getTooltip($scope);
            return;
        }

        let query;
        if ($scope.lat && $scope.lon) {
            query = this.weatherService.getMetarByLatLon($scope.lat, $scope.lon);
        }
        else if ($scope.ident) {
            query = this.weatherService.getMetarByIdent($scope.ident);
        }

        if (query) {
            $scope.loading = true;
            query
                .then(data => {
                    $scope.data = data;
                    $scope.iconCls = this.getIcon($scope);
                    $scope.tooltip = this.getTooltip($scope);
                })
                .catch(err => {
                    $scope.error = err.message;
                })
                .finally(() => {
                    $scope.loading = false;
                });
        }
    }

    formatWind ($scope) {
        if (!$scope.data) {
            return "";
        }

        if ($scope.data.wind_speed) {
            if ($scope.data.wind_gust) {
                return `${$scope.data.wind_speed} - ${$scope.data.wind_gust}`;
            }

            return String($scope.data.wind_speed);
        }

        return 'CALM';
    }

    getIcon ($scope) {
        // Return neutral icon if no data is loaded
        if (!$scope.data) {
            return 'wi-na';
        }

        // If significant weather phenomena are present, return the icon associated with that
        // phenomena.
        let type = this.weatherService.getWxType($scope.data.wx_string);
        if (type != null) {
            return type.icon;
        }

        // Otherwise, return icon for cloudy, partly cloudy, or sunny depending on what cloud
        // layers are present.
        let skc = $scope.data.sky_conditions_trans;
        if (skc && skc.length >= 1) {
            let layers = skc.map(layer => layer.sky_cover);

            // Return cloudy if there is at least one overcast or broken cloud layer
            if (layers.indexOf('Overcast') >= 0 || layers.indexOf('Broken clouds') >= 0) {
                return 'wi-cloudy';
            }

            // Return partly sunny if there is at least one scattered/few cloud layer
            if (layers.indexOf('Scattered clouds') >= 0 || layers.indexOf('Few clouds') >= 0) {
                return 'wi-day-cloudy';
            }
        }

        // Otherwise, return sunny
        return 'wi-day-sunny';
    }

    getCategoryIcon ($scope) {
        if ($scope.error) {
            return 'error';
        }

        if ($scope.loading) {
            return 'loading';
        }

        if (!$scope.data || !$scope.data.flight_category) {
            return 'na';
        }

        return $scope.data.flight_category.toLowerCase();
    }

    getTooltip ($scope) {
        let wx = this.weatherService.translateWx($scope.data.wx_string);
        let clouds = this.weatherService.getCloudLayersText($scope.data.sky_conditions_trans);
        return [wx, clouds].filter(t => t).join(', ') || null;
    }

    getLocation ($scope) {
        navigator.geolocation.getCurrentPosition(pos => {
            $scope.lat = pos.coords.latitude;
            $scope.lon = pos.coords.longitude;
            this.load($scope);
        }, error => {
            $scope.$apply(() => {
                this.clear($scope);
                $scope.error = `Unable to detect location: ${error.message || "An unknown error occurred while attempting to use the browser's geolocation service."}`;
            });
        });
    }
}

WeatherWidget.directiveFactory.$inject = ['$q', 'weatherService'];

export default WeatherWidget;
