﻿/*
Custom script for validating CC Expiration Date
*/
(function ($) {
    $.validator.addMethod("airportcodecheck", function (value, element, params) {

        var result = false;

        var data = $.trim(value);


        if (data.length > 0) {
            if (value.length >= 3) {
                $.ajax({
                    url: "/api/AirportCodeValidator?airportCode=" + data,
                    async: false,
                    dataType: "json",
                    success: function(returnData) {
                        result = returnData.data;
                    }
                });
            }
        } else {
            result = true;
        }

        return result;
    });

    $.validator.unobtrusive.adapters.addBool("airportcodecheck");
}(jQuery));
