﻿$(document).ready(function () {
    if ($('div#asnNominationForm').length) {
        var theForm = $('#frmAsnNomination');


        $(".asn-cur-inv").click(function () {
            var checkVals = $('.asn-cur-inv:checkbox:checked').map(function () {
                return this.dataset.checkValue;
            }).get();


            $('#CurrentlyInvovledSelected').val(checkVals.join("|"));
        });



        //$('#AirportAssociationSelected').change(function () {
        //    var option = $(this).find('option:selected').val();

        //    if (option === "Yes") {
        //        $('#divAirportAssociationOther').show();
        //    } else {
        //        $('#divAirportAssociationOther').hide();
        //    }
        //});




        $(".submitButtonAsnNom").click(function () {
            if (theForm.valid()) {
                if ($('#TermsAgreed').val() === "AGREED") {
                    $('#btnSubmitNomination').hide();
                    $(this).hide();
                    $(".clsSubmitButton").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                } else {
                    LaunchTermsModal();

                }
            }
        });
    };
});
