﻿$(document)
    .ready(function () {
        if ($('div#divAftaAopaUserAdminForm').length) {
            var theForm = $('#frmAftaAopaUserAdmin');

            $(".lookupButtonAftaAopaUser").click(function () {
                if (theForm.valid()) {
                    $('#FormMode').val('Lookup');


                    $('#btnSearch').hide();
                    $('.afta-aopauser-process').hide();
                    $(this).hide();
                    $(".clsLookup").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });

            $(".processButtonAftaAopaUser").click(function () {
                if (theForm.valid()) {
                    $('#FormMode').val('Process');


                    $('#btnSearch').hide();
                    $('#btnProcess').hide();
                    $(this).hide();
                    $(".clsProcess").append('<div class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            });
        }
    });