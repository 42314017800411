export default function datepickerbutton() {
/* 
 * Directive: datepickerbutton
 * Make a click on datepicker's sibling button trigger the picker
 */
	return {
		restrict: 'A',
		link : function (scope, element, attrs, ngModelCtrl) {
			$(function(){
				element.click(function() {
					$(this).siblings("input").datepicker( "show" );
				});
			});
		}
	}
}