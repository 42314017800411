export default () => {
  const burgerBtn = document.getElementById("mobileToggle");
  const mobileDrawer = document.getElementById("mobileDrawer");
  const burgerIcon = document.getElementById("burgerIcon");
  const closeIcon = document.getElementById("closeIcon");

  burgerBtn.addEventListener("click", () => {
    burgerBtn.classList.toggle("js-mobileBtn--clicked");

    if (burgerBtn.classList.contains("js-mobileBtn--clicked")) {
      burgerIcon.classList.add("hidden");
      closeIcon.classList.remove("hidden");
      mobileDrawer.classList.remove("hidden");
    } else {
      burgerIcon.classList.remove("hidden");
      closeIcon.classList.add("hidden");
      mobileDrawer.classList.remove("js-dropdown--open");
      mobileDrawer.classList.add("hidden");
    }
  });
};
