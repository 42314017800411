﻿$(document).ready(function() {
    if ($('div#divOneClickDonationCombined').length) {


        $('input[name=AcceptPremium]').on('change', function () {
            var $option;
            if ($('#HasPremiumsAvailable').val().toUpperCase() === "TRUE" && $('#ShowPremiumList').val().toUpperCase() === "TRUE") {
                if ($('input[name=AcceptPremium]:checked').val() !== "true") {
                    $("#divPremium").hide();
                } else {
                    $("#divPremium").show();
                }
            }
        });

        $('#SelectedPremiumItem').change(function () {
            var option = $(this).find('option:selected').val().split("|");
            $.getJSON('/api/OneClickPremium/Get/?productId=' + option[0])
                .done(function (data) {
                    $('#SelectedPremiumRateCode').empty();
                    var $option = $("<option />");
                    $option.attr("value", "").text($('#PremiumRateSelect').val());
                    $('#SelectedPremiumRateCode').append($option);
                    $.each(data, function (key, item) {
                        var $option = $("<option />");
                        $option.attr("value", item.value).text(item.text);
                        $('#SelectedPremiumRateCode').append($option);
                    });
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#SelectedPremiumRateCode').empty();
                    var $option = $("<option />");
                    $options.attr("value", "").text("- Error -");
                    $('#SelectedPremiumRateCode').append($option);
                });
        });

        $(".donateButton").click(function () {
            var form = $(this).closest('form');
            if (form.valid()) {
                $(this).hide();
                $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
            }
        });

        $('#SelectedCountry').change(function () {
            var option = $(this).find('option:selected').val();
            $.getJSON('/api/CountryState/GetStates/?countryCode=' + option)
                .done(function (data) {
                    $('#SelectedState').empty();
                    $.each(data, function (key, item) {
                        var $option = $("<option />");
                        $option.attr("value", item.value).text(item.text);
                        $('#SelectedState').append($option);
                    });
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('#SelectedState').empty();
                    var $option = $("<option />");
                    $options.attr("value", "").text("- Error -");
                    $('#SelectedState').append($option);
                });
        });

        $('input[name=SelectedDonationAmount]').on('change', function () {
            if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER") {
                if ($('#MinimumDonationAmount').length) {
                    $('#MinimumDonationAmount').val('');
                    $('#MinimumDonationAmount').valid();
                    $('#MinimumDonationAmount').prop("disabled", true);
                    $("#divOtherAmount").hide();
                }
            } else {
                $('#MinimumDonationAmount').prop("disabled", false);
                $("#divOtherAmount").show();
            }

            fixFrequency($('input[name=SelectedDonationAmount]:checked').val());
            fixAdditionalText($('input[name=SelectedDonationAmount]:checked').val());
            calculateTotal();
            fixDonationAmount($('input[name=SelectedDonationAmount]:checked').val());
            fixProductPremiums($('input[name=SelectedDonationAmount]:checked').val());
            appealPremiumsUpdate();
        });


        $('input[name=MinimumDonationAmount]').on('change', function () {
            fixFrequency($('input[name=SelectedDonationAmount]:checked').val());
            fixAdditionalText($('input[name=SelectedDonationAmount]:checked').val());
            calculateTotal();
            fixDonationAmount($('input[name=SelectedDonationAmount]:checked').val());
            fixProductPremiums($('input[name=SelectedDonationAmount]:checked').val());
            appealPremiumsUpdate();
        });

        $('input[name=SelectedRegularDonationFrequency]').on('change', function () {
            calculateTotal();
            fixDonationAmount($('input[name=SelectedDonationAmount]:checked').val());
            fixProductPremiums($('input[name=SelectedDonationAmount]:checked').val());
            appealPremiumsUpdate();
        });

        $('input[name=SelectedPledgeDonationFrequency]').on('change', function () {
            calculateTotal();
            fixDonationAmount($('input[name=SelectedDonationAmount]:checked').val());
            fixProductPremiums($('input[name=SelectedDonationAmount]:checked').val());
            appealPremiumsUpdate();
        });



        function calculateTotal() {
            var theType = "REGULAR";
            var theAmount = 0;
            var thePayments = 1;

            if ($('input[name=SelectedDonationAmount]:checked').val() !== "OTHER") {
                var data = $('input[name=SelectedDonationAmount]:checked').val().split('|');
                theType = data[1];
                theAmount = data[0];

                if (data[1] === "PLEDGE") {
                    var info = $('input[name=SelectedPledgeDonationFrequency]:checked').val().split('|');
                    thePayments = info[1];
                }
            } else {
                var data = JSON.parse($('#OtherAmountsString').val());

                if ($.isNumeric($('#MinimumDonationAmount').val())) {
                    theAmount = parseInt($('#MinimumDonationAmount').val());
                    $.each(data, function () {
                        if (this.otherMinimum <= theAmount && this.otherMaximum > theAmount) {
                            theType = this.amountType;

                            if (this.amountType === "PLEDGE") {
                                var info = $('input[name=SelectedPledgeDonationFrequency]:checked').val().split('|');
                                thePayments = info[1];
                            }
                        }
                    });

                }
            }

            $('#PaymentTotal').val(parseFloat((theAmount / thePayments), 10).toFixed(2));
            $('#DonationTotal').val(parseFloat(theAmount).toFixed(2));
            $('#divPaymentAmount').html('$' + parseFloat((theAmount / thePayments), 10).toFixed(2));
        }

        function appealPremiumsUpdate() {
            var total = parseFloat($('#DonationTotal').val(), 10);
            var hasQualPremiums = false;
            var qualVal = 0;


            for (var i = 1; i <= 3; i++) {
                if ($('#OfferPremium' + i + 'QualValue').val()) {
                    qualVal = parseFloat($('#OfferPremium' + i + 'QualValue').val(), 10);
                    if (qualVal <= total) {
                        hasQualPremiums = true;
                        $('.divAppealPremiumItem' + i).show();
                        $('#OfferPremium' + i + 'Show').val('True');
                    } else {
                        $('.divAppealPremiumItem' + i).hide();
                        $('#OfferPremium' + i + 'Show').val('False');
                    }
                } else {
                    $('.divAppealPremiumItem' + i).hide();
                    $('#OfferPremium' + i).prop('checked', false);
                    $('#OfferPremium' + i + 'Show').val('False');
                }
            }

            if (hasQualPremiums) {
                $('.divAppealPremium').show();

            } else {
                $('.divAppealPremium').hide();
            }
            $('#hasQualifiedOfferPremium').val(hasQualPremiums);
        }


        function fixFrequency(valSelected) {
            if (valSelected !== "OTHER") {
                var data = valSelected.split('|');

                if (data[1] === "REGULAR") {
                    $('.occ-frequency-selection[data-freq-type="REGULAR"]').show();
                    $('.occ-frequency-selection[data-freq-type="PLEDGE"]').hide();
                }
                if (data[1] === "PLEDGE") {
                    $('.occ-frequency-selection[data-freq-type="REGULAR"]').hide();
                    $('.occ-frequency-selection[data-freq-type="PLEDGE"]').show();
                }
            } else {
                var data = JSON.parse($('#OtherAmountsString').val());

                if ($.isNumeric($('#MinimumDonationAmount').val())) {
                    var amount = parseInt($('#MinimumDonationAmount').val());

                    $.each(data, function () {

                        if (this.otherMinimum <= amount && this.otherMaximum > amount) {
                            if (this.amountType === "REGULAR") {
                                $('.occ-frequency-selection[data-freq-type="REGULAR"]').show();
                                $('.occ-frequency-selection[data-freq-type="PLEDGE"]').hide();
                            }
                            if (this.amountType === "PLEDGE") {
                                $('.occ-frequency-selection[data-freq-type="REGULAR"]').hide();
                                $('.occ-frequency-selection[data-freq-type="PLEDGE"]').show();
                            }
                        }
                    });

                } else {
                    $('.occ-frequency-selection[data-freq-type="REGULAR"]').show();
                    $('.occ-frequency-selection[data-freq-type="PLEDGE"]').hide();
                }
            }
        }

        function fixAdditionalText(valSelected) {
            if (valSelected !== "OTHER") {
                var data = valSelected.split('|');

                $('.occ-addtional-text-wrapper').each(function() {
                    if ($(this).data('text-type') === data[2]) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            } else {
                var data = JSON.parse($('#OtherAmountsString').val());

                if ($.isNumeric($('#MinimumDonationAmount').val())) {
                    var amount = parseInt($('#MinimumDonationAmount').val());

                    $.each(data, function () {
                        if (this.otherMinimum <= amount && this.otherMaximum > amount) {
                            var theData = this;
                            $('.occ-addtional-text-wrapper').each(function () {
                                if ($(this).data('text-type') === theData.additionalTextType) {
                                    $(this).show();
                                } else {
                                    $(this).hide();
                                }
                            });
                        }
                    });

                } else {
                    //$('.occ-addtional-text-wrapper').hide();
                }
            }
        }

        function fixProductPremiums(valSelected) {
            if (valSelected !== "OTHER") {
                var data = valSelected.split('|');

                $('.occ-product-premium-wrapper').each(function () {
                    if ($(this).data('prem-section') === data[3]) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            } else {
                var data = JSON.parse($('#OtherAmountsString').val());

                if ($.isNumeric($('#MinimumDonationAmount').val())) {
                    var amount = parseInt($('#MinimumDonationAmount').val());

                    $.each(data, function () {
                        if (this.otherMinimum <= amount && this.otherMaximum > amount) {
                            var theData = this;
                            $('.occ-product-premium-wrapper').each(function () {
                                if ($(this).data('prem-section') === theData.premiumSectionType) {
                                    $(this).show();
                                } else {
                                    $(this).hide();
                                }
                            });
                        }
                    });

                } else {
                    $('.occ-product-premium-wrapper').hide();
                }
            }
        }

        function fixDonationAmount(valSelected) {
            if (valSelected !== "OTHER") {
                var data = valSelected.split('|');

                if (data[1] === "REGULAR") {
                    $('.occ-donation-type-pledge').hide();
                } else {
                    $('.occ-donation-type-pledge').show();
                }
            } else {
                var data = JSON.parse($('#OtherAmountsString').val());

                if ($.isNumeric($('#MinimumDonationAmount').val())) {
                    var amount = parseInt($('#MinimumDonationAmount').val());

                    $.each(data, function () {
                        if (this.otherMinimum <= amount && this.otherMaximum > amount) {
                            if (this.amountType === "REGULAR") {
                                $('.occ-donation-type-pledge').hide();
                            } else {
                                $('.occ-donation-type-pledge').show();
                            }
                        }
                    });

                } else {
                    $('.occ-donation-type-pledge').hide();
                }
            }
        }

    }
});
