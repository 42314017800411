﻿$(document)
    .ready(function() {
        if ($('div#divGlobalMemberUpgrade').length) {

            $('#SelectedRateCode')
                .change(function() {
                    var rateSel = $(this).find('option:selected').val().split("|");
                    var total = parseFloat(rateSel[1]);
                    $('#FinalPrice').val(parseFloat(total, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString());
                    var totaltext = '$' + parseFloat(total, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
                    $('#order-total').html($.parseHTML(totaltext));
                });
        }
    });