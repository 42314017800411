﻿$(document).ready(function() {
    if ($('div#divAopaRenewFormV2').length) {
        var theForm = $('#frmAopaRenewFormV2');

        var rfStripe = Stripe($('#sapik').val());

        var rfElements = rfStripe.elements();
        var rfStyle = {
            base: {
                backgroundColor: '#ffffff',
                fontSize: '0.9375rem',
                fontFamily: 'Roboto, Helvetica Neue, sans-serif',
                lineHeight: '42px',
                ':focus': {
                    border: '10px solid black'
                }
            }
        };
        var rfCardElement = rfElements.create("card", { style: rfStyle, hidePostalCode: true, disableLink: true });
        rfCardElement.mount("#card-element");


        rfCardElement.on('change',
            function (event) {
                var displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }

                $('#CardBrand').val(event.brand);
            });

        var currentDate = new Date();

        $("#BirthDate").datepicker({
            changeMonth: true,
            changeYear: true,
            endDate: "currentDate",
            maxDate: currentDate,
            yearRange: (new Date).getFullYear() - 100 + ':' + (new Date).getFullYear()
        });

        $("#BirthDate").change(function() {
            if ($("#BirthDate").val()) {
                var bthDt = new Date($("#BirthDate").val())

                //var renewDate = $('#RenewalCycleBeginDate').val().split('/');
                //var dtNow = new Date(renewDate[2], renewDate[0] - 1, renewDate[2]);
                var dtNow = new Date($('#RenewalCycleBeginDate').val());
                var age = dtNow.getFullYear() - bthDt.getFullYear();

                if ((dtNow.getMonth() < bthDt.getMonth()) ||
                    (dtNow.getMonth() === bthDt.getMonth() && dtNow.getDate() < bthDt.getDate())) {
                    age -= 1;
                }

                if (age >= 75) {
                    $("#Is75Plus").val("True");
                } else {
                    $("#Is75Plus").val("False");
                }
            } else {
                $("#Is75Plus").val("False");
            }
            setTotal();

        });

        $('#SelectedPpsOption').change(function () {
            setAddOptions($("#SelectedMagazineOption option:selected").val());
            setTotal();
        });

        $('#SelectedMagazineOption').change(function () {
            $('#renewv2-form-data_summary_mag').html($("#SelectedMagazineOption option:selected").text() + " Magazine");

            setAddOptions($("#SelectedMagazineOption option:selected").val());
            setTotal();
        });

        function setAddOptions(rate) {
            var hasPPS = $('#SelectedPpsOption').val() != '';

            switch (rate) {
                case "MAG-PRINT-PILOT":
                    setCheckBox('#AddOnPilotPrint', true);
                    setCheckBox('#AddOnPilotDigital', hasPPS);
                    setCheckBox('#AddOnTurbinePilotPrint', false);
                    setCheckBox('#AddOnTurbinePilotDigital', false);
                    setCheckBox('#AddOnFtPrint', false);
                    setCheckBox('#AddOnFtDigital', false);
                    break;
                case "MAG-DIGITAL-PILOT":
                    setCheckBox('#AddOnPilotPrint', false);
                    setCheckBox('#AddOnPilotDigital', true);
                    setCheckBox('#AddOnTurbinePilotPrint', false);
                    setCheckBox('#AddOnTurbinePilotDigital', false);
                    setCheckBox('#AddOnFtPrint', false);
                    setCheckBox('#AddOnFtDigital', false);
                    break;
                case "MAG-PRINT-PILOT-TURBINE":
                    setCheckBox('#AddOnPilotPrint', false);
                    setCheckBox('#AddOnPilotDigital', false);
                    setCheckBox('#AddOnTurbinePilotPrint', true);
                    setCheckBox('#AddOnTurbinePilotDigital', hasPPS);
                    setCheckBox('#AddOnFtPrint', false);
                    setCheckBox('#AddOnFtDigital', false);
                    break;
                case "MAG-DIGITAL-PILOT-TURBINE":
                    setCheckBox('#AddOnPilotPrint', false);
                    setCheckBox('#AddOnPilotDigital', false);
                    setCheckBox('#AddOnTurbinePilotPrint', false);
                    setCheckBox('#AddOnTurbinePilotDigital', true);
                    setCheckBox('#AddOnFtPrint', false);
                    setCheckBox('#AddOnFtDigital', false);
                    break;
                case "MAG-PRINT-FT":
                    setCheckBox('#AddOnPilotPrint', false);
                    setCheckBox('#AddOnPilotDigital', false);
                    setCheckBox('#AddOnTurbinePilotPrint', false);
                    setCheckBox('#AddOnTurbinePilotDigital', false);
                    setCheckBox('#AddOnFtPrint', true);
                    setCheckBox('#AddOnFtDigital', hasPPS);
                    break;
                case "MAG-DIGITAL-FT":
                    setCheckBox('#AddOnPilotPrint', false);
                    setCheckBox('#AddOnPilotDigital', false);
                    setCheckBox('#AddOnTurbinePilotPrint', false);
                    setCheckBox('#AddOnTurbinePilotDigital', false);
                    setCheckBox('#AddOnFtPrint', false);
                    setCheckBox('#AddOnFtDigital', true);
                    break;
            }
        }

        function setCheckBox(field, disabled) {
            if (disabled) {
                $(field).prop('checked', false);
                $(field).parent().css('color', 'grey');
            } else {
                $(field).parent().css('color', '');
            }

            $(field).prop('disabled', disabled);
            $(field + 'Disabled').val(disabled);
        }

        $('#AarInterested').change(function () {
            updateAAR();
        });

        $('#IncludeAar').change(function () {
            updateAAR();
        });

        $('#AarIncentiveOptOut').change(function () {
            updateAAR();
        });

        var updateAAR = () => {
            if ($('#AarInterested').is(":checked")) {
                $('#divAARDisplay').show();

                if ($('#IncludeAar').is(":checked")) {
                    $('#renewv2-form-data_summary_aar').html("YES");

                    $('#divAarIncentiveOptOut').show();

                    if ($('#AarIncentiveShowOptOut').val() === "True") {
                        if ($('#AarIncentiveOptOut').is(":checked")) {
                            $('#renewv2-form-data_summary_aar_incentive_row').hide();
                        } else {
                            $('#renewv2-form-data_summary_aar_incentive_row').show();
                        }
                    } else {
                        $('#renewv2-form-data_summary_aar_incentive_row').show();

                    }
                } else {
                    $('#renewv2-form-data_summary_aar').html("NO");
                    $('#divAarIncentiveOptOut').hide();
                    $('#renewv2-form-data_summary_aar_incentive_row').hide();
                }
            } else {
                $('#divAARDisplay').hide();
                $('#renewv2-form-data_summary_aar').html("NO");
                $('#divAarIncentiveOptOut').hide();
                $('#renewv2-form-data_summary_aar_incentive_row').hide();
            }
        }






        $("#lnkKeyCode").click(function () {
            $('#divKeycodeForm').show();
        });


        $("#btnRenewKeyCode").click(function () {
            var form = $(this).closest('form');
            if ($('#KeyCodeEntered').val() && $('#KeyCodeEntered').val().toUpperCase() !== $('#KeyCode').val().toUpperCase()) {
                $.getJSON('/api/JoinKeyCodeV4/Get/?keycode=' + $('#KeyCodeEntered').val())
                    .done(function (data) {
                        $('#KeyCode').val(data.keycode);
                        if (data.promoCodeActive) {
                            $('#PromoCodeId').val(data.promoCodeId);
                            $('#PromoCode').val(data.promoCode);
                            if (data.discount > 0) {
                                $('#DiscountProduct').val(data.productCode);
                                $('#DiscountPercentage').val(data.discount);
                                $('#IncludePremium').val("False");
                                $('#PremiumProductCode').val("");
                                $('#PremiumAttribute').val("");
                                $('#imgPremium').attr('src', '');
                                //$('#divPremiumSpan').html('');
                                //$('#divSummaryPremiumText').html('');
                                //$('#PremiumDescription').val('');
                                $('#divPremiumWrapper').hide();
                                $('#divSummaryPremium').hide();
                            } else {
                                $('#DiscountProduct').val("");
                                $('#DiscountPercentage').val("");
                                $('#IncludePremium').val("True");
                                $('#PremiumProductCode').val(data.productCode);
                                $('#PremiumAttribute').val(data.productAttribute);
                                $('#imgPremium').attr('src', $('#PremiumImageRoot').val().replace("%%PRODUCT_CODE%%", data.premiumImage));
                                //$('#divPremiumSpan').html(data.premiumDescription);
                                //$('#divSummaryPremiumText').html(data.premiumDescription);
                                //$('#PremiumDescription').val(data.premiumDescription);
                                $('#divPremiumWrapper').show();
                                $('#divSummaryPremium').show();
                            }
                            setTotal();
                        } else {
                            $('#PromoCodeId').val("");
                            $('#PromoCode').val("");
                            $('#DiscountProduct').val("");
                            $('#DiscountPercentage').val("");
                            $('#IncludePremium').val("False");
                            $('#PremiumProductCode').val("");
                            $('#PremiumAttribute').val("");
                            $('#divPremiumWrapper').hide();
                            $('#divSummaryPremium').hide();
                            $('#imgPremium').attr('src', '');
                            //$('#divPremiumSpan').html('');
                            //$('#divSummaryPremiumText').html('');
                            //$('#PremiumDescription').val('');
                            $('#divPremiumWrapper').hide();
                            $('#divSummaryPremium').hide();

                        }
                        $('#divKeyCodeFail').hide();
                        $('#divKeyCodeSuccess').show();
                    })
                    .fail(function (jqXHR, textStatus, err) {
                        $('#divKeyCodeFail').show();
                        $('#divKeyCodeSuccess').hide();
                    });
            } else if ($('#KeyCodeEntered').val() && $('#KeyCodeEntered').val().toUpperCase() === $('#KeyCode').val().toUpperCase()) {
                $('#divKeyCodeFail').hide();
                $('#divKeyCodeSuccess').show();
            }
        });

        $('#AddOnPilotPrint').change(function () {
            if (this.checked) {
                $('#divAddOnPilotPrint').show();
                if ($('#IsInternational').val() == "True") {
                    $(".AddOnPilotPrint-ipf").show();
                }
            } else {
                $('#divAddOnPilotPrint').hide();
                if ($('#IsInternational').val() == "True") {
                    $(".AddOnPilotPrint-ipf").hide();
                }
            }
            setTotal();
        });

        $('#AddOnPilotDigital').change(function () {
            if (this.checked) {
                $('#divAddOnPilotDigital').show();
            } else {
                $('#divAddOnPilotDigital').hide();
            }
            setTotal();
        });

        $('#AddOnTurbinePilotPrint').change(function () {
            if (this.checked) {
                $('#divAddOnTurbinePilotPrint').show();
                if ($('#IsInternational').val() == "True") {
                    $(".AddOnTurbinePilotPrint-ipf").show();
                }
            } else {
                $('#divAddOnTurbinePilotPrint').hide();
                if ($('#IsInternational').val() == "True") {
                    $(".AddOnTurbinePilotPrint-ipf").hide();
                }
            }
            setTotal();
        });

        $('#AddOnTurbinePilotDigital').change(function () {
            if (this.checked) {
                $('#divAddOnTurbinePilotDigital').show();
            } else {
                $('#divAddOnTurbinePilotDigital').hide();
            }
            setTotal();
        });

        $('#AddOnFtPrint').change(function () {
            if (this.checked) {
                $('#divAddOnFTPrint').show();
                if ($('#IsInternational').val() == "True") {
                    $(".AddOnFtPrint-ipf").show();
                }

            } else {
                $('#divAddOnFTPrint').hide();
                if ($('#IsInternational').val() == "True") {
                    $(".AddOnFtPrint-ipf").hide();
                }
            }
            setTotal();
        });

        $('#AddOnFtDigital').change(function () {
            if (this.checked) {
                $('#divAddOnFTDigital').show();
            } else {
                $('#divAddOnFTDigital').hide();
            }
            setTotal();
        });

        $('#SelectedDonationAmount').change(function () {
            var option = $(this).find('option:selected').val();
            var optionText = $(this).find('option:selected').text();

            if (option !== "OTHER|0") {
                if ($('#MinimumDonationAmount').length) {
                    $('#MinimumDonationAmount').val('');
                    $('#MinimumDonationAmount').valid();
                    $('#MinimumDonationAmount').prop("disabled", true);
                    $("#divOtherAmount").hide();

                    if (option !== "0|0") {
                        $('#divSummaryDonationPrice').html(optionText);
                        $('#divSummaryDonationRow').show();
                    } else {
                        $('#divSummaryDonationRow').hide();
                        $('#divSummaryDonationPrice').html('');
                    }
                }
            } else {
                $('#MinimumDonationAmount').prop("disabled", false);
                $("#divOtherAmount").show();
                $('#divSummaryDonationRow').hide();
                $('#divSummaryDonationPrice').html('');
            }

            setTotal();
        });

        $('input[name=MinimumDonationAmount]').on('change', function () {
            if ($('#SelectedDonationAmount').find('option:selected').val() === "OTHER|0") {

                if ($.isNumeric($('#MinimumDonationAmount').val())) {
                    $('#divSummaryDonationPrice').html('$' + parseFloat($('#MinimumDonationAmount').val(), 10).toFixed(2));
                    $('#divSummaryDonationRow').show();
                } else {
                    $('#divSummaryDonationRow').hide();
                    $('#divSummaryDonationPrice').html('');
                }

                setTotal();
            }
        });

        //$('#IncludeAar').change(function () {
        //    if ($('#IncludeAar').is(":checked")) {
        //        $('#renewv2-form-data_summary_aar').html("YES");
        //    } else {
        //        $('#renewv2-form-data_summary_aar').html("NO");
        //    }
        //});

        $('#ShowPayment').change(function () {
            if ($(this).is(":checked")) {
                $("#divPayment").css({ "visibility": "visible" });
                $("#divPayment").css({ "display": "block" });
                $("#ShowStripeForm").val('True');
            } else {
                $("#divPayment").css({ "visibility": "hidden" });
                $("#divPayment").css({ "display": "none" });
                $("#ShowStripeForm").val('False');
            }
        });

        var setMem = (isAnnual, isMonthly, isDomestic, isInternational, isSenior, membershipOptions) => {
            var retVal = 0.0;
            var currAnnual;

            if (isInternational) {
                currAnnual = membershipOptions.filter(o => {
                    return o.isInternational === true;
                })[0];

            } else {
                currAnnual = membershipOptions.filter(o => {
                    return o.isDomestic === true && o.isAnnual === true && o.isSenior === isSenior;
                })[0];
            }

            if (isAnnual) {
                $("#renewv2-form-data_summary_membership").html(currAnnual.summaryDescription);
                $("#spHeaderMembership").html(currAnnual.summaryDescription);
                $("#renewv2-form-data_summary_membership_price").html('$' + currAnnual.price.toFixed(2));
                $("#MembershipBundleSelected").val(currAnnual.bundleCode);
                $("#MembershipProductSelected").val(currAnnual.productCode);
                retVal += currAnnual.price;
            }

            return retVal;
        };

        var setAddOn = (field) => {
            var retVal = 0.0;

            if ($('#' + field).is(":checked")) {
                var price = parseFloat($('#' + field + 'Price').val());
                retVal = price;
                $('#div' + field).show();
            } else {
                $('#div' + field).hide();
            }


            return retVal;
        };

        var setIpf = (isDomestic) => {
            var retVal = 0.0;

            if (isDomestic) {
                $('#renewv2-form-data_summary_ipf_wrapper').hide();
            } else {
                if ($('#SelectedMagazineOption').val().indexOf("-PRINT-") >= 0) {
                    retVal = parseFloat($('#InternationalPrintFeeSummaryPrice').val());
                    $('#renewv2-form-data_summary_ipf_wrapper').show();
                } else {
                    $('#renewv2-form-data_summary_ipf_wrapper').hide();
                }
            }

            return retVal;
        };

        var setIpfAddOn = (field, isDomestic) => {
            var ipf = parseFloat($('#InternationalPrintFeeSummaryPrice').val());
            var retVal = 0.0;

            if (isDomestic) {
                $('.' + field).hide();
            } else {
                if ($('#' + field).is(":checked")) {
                    retVal = ipf;
                    $('.' + field + '-ipf').show();
                } else {
                    $('.' + field + '-ipf').hide();
                }
            }

            return retVal;
        };

        var setPps = (ppsAnnualOptions) => {
            var retVal = 0.0;

            var currentSelection = $('#SelectedPpsOption').val();
            
            ppsAnnualOptions.forEach((item) => {
                if (item.productCode === currentSelection) {
                    retVal = item.price;
                    $("#renewv2-form-data_summary_pps").html(item.summaryDescription);
                    $("#renewv2-form-data_summary_pps_price").html('$' + retVal.toFixed(2));
                }
            });

            if (currentSelection) {
                $("#renewv2-form-data_summary_pps_wrapper").show();
            } else {
                $("#renewv2-form-data_summary_pps_wrapper").hide();
            }

            return retVal;
        };

        var setDonation = () => {
            var retVal = 0.0;
            var option = $('#SelectedDonationAmount').find('option:selected').val();
            if (option !== "OTHER|0") {
                var amount = option.split("|");
                retVal = parseFloat(amount[1], 10);
            } else {
                if ($.isNumeric($('#MinimumDonationAmount').val())) {
                    retVal = parseFloat($('#MinimumDonationAmount').val(), 10);
                }

            }

            return retVal;
        };

        var setAAR = (total) => {

            if ($('#ShowAar').val() === 'True') {
                var curText = $('.aopa-annual-membership-price').html();

                var newText = '$' + parseFloat(total, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();

                if (curText != newText) {
                    $('.aopa-annual-membership-price').html(newText);
                    $('input[type="checkbox"][id="IncludeAar"]').prop("checked", false).change();
                }
            }
        };

        var setDiscount = (isAnnual, isDomestic, isInternational, isSenior, membershipOptions) => {
            var retVal = 0.0;
            var curMembership;

            if (isInternational) {
                curMembership = membershipOptions.filter(o => {
                    return o.isInternational === true;
                })[0];

            } else {
                curMembership = membershipOptions.filter(o => {
                    return o.isDomestic === true && o.isAnnual === true && o.isSenior === isSenior;
                })[0];
            }


            if ($("#DiscountPercentage").val()) {
                var discAnnAmount =
                    Math.round((curMembership.price * parseFloat($("#DiscountPercentage").val()) / 100) * 100) / 100;

                if (isAnnual && curMembership.productCode === $('#DiscountProduct').val()) {
                    $("#DiscountAmount").val(discAnnAmount);
                    $("#DiscountAmountString").val("-$" + discAnnAmount.toFixed(2));
                    $("#renewv2-form-data_summary_discount_amount").html("-$" + discAnnAmount.toFixed(2));
                    $("#divSummmaryDiscountDescription").html("Discount");
                    $("#DiscountDescription").val("Discount");
                    $("#renewv2-form-data_summary_discount_wrapper").show();
                    retVal -= discAnnAmount;
                }
                if (isAnnual && curMembership.productCode !== $('#DiscountProduct').val()) {
                    $("#DiscountAmount").val(0);
                    $("#DiscountAmountString").val("");
                    $("#renewv2-form-data_summary_discount_amount").html("");
                    $("#divSummmaryDiscountDescription").html("");
                    $("#DiscountDescription").val("");
                    $("#renewv2-form-data_summary_discount_wrapper").hide();
                }
            } else {
                $("#DiscountAmount").val(0);
                $("#DiscountAmountString").val("");
                $("#renewv2-form-data_summary_discount_amount").html("");
                $("#divSummmaryDiscountDescription").html("");
                $("#DiscountDescription").val("");
                $("#renewv2-form-data_summary_discount_wrapper").hide();
            }

            return retVal;
        };


        var setTotal = () => {
            var oldTotal = parseFloat($("#OrderTotal").val());


            var membershipOptions = JSON.parse($('#divMembershipOptions').text());
            var ppsAnnualOptions = JSON.parse($('#divPpsAnnualOptions').text());
            var isDomestic = ($('#IsDomestic').val().toUpperCase() === "TRUE");
            var isInternational = ($('#IsInternational').val().toUpperCase() === "TRUE");
            var isSenior = ($('#Is75Plus').val().toUpperCase() === "TRUE");
            var isAnnual = true;
            var isMonthly = false;

            var total = 0.0;
            total += setMem(isAnnual, isMonthly, isDomestic, isInternational, isSenior, membershipOptions);
            total += setIpf(isDomestic);
            total += setAddOn('AddOnPilotPrint');
            total += setAddOn('AddOnPilotDigital');
            total += setAddOn('AddOnTurbinePilotPrint');
            total += setAddOn('AddOnTurbinePilotDigital');
            total += setAddOn('AddOnFtPrint');
            total += setAddOn('AddOnFtDigital');
            total += setIpfAddOn('AddOnPilotPrint',isDomestic);
            total += setIpfAddOn('AddOnTurbinePilotPrint',isDomestic);
            total += setIpfAddOn('AddOnFtPrint',isDomestic);
            total += setPps(ppsAnnualOptions);
            setAAR(total);
            total += setDiscount(isAnnual, isDomestic, isInternational, isSenior, membershipOptions);
            $("#spHeaderPrice").html("$" + total.toFixed(2));

            total += setDonation();

            $("#renewv2-form-data_summary_total").html("$" + total.toFixed(2));
            $("#OrderTotal").val(total);
        };

        var checkCardFields = () => {
            var isValid = false;

            if ($('#ShowStripeForm').val() === "True") {

                var cardElement = document.querySelector('#card-element');

                isValid = cardElement.classList.contains('StripeElement--complete');

                var brand = $('#CardBrand').val();

                if (isValid &&
                    (brand === 'visa' || brand === 'mastercard' || brand === 'amex' || brand === 'discover')) {
                    $('#card-errors').html('');
                } else {
                    if ($('#card-errors').is(':empty')) {
                        $('#card-errors').html('Please check your credit card information.');
                    }
                }

            } else {
                isValid = true;
            }


            return isValid;
        }

        $(".submitButtonRFV2").click(function () {

            var ccValid = checkCardFields();

            if (theForm.valid() && ccValid) {

                var progressBar = new Sfpb_progressBar();
                $('#btnRenew').hide();
                $(this).hide();
                $('body').addClass('progressbar-modal');
                progressBar.start();
                $(".clsSubmitButton").append('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');


                if ($('#ShowStripeForm').val() === "True") {
                    rfStripe.createToken(rfCardElement, { name: $("#CardName").val() })
                        .then(function(result) {
                            if (result.token !== null) {
                                $("#CardToken").val(JSON.stringify(result.token));
                                if (!isDowngrade()) {
                                    theForm.submit();
                                } else {
                                    showAOPAModal('renewalModal');
                                }
                            } else {
                                if ($('#card-errors').is(':empty')) {
                                    $('#card-errors').html('Please check your credit card information.');
                                }
                                var spinner = document.getElementById('aopaSpinner');
                                spinner.parentElement.removeChild(spinner);
                                $(this).show();
                                progressBar.kill();
                                $('body').removeClass('progressbar-modal');
                                $('#btnRenew').show();
                            }
                        });
                } else {
                    if (!isDowngrade()) {
                        theForm.submit();
                    } else {
                        showAOPAModal('renewalModal');
                    }
                }
            } else {
                var firstInstance = $('.input-validation-error').first();
                var offsetTop = firstInstance.offset().top - 100;

                $('html, body').animate({
                    scrollTop: offsetTop
                }, 500);

            }
        });

        function isDowngrade() {
            var selOption = $('#SelectedPpsOption').val();

            switch ($('#CurrentPpsCode').val()) {
                case 'PPS-PLUS':
                    if (selOption !== 'PPS-PLUS') {
                        return true;
                    }
                   
                    break;
                case 'PPS-BASIC':
                    if (selOption === '') {
                        return true;
                    }
                    break;
            }
            return false;
        }
    }
});