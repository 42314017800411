// AOPA Aviation Finance Form
//Creating the click function to show the form
var showHiddenForm = function(){
	$(".hiddenFinanceForm").slideDown(400);
	$(".activateForm").unbind("click", showHiddenForm); //unbind the click function
	if($(window).width() >= 500){
		$(".visibleFinanceForm__wrapper .ctaBtns button:first-child").css({"background-color":"#00a3e0", "color":"#ffffff", "float":"right"}).removeClass("activateForm");
	} else {
		$(".visibleFinanceForm__wrapper .ctaBtns button:first-child").css({"background-color":"#00a3e0", "color":"#ffffff"}).removeClass("activateForm");
	}
	findFormSize();
}
//bind the click function to the button 
$(".activateForm").bind( "click", showHiddenForm);
//close the form is mouse clicked anywhere outside of it's wrapper
$(document).mouseup(function (e)
{
    var container = $(".hiddenFinanceForm");

    if (!container.is(e.target) // if the target of the click isn't the container...
        && container.has(e.target).length === 0) // ... nor a descendant of the container
    {
        container.slideUp(400);
		//bind the click function when the form closes and re-add it's styles
		$(".activateForm").bind( "click", showHiddenForm); 
		
		if($(window).width() >= 500){
			$(".visibleFinanceForm__wrapper .ctaBtns button:first-child").css({"background-color":"transparent", "color":"#00a3e0", "float":"right"}).addClass("activateForm");
		} else {
			$(".visibleFinanceForm__wrapper .ctaBtns button:first-child").css({"background-color":"transparent", "color":"#00a3e0"}).addClass("activateForm");
		}
    }
});

// Listen to message from child IFrame window
function findFormSize(){
	// Here "addEventListener" is for standards-compliant web browsers and "attachEvent" is for IE Browsers.
	var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
	var eventer = window[eventMethod];
	
	// Now...
	// if 
	//    "attachEvent", then we need to select "onmessage" as the event. 
	// if 
	//    "addEventListener", then we need to select "message" as the event
	
	var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
	
	eventer(messageEvent, function (e) {
		   console.log(e.data);
		   var financeFormHeight = e.data;
		   
		   //find the scroll height and dynamically change the iframe height
			$("#frameId").height(financeFormHeight + 100);
			//When the form is completed it sends a height of 0 to AOPA.org so that the user 
			//is scrolled to the top of the page to read the thankyou message. Then a new post 
			//message is sent as the thankyou page loads and runs the parent function again.
			if(financeFormHeight == 0){
				$('html,body').animate({ scrollTop: 0 }, 'slow');
				findFormSize();
				console.log("the form height is 0 which means the user clicked submit");
			}
	}, false);
};

//resize the iframe when page loads and resizes
$(window).on("load resize",function() {
	findFormSize();
	});

//JSON script of makes and models of planes turned into an object
var jsonData = {
    "Planes": [
 {
   "ID": 1,
   "Make": "Aeronca",
   "Model": "Champ",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 2,
   "Make": "Aerostar",
   "Model": "600",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 3,
   "Make": "Aerostar",
   "Model": "601",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 4,
   "Make": "Aerostar",
   "Model": "602",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 5,
   "Make": "Aerostar",
   "Model": "700",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 6,
   "Make": "Agusta",
   "Model": "Koala A119 KE",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 7,
   "Make": "Agusta",
   "Model": "A109",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 8,
   "Make": "Agusta",
   "Model": "Aw139",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 9,
   "Make": "AMD",
   "Model": "Zodiac XL",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 10,
   "Make": "American Champion",
   "Model": "Citabria",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 11,
   "Make": "American Champion",
   "Model": "Scout",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 12,
   "Make": "American Champion",
   "Model": "Decathlon",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 13,
   "Make": "American General",
   "Model": "Yankee",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 14,
   "Make": "American General",
   "Model": "Cheetah",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 15,
   "Make": "American General",
   "Model": "Tiger",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 16,
   "Make": "American General",
   "Model": "Cougar",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 17,
   "Make": "American Legend",
   "Model": "Legend Cub",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 18,
   "Make": "Aviat",
   "Model": "Husky",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 19,
   "Make": "Aviat",
   "Model": "Pitts S2B",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 20,
   "Make": "Beech",
   "Model": "Skipper 77",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 21,
   "Make": "Beech",
   "Model": "Sport 19",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 22,
   "Make": "Beech",
   "Model": "Musketeer",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 23,
   "Make": "Beech",
   "Model": "Sundowner",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 24,
   "Make": "Beech",
   "Model": "Sierra",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 25,
   "Make": "Beech",
   "Model": "Debonair",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 26,
   "Make": "Beech",
   "Model": "Bonanza 33",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 27,
   "Make": "Beech",
   "Model": "Bonanza 35",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 28,
   "Make": "Beech",
   "Model": "Bonanza A36",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 29,
   "Make": "Beech",
   "Model": "Twin Bonanza",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 30,
   "Make": "Beech",
   "Model": "Duchess 76",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 31,
   "Make": "Beech",
   "Model": "Travel Air",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 32,
   "Make": "Beech",
   "Model": "Baron 55",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 33,
   "Make": "Beech",
   "Model": "Baron 58",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 34,
   "Make": "Beech",
   "Model": "Duke 60",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 35,
   "Make": "Beech",
   "Model": "Model 18",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 36,
   "Make": "Beech",
   "Model": "Queen Air",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 37,
   "Make": "Beech",
   "Model": "King Air 90",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 38,
   "Make": "Beech",
   "Model": "King Air A 100",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 39,
   "Make": "Beech",
   "Model": "King Air B 200",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 40,
   "Make": "Beech",
   "Model": "King Air 250",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 41,
   "Make": "Beech",
   "Model": "King Air 300",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 42,
   "Make": "Beech",
   "Model": "King Air 350",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 43,
   "Make": "Beech",
   "Model": "Premier I 390",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 44,
   "Make": "Beech",
   "Model": "Beechjet 400 ",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 45,
   "Make": "Bell",
   "Model": "Jetranger",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 46,
   "Make": "Bell",
   "Model": "Longranger ",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 47,
   "Make": "Bell",
   "Model": "407",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 48,
   "Make": "Bell",
   "Model": "212",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 49,
   "Make": "Bell",
   "Model": "222",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 50,
   "Make": "Bell",
   "Model": "230",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 51,
   "Make": "Bell",
   "Model": "412",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 52,
   "Make": "Bell",
   "Model": "427",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 53,
   "Make": "Bell",
   "Model": "429",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 54,
   "Make": "Bell",
   "Model": "430",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 55,
   "Make": "Bellanca",
   "Model": "Viking",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 56,
   "Make": "Bellanca",
   "Model": "Super Viking",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 57,
   "Make": "Boeing",
   "Model": "Stearman",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 58,
   "Make": "Bombardier",
   "Model": "Challenger 600",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 59,
   "Make": "Bombardier",
   "Model": "Challenger 300",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 60,
   "Make": "Bombardier",
   "Model": "Challenger 601 ",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 61,
   "Make": "Bombardier",
   "Model": "Challenger 604",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 62,
   "Make": "Bombardier",
   "Model": "Challenger 605",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 63,
   "Make": "Bombardier",
   "Model": "Challenger 850",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 64,
   "Make": "Bombardier",
   "Model": "Global Express ",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 65,
   "Make": "Bombardier",
   "Model": "Global 6000",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 66,
   "Make": "Cessna",
   "Model": "120/140",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 67,
   "Make": "Cessna",
   "Model": "150 /152",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 68,
   "Make": "Cessna",
   "Model": "162 Skycatcher",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 69,
   "Make": "Cessna",
   "Model": "170",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 70,
   "Make": "Cessna",
   "Model": "172 Skyhawk",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 71,
   "Make": "Cessna",
   "Model": "175 Skylark",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 72,
   "Make": "Cessna",
   "Model": "177 Cardinal",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 73,
   "Make": "Cessna",
   "Model": "180 Skywagon",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 74,
   "Make": "Cessna",
   "Model": "182 Skylane",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 75,
   "Make": "Cessna",
   "Model": "185 Skywagon",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 76,
   "Make": "Cessna",
   "Model": "188 Ag Wagon",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 77,
   "Make": "Cessna",
   "Model": "190/195",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 78,
   "Make": "Cessna",
   "Model": "205",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 79,
   "Make": "Cessna",
   "Model": "206 Stationair",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 80,
   "Make": "Cessna",
   "Model": "207 Skywagon",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 81,
   "Make": "Cessna",
   "Model": "208 Caravan",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 82,
   "Make": "Cessna",
   "Model": "210 Centurion",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 83,
   "Make": "Cessna",
   "Model": "300-400 Corvalis",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 84,
   "Make": "Cessna",
   "Model": "TTX",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 85,
   "Make": "Cessna",
   "Model": "303 Crusader",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 86,
   "Make": "Cessna",
   "Model": "310",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 87,
   "Make": "Cessna",
   "Model": "320 Skyknight",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 88,
   "Make": "Cessna",
   "Model": "337 Skymaster",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 89,
   "Make": "Cessna",
   "Model": "340",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 90,
   "Make": "Cessna",
   "Model": "401",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 91,
   "Make": "Cessna",
   "Model": "402",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 92,
   "Make": "Cessna",
   "Model": "404 Titan",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 93,
   "Make": "Cessna",
   "Model": "411",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 94,
   "Make": "Cessna",
   "Model": "414 Chancellor",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 95,
   "Make": "Cessna",
   "Model": "421 Golden Eagle",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 96,
   "Make": "Cessna",
   "Model": "425 Conquest I",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 97,
   "Make": "Cessna",
   "Model": "441 Conquest II",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 98,
   "Make": "Cessna",
   "Model": "CJ",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 99,
   "Make": "Cessna",
   "Model": "CJ 2",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 100,
   "Make": "Cessna",
   "Model": "CJ 3",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 101,
   "Make": "Cessna",
   "Model": "CJ 4",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 102,
   "Make": "Cessna",
   "Model": "Citation Mustang ",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 103,
   "Make": "Cessna",
   "Model": "Citation M2",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 104,
   "Make": "Cessna",
   "Model": "Citation I",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 105,
   "Make": "Cessna",
   "Model": "Citation II",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 106,
   "Make": "Cessna",
   "Model": "Citation III",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 107,
   "Make": "Cessna",
   "Model": "Citation V",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 108,
   "Make": "Cessna",
   "Model": "Citation VI",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 109,
   "Make": "Cessna",
   "Model": "Citation VII",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 110,
   "Make": "Cessna",
   "Model": "Citation SII",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 111,
   "Make": "Cessna",
   "Model": "Citation X",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 112,
   "Make": "Cessna",
   "Model": "Citation Bravo",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 113,
   "Make": "Cessna",
   "Model": "Citation Encore",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 114,
   "Make": "Cessna",
   "Model": "Citation Excel",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 115,
   "Make": "Cessna",
   "Model": "Citation Ultra",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 116,
   "Make": "Cessna",
   "Model": "Citation Sovereign",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 117,
   "Make": "Cirrus",
   "Model": "SR20",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 118,
   "Make": "Cirrus",
   "Model": "SR22",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 119,
   "Make": "Cirrus",
   "Model": "Vision Jet",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 120,
   "Make": "Commander",
   "Model": "112",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 121,
   "Make": "Commander",
   "Model": "114",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 122,
   "Make": "Cubcrafters",
   "Model": "Top Cub",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 123,
   "Make": "Cubcrafters",
   "Model": "Sport Cub S2",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 124,
   "Make": "Cubcrafters",
   "Model": "Carbon Cub",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 125,
   "Make": "Cubcrafters",
   "Model": "Xcub",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 126,
   "Make": "Cruiser Aircraft",
   "Model": "SportCruiser",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 334,
   "Make": "De Havilland",
   "Model": "Beaver",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 335,
   "Make": "De Havilland",
   "Model": "Chipmunk",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
},
{
   "ID": 127,
   "Make": "Diamond",
   "Model": "DA20",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 128,
   "Make": "Diamond",
   "Model": "DA40",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 129,
   "Make": "Diamond",
   "Model": "DA42",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 130,
   "Make": "Diamond",
   "Model": "DA62",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 131,
   "Make": "Eclipse",
   "Model": "500/550",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 132,
   "Make": "Embraer",
   "Model": "Phenom 100",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 133,
   "Make": "Embraer",
   "Model": "Phenom 300",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 134,
   "Make": "Embraer",
   "Model": "Legacy ",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 135,
   "Make": "Ercoupe",
   "Model": "Ercoupe",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 136,
   "Make": "Eurocopter",
   "Model": "Astar AS350 ",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 137,
   "Make": "Eurocopter",
   "Model": "EC120",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 138,
   "Make": "Eurocopter",
   "Model": "EC130 ",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 139,
   "Make": "Eurocopter",
   "Model": "Twinstar AS355",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 140,
   "Make": "Eurocopter",
   "Model": "Dauphin AS365",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 141,
   "Make": "Eurocopter",
   "Model": "BK117",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 142,
   "Make": "Eurocopter",
   "Model": "EC135",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 143,
   "Make": "Eurocopter",
   "Model": "EC145",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 144,
   "Make": "Eurocopter",
   "Model": "EC155",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 145,
   "Make": "Eurofox",
   "Model": "Eurofox",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 146,
   "Make": "Evektor",
   "Model": "Sportstar",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 147,
   "Make": "Experimental",
   "Model": "Air Cam",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 148,
   "Make": "Experimental",
   "Model": "Christen Eagle",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 149,
   "Make": "Experimental",
   "Model": "Cozy",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 150,
   "Make": "Experimental",
   "Model": "GlaStar",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 151,
   "Make": "Experimental",
   "Model": "Harmon Rocket",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 152,
   "Make": "Experimental",
   "Model": "SeaRey",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 153,
   "Make": "Experimental",
   "Model": "Skybolt",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 154,
   "Make": "Experimental",
   "Model": "Sonex",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 155,
   "Make": "Experimental",
   "Model": "Zenith",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 156,
   "Make": "Experimental",
   "Model": "Zodiac",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 157,
   "Make": "Experimental",
   "Model": "Other",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 158,
   "Make": "Fairchild",
   "Model": "Merlin II",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 159,
   "Make": "Fairchild",
   "Model": "Merlin III",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 160,
   "Make": "Fairchild",
   "Model": "Merlin IV",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 161,
   "Make": "Fairchild",
   "Model": "Metro",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 162,
   "Make": "Falcon",
   "Model": "10",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 163,
   "Make": "Falcon",
   "Model": "100",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 164,
   "Make": "Falcon",
   "Model": "20",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 165,
   "Make": "Falcon",
   "Model": "200",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 166,
   "Make": "Falcon",
   "Model": "2000",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 167,
   "Make": "Falcon",
   "Model": "50",
   "Engine": "Turbine",
   "Engine Number": "Tri Engine",
   "Category": "Airplane"
 },
 {
   "ID": 168,
   "Make": "Falcon",
   "Model": "900 -B",
   "Engine": "Turbine",
   "Engine Number": "Tri Engine",
   "Category": "Airplane"
 },
 {
   "ID": 169,
   "Make": "Falcon",
   "Model": "7X",
   "Engine": "Turbine",
   "Engine Number": "Tri Engine",
   "Category": "Airplane"
 },
 {
   "ID": 170,
   "Make": "Flight Design",
   "Model": "CTSW",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 171,
   "Make": "Flight Design",
   "Model": "CTLS",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 172,
   "Make": "Gippsaero",
   "Model": "GA 8",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 173,
   "Make": "Glasair",
   "Model": "Sportsman",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 174,
   "Make": "Glasair",
   "Model": "Legacy",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 175,
   "Make": "Glasair",
   "Model": "Merlin LSA",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 176,
   "Make": "Gobosh",
   "Model": "700S 800XP",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 177,
   "Make": "Gulfstream",
   "Model": "G 159",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 178,
   "Make": "Gulfstream",
   "Model": "Astrajet 1125",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 179,
   "Make": "Gulfstream",
   "Model": "G150",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 180,
   "Make": "Gulfstream",
   "Model": "G200",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 181,
   "Make": "Gulfstream",
   "Model": "G280",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 182,
   "Make": "Gulfstream",
   "Model": "GII",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 183,
   "Make": "Gulfstream",
   "Model": "GIII",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 184,
   "Make": "Gulfstream",
   "Model": "GIV",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 185,
   "Make": "Gulfstream",
   "Model": "G350/G450",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 186,
   "Make": "Gulfstream",
   "Model": "GV",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 187,
   "Make": "Gulfstream",
   "Model": "G500/G550",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 188,
   "Make": "Gulfstream",
   "Model": "G650",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 189,
   "Make": "Hawker",
   "Model": "Beechcraft 125",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 190,
   "Make": "Hawker",
   "Model": "Beechcraft 125-400 ",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 191,
   "Make": "Hawker",
   "Model": "Beechcraft 125-600 ",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 192,
   "Make": "Hawker",
   "Model": "Beechcraft 125-700 ",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 193,
   "Make": "Hawker",
   "Model": "Beechcraft 750",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 194,
   "Make": "Hawker",
   "Model": "Beechcraft 125-800",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 195,
   "Make": "Hawker",
   "Model": "Beechcraft 900XP",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 196,
   "Make": "Hawker",
   "Model": "Beechcraft 125-1000",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 197,
   "Make": "Hawker",
   "Model": "Beechcraft 4000",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 198,
   "Make": "IAI",
   "Model": "1121 A/B",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 199,
   "Make": "IAI",
   "Model": "Westwind 1123",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 200,
   "Make": "IAI",
   "Model": "Westwind 1124",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 336,
   "Make": "Icon",
   "Model": "A5",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },     
 {
   "ID": 201,
   "Make": "Jabiru",
   "Model": "Jabiru",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 202,
   "Make": "Kitfox",
   "Model": "Supersport",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 203,
   "Make": "Lake",
   "Model": "Buccaneer",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 204,
   "Make": "Lake",
   "Model": "Renegade",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 205,
   "Make": "Lancair",
   "Model": "320-360",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 206,
   "Make": "Lancair",
   "Model": "IV",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 207,
   "Make": "Lancair",
   "Model": "Legacy",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 208,
   "Make": "Lancair",
   "Model": "ES",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 209,
   "Make": "Lancair",
   "Model": "Evolution",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 210,
   "Make": "Lancair",
   "Model": "Evolution (Turbine)",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 211,
   "Make": "Learjet",
   "Model": "23",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 212,
   "Make": "Learjet",
   "Model": "24",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 213,
   "Make": "Learjet",
   "Model": "25",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 214,
   "Make": "Learjet",
   "Model": "31",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 215,
   "Make": "Learjet",
   "Model": "35/36",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 216,
   "Make": "Learjet",
   "Model": "40",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 217,
   "Make": "Learjet",
   "Model": "45",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 218,
   "Make": "Learjet",
   "Model": "55",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 219,
   "Make": "Learjet",
   "Model": "60",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 220,
   "Make": "Learjet",
   "Model": "70 75",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 221,
   "Make": "Liberty",
   "Model": "Xl2",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 222,
   "Make": "Lockheed",
   "Model": "Jetstar II",
   "Engine": "Turbine",
   "Engine Number": "Quad Engine",
   "Category": "Airplane"
 },
 {
   "ID": 223,
   "Make": "Lockheed",
   "Model": "Jetstar 6/8",
   "Engine": "Turbine",
   "Engine Number": "Quad Engine",
   "Category": "Airplane"
 },
 {
   "ID": 224,
   "Make": "Lockheed",
   "Model": "Jetstar 731 II",
   "Engine": "Turbine",
   "Engine Number": "Quad Engine",
   "Category": "Airplane"
 },
 {
   "ID": 225,
   "Make": "Luscombe",
   "Model": "Silvaire 8 ",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 226,
   "Make": "Maule",
   "Model": "M-4/5",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 227,
   "Make": "Maule",
   "Model": "M-6/7",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 228,
   "Make": "MD",
   "Model": "500",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 229,
   "Make": "MD",
   "Model": "520",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 230,
   "Make": "MD",
   "Model": "530",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 231,
   "Make": "MD",
   "Model": "600",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 232,
   "Make": "MD",
   "Model": "Explorer 902",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 233,
   "Make": "Meyers",
   "Model": "200 A-D",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 234,
   "Make": "Mitsubishi",
   "Model": "MU-2",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 235,
   "Make": "Mitsubishi",
   "Model": "MU-300",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 236,
   "Make": "Mooney",
   "Model": "Cadet M10",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 237,
   "Make": "Mooney",
   "Model": "Mite M18",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 238,
   "Make": "Mooney",
   "Model": "M20A-F",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 239,
   "Make": "Mooney",
   "Model": "M20J",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 240,
   "Make": "Mooney",
   "Model": "M20K",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 241,
   "Make": "Mooney",
   "Model": "M20L",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 242,
   "Make": "Mooney",
   "Model": "TLS",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 243,
   "Make": "Mooney",
   "Model": "Ovation",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 244,
   "Make": "Mooney",
   "Model": "Acclaim",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 245,
   "Make": "Mooney",
   "Model": "Eagle",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 246,
   "Make": "Mooney",
   "Model": "Mustang M22",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 247,
   "Make": "Navion",
   "Model": "Rangemaster",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 248,
   "Make": "Nextant",
   "Model": "400XTI",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 249,
   "Make": "North American",
   "Model": "Trojan T28",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 250,
   "Make": "North American",
   "Model": "T6",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 251,
   "Make": "Paradise",
   "Model": "P1",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 252,
   "Make": "Piaggio",
   "Model": "Avanti P180",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 253,
   "Make": "Pilatus",
   "Model": "PC 12",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 254,
   "Make": "Pilatus",
   "Model": "PC 24",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 255,
   "Make": "Piper",
   "Model": "Sport",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 256,
   "Make": "Piper",
   "Model": "J3 Cub",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 257,
   "Make": "Piper",
   "Model": "Super Cruiser",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 258,
   "Make": "Piper",
   "Model": "Super Cub",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 259,
   "Make": "Piper",
   "Model": "Tomahawk",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 260,
   "Make": "Piper",
   "Model": "Tri-Pacer",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 261,
   "Make": "Piper",
   "Model": "Comanche",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 262,
   "Make": "Piper",
   "Model": "Pawnee/Brave",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 263,
   "Make": "Piper",
   "Model": "Cherokee",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 264,
   "Make": "Piper",
   "Model": "Warrior",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 265,
   "Make": "Piper",
   "Model": "Archer",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 266,
   "Make": "Piper",
   "Model": "Arrow",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 267,
   "Make": "Piper",
   "Model": "Dakota",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 268,
   "Make": "Piper",
   "Model": "Cherokee Six",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 269,
   "Make": "Piper",
   "Model": "Lance",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 270,
   "Make": "Piper",
   "Model": "6X",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 271,
   "Make": "Piper",
   "Model": "Saratoga",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 272,
   "Make": "Piper",
   "Model": "Malibu",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 273,
   "Make": "Piper",
   "Model": "Malibu Mirage",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 274,
   "Make": "Piper",
   "Model": "Matrix",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 275,
   "Make": "Piper",
   "Model": "Meridian",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 276,
   "Make": "Piper",
   "Model": "Seminole",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 277,
   "Make": "Piper",
   "Model": "Apache",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 278,
   "Make": "Piper",
   "Model": "Aztec",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 279,
   "Make": "Piper",
   "Model": "Twin Comanche",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 280,
   "Make": "Piper",
   "Model": "Seneca",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 281,
   "Make": "Piper",
   "Model": "Navajo",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 282,
   "Make": "Piper",
   "Model": "Navajo Chieftain",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 283,
   "Make": "Piper",
   "Model": "Mojave",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 284,
   "Make": "Piper",
   "Model": "Cheyenne",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 285,
   "Make": "Quest",
   "Model": "Kodiak",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 286,
   "Make": "Remos",
   "Model": "G3 ",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 287,
   "Make": "Robinson",
   "Model": "R22",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 288,
   "Make": "Robinson",
   "Model": "R44",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 289,
   "Make": "Robinson",
   "Model": "R66",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 290,
   "Make": "Rockwell",
   "Model": "Commander 100",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 291,
   "Make": "Sabreliner",
   "Model": "40",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 292,
   "Make": "Sabreliner",
   "Model": "60",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 293,
   "Make": "Sabreliner",
   "Model": "65",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 294,
   "Make": "Sabreliner",
   "Model": "75",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 295,
   "Make": "Sabreliner",
   "Model": "75",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 296,
   "Make": "Sikorsky",
   "Model": "300C 269C",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 297,
   "Make": "Sikorsky",
   "Model": "333",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 298,
   "Make": "Sikorsky",
   "Model": "S-76 ",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Helicopter"
 },
 {
   "ID": 299,
   "Make": "Socata",
   "Model": "Tampico",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 300,
   "Make": "Socata",
   "Model": "Tobago",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 301,
   "Make": "Socata",
   "Model": "Trinidad",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 302,
   "Make": "Socata",
   "Model": "TBM 700",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 303,
   "Make": "Socata",
   "Model": "TBM 850",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 304,
   "Make": "Socata",
   "Model": "TBM 930",
   "Engine": "Turbine",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 305,
   "Make": "Stingsport",
   "Model": "Stingsport",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 306,
   "Make": "Stinson",
   "Model": "108",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 353,
   "Make": "Super Petrel USA",
   "Model": "Super Petrel LS",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },    
 {
   "ID": 307,
   "Make": "Symphony",
   "Model": "SA 160",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 308,
   "Make": "Taylorcraft",
   "Model": "Taylorcraft",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 309,
   "Make": "Tecnam",
   "Model": "Astore",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 310,
   "Make": "Tecnam",
   "Model": "Tecnam",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 311,
   "Make": "Tecnam",
   "Model": "P92",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 312,
   "Make": "Tecnam",
   "Model": "P2002",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 313,
   "Make": "Tecnam",
   "Model": "P2006",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 314,
   "Make": "Tecnam",
   "Model": "P2008",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 315,
   "Make": "Tecnam",
   "Model": "P2010",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 316,
   "Make": "Tecnam",
   "Model": "P2012",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 317,
   "Make": "Tiger",
   "Model": "AG5B",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 318,
   "Make": "Twin Commander",
   "Model": "500",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 319,
   "Make": "Twin Commander",
   "Model": "Shrike 500 S",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 320,
   "Make": "Twin Commander",
   "Model": "520",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 321,
   "Make": "Twin Commander",
   "Model": "560",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 322,
   "Make": "Twin Commander",
   "Model": "680",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 323,
   "Make": "Twin Commander",
   "Model": "681 /B",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 324,
   "Make": "Twin Commander",
   "Model": "685",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 325,
   "Make": "Twin Commander",
   "Model": "Fuji 700",
   "Engine": "Piston",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 326,
   "Make": "Twin Commander",
   "Model": "690",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 327,
   "Make": "Twin Commander",
   "Model": "840",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 328,
   "Make": "Twin Commander",
   "Model": "900",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 329,
   "Make": "Twin Commander",
   "Model": "980",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 330,
   "Make": "Twin Commander",
   "Model": "1000",
   "Engine": "Turbine",
   "Engine Number": "Twin Engine",
   "Category": "Airplane"
 },
 {
   "ID": 331,
   "Make": "Vans",
   "Model": "RV 3-10",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 332,
   "Make": "Vans",
   "Model": "RV 12 LSA",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 333,
   "Make": "Varga",
   "Model": "Kachina 2150",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 337,
   "Make": "Other",
   "Model": "BearHawk LSA",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 338,
   "Make": "Other",
   "Model": "Extra 300-330",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 339,
   "Make": "Other",
   "Model": "Great Lakes",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 340,
   "Make": "Other",
   "Model": "Hatz Biplane",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 341,
   "Make": "Other",
   "Model": "Just Aircraft",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 342,
   "Make": "Other",
   "Model": "Nanchang",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 343,
   "Make": "Other",
   "Model": "Other",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 344,
   "Make": "Other",
   "Model": "Rans",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 345,
   "Make": "Other",
   "Model": "Sling",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 346,
   "Make": "Other",
   "Model": "Stinson",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 347,
   "Make": "Other",
   "Model": "T-34",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 348,
   "Make": "Other",
   "Model": "T-6",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 349,
   "Make": "Other",
   "Model": "Thorp",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 350,
   "Make": "Other",
   "Model": "Waco",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 351,
   "Make": "Other",
   "Model": "Yak",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 },
 {
   "ID": 352,
   "Make": "Vashon Aircraft",
   "Model": "Ranger",
   "Engine": "Piston",
   "Engine Number": "Single Engine",
   "Category": "Airplane"
 }
        
]};
//Hide Member Number
$("#memberNumber").hide();
//If member, show member number field
$("#00N6100000BdnRt").change(function (){
	if ($("#00N6100000BdnRt").val() == "Yes"){
		$("#memberNumber").toggle();
	} else {
		$("#memberNumber").hide();
	};
});


//hide other financing needs

$("#otherFinanceNeeds").hide();
//if other type of financing needs, show the field
$("#00N6100000BdnS8").change(function (){
	if ($("#00N6100000BdnS8").val() == "Other"){
		$("#otherFinanceNeeds").toggle();
	} else {
		$("#otherFinanceNeeds").hide();
	};
});
//show the list of make's from the json script
var listItems = '<option selected="selected" value="0">Select Make</option>';
 
for (var i = 0; i < jsonData.Planes.length; i++) {
    listItems += "<option value='" + jsonData.Planes[i].Make + "'>" + jsonData.Planes[i].Make + "</option>";          
};

// clear the model listing if a new make is chosen
$("#00N6100000BdnT6").html(listItems); //id is make
var previousOption;
$('select[name=00N6100000BdnT6] option').each(function() {
    if (this.text == previousOption) $(this).remove();
    previousOption = this.text;
}); 
//show the list of models if a particular make is chosen
$("#00N6100000BdnT6").change(function () { //id is make
    $('#00N6100000BdnTG').empty().append($('<option></option>').val('Select Model').html('Select Model')); //id is model
    var matchVal = $("#00N6100000BdnT6 option:selected").text(); //id is make
    jsonData.Planes.filter(function (plane) {
        if (plane.Make == matchVal) {
            $("#00N6100000BdnTG").append($("<option value ='" + plane.Model + "'></option>").html(plane.Model)); //id is model
			
        }
    });
});
// support the placeholder in IE 7 and 8
(function ($) {
         $.support.placeholder = ('placeholder' in document.createElement('input'));
     })($);


     //fix for IE7 and IE8
     $(function () {
         if (!$.support.placeholder) {
             $("[placeholder]").focus(function () {
                 if ($(this).val() == $(this).attr("placeholder")) $(this).val("");
             }).blur(function () {
                 if ($(this).val() == "") $(this).val($(this).attr("placeholder"));
             }).blur();

             $("[placeholder]").parents("form").submit(function () {
                 $(this).find('[placeholder]').each(function() {
                     if ($(this).val() == $(this).attr("placeholder")) {
                         $(this).val("");
                     }
                 });
             });
         }
		 
	  	

     });
	 
