class ReadMore {
    constructor ($interval) {
        this.$interval = $interval;
        this.restrict = 'A';
        this.scope = {
            readMoreHeight: '@'
        };
        this.transclude = true;
        this.templateUrl = '/js/aopa/shared/templates/read-more.html';
        this.controller = ['$scope', $scope => {
            $scope.$interval = $interval;
        }];
    }

    static directiveFactory ($interval) {
        ReadMore.instance = new ReadMore($interval);
        return ReadMore.instance;
    }

    link ($scope, $element, $attrs) {
        $scope.expanded = false;

        $scope.$interval(function(){
            renderStyles();
        }, 300);

        $scope.expandable = false;

        function renderStyles(){
            if ($element.height() >= $scope.readMoreHeight && $scope.expanded === false){
                $scope.expandable = true;
            }
        }

        $scope.showLessStyle = {
            'max-height': $scope.readMoreHeight + 'px',
            'overflow': 'hidden'
        };
    }
}

ReadMore.directiveFactory.$inject = ['$interval'];

export default ReadMore;
