﻿/*
Custom script for validating CC Expiration Date
*/
(function ($) {
    $.validator.addMethod("ccexpdatechecktwo", function (value, element, params) {

        var patt = new RegExp("^[0-1][0-9]\/[0-9]{4}$");

        if (patt.test(value)) {
            var vals = value.split("/");

            if (parseInt(vals[1]) >= parseInt((new Date).getFullYear()) &&
                parseInt(vals[0]) >= 1 &&
                parseInt(vals[0]) <= 12) {
                if (parseInt(vals[1]) == parseInt((new Date).getFullYear())) {
                    if (parseInt(vals[0]) < ((new Date).getMonth() + 1)) {
                        return false;
                    }
                } 
            } else {
                return false;
            }
        } else {
            return false;
        }

        return true;
    });

    $.validator.unobtrusive.adapters.addBool("ccexpdatechecktwo");
}(jQuery));