﻿$(document)
    .ready(function() {
        if ($('div#divJoinCheckoutControl').length) {

            $(".btnCancel")
                .click(function () {
                    window.location.href = "https://www.aopa.org";
                });


            $(".submitButtonMpf").click(function () {
                var form = $(this).closest('form');
                if (form.valid() && $('#FinalPrice').val() !== "Error") {
                    $(this).hide();
                    $(".clsSubmitButton").append('<div class="spinner spinner--clockwise"></div>');
                    form.submit();
                }
            });



            $(".btnPage2Back")
                .click(function () {
                    window.location.href = $("#BackUrl").val();
                });



            $('#Country').change(function () {

                if ($('#RateCodeHelper').val().length && $('#SelectedRateCode').val().includes("_PRINT")) {

                    var option = $(this).find('option:selected').val();

                    $.getJSON('/api/JoinCheckoutRateHelper/Get/?country=' + option + '&rateCode=' + $('#SelectedRateCode').val() + '&rateHelper=' + $('#RateCodeHelper').val())
                        .done(function (data) {
                            $('#Price').val('$' + parseFloat(data.price).toFixed(2));
                            $('#spPrice').html('$' + parseFloat(data.price).toFixed(2));
                            $('#spRateDescription').html(data.description);
                            $('#SelectedRateCode').val(data.rateCode);
                            var fa = parseFloat(data.price) - parseFloat($('#DiscountAmount').val());
                            $('#FinalPrice').val(fa.toFixed(0));
                            $('#join-total').html('$' + fa.toFixed(2));

                        }).fail(function (jqXHR, textStatus, err) {
                            $('#Price').val('');
                            $('#spPrice').html('Error');
                            $('#FinalPrice').val('Error');
                            $('#spRateDescription').html('Error');
                            $('#join-total').html('Error');
                        });
                }
            });


        }
    });